export const vehicleType = [
  'Single',
  'Double',
  'Semi',
  'CarWithTrailer',
  'SixxSix',
  'HookLift',
] as const;

export type VehicleType = typeof vehicleType[number];
