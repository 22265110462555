import { useEffect, useMemo, useState } from 'react';
import { formatISO } from 'date-fns';
import {
  latestAssignment,
  timeWithinDay,
} from '../../../core/helpers/functions';
import { getTimelineEvent } from '../../../core/helpers/eventRender';
import { canEditData } from '../../../core/helpers/assignmentPermissions';
import { Assignment } from '../../../core/types/assignment';
import { useSignalR } from '../../../core/signalR';
import {
  useGetAssignmentsQuery,
} from '../../../core/redux/transport';
import { useFilteredAssignment } from '../../../core/redux/assignmentFilter';
import { useHasRoles } from '../../../core/msal';

export const useAssignments = (currentDate: Date) => {
  const [hasRole] = useHasRoles();

  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const { data: assignmentData, isFetching: loading, refetch } = useGetAssignmentsQuery(
    formatISO(currentDate, { representation: 'date' }),
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => { refetch(); }, []);

  useEffect(() => {
    if (assignmentData) {
      setAssignments(assignmentData);
    }
  }, [assignmentData, setAssignments]);

  const addAssignments = (newAssignments: Assignment[]) => {
    setAssignments((old) => [
      ...old,
      // eslint-disable-next-line max-len
      ...newAssignments.filter((a) => timeWithinDay(new Date(a.startTime), new Date(a.endTime), currentDate)),
    ]);
  };

  const updateAssignment = (newAssignment: Assignment) => {
    // eslint-disable-next-line max-len
    setAssignments((as) => as.map((a) => (
      a.id === newAssignment.id ? latestAssignment([newAssignment, a]) : a
    )));
  };

  const updateAssignments = (as: Assignment[]) => {
    setAssignments((old) => old.map((oa) => { // oa: Old Assignment
      const na = as.find((a) => a.id === oa.id); // na: New Assignment
      if (na) {
        return latestAssignment([na, oa]);
      }
      return oa;
    }));
  };

  const listeners = useMemo(
    () => [
      { method: 'assignmentCreated', action: addAssignments },
      { method: 'assignmentUpdated', action: updateAssignment },
      { method: 'assignmentStatusUpdated', action: updateAssignment },
      { method: 'assignmentApproved', action: updateAssignments },
    ],
    [currentDate],
  );

  useSignalR({
    url: '/assignment',
    listeners,
  });

  const moveAssignment = (id: string, startTime: Date, endTime: Date, newResource?: string) => {
    setAssignments((as) => as.map((a) => {
      if (`${a.id}` !== `${id}` || !canEditData(a.status)) {
        return a;
      }
      if (newResource) {
        return {
          ...a,
          startTime: formatISO(startTime),
          endTime: formatISO(endTime),
          lastEditTime: formatISO(new Date()),
          vehicle: {
            ...a.vehicle,
            internalNumber: newResource,
          },
        };
      }
      return {
        ...a,
        startTime: formatISO(startTime),
        endTime: formatISO(endTime),
        lastEditTime: formatISO(new Date()),
      };
    }));
  };

  const assignmentFilter = useFilteredAssignment(assignments);

  const events = useMemo(() => (
    !assignments
      ? []
      : assignments.filter((a) => a.status !== 'Deleted')
        .map((a) => getTimelineEvent(
          a,
          !assignmentFilter.some((id) => a.id === id),
          !hasRole('admin', 'container-koordinator', 'transport-koordinator'),
        ))
  ), [assignments, assignmentFilter]);

  return {
    assignments,
    events,
    loading,
    moveAssignment,
  };
};
