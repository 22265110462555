import { useMsal } from '@azure/msal-react';
import { useCallback, useMemo } from 'react';
import { Roles } from './types/roles';

export const useHasRole = (role: Roles) => {
  const { instance, inProgress } = useMsal();
  const a = instance.getActiveAccount();
  let hasRole = false;
  if (a) {
    const { roles } = a?.idTokenClaims as { roles: string[] | undefined };
    hasRole = (roles && roles.indexOf(role) >= 0) ?? false;
  }
  return { hasRole, isAuthenticated: !!a, inProgress };
};

/**
 * Get all currently active roles the user is assigned to.
 *
 * Defaults to an empty array if the user data is not loaded
 */
export const useGetRoles = () => {
  const { instance, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const activeRoles = useMemo(() => (
    activeAccount?.idTokenClaims?.roles ?? [] as string[]
  ), [activeAccount]);

  return [activeRoles, { inProgress, activeAccount }] as const;
};

/**
 * Get a function to easily check if a user has a given role
 */
export const useHasRoles = () => {
  const [activeRoles, { activeAccount, inProgress }] = useGetRoles();

  /** Returns true if the currently logged in used has any of the roles */
  const hasRole = useCallback((...roles: Roles[]) => (
    roles.some((r) => activeRoles.includes(r))
  ), [activeRoles.join(',')]);

  return [hasRole, { isAuthenticated: !!activeAccount, inProgress }] as const;
};

export const useIsCoordinator = () => {
  const { hasRole, isAuthenticated, inProgress } = useHasRole('transport-koordinator');
  return { isCoordinator: hasRole, isAuthenticated, inProgress };
};

export const useIsAdmin = () => {
  const { hasRole, isAuthenticated, inProgress } = useHasRole('admin');
  return { isAdmin: hasRole, isAuthenticated, inProgress };
};
