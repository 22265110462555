import { FormControlLabel, Checkbox } from '@mui/material';

export const AllDayCheckbox = ({
  checked,
  onChange = () => null,
  disabled = false,
}: {
  checked: boolean,
  onChange?: (v: boolean) => void,
  disabled?: boolean,
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      />
        )}
    label="07:00 - 15:00"
  />
);
