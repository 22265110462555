import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  formatISO,
  subDays,
} from 'date-fns';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import './style.scss';
import { useGetUnavailabilitiesQuery } from '../../core/redux/transport';
import { UnavailabilityList } from './partials/UnavailabilityList';

export const UnavailabilityPage = () => {
  const [selection, setSelection] = useState<'Future' | 'Last7Days' | 'Last14Days'>('Future');
  const today = new Date();
  const future = { from: formatISO(today, { representation: 'date' }), to: null };
  const week = { from: formatISO(subDays(today, 7), { representation: 'date' }), to: formatISO(today, { representation: 'date' }) };
  const twoWeeks = { from: formatISO(subDays(today, 14), { representation: 'date' }), to: formatISO(today, { representation: 'date' }) };

  const range = useMemo(() => {
    switch (selection) {
      case 'Last7Days': return week;
      case 'Last14Days': return twoWeeks;
      case 'Future':
      default:
        return future;
    }
  }, [selection]);

  const {
    data: unavailabilities,
    isLoading,
    isFetching,
    refetch,
  } = useGetUnavailabilitiesQuery(range);

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Page className="unavailability-page">
      <Header>Utilgjengelighet</Header>
      <div className="unavailability-wrapper">
        <div className="unavailability-area">
          <div className="header">

            <span className="header-group">
              <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
                <Button variant={selection === 'Future' ? 'contained' : 'outlined'} onClick={() => setSelection('Future')}>Fremtidige</Button>
                <Button variant={selection === 'Last7Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last7Days')}>siste 7 dager</Button>
                <Button variant={selection === 'Last14Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last14Days')}>siste 14 dager</Button>
              </ButtonGroup>
            </span>
            <span className="header-button">
              <ButtonGroup fullWidth aria-label="outlined create button">
                <Button onClick={() => navigate('/utilgjengelighet/opprett')} variant="contained"><AddIcon /></Button>
              </ButtonGroup>
            </span>
          </div>

          <UnavailabilityList
            unavailabilities={unavailabilities}
            isLoading={isLoading || isFetching}
          />
        </div>
      </div>
    </Page>

  );
};
