import React, { FC, useCallback, useMemo } from 'react';
import { Card } from '@mui/material';
import { format } from 'date-fns';
import { AssignmentStatusDriver } from '../../../components/AssignmentStatusDriver';
import { Assignment } from '../../../core/types/assignment';
import { DateTime } from '../../../core/types/dateTime';
import { translateMassType, translateUnavailabilityType } from '../../../core/helpers/translate';
import { autoFormat } from '../../../core/helpers/dates';
import { NOT_DELIVERED_COLOR, STARTED_COLOR } from '../../../shared/constants';
import { formatLoad } from '../../../core/helpers/assignmentHelpers';
import { RegistrationPlate } from '../../../components/RegistrationPlate';
import { useIsType } from '../../../core/hooks/useIsType';

export const AssignmentItem: FC<{
  assignment: Assignment,
  onClick?: (id: number) => void,
}> = (
  {
    assignment,
    onClick = () => null,
  },
) => {
  const isType = useIsType(assignment.type);
  const formatTime = useCallback((time: DateTime) => (format(new Date(time), 'HH:mm')), []);
  const border = useMemo(() => {
    switch (assignment.status) {
      case 'VerifiedNotDelivered':
      case 'NotDelivered':
        return {
          padding: '7px',
          border: `3px solid ${NOT_DELIVERED_COLOR}`,
        };
      case 'Completed':
        return {
          padding: '7px',
          border: `3px solid ${STARTED_COLOR}`,
        };
      default: return { padding: '10px' };
    }
  }, [assignment.status]);
  const background = useMemo(() => {
    switch (assignment.status) {
      case 'Cancelled':
        return {
          backgroundColor: '#e2e2e2',
        };
      default: return {};
    }
  }, [assignment.status]);

  const assignmentTime = useMemo(() => {
    const { startTime, endTime } = assignment;
    if (assignment.status !== 'Unavailable') return `${formatTime(startTime)} - ${formatTime(endTime)}`;

    return `${autoFormat(new Date(startTime), 'yyyy-MM-dd HH:mm')} - ${autoFormat(new Date(endTime), 'yyyy-MM-dd HH:mm')}`;
  }, [assignment]);

  return (
    <Card
      className="assignment-card"
      sx={{ ...border, ...background }}
      onClick={() => onClick(assignment.id)}
    >
      <div className="assignment-item">
        <div className="info-line">
          <span
            className="flexchild flexparent flexcenter flex-gap-10"
          >
            <span className="assignment-title">{assignment.id}</span>
            <span className="assignment-time">
              {assignmentTime}
            </span>
            <span className="assignment-load flexchild one-line-ellipse">
              {assignment.loads.map((l) => formatLoad(l, assignment.type)).join(', ')}
            </span>
          </span>
          <span className="flexparent flex-gap-10">
            <RegistrationPlate number={assignment.vehicle.vehicleRegistrationPlateNumber} />
            <span className="order-type">
              {
              assignment.order
                ? translateMassType(assignment.order)
                : translateUnavailabilityType(assignment)
              }
            </span>
            <AssignmentStatusDriver status={assignment.status} dotonly />
          </span>
        </div>
        <div className="info-line pt3">
          { assignment.status !== 'Unavailable' && assignment.status !== 'VehicleService'
            ? (
              <>
                {!isType('MassInternal') && (
                <span className="flexchild one-line-ellipse project-name">
                  {assignment.fromProject?.id} - {assignment.fromProject?.projectName}
                </span>
                )}
                <span className="flexchild one-line-ellipse project-name">
                  {assignment.toProject?.id} - {assignment.toProject?.projectName}
                </span>
              </>
            ) : (
              <span className="flexchild one-line-ellipse project-name">
                {assignment.status === 'VehicleService' ? `${assignment.orderComment} - ` : ''}{assignment.title}
              </span>
            ) }
        </div>
      </div>
    </Card>
  );
};
