import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Chip,
  Box,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
import { extractData } from '../../core/helpers/functions';
import { getTransportType } from '../../core/helpers/translate';
import { useGetOrderQuery, usePutOrderMutation } from '../../core/redux/transport';
import { OrderStatusIcon } from '../OrderStatusIcon';
import { Modal } from '../../shared/components/modal';
import { UpdateOrder } from '../../core/types/api/updateOrder';
import { order2UpdateOrder } from '../../core/helpers/converters';
import { Button } from '../../shared/components/button';
import { OrderField } from './partials/orderField';
import { LoadPicker, useIsLoadsValid } from '../../LoadPicker';
import { useIsFormValid } from './partials/useIsFormValid';
import { getTypeColor } from '../../core/helpers/eventRender';

export const EditOrderInfo = ({
  orderId,
  open = true,
  onClose = () => null,
}: {
  orderId: number,
  open?: boolean,
  onClose?: () => void,
}) => {
  const { data: order } = useGetOrderQuery(orderId);

  const [putOrder, { isLoading }] = usePutOrderMutation();
  const [data, setData] = useState<Partial<UpdateOrder>>({});

  // Check validity
  const loadsValid = useIsLoadsValid(data.type, data.loads ?? []);
  const isFormValid = useIsFormValid(data);

  /** Only show the loads if no assignments have been created */
  const showLoads = data && data.type && order?.status === 'Created';

  const formValid = isFormValid && (!showLoads || loadsValid);

  const send = () => {
    if (!formValid) return;
    if (!order) return;
    putOrder({ id: `${order.id}`, data: data as UpdateOrder })
      .unwrap()
      // Catching errors, otherwise thrown
      .catch(() => null)
      .finally(() => onClose());
  };

  // Handle input data updated
  useEffect(() => {
    if (!order) return;
    setData(order2UpdateOrder(order));
  }, [order]);

  /** Partially update the order */
  const updateData = (d: Partial<UpdateOrder>) => (
    setData((old) => ({ ...old, ...d } as Partial<UpdateOrder>))
  );

  const orderData = useMemo(() => {
    if (order !== undefined) {
      return extractData(order);
    }
    return null;
  }, [order]);

  if (!order) return null;
  if (!orderData) return null;

  return (
    <Modal
      compactHeader
      open={open}
      onClose={onClose}
      title={(
        <Typography
          color="primary"
          fontWeight="bold"
          fontSize={20}
          display="flex"
          alignItems="center"
        >
          {orderData?.title}
          <Typography
            title="Bestillingsnummer"
            component="span"
            fontSize={16}
            color="#888"
            paddingLeft={2}
          >
            #{order.id}
          </Typography>
        </Typography>
      )}
      footer={(
        <Box sx={{
          display: 'flex',
          gap: 1,
          flex: 1,
          alignItems: 'flex-end',
          padding: 2,
          paddingTop: 0,
        }}
        >
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <span className="updated-by">Oppdatert {`${order?.lastEditTime
              ? `${format(new Date(order?.lastEditTime), 'dd.MM.yyyy')} kl. ${format(new Date(order?.lastEditTime), 'HH:mm')}`
              : 'uspesifisert tidspunkt'} av ${order?.lastEditByName
              ? order?.lastEditByName : 'uspesifisert person'}`}
            </span>
          </Box>
          <Button
            loading={isLoading}
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Avbryt
          </Button>
          <Button
            loading={isLoading}
            variant="contained"
            color="primary"
            disabled={!formValid}
            onClick={send}
          >
            Lagre
          </Button>
        </Box>
      )}
    >
      <Box sx={{ display: 'flex', marginBottom: 4, justifyContent: 'space-between' }}>
        <OrderStatusIcon currStatus={order.status} />
        <Chip color={getTypeColor(order.type)} label={getTransportType(order.type)} size="small" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <OrderField order={data} onChange={updateData} field="fromProject" />
        <OrderField order={data} onChange={updateData} field="toProject" />
        <OrderField order={data} onChange={updateData} field="numberOfVehicles" />
        <OrderField order={data} onChange={updateData} field="vehicle" />
        <OrderField order={data} onChange={updateData} field="date" />
        <OrderField order={data} onChange={updateData} field="earlyDelivery" />
        <OrderField order={data} onChange={updateData} field="extraTimeInFacility" />
        <OrderField order={data} onChange={updateData} field="when" />
        <OrderField order={data} onChange={updateData} field="contactPersonEmployeeNumber" />
        <OrderField order={data} onChange={updateData} field="gateCode" />
        <OrderField order={data} onChange={updateData} field="extraEquipment" />
        <OrderField order={data} onChange={updateData} field="hasDispensation" />
        <OrderField order={data} onChange={updateData} field="daytimeDelivery" />
        <OrderField order={data} onChange={updateData} field="comment" />
        {showLoads && <LoadPicker value={data} onChange={updateData} />}
      </Box>
    </Modal>
  );
};
