import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import {
  formatISO,
  compareAsc,
  isSameMinute,
  isValid,
} from 'date-fns';
import { toast } from 'react-toastify';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { usePutRentalPeriodMutation } from '../../core/redux/transport';
import './style.scss';
import { RentalPeriod } from '../../core/types/rentalPeriod';
import { UpdateRentalPeriod } from '../../core/types/api/UpdateRentalPeriod';
import { ensureAfter } from '../../core/helpers/dates';

export const EditRentalPeriod: FC<{
    data: RentalPeriod,
    onAction?: () => void;
    }> = ({
      data,
      onAction = () => null,
    }) => {
      const [from, setFrom] = useState<Date|null>(null);
      const [to, setTo] = useState<Date|null>(null);
      const [comment, setComment] = useState<string>('');

      const [send, { isLoading }] = usePutRentalPeriodMutation();

      const valid = useMemo(() => {
        if (from === null || to === null) return false;
        if (compareAsc(from, to) >= 0) return false;
        if (!isValid(from)) return false;
        if (!isValid(to)) return false;
        return true;
      }, [from, to, comment]);

      useEffect(() => {
        if (data) {
          setFrom(new Date(data.from));
          setTo(new Date(data.to));
          setComment(data.comment);
        }
      }, [data]);

      const hasChanged = useMemo(() => {
        if (!data) return false;
        if (comment !== data.comment) return true;
        if (from && !isSameMinute(from, new Date(data.from))) return true;
        if (to && !isSameMinute(to, new Date(data.to))) return true;
        return false;
      }, [data, comment, from, to]);

      const submit = () => {
        if (!valid) return;
        if (!from) return;
        if (!to) return;
        const body: UpdateRentalPeriod = {
          from: formatISO(from),
          to: formatISO(to),
          comment,
        };
        send({ id: data.id, body }).unwrap().then(() => {
          toast.success('Innleieperiode lagret');
          onAction();
        });
      };

      const fromError = useMemo(() => {
        if (!from) return 'Fra tid kan ikke være tom';
        if (!isValid(from)) return 'Fra tid er ugyldig';
        if (!to) return null;
        if (compareAsc(from, to) >= 0) return 'Fra tid kan ikke være etter til tid';
        return null;
      }, [from, to]);

      const toError = useMemo(() => {
        if (!to) return 'Til tid kan ikke være tom';
        if (!isValid(to)) return 'Til tid er ugyldig';
        if (!from) return null;
        if (compareAsc(from, to) >= 0) return 'Til tid kan ikke være før fra tid';
        return null;
      }, [from, to]);

      const updateFrom = (time: Date) => {
        setFrom(time);
        setTo((old) => old && ensureAfter(old, time));
      };

      return (
        <div className="edit-rental-period-component">
          <FormControl fullWidth className="form">
            <DateTimePicker
              label="Fra"
              value={from || new Date()}
              onChange={(date) => date && updateFrom(date)}
              error={fromError}
            />
            <DateTimePicker
              label="Til"
              value={to || new Date()}
              onChange={(date) => date && setTo(date)}
              minDate={from || undefined}
              error={toError}
            />
            <TextField
              multiline
              rows={4}
              label="Kommentar"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
          <div className="send-buttons">
            <Button variant="outlined" onClick={onAction}>Avbryt</Button>
            <Button
              disabled={!hasChanged || !valid || isLoading}
              variant="contained"
              onClick={submit}
            >
              {isLoading
                ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                ) : 'Lagre'}
            </Button>
          </div>
        </div>
      );
    };
