import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { getAccessToken } from '../shared/api';
import { config } from '../shared/config/transport';
import { urlCombine } from '../shared/logic/functions';

interface signalROptions {
  url?: string;
  defaultMethod?: string;
  listeners?: { method: string; action: (data: any) => void }[];
  onConnect?: () => void;
}

export const useSignalR = ({
  url = '/order',
  defaultMethod = 'method',
  listeners = [],
  onConnect = () => null,
}: signalROptions = {}) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  // Set up connection
  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(urlCombine(config.HUB_URL, url), {
        accessTokenFactory: async () => `${await getAccessToken(config)}`,
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  // Start connection
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setIsConnected(true);
          onConnect();
          connection.onclose(() => setIsConnected(false));
        })
        .catch(() => setIsConnected(false));
    }
  }, [connection]);

  useEffect(() => {
    if (isConnected && connection !== null) {
      listeners.forEach((l) => {
        connection.on(l.method, (message) => {
          l.action(message);
        });
      });

      return () => {
        listeners.forEach((l) => {
          connection.off(l.method);
        });
      };
    }

    return () => null;
  }, [connection, isConnected, listeners]);

  /**
   * Sends data to the given method on the backend
   *
   * @param data Data to transmit
   * @param method Method to transmit to
   * @returns true if successfully sent, false otherwise
   */
  const sendData = async (data: any, method: string = defaultMethod) => {
    if (connection && isConnected) {
      try {
        await connection.send(method, data);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };

  const closeConection = () => {
    if (connection && isConnected) {
      return connection.stop();
    }
    return null;
  };

  useEffect(
    () => () => {
      closeConection();
    },
    [connection, isConnected],
  );

  return {
    sendData,
    closeConection,
  };
};
