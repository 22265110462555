import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { PageContent } from '../../components/PageContent/index';
import { ListRentalVehicles } from '../../components/ListRentalVehicles/index';
import './style.scss';

export const RentalVehicleListPage = () => {
  const navigate = useNavigate();
  return (
    <Page className="rental-vehicle-list-page">
      <Header>Innleiebiler</Header>
      <PageContent>
        <ListRentalVehicles
          onClickNew={() => navigate('/innleiebiler/opprett')}
          onClickItem={(id) => navigate(`/innleiebiler/${id}`)}
        />
      </PageContent>
    </Page>
  );
};
