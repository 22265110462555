import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  addMinutes,
  differenceInMinutes,
  formatISO,
  getUnixTime,
  isSameDay,
  setHours,
  setMinutes,
} from 'date-fns';
import { toast } from 'react-toastify';
import { getTransportType } from '../../core/helpers/translate';
import { StatusButtons } from '../StatusButtons';
import './style.scss';
import { AssignmentStatusIcon } from '../AssignmentStatusIcon';
import {
  useGetOrderQuery,
  usePutAssignmentCompleteContainerMutation,
  usePutAssignmentsBodyMutation,
  usePutAssignmentsStatusMutation,
} from '../../core/redux/transport';
import { ProjectPicker } from '../ProjectPicker';
import { Assignment } from '../../core/types/assignment';
import { Load, LoadItem } from '../../core/types/load';
import { Project } from '../../core/types/project';
import { GlobalAssignmentStatus } from '../../core/types/utilityTypes';
import {
  canEditFromTime,
  canEditToTime,
  canEditData,
  canEditVehicle,
} from '../../core/helpers/assignmentPermissions';
import {
  CreateAssignmentData,
  CreateAssignmentModal,
} from '../CreateAssignmentModal';
import { CallLink } from '../CallLink';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { useUrl } from '../../shared/hooks/params';
import { SelectVehicle } from '../AssignmentFields/SelectVehicle';
import { OrderLink } from '../AssignmentFields/OrderLink';
import { DurationPicker } from '../AssignmentFields/DurationPicker';
import { AllDayCheckbox } from '../AssignmentFields/AllDayCheckbox';
import { CommentBox } from '../AssignmentFields/CommentBox';
import { LastEditInfo } from '../../shared/components/lastEditInfo';
import { LoadPicker } from '../AssignmentFields/LoadPicker';
import { emptyGuard, forI } from '../../core/helpers/functions';
import { useIsType } from '../../core/hooks/useIsType';
import { combineLoads } from '../../core/helpers/assignmentHelpers';
import { MAX_CONTAINERS } from '../../shared/constants';
import { ContainerIntermediateStorage } from '../ContainerIntermediateStorage';
import { ContainerSelector } from '../DriverComponents/ContainerSelector';
import { useIsStatus } from '../../core/hooks/useIsStatus';
import { useProjectPermissions } from '../../core/hooks/useProjectPermissions';
import { useHasRoles } from '../../core/msal';
import { getTypeColor } from '../../core/helpers/eventRender';

interface CreateAssignmentModalProps {
  assignment: Assignment | null;
  today: Date;
  onClose: () => void;
}

export const EditAssignmentModal: FC<CreateAssignmentModalProps> = ({
  assignment,
  today,
  onClose,
}) => {
  const { data: orderInfo, isFetching: orderInfoIsFetching } = useGetOrderQuery(
    `${assignment?.order?.id}`,
    { skip: assignment?.order?.id === undefined },
  );
  const { data: vehicles } = useVehicleList();
  const [hasRole] = useHasRoles();

  const [putAssignmentsBody, { isLoading: isBodyLoading }] = usePutAssignmentsBodyMutation();
  const [putAssignmentsStatus, { isLoading: isStatusLoading }] = usePutAssignmentsStatusMutation();
  const [putAssignmentComplete, { isLoading: isCompleteLoading }] = usePutAssignmentCompleteContainerMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromProject, setFromProject] = useState<Project|null>(null);
  const [toProject, setToProject] = useState<Project|null>(null);
  const [collectionProject, setCollectionProject] = useState<Project|null>(null);
  const [externalCollectionProject, setExternalCollectionProject] = useState<string|undefined>();
  const [intermediateStorage, setIntermediateStorage] = useState<boolean>(false);
  const [fromTime, setFromTime] = useState<Date|null>(null);
  const [loads, setLoads] = useState<LoadItem[]>([]);
  const [allDay, setAllDay] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(45); // In minutes
  const [orderComment, setOrderComment] = useState<string>('');
  const [driverComment, setDriverComment] = useState<string|undefined>('');
  const [privateComment, setPrivateComment] = useState<string|undefined>('');
  const [lockState, setLockState] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<string|null>(null);
  const [addEvent, setAddEvent] = useState<CreateAssignmentData|null>(null);
  const [showExternalCollectionProject, setShowExternalCollectionProject] = useState<boolean>(false);

  const isType = useIsType(assignment?.type);
  const isStatus = useIsStatus(assignment?.status);

  /** Indicates that the assignment has been finished, and no more editing should take place */
  const assignmentEnded = isType('Container')
    && isStatus('Completed', 'NotDelivered', 'VerifiedNotDelivered', 'Cancelled');

  /** User does not have a role that allows them to edit */
  const notAllowedToEdit = (
    isType('Container')
      ? !hasRole('admin', 'transport-koordinator', 'container-koordinator')
      : !hasRole('admin', 'transport-koordinator')
  );

  const disabled = assignmentEnded || notAllowedToEdit;
  const projectEditable = useProjectPermissions(assignment?.type);
  const loadDisabled = isType('Container') && !isStatus('UnderPlanning');
  const collectionProjectDisabled = isType('Container') && !isStatus('UnderPlanning', 'Approved', 'Started');

  // set initial values
  useEffect(() => {
    if (!assignment) return;
    const { order } = assignment;
    const defaultAmount = (l:Load) => (
      isType('ContainerDeliver')
        ? Math.min(MAX_CONTAINERS, l.amount)
        : Math.min(l.amount, order?.vehicle === 'Single' ? 10 : 1000)
    );
    const assignmentLoads: LoadItem[] = combineLoads(assignment?.loads || []).map((l) => ({
      ...l,
      amount: defaultAmount(l),
      label: l.type?.name,
      max: l.amount,
    })) || [];
    setIsUpdated(false);
    setDuration(Math.abs(differenceInMinutes(
      new Date(assignment.startTime),
      new Date(assignment.endTime),
    )));
    setIsOpen(true);
    setLoads(assignmentLoads);
    setFromTime(new Date(assignment.startTime));
    setOrderComment(assignment?.orderComment ?? '');
    setDriverComment(assignment?.commentForDriver);
    setPrivateComment(assignment?.comment);
    setSelectedVehicle(assignment?.vehicle.internalNumber);
    setFromProject(assignment.fromProject || null);
    setToProject(assignment.toProject || null);
    setCollectionProject(assignment.collectionProject || null);
    setExternalCollectionProject(assignment.externalCollectionProject || undefined);
    setShowExternalCollectionProject(!!assignment.externalCollectionProject);
    setIntermediateStorage(assignment.intermediateStorage);
    setAllDay(false);
    setLockState(assignment.lockState);
  }, [assignment]);

  const fullDayStart = fromTime ? new Date(fromTime) : new Date();
  fullDayStart.setHours(7, 0, 0);

  const fullDayEnd = fromTime ? new Date(fromTime) : new Date();
  fullDayEnd.setHours(15, 0, 0);

  const getValidDuration = (d: number) => {
    let rounded = Math.round((d || 0) / 15) * 15;
    while (rounded <= 0) {
      rounded += 1440; // 24 hours in minutes (60 * 24)
    }
    return rounded;
  };

  const [, change] = useUrl();

  const onExit = () => {
    setIsOpen(false);
    setAddEvent(null);
    onClose();
  };

  const isLoading = isBodyLoading || isStatusLoading || isCompleteLoading;

  const isEmptyDeliverAssignment = assignment?.order?.type === 'ContainerEmptying' && assignment?.type === 'ContainerDeliver';

  const collectionProjectRequired = isType('Container')
    && !isEmptyDeliverAssignment
    && !intermediateStorage && loads.some((l) => l.amount && (l.type || l.wasteType));

  const showIntermediateStorage = isType('ContainerCollect') && loads.some((l) => l.amount && l.wasteType);

  const updateToTime = (e: Date|null) => {
    if (!fromTime || !e || !today) return;
    const startUnix = Math.round(getUnixTime(fromTime) / 60); // in minutes
    const endUnix = Math.round(getUnixTime(e) / 60); // in minutes
    const delta = endUnix - startUnix;
    setDuration(getValidDuration(delta));
  };

  const validateDuration = () => {
    setDuration(getValidDuration(duration));
  };

  useEffect(validateDuration, [duration]);
  const toTime = fromTime ? addMinutes(fromTime, duration) : null;

  const sendAssignmentsData = async (assignmentStatus?: GlobalAssignmentStatus) => {
    if (!selectedVehicle || !toProject) {
      throw new Error('Kan ikke oppdatere oppdrag - bil eller prosjekt mangler');
    }
    if (!fromTime || !toTime) {
      throw new Error('Fra og til er ikke spesifisert');
    }
    if (isUpdated) {
      let actualLoads: LoadItem[] = loads.filter((l) => l.amount > 0).map((l) => {
        if (isType('ContainerSwap')) {
          return ({
            ...l,
            wasteType: emptyGuard(l.wasteType),
            massTypeId: l.type && parseInt(l.type.id, 10),
            containerSubcategory: l.container?.subCategoryName,
          });
        }
        return ({
          ...l,
          wasteType: emptyGuard(l.wasteType),
          massTypeId: l.type && parseInt(l.type.id, 10),
        });
      });
      if (isType('ContainerDeliver')) {
        if (loads.length === 0) throw new Error('Innhenting av last feilet');
        actualLoads = loads.filter((l) => l.amount > 0).flatMap((l) => forI(l.amount, () => ({
          ...l,
          id: undefined,
          amount: 1,
          massTypeId: l?.type ? parseInt(l?.type.id, 10) : undefined,
        })));
      }
      if (!showIntermediateStorage) {
        setIntermediateStorage(false);
      }
      await putAssignmentsBody({
        id: `${assignment?.id}`,
        data: {
          type: assignment?.type,
          vehicleInternalNumber: selectedVehicle,
          fromProject: fromProject ? fromProject?.id : undefined,
          toProject: toProject.id,
          collectionProject: intermediateStorage || showExternalCollectionProject
            ? undefined
            : collectionProject?.id,
          externalCollectionProject: !showExternalCollectionProject || intermediateStorage
            ? undefined
            : externalCollectionProject,
          intermediateStorage,
          startTime: allDay ? formatISO(fullDayStart) : formatISO(fromTime),
          endTime: allDay ? formatISO(fullDayEnd) : formatISO(toTime),
          loads: actualLoads,
          commentForDriver: driverComment,
          comment: privateComment,
          orderComment: orderComment === orderInfo?.comment ? undefined : orderComment,
          lockState,
        },
      });
    }
    if (assignmentStatus !== undefined) {
      if (assignmentStatus === 'DeleteOrder') {
        const orderVehicle = orderInfo?.vehicles
          ?.find((v) => assignment?.vehicle.internalNumber === v.id);

        const toDelete = orderVehicle?.assignments
          .filter((a) => a.status === 'UnderPlanning')
          .filter((a) => isSameDay(today, new Date(a.startTime)));

        if (toDelete) {
          const promises = toDelete.map((a) => (
            putAssignmentsStatus({
              id: `${a.id}`,
              data: 'Deleted',
            })));
          await Promise.all(promises);
        }
      } else if (assignmentStatus === 'CancelOrder') {
        const orderVehicle = orderInfo?.vehicles
          ?.find((v) => assignment?.vehicle.internalNumber === v.id);

        const toCancel = orderVehicle?.assignments
          .filter((a) => a.status === 'Approved')
          .filter((a) => isSameDay(today, new Date(a.startTime)));

        if (toCancel) {
          const promises = toCancel.map((a) => (
            putAssignmentsStatus({
              id: `${a.id}`,
              data: 'Cancelled',
            })));
          await Promise.all(promises);
        }
      } else if (isType('Container') && assignmentStatus === 'Completed') {
        if (!assignment) return;
        await putAssignmentComplete({ id: assignment.id, position: { latitude: '', longitude: '' } });
      } else {
        await putAssignmentsStatus({
          id: `${assignment?.id}`,
          data: assignmentStatus || assignment?.status,
        });
      }
    }
    onExit();
  };

  const updateAssignment = async (assignmentStatus?: GlobalAssignmentStatus) => {
    try {
      await sendAssignmentsData(assignmentStatus);
    } catch (e: any) {
      toast.error(e?.message || 'Kunne ikke sende inn');
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const isSaveDisabled = useMemo(() => {
    if (notAllowedToEdit) return true;
    if (isType('MassInternal')) {
      return (typeof toProject === 'undefined' || toProject === null);
    }
    if (loads.filter((l) => l.amount !== 0).length < 1) {
      return true;
    }

    if (isType('ContainerDeliver')
      && loads.some((l) => l.amount > 0 && !l.containerSubcategory)
    ) {
      return true;
    }
    if (collectionProjectRequired && ((!showExternalCollectionProject && !collectionProject)
    || (showExternalCollectionProject && (!externalCollectionProject || externalCollectionProject?.trim().length === 0)))) return true;

    return (typeof toProject === 'undefined' || toProject === null)
      || (typeof fromProject === 'undefined' || fromProject === null);
  }, [
    assignment,
    toProject,
    fromProject,
    collectionProjectRequired,
    collectionProject,
    externalCollectionProject,
    showExternalCollectionProject,
    loads,
  ]);

  /** Open order on the right hand menu */
  const goToOrder = () => {
    if (assignment?.order?.id) {
      change('/transportplanlegger', { id: assignment.order.id });
    }
  };

  /** Save changes and go to the order */
  const handleConfirm = () => {
    updateAssignment();
    onExit();
    goToOrder();
  };

  /** Discard changes and go to the order */
  const handleCancel = () => {
    onExit();
    goToOrder();
  };

  const copyAssignment = () => {
    if (assignment && assignment.order) {
      setAddEvent({
        type: assignment.type,
        fromProject: fromProject || undefined,
        toProject: toProject || undefined,
        duration,
        startTime: new Date(`${assignment?.startTime}`),
        vehicle: assignment.vehicle.internalNumber,
        order: assignment?.order,
        loads: assignment.loads,
      });
    } else {
      setAddEvent(null);
    }
  };

  if (!assignment) return null;

  const choosingContainers = (
    isType('ContainerCollect', 'ContainerDeliver', 'ContainerEmptying')
      && assignment.status === 'Approved'
      && assignment.loads.some((l) => !l.actualContainerInternalNumber && !l.actualContainerExternal)
  ) || (
    isType('ContainerSwap')
      && assignment.status === 'Approved'
      && assignment.loads.some((l) => !l.actualContainerTwoInternalNumber && !l.actualContainerExternal)
  );
  const choosingSecondaryContainer = isType('ContainerSwap')
    && assignment.status === 'Started'
    && assignment.loads.some((l) => !l.actualContainerInternalNumber && !l.actualContainerExternal);

  return (
    <Dialog
      open={isOpen}
      onClose={onExit}
      maxWidth="md"
      fullWidth
    >
      <div className="align-status">
        <div className="left">
          <DialogTitle>{`Oppdrag ${assignment?.id}`}</DialogTitle>
        </div>
        <div className="right">
          <OrderLink
            assignment={assignment}
            isUpdated={isUpdated}
            saveDisabled={isSaveDisabled}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
          <Chip
            color={getTypeColor(assignment?.type)}
            label={assignment?.order && getTransportType(assignment?.type)}
            size="medium"
          />
          <AssignmentStatusIcon currStatus={assignment?.status} />
        </div>
      </div>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <SelectVehicle
            value={selectedVehicle}
            options={vehicles}
            onChange={(e) => {
              setIsUpdated(true);
              setSelectedVehicle(e);
            }}
            disabled={disabled || !canEditVehicle(assignment?.status)}
          />
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            {!isType('MassInternal') && (
            <span className="flexchild">
              <ProjectPicker
                showLastUsed={false}
                value={fromProject}
                onChange={(e) => {
                  setIsUpdated(true);
                  setFromProject(e);
                }}
                label={isType('ContainerEmptying') ? 'Prosjekt' : 'Fra prosjekt'}
                size="small"
                required
                disabled={disabled || !projectEditable.from || !canEditData(assignment?.status)}
              />
            </span>
            )}
            {!isType('ContainerEmptying') && (
            <span className="flexchild">
              <ProjectPicker
                showLastUsed={false}
                value={toProject}
                onChange={(e) => {
                  setIsUpdated(true);
                  setToProject(e);
                }}
                label={isType('MassInternal') ? 'Prosjekt' : 'Til prosjekt'}
                size="small"
                required
                disabled={disabled || !projectEditable.to || !canEditData(assignment?.status)}
              />
            </span>
            )}
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 1.5 }}>
              <DateTimePicker
                value={(
                  allDay
                    ? setHours(setMinutes(fromTime || new Date(), 0), 7)
                    : fromTime) || new Date()}
                onChange={(nd) => {
                  if (!allDay) {
                    setFromTime(nd);
                  } else {
                    // Don't set the time, only the date
                    const time = new Date(nd);
                    if (!fromTime) return;
                    time.setHours(fromTime.getHours());
                    time.setMinutes(fromTime.getMinutes());
                    setFromTime(time);
                  }
                  setIsUpdated(true);
                }}
                fullWidth
                label="Fra"
                disabled={disabled}
                disableTime={disabled || allDay}
              />
              <DateTimePicker
                value={(
                  allDay
                    ? setHours(setMinutes(fromTime || new Date(), 0), 15)
                    : toTime) || new Date()}
                minDate={fromTime || undefined}
                onChange={(nd) => {
                  updateToTime(nd);
                  setIsUpdated(true);
                }}
                fullWidth
                label="Til"
                disabled={disabled || allDay}
              />
            </Box>

            <AllDayCheckbox
              checked={allDay}
              disabled={disabled || !canEditFromTime(assignment?.status)}
              onChange={(v) => {
                setIsUpdated(true);
                setAllDay(v);
              }}
            />
          </Box>

          <DurationPicker
            value={duration}
            allDay={allDay}
            disabled={disabled || allDay || !canEditToTime(assignment?.status)}
            onChange={(v) => {
              setIsUpdated(true);
              setDuration(v);
            }}
          />

          <Box>
            <LoadPicker
              orderType={assignment.order?.type}
              assignmentType={assignment.type}
              projectId={assignment.fromProject?.id}
              value={loads}
              onChange={(l) => {
                setIsUpdated(true);
                setLoads(l);
              }}
              disabled={disabled || loadDisabled}
            />

            {showIntermediateStorage && (
            <ContainerIntermediateStorage
              disabled={disabled || loadDisabled}
              value={intermediateStorage}
              onChange={(v) => {
                setIntermediateStorage(v);
                setIsUpdated(true);
              }}
            />
            )}
          </Box>

          {/* Collection Project */}
          {collectionProjectRequired && (
            <Box flex={1}>
              {showExternalCollectionProject ? (
                <TextField
                  label={`Eksternt ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}`}
                  value={externalCollectionProject}
                  disabled={disabled || collectionProjectDisabled}
                  onChange={(v) => { setExternalCollectionProject(v.target.value); setIsUpdated(true); }}
                  error={!externalCollectionProject}
                  helperText={!externalCollectionProject ? `Skriv inn et ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}` : ''}
                  size="small"
                  fullWidth
                />
              ) : (
                <ProjectPicker
                  showLastUsed={false}
                  value={collectionProject}
                  disabled={disabled || collectionProjectDisabled}
                  onChange={(v) => { setCollectionProject(v); setIsUpdated(true); }}
                  label={isType('ContainerDeliver') ? 'Hentested' : 'Tømmested'}
                  size="small"
                  required
                />
              )}
              <FormControlLabel
                sx={{ paddingTop: showExternalCollectionProject ? 0 : 3 }}
                disabled={disabled || collectionProjectDisabled}
                label={`Eksternt ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}`}
                control={(
                  <Checkbox
                    onChange={() => setShowExternalCollectionProject(!showExternalCollectionProject)}
                    checked={showExternalCollectionProject}
                    disabled={collectionProjectDisabled}
                  />
                )}
              />
            </Box>
          )}

          <TextField
            value=" "
            label="Kontakt Person"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <span className="contactPerson">
                  {`${assignment?.order?.contactPersonName} `}
                  { assignment?.order?.contactPersonPhoneNumber
                    && <CallLink phoneNumber={assignment?.order?.contactPersonPhoneNumber} />}
                </span>
              ),
            }}
          />

          <CommentBox
            label="Bestillingskommentar"
            disabled={disabled || orderComment === ''}
            value={orderComment || ''}
            onChange={(v) => {
              setIsUpdated(true);
              setOrderComment(v);
            }}
          />

          <CommentBox
            label="Kommentar til sjåfør"
            disabled={disabled || !canEditData(assignment?.status)}
            value={driverComment}
            onChange={(v) => {
              setIsUpdated(true);
              setDriverComment(v);
            }}
          />

          <CommentBox
            disabled={notAllowedToEdit}
            label="Internkommentar"
            value={privateComment}
            onChange={(v) => {
              setIsUpdated(true);
              setPrivateComment(v);
            }}
          />

          <LastEditInfo data={assignment} />

          <ContainerSelector assignment={assignment} />
        </Box>
        {choosingContainers && (
        <Box sx={{ paddingTop: 1 }}>
          <Alert severity="warning">
            Container(e) må være valgt for å starte oppdraget
          </Alert>
        </Box>
        )}
        {choosingSecondaryContainer && (
        <Box sx={{ paddingTop: 1 }}>
          <Alert severity="warning">
            Container(e) hentet må være valgt for å fullføre oppdraget
          </Alert>
        </Box>
        )}
      </DialogContent>
      <DialogActions>
        <StatusButtons
          assignment={assignment}
          isSaveDisabled={isSaveDisabled || isLoading || orderInfoIsFetching}
          updateAssignment={updateAssignment}
          disabled={notAllowedToEdit}
        />
        <Box display="flex" alignSelf="flex-end" alignItems="flex-end" gap={1} marginBottom={1} marginRight={1}>
          {isStatus('UnderPlanning') && (
            <Box marginRight={1} alignSelf="center">
              <Tooltip
                title={lockState ? 'Fjern lås oppdrag' : 'Lås oppdrag'}
                placement="top"
              >
                <Checkbox
                  disabled={notAllowedToEdit}
                  icon={<LockOpenIcon />}
                  checkedIcon={<LockIcon />}
                  checked={lockState}
                  onChange={() => { setIsUpdated(true); setLockState(!lockState); }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 }, padding: '4px', margin: 0 }}
                />
              </Tooltip>
            </Box>
          )}
          {!isType('Container') && (
            <Button
              variant="contained"
              color="info"
              disabled={isUpdated || notAllowedToEdit}
              onClick={copyAssignment}
            >kopiér
            </Button>
          )}
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={onExit}
          >Avbryt
          </Button>
          <Button
            variant="contained"
            disabled={isSaveDisabled || !isUpdated || isLoading}
            onClick={() => updateAssignment()}
          >
            {isLoading
              ? <CircularProgress size={24} sx={{ marginLeft: 6, marginRight: 6 }} />
              : 'Lagre endringer'}
          </Button>
        </Box>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </DialogActions>
      <CreateAssignmentModal
        event={addEvent}
        today={today}
        onClose={() => setAddEvent(null)}
        canChangeVehicle
      />
    </Dialog>
  );
};
