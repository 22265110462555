import { useIsType } from './useIsType';
import { AssignmentType } from '../types/enums/assignmentType';

/**
 * Check if editing of either from or to project is allowed on assignments of given type
 * @param type Type of assignment
 * @returns Object containing boolean values for from and to. True if allowed to edit, false otherwise
 */
export const useProjectPermissions = (type: AssignmentType|undefined): {from: boolean, to: boolean} => {
  const isType = useIsType(type);
  const perms = { from: true, to: true };

  if (isType('ContainerEmptying', 'ContainerCollect')) {
    perms.from = false;
  }
  if (isType('ContainerDeliver')) {
    perms.to = false;
  }

  return perms;
};
