import { Draggable } from '@fullcalendar/interaction';
import { useEffect, useState } from 'react';
import { getAttributes } from '../../../core/helpers/functions';
import { getEventColorFromStatus } from '../../../core/helpers/eventRender';

export const useDraggable = (shouldAttach = true) => {
  const [draggable, setDraggable] = useState<Draggable|null>(null);
  const attachDraggable = () => {
    if (draggable) {
      return; // Draggable is already connected
    }
    const draggableEl = document.getElementById('external-events');
    if (draggableEl === null) {
      setTimeout(attachDraggable, 500);
      return;
    }
    // eslint-disable-next-line no-new
    const drag = new Draggable(draggableEl, {
      itemSelector: '.task-item',
      eventData(eventEl: { getAttribute: (arg0: string) => any }) {
        const title = eventEl.getAttribute('data-title');
        const id = eventEl.getAttribute('data-id');
        const duration = eventEl.getAttribute('data-duration');
        const assignmentType = eventEl.getAttribute('data-assignmenttype');
        const extprops = getAttributes(eventEl, ['from', 'to', 'orderType', 'assignmentType', 'loadType', 'time', 'contact', 'isInternal']);
        extprops.type = 'assignment';

        return {
          title,
          id,
          editable: true,
          duration,
          assignmentType,
          color: getEventColorFromStatus('UnderPlanning'),
          extendedProps: extprops,
        };
      },
    });
    setDraggable(drag);
  };

  useEffect(() => {
    if (!shouldAttach) return;
    attachDraggable();
  }, [shouldAttach]);
  return draggable;
};
