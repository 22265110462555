import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import '../style.scss';
import { EditUnavailability } from '../../../components/EditUnavailability';

export const EditUnavailabilityModal: FC<{
  id: number,
  onClose: () => void;
}> = ({
  id,
  onClose,
}) => (
  <Dialog
    open={!!id}
    onClose={() => {
      onClose();
    }}
    maxWidth="md"
    fullWidth
  >

    <div className="align-status">
      <div className="left">
        <DialogTitle>Rediger Utilgjengelighet</DialogTitle>
      </div>
    </div>

    <DialogContent>
      <EditUnavailability id={id} onAction={onClose} />
    </DialogContent>
  </Dialog>
);
