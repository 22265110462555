import { CalendarApi } from '@fullcalendar/core';

export const setShadow = (
  calApi: CalendarApi,
  start: Date|string,
  end: Date|string,
  resourceId: string,
) => {
  if (!calApi) return;
  const item = calApi.getEventById('shadow-item');
  if (!item) return;
  item.setStart(start);
  item.setEnd(end);
  item.setResources([resourceId]);
  item.setProp('display', 'background');
};

export const clearShadow = (calApi: CalendarApi) => {
  if (!calApi) return;
  const item = calApi.getEventById('shadow-item');
  if (!item) return;
  item.setProp('display', 'none');
};

export const shadowEvent = {
  id: 'shadow-item',
  textColor: '#444',
  backgroundColor: '#444',
  borderColor: '#444',
  start: new Date(),
  end: new Date(),
  display: 'none',
  className: 'shadow-item-event',
};
