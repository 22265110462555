/* eslint-disable no-param-reassign */
import { useSelector } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DEFAULT_REGION, RYFYLKE_REGION } from '../../shared/constants';
import { useHasRoles } from '../msal';

const stateName = 'region';

interface RegionSettingItem {
  id: number;
  name?: string;
  active: boolean;
}

interface RegionState {
  regionVisibility: RegionSettingItem[];
}

const initialState: RegionState = {
  regionVisibility: [{ id: DEFAULT_REGION, active: true }],
};

const regionSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setRegionVisibility(state, action: PayloadAction<RegionSettingItem[]>) {
      state.regionVisibility = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const useRegionVisibility = () => {
  const [hasRole] = useHasRoles();
  const {
    regionVisibility,
  } = useSelector(
    (state: { [stateName]: RegionState }) => state[stateName],
  );

  if (!hasRole('admin', 'container-koordinator', 'transport-koordinator') && hasRole('lese-ryfylke')) {
    return [
      { active: true, id: RYFYLKE_REGION, name: 'Ryfylke' },
    ] as RegionSettingItem[];
  }

  return regionVisibility;
};

export const {
  setRegionVisibility,
  reset,
} = regionSlice.actions;

export default persistReducer({ key: stateName, storage }, regionSlice.reducer);
