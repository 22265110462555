import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import { formatISO, subDays } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import { Guid } from '../../core/types/guid';
import { useGetUnavailabilityByVehicleIdQuery } from '../../core/redux/transport';
import './style.scss';
import { UnavailabilityList } from '../../Pages/UnavailabilityPage/partials/UnavailabilityList';
import { EditUnavailabilityModal } from '../../Pages/TransportplanleggerPage/partials/EditUnavailabilityModal';
import { CreateUnavailabilityModal } from '../../Pages/TransportplanleggerPage/partials/CreateUnavailabilityModal';

export const UnavailabilityView: FC<{ id: Guid, currentDate: Date, onClose: () => void, }> = ({ id, currentDate, onClose }) => {
  const [unavailabilityToEdit, setUnavailabilityToEdit] = useState<number>(0);
  const [createUnavailability, setCreateUnavailability] = useState<boolean>(false);

  const [selection, setSelection] = useState<'Future' | 'Last7Days' | 'Last14Days' | 'All'>('Future');
  const today = new Date();

  const range = useMemo(() => {
    switch (selection) {
      case 'Last7Days': return { start: formatISO(subDays(today, 7), { representation: 'date' }), end: formatISO(today, { representation: 'date' }) };
      case 'Last14Days': return { start: formatISO(subDays(today, 14), { representation: 'date' }), end: formatISO(today, { representation: 'date' }) };
      case 'All': return {};
      case 'Future':
      default:
        return { start: formatISO(today, { representation: 'date' }) };
    }
  }, [selection]);

  const {
    data: unavailabilities,
    isLoading,
    isFetching,
    refetch,
  } = useGetUnavailabilityByVehicleIdQuery({ id, ...range });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="unavailability-wrapper">
      <div className="unavailability-area">
        <div className="header">
          <span className="header-group">
            <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
              <Button variant={selection === 'Future' ? 'contained' : 'outlined'} onClick={() => setSelection('Future')}>Fremtidige</Button>
              <Button variant={selection === 'Last7Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last7Days')}>siste 7 dager</Button>
              <Button variant={selection === 'Last14Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last14Days')}>siste 14 dager</Button>
              <Button variant={selection === 'All' ? 'contained' : 'outlined'} onClick={() => setSelection('All')}>Alle</Button>
            </ButtonGroup>
          </span>
          <span className="header-button">
            <ButtonGroup fullWidth aria-label="outlined create button">
              <Button onClick={() => setCreateUnavailability(true)} variant="contained"><AddIcon /></Button>
            </ButtonGroup>
          </span>
        </div>
        <div>
          <UnavailabilityList
            unavailabilities={unavailabilities}
            isLoading={isLoading || isFetching}
            onClick={setUnavailabilityToEdit}
          />
        </div>
      </div>
      <div className="close-button">
        <Button variant="outlined" onClick={onClose}>Lukk</Button>
      </div>
      {createUnavailability && (
      <CreateUnavailabilityModal internalNumber={id} currentDate={currentDate} onClose={() => setCreateUnavailability(false)} />
      )}
      {unavailabilityToEdit !== 0 && (<EditUnavailabilityModal id={unavailabilityToEdit} onClose={() => setUnavailabilityToEdit(0)} />)}
    </div>
  );
};

export default UnavailabilityView;
