import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import '../style.scss';
import { CreateUnavailability } from '../../../components/CreateUnavailability';
import { UnavailabilityType } from '../../../core/types/unavailability';

export const CreateUnavailabilityModal: FC<{
  internalNumber: string;
  currentDate?: Date;
  onClose: () => void;
  type?: UnavailabilityType;
}> = ({
  internalNumber,
  currentDate,
  onClose,
  type,
}) => (
  <Dialog
    open
    onClose={() => {
      onClose();
    }}
    maxWidth="md"
    fullWidth
  >

    <div className="align-status">
      <div className="left">
        <DialogTitle>Opprett Utilgjengelighet</DialogTitle>
      </div>
    </div>

    <DialogContent>
      <CreateUnavailability
        currentDate={currentDate}
        internalNumber={internalNumber}
        onClose={onClose}
        type={type}
      />
    </DialogContent>
  </Dialog>
);
