import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { RootState } from '../../../core/redux';
import { useGetMassTypesQuery, useGetProjectsQuery } from '../../../core/redux/transport';
import {
  setLoadTypes,
  setVehicleTypes,
  setProjects,
  setOrderTypes,
} from '../../../core/redux/orderFilter';
import { vehicleType } from '../../../core/types/enums/vehicleType';
import { vehicleType as translateVehicleType } from '../../../core/helpers/translate';
import { MassType } from '../../../core/types/massType';
import { useIsType } from '../../../core/hooks/useIsType';

export const OrderFilter: FC<{
  show?: boolean,
}> = ({
  show = true,
}) => {
  const {
    projects,
    loadTypes,
    vehicleTypes,
    orderTypes,
  } = useSelector((state: RootState) => state.orderFilter);
  const dispatch = useDispatch();
  const [massTypes, setMassTypes] = useState<MassType[]>([]);
  const { data: rawMassTypes } = useGetMassTypesQuery();
  useEffect(() => {
    if (rawMassTypes) {
      setMassTypes((mt) => {
        const newMassTypes = [...mt];
        rawMassTypes.forEach((rmt) => {
          if (!newMassTypes.some((c) => c.name === rmt.name)) {
            newMassTypes.push(rmt);
          }
        });
        return newMassTypes;
      });
    }
  }, [rawMassTypes]);
  const { data: allProjects = [] } = useGetProjectsQuery();
  const orderOptions: {
    id: Parameters<ReturnType<typeof useIsType>>[number],
    label: string,
  }[] = [
    { id: 'Mass', label: 'Masse' },
    { id: 'Container', label: 'Container' },
    { id: 'HaulHook', label: 'Maskinslep Krok' },
    { id: 'HaulSemi', label: 'Maskinslep Semi' },
  ];

  return (
    <div className="orders-filter-partial">
      <div className="filters" style={{ display: show ? 'block' : 'none' }}>
        <FormControl fullWidth sx={{ p: '8px 0' }}>
          <Autocomplete
            multiple
            id="loadType"
            limitTags={1}
            disableCloseOnSelect
            value={orderTypes}
            onChange={((e, ns) => (dispatch(setOrderTypes(ns))))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={orderOptions}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Bestillingstype"
                size="small"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ p: '8px 0' }}>
          <Autocomplete
            multiple
            id="loadType"
            limitTags={1}
            disableCloseOnSelect
            value={loadTypes}
            onChange={((e, ns) => (dispatch(setLoadTypes(ns))))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={massTypes.map((m) => ({ id: m.id, label: m.name }))}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Last"
                size="small"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ p: '8px 0' }}>
          <Autocomplete
            multiple
            id="vehicleType"
            limitTags={1}
            disableCloseOnSelect
            value={vehicleTypes}
            onChange={((e, ns) => (dispatch(setVehicleTypes(ns))))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={vehicleType.map((m) => ({ id: m, label: translateVehicleType(m) }))}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Kjøretøykategori"
                size="small"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ p: '8px 0' }}>
          <Autocomplete
            multiple
            id="project"
            limitTags={1}
            disableCloseOnSelect
            value={projects}
            onChange={((e, ns) => (dispatch(setProjects(ns))))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={allProjects.map((m) => ({ id: m.id, label: `${m.id} - ${m.projectName}` }))}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Prosjekt"
                size="small"
              />
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};
