import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import './style.scss';
import { EditUnavailability } from '../../components/EditUnavailability';

export const EditUnavailabilityPage = () => {
  const navigate = useNavigate();

  const { id = '' } = useParams<{id: string}>();

  return (
    <Page className="edit-unavailability-page">
      <Header onBack={() => navigate('/utilgjengelighet')}>Rediger Utilgjengelighet</Header>
      <EditUnavailability id={id} onAction={() => navigate('/utilgjengelighet')} />
    </Page>

  );
};
