import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ButtonGroup,
  Button,
  Popover,
} from '@mui/material';
import {
  addDays,
  format,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AssignmentFilter } from './AssignmentFilter';
import { VehicleFilter } from './VehicleFilter';
import { useHasRoles } from '../../../core/msal';

export const TimelineHeader: FC<{
  day?: Date,
  vehicleCategory: string[],
  unlockedUnderPlanningExists: boolean,
  onDayChange?: (date: Date) => void,
  onApproveAll?: () => void,
  assignmentsLoading: boolean,
}> = ({
  day = new Date(),
  vehicleCategory,
  unlockedUnderPlanningExists,
  onDayChange = () => null,
  onApproveAll = () => null,
  assignmentsLoading = false,
}) => {
  const [hasRole] = useHasRoles();

  const sameDay = useMemo(() => (format(day, 'P') === format(new Date(), 'P')), [day]);

  const [pickerVal, setPickerVal] = useState<Date>(day);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!isSameDay(pickerVal, day)) {
      setPickerVal(day);
    }
  }, [day]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirm = () => {
    onApproveAll();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <div className="timeline-header-component">
      <span className="align-left">
        <ButtonGroup>
          <Button variant="contained" disabled={assignmentsLoading} onClick={() => onDayChange(startOfDay(subDays(day, 1)))}><ChevronLeft /></Button>
          <Button variant="contained" disabled={assignmentsLoading} onClick={() => onDayChange(startOfDay(addDays(day, 1)))}><ChevronRight /></Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button variant="contained" disabled={sameDay || assignmentsLoading} onClick={() => onDayChange(new Date())}> I dag</Button>
        </ButtonGroup>
        <MobileDatePicker
          value={pickerVal}
          onChange={(v) => setPickerVal(v || day)}
          onOpen={() => setPickerVal(day)}
          inputFormat="dd. MMM yyyy"
          label="Velg dato"
          onAccept={(newValue) => {
            if (newValue) onDayChange(startOfDay(newValue));
          }}
          renderInput={({ inputRef, inputProps }) => (
            <input
              className="date-text"
              readOnly
              id="datePicker"
              ref={inputRef}
              value={inputProps?.value}
              onClick={inputProps?.onClick}
            />
          )}
        />
      </span>
      <VehicleFilter vehicleCategory={vehicleCategory} />
      <AssignmentFilter />
      {hasRole('admin', 'container-koordinator', 'transport-koordinator') && (
        <span>
          <Button
            variant="contained"
            disabled={!unlockedUnderPlanningExists}
            onClick={handleClick}
          >
            Godkjenn alle
          </Button>
        </span>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={8}
        transitionDuration="auto"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="m10">
          <div className="ml5 mb10">
            <span className="bold-text font-size-17">Er du sikker?</span>
            <div className="mt5">
              <span className="font-size-15 italic">
                Alle dagens oppdrag under planlegging<br />
                vil godkjennes
              </span>
            </div>
          </div>
          <div className="text-align-end">
            <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
            <Button
              sx={{ m: 0.75 }}
              variant="contained"
              onClick={handleConfirm}
            >Godkjenn Alle
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
