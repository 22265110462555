import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  formatISO,
  subDays,
} from 'date-fns';
import { Guid } from '../../core/types/guid';
import './style.scss';
import { SubstituteModal } from '../../Pages/TransportplanleggerPage/partials/SubstituteModal';
import { useGetSubstitutesQuery } from '../../core/redux/transport';
import { SubList } from './partials/SubList';

export const SubstituteView: FC<{
  id: Guid,
  currentDate: Date,
  onClose?: () => void;
}> = ({
  id,
  currentDate,
  onClose = () => null,
}) => {
  const [editSubstitute, setEditSubstitute] = useState<boolean>(false);
  const [subExist, setSubExist] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const [selection, setSelection] = useState<'Future' | 'Last7Days' | 'Last14Days' | 'All'>('Future');
  const today = new Date();
  const future = { start: formatISO(today, { representation: 'date' }) };
  const week = { start: formatISO(subDays(today, 7), { representation: 'date' }), end: formatISO(today, { representation: 'date' }) };
  const twoWeeks = { start: formatISO(subDays(today, 14), { representation: 'date' }), end: formatISO(today, { representation: 'date' }) };
  const all = {};

  const range = useMemo(() => {
    switch (selection) {
      case 'Last7Days': return week;
      case 'Last14Days': return twoWeeks;
      case 'All': return all;
      case 'Future':
      default:
        return future;
    }
  }, [selection]);

  const {
    data: subs,
    isLoading,
    isFetching,
    refetch,
  } = useGetSubstitutesQuery({ id, ...range });

  useEffect(() => {
    refetch();
  }, [editSubstitute]);

  const subResult = useMemo(() => (
    subs?.filter((s) => (
      s.driver.phone.includes(search)
      || s.driver.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
      .sort((a, b) => a.start.localeCompare(b.start, 'nb'))
  ), [search, subs]);

  return (
    <div className="substitute-wrapper">
      <div className="substitute-area">
        <div className="header">
          <span className="header-group">
            <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
              <Button variant={selection === 'Future' ? 'contained' : 'outlined'} onClick={() => setSelection('Future')}>Fremtidige</Button>
              <Button variant={selection === 'Last7Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last7Days')}>siste 7 dager</Button>
              <Button variant={selection === 'Last14Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last14Days')}>siste 14 dager</Button>
              <Button variant={selection === 'All' ? 'contained' : 'outlined'} onClick={() => setSelection('All')}>Alle</Button>
            </ButtonGroup>
          </span>
          <span className="header-button">
            <ButtonGroup fullWidth aria-label="outlined create button">
              <Button onClick={() => { setSubExist(0); setEditSubstitute(true); }} variant="contained"><AddIcon /></Button>
            </ButtonGroup>
          </span>
        </div>
        <TextField
          sx={{ mt: 1, mb: 1 }}
          label="Søk vikarer"
          type="search"
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <SubList
          subs={subResult}
          isLoading={isLoading || isFetching}
          onClick={(value: number) => { setSubExist(value); setEditSubstitute(true); }}
        />
      </div>

      <div className="close-button">
        <Button variant="outlined" onClick={onClose}>Lukk</Button>
      </div>
      {editSubstitute && (
      <SubstituteModal
        internalNumber={id}
        currentDate={currentDate}
        isEdit
        subExist={subExist}
        title={subExist ? 'Endre vikar' : 'Opprett vikar'}
        onClose={() => { setSubExist(0); setEditSubstitute(false); }}
      />
      )}
    </div>
  );
};
