import { FC } from 'react';
import { TextField } from '@mui/material';
import { Loading } from './Loading';
import { format } from '../../../shared/logic/dates';
import { Staff } from '../../../core/types/staff';

export const Details: FC<{
    worker: Staff|undefined,
    isLoading: boolean,
    cardNumber?: string;
  }> = ({
    worker,
    isLoading = false,
    cardNumber,
  }) => {
    if (!worker || isLoading) return <Loading />;

    return (
      <div className="worker-details">
        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.mobilePhoneWork ? 'Ingen data' : worker.mobilePhoneWork}
            label="Telefon"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.email ? 'Ingen data' : worker.email}
            label="Epost"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.department ? 'Ingen data' : worker.department}
            label="Avdeling"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.leader ? 'Ingen data' : worker.leader}
            label="Leder"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.jobType ? 'Ingen data' : worker.jobType}
            label="Arbeid"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.jobRole ? 'Ingen data' : worker.jobRole}
            label="Rolle"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={cardNumber || ' '}
            label="HMS kortnummer"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          {worker.lastEmploymentDay && (
            <TextField
              fullWidth
              value={format(new Date(worker.lastEmploymentDay), 'dd.MM.yyyy')}
              label="Sluttdato"
              variant="standard"
              multiline
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              InputLabelProps={{ sx: { fontSize: 17 } }}
            />
          )}
        </div>
      </div>
    );
  };
