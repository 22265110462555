import { useState } from 'react';
import { Draggable } from '@fullcalendar/interaction';
import { getEventColorFromStatus } from '../../../core/helpers/eventRender';
import { getAttributes } from '../../../core/helpers/functions';

export const useAttachDraggable = () => {
  const [draggable, setDraggable] = useState<boolean>(false);

  const attachDraggable = () => {
    if (draggable) {
      return; // Draggable is already connected
    }
    const draggableEl = document.getElementsByClassName('make-draggable');
    if (Object.values(draggableEl).length === 0) {
      setTimeout(attachDraggable, 500);
      return;
    }

    Object.values(draggableEl).forEach((e) => new Draggable(e as HTMLElement, {
      eventData(eventEl: { getAttribute: (arg0: string) => any; }) {
        const title = eventEl.getAttribute('data-title');
        const id = eventEl.getAttribute('data-id');
        const duration = eventEl.getAttribute('data-duration');
        const loadId = parseInt(eventEl.getAttribute('data-loadid'), 10);
        const assignmentType = eventEl.getAttribute('data-assignmenttype');
        const extprops = getAttributes(eventEl, ['from', 'to', 'orderType', 'assignmentType', 'loadType', 'time', 'contact', 'isInternal']);
        extprops.type = 'assignment';
        return {
          title,
          id,
          editable: true,
          duration,
          loadId,
          assignmentType,
          color: getEventColorFromStatus('UnderPlanning'),
          extendedProps: extprops,
        };
      },
    }));
    setDraggable(true);
  };

  return attachDraggable;
};
