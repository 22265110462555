import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import HardwareIcon from '@mui/icons-material/Hardware';
import { SecurityTraining } from '../../types/securityTraining';

export const SecurityTrainings: FC<{
    secs: SecurityTraining[]|undefined,
    isLoading: boolean,
  }> = ({
    secs,
    isLoading = false,
  }) => {
    if (!secs || isLoading) return null;

    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Kurs</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Påkrevd</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Ønskelig</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Dato</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...secs].sort((a, b) => a.name.localeCompare(b.name)).map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="td" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.required ? 'Ja' : 'Nei'}</TableCell>
                <TableCell>{row.desired ? 'Ja' : 'Nei'}</TableCell>
                <TableCell scope="row">{row.date}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>{row.required && row.date === undefined
                  ? <HardwareIcon color="warning" />
                  : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
