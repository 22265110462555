import { ReactNode } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsOrderType } from '../core/hooks/useIsType';
import { MassPicker, useIsMassValid } from './components/MassPicker';
import { ContainerPicker, useIsContainersValid } from './components/ContainerPicker';
import { UpdateOrder } from '../core/types/api/updateOrder';
import { UpdateLoad } from '../core/types/api/updateLoad';
import { OrderType } from '../core/types/enums/orderType';
import { MachinePicker } from '../components/MachinePicker';

export const useIsLoadsValid = (type: OrderType|undefined, loads: UpdateLoad[]) => {
  const massValid = useIsMassValid(type, loads);
  const containerValid = useIsContainersValid(type, loads);
  return massValid && containerValid;
};

const LoadWrapper = ({ children, title = 'Last' }: {children: ReactNode, title?: string}) => (
  <Box sx={{ mt: 2 }}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          sx={{ fontSize: 18, fontWeight: 'bold' }}
          color="primary"
        >{title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  </Box>
);

export const LoadPicker = ({
  value,
  onChange,
}: {
  value: Partial<UpdateOrder>,
  onChange: (v: Partial<UpdateOrder>) => void,
}) => {
  const isType = useIsOrderType(value.type);
  if (!value.type) return null;
  if (isType('MassIn', 'MassOut')) {
    return (
      <LoadWrapper>
        <MassPicker
          value={value.loads ?? []}
          onChange={onChange}
          type={value.type}
        />
      </LoadWrapper>
    );
  }
  if (isType('Container')) {
    return (
      <LoadWrapper>
        <ContainerPicker
          value={value.loads ?? []}
          onChange={(loads) => onChange({ loads })}
          type={value.type}
          fromProjectId={value.fromProject}
        />
      </LoadWrapper>
    );
  }
  if (isType('Haul')) {
    const load = value.loads?.[0];
    if (!load) return null;
    return (
      <LoadWrapper title="Maskin">
        <Box sx={{ pb: 2 }}>
          <MachinePicker
            value={{
              internal: load.machineInternalNumber,
              external: load.machineExternal,
            }}
            onChange={({ internal, external }) => onChange({
              loads: [{
                ...load,
                machineInternalNumber: internal ?? undefined,
                machineExternal: external ?? undefined,
              }],
            })}
          />
        </Box>
      </LoadWrapper>
    );
  }
  return null;
};
