import {
  Box,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Load, LoadItem } from '../../../core/types/load';

export const MassPicker = <L extends LoadItem|Load>({
  value,
  onChange,
  disabled = false,
}: {
  value: L[],
  onChange: (loads: L[]) => void,
  disabled?: boolean,
}) => {
  const [inactiveLoad, setInactiveLoad] = useState<number|null>(
    value.filter((l) => l.amount === 0)[0]?.id || null,
  );

  const updateLoad = (
    load: L,
    e?: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
  ) => {
    const newLoads = value.map((l) => {
      if (l.id !== load.id) return l;
      let { amount } = l;
      if (e?.target.value) {
        amount = Math.max(parseInt(e.target.value, 10), 1);
      }
      return {
        ...l,
        amount: Math.min(amount, 1000),
      };
    });
    onChange(newLoads);
  };

  const handlecheckbox = (
    l: L,
  ) => () => {
    const load = l;
    const isInactive = inactiveLoad === l.id;
    if (isInactive) {
      setInactiveLoad(null);
      load.amount = 1;
    } else {
      setInactiveLoad(l?.id || null);
      load.amount = 0;
    }
    updateLoad(load);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: 1.5 }} className="flexcenter underline">
        <Box flex={1}>Last</Box>
        <Box flex={1}>Mengde</Box>
      </Box>

      { value.map((l) => (
        <Box key={l.id} sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
          <Box
            flex={1}
            sx={{ display: 'flex', alignItems: 'center' }}
            className={l.id !== inactiveLoad ? '' : 'strike-text'}
          >
            <Checkbox
              onClick={handlecheckbox(l)}
              checked={l.id !== inactiveLoad}
              disabled={(!!inactiveLoad && inactiveLoad !== l.id) || (value.length === 1)}
            />
            <Box sx={{ pb: '2px' }}>{l.type?.name}</Box>
          </Box>
          <Box flex={1} className={l.id !== inactiveLoad ? '' : 'strike-text'}>
            <OutlinedInput
              type="number"
              fullWidth
              endAdornment={<InputAdornment position="end">m³</InputAdornment>}
              value={(inactiveLoad !== l.id ? Math.max(1, l.amount) : 0) || ''}
              size="small"
              inputProps={{ type: 'number', step: 1, min: 0 }}
              disabled={inactiveLoad === l.id || disabled}
              onChange={(e) => {
                updateLoad(l, e);
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
