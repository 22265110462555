import React, { FC, useCallback, useState } from 'react';
import {
  addDays,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { Button, ButtonGroup } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

export const DaySwitcher: FC<{
  value: Date,
  onChange: (d: Date) => void,
}> = ({
  value,
  onChange,
}) => {
  const [pickerVal, setPickerVal] = useState<Date>(value);
  const switchDay = (date: Date) => {
    onChange(date);
    setPickerVal(date);
  };
  const getRelativeDay = useCallback((days: number) => {
    if (days < 0) return subDays(value, Math.abs(days));
    if (days > 0) return addDays(value, days);
    return value;
  }, [value]);
  return (
    <div className="day-switcher">
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          variant="contained"
          onClick={() => switchDay(getRelativeDay(-1))}
        >
          {'<'}
        </Button>
        <Button
          variant="contained"
          disabled={isSameDay(value, new Date())}
          onClick={() => switchDay(startOfDay(new Date()))}
        >
          I dag
        </Button>
        <Button
          variant="contained"
          onClick={() => switchDay(getRelativeDay(1))}
        >
          {'>'}
        </Button>
      </ButtonGroup>
      <MobileDatePicker
        value={pickerVal}
        onChange={(v) => setPickerVal(v || value)}
        onOpen={() => setPickerVal(value)}
        label="Velg dato"
        onAccept={(newValue) => {
          if (newValue) onChange(startOfDay(newValue));
        }}
        renderInput={({ inputRef, inputProps }) => (
          <input
            className="day-text"
            readOnly
            ref={inputRef}
            value={inputProps?.value}
            onClick={inputProps?.onClick}
          />
        )}
      />
    </div>
  );
};
