import React, { FC, ComponentType } from 'react';

/**
 * Displays data with a header and the data underneath
 */
export const Data: FC<{
  header: string,
  data: string | ComponentType,
  className?: string,
  textAbove?: boolean,
  show?: boolean,
}> = (
  {
    header,
    data,
    className,
    textAbove = false,
    show = true,
  },
) => {
  const DataComponent = data;
  const cn = [];
  cn.push('data-component');
  cn.push(textAbove ? 'text-above' : 'text-left');
  if (className) cn.push(className);
  if (!show) return null;
  return (
    <div className={cn.join(' ')}>
      <div className="data-title">
        {header}
      </div>
      {
        typeof data === 'string' ? (
          <div className="data-content">
            {data}
          </div>
        ) : (
          <DataComponent />
        )
      }
    </div>
  );
};
