import { Machine } from '../../shared/types/machine';
import { useIsType } from '../hooks/useIsType';
import { AssignmentType } from '../types/enums/assignmentType';
import { Load } from '../types/load';

export const combineLoads = (arr: Load[]) => {
  const loads: Load[] = [];
  arr.forEach((n) => { // N for New
    let index = null;
    let amount = 0;
    loads.some((o, i) => { // O for Old
      // Return false if loads don't match so the search can continue
      if (o.containerInternalNumber !== n.containerInternalNumber) return false;
      if (o.containerSubcategory !== n.containerSubcategory) return false;
      if (o.containerType !== n.containerType) return false;
      if (o.wasteType !== n.wasteType) return false;
      if (o.type?.id !== n.type?.id) return false;
      index = i;
      amount = n.amount;
      return true; // Stop execution of some method
    });
    if (index === null) {
      loads.push({ ...n });
    } else {
      loads[index].amount += amount;
    }
  });
  return loads;
};

/** Replace M3 with m³ */
export const replaceM3 = (str?: string) => str && str.replace(/([mM])3/, 'm³');

/** Trim off KROKCONTAINER */
export const trimSubcat = (str?: string) => str && str.replace(/^KROKCONTAINER/, '');

/**
 * Find the active subcategory
 * @param load Load to find subcat for
 * @param type Assignment type
 * @returns Subcat or null if none found
 */
export const formatCategory = (
  load: Load,
  type: AssignmentType | undefined,
) => {
  const isType = useIsType(type);
  const {
    containerSubcategory,
    container,
    containerType,
    containerExternal,
  } = load;

  if (containerExternal) {
    return `(Ekstern) ${containerExternal}`;
  }
  if (isType('ContainerDeliver') && containerSubcategory) {
    return trimSubcat(replaceM3(containerSubcategory));
  }
  if (isType('Container') && container) {
    return trimSubcat(replaceM3(container.subCategoryName));
  }
  if (containerType) {
    return replaceM3(containerType);
  }
  return null;
};

/** Format a machine to a string */
export const formatMachine = (m: Machine) => (
  `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}`
);

/**
 * Format a load to a string
 * @param load Load object
 * @param type Assignment type
 * @param noContentStr String to use instead of contents when empty
 * @returns Text representation of load
 */
export const formatLoad = (
  load: Load,
  type: AssignmentType|undefined,
  noContentStr: string = ' (tom)',
) => {
  const isType = useIsType(type);
  const {
    amount,
    container,
    type: rawType,
    wasteType,
    machine,
    machineInternalNumber,
    machineExternal,
  } = load;
  const loadType = rawType?.name;

  let contents = noContentStr;
  if (isType('ContainerDeliver')) {
    contents = loadType ? ` med ${loadType}` : contents;
  } else if (isType('Container') && wasteType) {
    contents = ` med ${wasteType}`;
  }

  const subcat = formatCategory(load, type);

  if (isType('ContainerDeliver')) {
    return `${amount}stk ${subcat}${contents}`;
  }
  if (isType('Container')) {
    if (!container?.internalNumber) return `${subcat}${contents}`;
    return `${container?.internalNumber} - ${subcat}${contents}`;
  }
  if (isType('Haul')) {
    if (machine) return formatMachine(machine);
    return machineInternalNumber ?? machineExternal;
  }
  return `${load.amount}m³ ${load.type?.name}`;
};
