import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { RootState } from '../../../core/redux';
import { useGetMassTypesQuery, useGetProjectsQuery } from '../../../core/redux/transport';
import {
  setLoadTypes,
  setProjects,
  setAssignmentStatus,
  useIsFiltersActive,
} from '../../../core/redux/assignmentFilter';
import { MassType } from '../../../core/types/massType';
import { assignmentStatus as assignmentStatuses } from '../../../core/types/enums/assignmentStatus';
import { getAssignmentStatusType } from '../../../core/helpers/translate';

export const AssignmentFilter: FC<{
  show?: boolean,
}> = ({
  show = true,
}) => {
  const {
    projects,
    loadTypes,
    assignmentStatus,
  } = useSelector((state: RootState) => state.assignmentFilter);
  const dispatch = useDispatch();
  const [massTypes, setMassTypes] = useState<MassType[]>([]);
  const { data: rawMassTypes } = useGetMassTypesQuery();
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false);
  const [isLoadOpen, setIsLoadOpen] = useState<boolean>(false);
  const [isProjectOpen, setIsProjectOpen] = useState<boolean>(false);
  useEffect(() => {
    if (rawMassTypes) {
      setMassTypes((mt) => {
        const newMassTypes = [...mt];
        rawMassTypes.forEach((rmt) => {
          if (!newMassTypes.some((c) => c.name === rmt.name)) {
            newMassTypes.push(rmt);
          }
        });
        return newMassTypes;
      });
    }
  }, [rawMassTypes]);
  const { data: allProjects = [] } = useGetProjectsQuery();
  const filterActive = useIsFiltersActive();

  return (
    <div className="assignment-filter-partial">
      <div style={{ display: show || filterActive ? 'block' : 'none' }}>
        <FormControl sx={{ p: '8px 0' }} className="formcontrol-flex-row">
          <div className="width33">
            <Autocomplete
              multiple
              id="assignmentStatus"
              disableCloseOnSelect
              value={assignmentStatus}
              onChange={((e, ns) => (dispatch(setAssignmentStatus(ns))))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={assignmentStatuses.filter((a) => !a.includes('Deleted')).map((s) => ({ id: s, label: `${getAssignmentStatusType(s)}` }))}
              onOpen={() => setIsStatusOpen(true)}
              onClose={() => setIsStatusOpen(false)}
              renderTags={(items) => (
                !isStatusOpen
                && (
                <span className="filter-display-line">
                  <span className="one-line-ellipse filter-display-width">
                    {`${items[0].label}`}
                  </span>
                  <span>
                    {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
                  </span>
                </span>
                )
              )}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Oppdragsstatus"
                  size="small"
                />
              )}
            />
          </div>
          <div className="width33">
            <Autocomplete
              multiple
              id="loadType"
              disableCloseOnSelect
              value={loadTypes}
              onChange={((e, ns) => (dispatch(setLoadTypes(ns))))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={massTypes.map((m) => ({ id: m.id, label: m.name }))}
              onOpen={() => setIsLoadOpen(true)}
              onClose={() => setIsLoadOpen(false)}
              renderTags={(items) => (
                !isLoadOpen
                && (
                <span className="filter-display-line">
                  <span className="one-line-ellipse filter-display-width">
                    {`${items[0].label}`}
                  </span>
                  <span>
                    {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
                  </span>
                </span>
                )
              )}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Last"
                  size="small"
                />
              )}
            />
          </div>
          <div className="width33">
            <Autocomplete
              multiple
              id="project"
              disableCloseOnSelect
              value={projects}
              onChange={((e, ns) => (dispatch(setProjects(ns))))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={allProjects.map((m) => ({ id: m.id, label: `${m.id} - ${m.projectName}` }))}
              onOpen={() => setIsProjectOpen(true)}
              onClose={() => setIsProjectOpen(false)}
              renderTags={(items) => (
                !isProjectOpen
                && (
                <span className="filter-display-line">
                  <span className="one-line-ellipse filter-display-width">
                    {`${items[0].label}`}
                  </span>
                  <span>
                    {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
                  </span>
                </span>
                )
              )}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Prosjekt"
                  size="small"
                />
              )}
            />
          </div>
        </FormControl>
      </div>
    </div>
  );
};
