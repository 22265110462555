import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Container } from '../../../core/types/container';
import { useGetContainerProjectAssignmentsQuery, useGetProjectsQuery } from '../../../core/redux/transport';
import { ContainerInvoicingRow } from './ContainerInvoicingRow';
import { SelectItem } from '../../../shared/types/util/selectItem';
import { Divider } from '../../../components/DriverComponents/ContainerSelector/partials/Divider';

export const ContainerInvoicing: FC<{
  container: Container;
}> = ({
  container,
}) => {
  const { data, isLoading } = useGetContainerProjectAssignmentsQuery(container.internalNumber, { pollingInterval: 30000 });
  const { data: projects } = useGetProjectsQuery();
  const [projectFilter, setProjectFilter] = useState<SelectItem[]>([]);
  const [from, setFrom] = useState<Date|null>(null);
  const [to, setTo] = useState<Date|null>(null);
  const [invoicedYes, setInvoicedYes] = useState<boolean>(true);
  const [invoicedNo, setInvoicedNo] = useState<boolean>(true);

  const projectList: SelectItem[] = projects?.filter((p) => data?.some((d) => d.project.id === p.id))?.map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` })) || [];

  const filteredData = useMemo(() => ((data && data
    .filter((d) => (projectFilter?.length === 0 || projectFilter?.some((p) => p.id === d.project.id)))
    .filter((d) => (from ? from <= new Date(d.from) : true))
    .filter((d) => (to && d.to ? to >= new Date(d.to) : true))
    .filter((d) => ((invoicedYes && d.invoiceable) || (invoicedNo && !d.invoiceable))))
  ), [data, projectFilter, from, to, invoicedYes, invoicedNo]);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Typography fontSize="18px">Filter</Typography>
        <Box display="flex" gap={3}>
          <Box width="45%">
            <Autocomplete
              fullWidth
              multiple
              value={projectFilter}
              id="project"
              size="small"
              disableCloseOnSelect
              onChange={(e, ns) => setProjectFilter(ns)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={projectList.map((p) => ({ id: p.id, label: `${p.id} - ${p.label}` }))}
              renderInput={(params) => (
                <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Prosjekt"
                  size="small"
                />
              )}
            />
          </Box>
          <Box display="flex" gap={3}>
            <DatePicker
              value={from}
              label="Fra"
              onChange={(d) => (d !== null ? setFrom(d) : setFrom(null))}
              renderInput={(params: any) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} size="small" />
              )}
            />
            <DatePicker
              value={to}
              label="Til"
              onChange={(d) => (d !== null ? setTo(d) : setTo(null))}
              renderInput={(params: any) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} size="small" />
              )}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>Faktureres</Typography>
            <ToggleButton size="small" color="primary" selected={invoicedYes} value="Ja" onChange={() => setInvoicedYes(!invoicedYes)}>Ja</ToggleButton>
            <ToggleButton size="small" color="primary" selected={invoicedNo} value="Nei" onChange={() => setInvoicedNo(!invoicedNo)}>Nei</ToggleButton>
          </Box>
        </Box>
        <Divider />
      </Box>
      <TableContainer>
        {!isLoading && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell component="th">Prosjekt</TableCell>
              <TableCell component="th">Fra</TableCell>
              <TableCell component="th">Til</TableCell>
              <TableCell component="th">Faktureres?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((r) => <ContainerInvoicingRow key={r.uid} row={r} />)}
          </TableBody>
        </Table>
        )}
      </TableContainer>
    </>
  );
};
