import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  addMinutes,
  formatISO,
  getUnixTime,
  setHours,
  setMinutes,
} from 'date-fns';
import { toast } from 'react-toastify';
import {
  updateDuration,
  extractData,
  getCurrentDriverName,
  getCurrentDriver,
  forI,
  emptyGuard,
  countProperty,
  isContainerInIntermediateStorage,
} from '../../core/helpers/functions';
import {
  useGetAllContainersQuery,
  usePostCreateAssignmentsMutation,
} from '../../core/redux/transport';
import { ProjectPicker } from '../ProjectPicker';
import { Order } from '../../core/types/order';
import { Load } from '../../core/types/load';
import { Project } from '../../core/types/project';
import { NewAssignment } from '../../core/types/api/newAssignment';
import { CallLink } from '../CallLink';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { AssignmentType } from '../../core/types/enums/assignmentType';
import { useIsType } from '../../core/hooks/useIsType';
import { DurationPicker } from '../AssignmentFields/DurationPicker';
import { LoadPicker } from '../AssignmentFields/LoadPicker';
import { formatDate } from '../../core/helpers/dates';
import { getTransportType } from '../../core/helpers/translate';
import { MAX_CONTAINERS } from '../../shared/constants';
import { ContainerIntermediateStorage } from '../ContainerIntermediateStorage';
import { useProjectPermissions } from '../../core/hooks/useProjectPermissions';
import { useDefaultDepo } from '../../core/hooks/useDefaultDepo';
import { getTypeColor } from '../../core/helpers/eventRender';

export interface LoadItem extends Load {
  id: number,
  amount: number,
  label?: string,
  checked?: boolean,
}

interface Trip {
  id: string,
  start: Date,
  end: Date,
}

// TODO: Also bring in collection project and waste type - for copying
export interface CreateAssignmentData {
  vehicle: string;
  order: Order;
  type?: AssignmentType;
  startTime: Date;
  fromProject?: Project;
  toProject?: Project;
  duration?: number;
  privateComment?: string;
  driverComment?: string;
  orderComment?: string;
  loads?: LoadItem[];
  single?: boolean;
  loadId?: number;
}

interface CreateAssignmentModalProps {
  event: CreateAssignmentData|null;
  today: Date;
  onClose: () => void;
  canChangeVehicle?: boolean;
}

export const CreateAssignmentModal: FC<CreateAssignmentModalProps> = ({
  event = null,
  today,
  onClose,
  canChangeVehicle = false,
}) => {
  const [postAssignments] = usePostCreateAssignmentsMutation();
  const { data: allContainers } = useGetAllContainersQuery({ includeAll: true });
  const { data: vehicles } = useVehicleList();

  const [inactiveLoad, setInactiveLoad] = useState<number | null>(null);
  const [addingOrder, setAddingOrder] = useState<Order|null>(null);
  const [fromProject, setFromProject] = useState<Project|null>(null);
  const [toProject, setToProject] = useState<Project|null>(null);
  const [collectionProject, setCollectionProject] = useState<Project|null>(null);
  const [externalCollectionProject, setExternalCollectionProject] = useState<string|undefined>();
  const [showExternalCollectionProject, setShowExternalCollectionProject] = useState<boolean>(false);
  const [intermediateStorage, setIntermediateStorage] = useState<boolean>(false);
  const [fromTime, setFromTime] = useState<Date|null>(null);
  const [allDay, setAllDay] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(45); // In minutes
  const [inBetweenTime, setInBetweenTime] = useState<number>(45); // In minutes
  const [loads, setLoads] = useState<LoadItem[]>([]);
  const [repeat, setRepeat] = useState<number>(0);
  const [selectedVehicle, setSelectedVehicle] = useState<string|null>(null);
  const [contactPersonName, setContactPersonName] = useState<string|null>(null);
  const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState<string|null>(null);
  const [orderComment, setOrderComment] = useState<string>('');
  const [initialOrderComment, setInitialOrderComment] = useState<string|undefined>(undefined);
  const [driverComment, setDriverComment] = useState<string>('');
  const [privateComment, setPrivateComment] = useState<string>('');
  const [lockState, setLockState] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  /** Whether the assignment is single or multi-load */
  const [singleLoad, setSingleLoad] = useState<boolean>(false);
  const [assignmentType, setAssignmentType] = useState<AssignmentType|null>(null);

  const showSuccess = () => {
    toast.success('Oppdrag opprettet');
  };

  const orderIsType = useIsType(event?.order.type || addingOrder?.type);
  const isType = useIsType(assignmentType || event?.type);
  const projectEditable = useProjectPermissions(assignmentType || event?.type);

  const totalAmount = () => {
    if (!addingOrder) return 0;
    const totalMass = extractData(addingOrder).totalAmount;
    return (addingOrder.details ? totalMass - addingOrder.details.totalAmount : totalMass);
  };

  const getMaxLoads = () => {
    if (allDay || isType('Container') || isType('Haul')) {
      return 1;
    }

    // Total load being taken by the vehicle
    const totalLoad = loads.reduce((acc, val) => acc + val.amount, 0);

    // Total time is 8 hours every day, but add 15 minutes to support 6 loads per day @45 min
    // Note: The inBetween time is counted one time less than duration (n trips has n-1 gaps).
    // total duration = trips*duration + (trips-1)*inBetweenTime
    // => trips = (total duration + inBetweenTime)/(duration + inBetweenTime)
    const mostLoadsTime = Math.floor((60 * 8 + 15 + inBetweenTime) / ((duration + inBetweenTime)));

    // total amount divided by the amount the vehicle can carry at a time
    const mostLoadsWeight = Math.ceil(totalAmount() / totalLoad);

    return Math.min(mostLoadsTime, mostLoadsWeight);
  };

  const maxLoads = getMaxLoads();

  const fullDayStart = new Date(today);
  fullDayStart.setHours(7, 0, 0);

  const fullDayEnd = new Date(today);
  fullDayEnd.setHours(15, 0, 0);

  const trips: Trip[] = [];
  if (allDay) {
    trips.push({ id: 'allday', start: fullDayStart, end: fullDayEnd });
  } else if (fromTime) {
    let currentStart = new Date(fromTime.getTime());
    for (let i = 0; i < repeat; i += 1) {
      const start = new Date(currentStart.getTime());
      const end = addMinutes(currentStart, duration);
      trips.push({
        id: `${start.getTime()}${end.getTime()}`,
        start,
        end,
      });
      currentStart = addMinutes(currentStart, duration + (duration < 240 ? inBetweenTime : 0));
    }
  }

  const getLoads = (e: CreateAssignmentData, o: Order): LoadItem[] => {
    const eventLoads = e.loads || [];
    const orderLoads = o.loads || [];
    const assignedLoads = o.vehicles?.flatMap((v) => v.assignments).flatMap((a) => a.loads) || [];
    const localIsType = useIsType(e.type);

    if (eventLoads.length > 0) return eventLoads;

    const getAmount = (l: Load) => {
      if (localIsType('ContainerDeliver')) {
        if (e.loadId === l.id) return 1;
        if (orderLoads.length > 1) return 0;
        return 1;
      }
      if (localIsType('Container')) {
        if (e.loadId === l.id) return 1;
        if (e.loadId) return 0;
        if (assignedLoads.some((x) => (l.containerExternal ? x.containerExternal === l.containerExternal : x.containerInternalNumber === l.containerInternalNumber))) {
          return 0;
        }
        return 1;
      }

      // Assignment is ordinary mass transport
      if (o?.vehicle === 'Single') {
        return Math.min(l.amount, 10);
      }
      return Math.min(l.amount, 20);
    };

    if (event && event.order.type === 'ContainerCollect' && event.type === 'ContainerEmptying') {
      const selectedLoad = orderLoads.find((l) => l.id === e.loadId);
      const selectedContainer = selectedLoad?.containerExternal || selectedLoad?.containerInternalNumber;
      return o.vehicles?.flatMap((v) => v.assignments)
        .filter((a) => a.status === 'Completed')
        .flatMap((a) => a.loads)
        .filter((l) => isContainerInIntermediateStorage(o, l))
        .map((l) => ({
          id: l.id,
          amount: (l.containerExternal || l.containerInternalNumber) === selectedContainer ? 1 : 0,
          containerExternal: l.actualContainerExternal,
          containerInternalNumber: l.actualContainerInternalNumber,
          container: l.actualContainer,
          wasteType: l.wasteType,
        })) || [];
    }

    return orderLoads.map((l) => ({
      ...l,
      amount: getAmount(l),
      label: l.type?.name,
    }));
  };

  useEffect(() => (
    singleLoad
      ? setRepeat((r) => Math.min(r, maxLoads))
      : setRepeat(maxLoads)
  ), [maxLoads]);

  /** Original assignment type from input data */
  const originalIsType = useIsType(event?.type);
  /** Original order type from input data */
  const originalOrderIsType = useIsType(event?.order.type);

  const eventReceived = () => {
    if (!event) return;
    const { order } = event;
    setFromTime(event.startTime);
    setDuration(event.duration || 45);
    setInBetweenTime(45);
    setFromProject(event.fromProject || order.fromProject || null);
    setCollectionProject(null);
    setExternalCollectionProject(undefined);
    setIntermediateStorage(false);
    setToProject(event.toProject || order.toProject || null);
    setRepeat(event.single || originalIsType('Container', 'Haul') ? 1 : 0);
    setLoads(getLoads(event, order));
    setAddingOrder(order || null);
    setSelectedVehicle(event.vehicle);
    setContactPersonName(order.contactPersonName);
    setContactPersonPhoneNumber(order.contactPersonPhoneNumber);
    setOrderComment(event.orderComment || order.comment || '');
    setInitialOrderComment(orderComment);
    setDriverComment(event.driverComment || '');
    setPrivateComment(event.privateComment || '');
    setSingleLoad(event.single || false);
    setAllDay(false);
    setLockState(false);
    setAssignmentType(event.type || order.type || null);
    setShowExternalCollectionProject(false);
  };
  useEffect(eventReceived, [event]);

  /** From or to project should be set to depo if true */
  const defaultDepoRequired = originalOrderIsType('ContainerEmptying') && originalIsType('ContainerDeliver', 'ContainerCollect');
  /** Depo project object */
  const defaultDepo = useDefaultDepo(!defaultDepoRequired);

  useEffect(() => {
    const comment = [fromProject?.fixedDriverComment, toProject?.fixedDriverComment]
      .filter(Boolean)
      .join('\n');

    setDriverComment(comment);
  }, [fromProject, toProject]);

  useEffect(() => {
    if (event?.order.type === 'ContainerEmptying' && event.type === 'ContainerDeliver') {
      setToProject(event.fromProject || event.order.fromProject || null);
      setFromProject(defaultDepo || null);
    }
    if (event && event.order.type === 'ContainerCollect' && event.type === 'ContainerEmptying') {
      const cn = loads.filter((l) => l.amount > 0)[0]?.containerInternalNumber;
      const activeProject = allContainers?.find((c) => c.internalNumber === cn)?.project;
      setFromProject(activeProject || defaultDepo || null);
      setToProject(activeProject || defaultDepo || null);
    }
  }, [event, defaultDepo, allContainers, loads]);

  // Set project to depo on split emptying:
  // from project for deliver and to project for collect
  useEffect(() => {
    if (defaultDepoRequired && defaultDepo) {
      if (originalIsType('ContainerDeliver')) {
        setFromProject(defaultDepo);
      } else {
        setToProject(defaultDepo);
      }
    }
  }, [event, defaultDepoRequired, defaultDepo, setFromProject]);

  const getValidDuration = (d: number) => {
    let rounded = Math.round((d || 0) / 15) * 15;
    while (rounded <= 0) {
      rounded += 1440; // 24 hours in minutes (60 * 24)
    }
    return rounded;
  };

  const toTime = fromTime ? addMinutes(fromTime, duration) : null;

  const updateToTime = (e: Date|null) => {
    if (!fromTime || !e || !today) return;
    const startUnix = Math.round(getUnixTime(fromTime) / 60); // in minutes
    const endUnix = Math.round(getUnixTime(e) / 60); // in minutes
    const delta = endUnix - startUnix;
    setDuration(getValidDuration(delta));
  };

  /**
   * Rounds the duration to the nearest 15 minutes and sets a minimum of 15
   */
  const validateDuration = () => {
    setDuration(getValidDuration(duration));
  };

  useEffect(validateDuration, [duration]);

  const updateRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(e.target.value, 10) || 1;
    setRepeat(Math.max(1, Math.min(1000, num)));
  };

  const createInternalAssigment = () => {
    const startTime = allDay ? fullDayStart : fromTime;
    const endTime = fromTime ? addMinutes(fromTime, duration) : null;
    if (!toProject) {
      throw new Error('Prosjekt er ikke spesifisert');
    }
    if (!startTime || !endTime) {
      throw new Error('Fra og til er ikke spesifisert');
    }
    if (!addingOrder) {
      throw new Error('Bestilling må være spesifisert');
    }
    if (!selectedVehicle) {
      throw new Error('Kjøretøy må være spesifisert');
    }
    return postAssignments([{
      type: 'MassInternal',
      toProject: toProject?.id,
      startTime: allDay ? formatISO(fullDayStart) : formatISO(startTime),
      endTime: allDay ? formatISO(fullDayEnd) : formatISO(endTime),
      orderId: addingOrder.id,
      vehicleInternalNumber: selectedVehicle,
      commentForDriver: driverComment || '',
      orderComment: orderComment === initialOrderComment ? undefined : orderComment,
      comment: privateComment,
      lockState,
    }]).unwrap().then(() => {
      showSuccess();
      setAddingOrder(null);
      setSending(false);
      onClose();
    });
  };

  const sendAssignmentsData = async () => {
    if (!addingOrder) {
      throw new Error('Bestilling må defineres');
    }
    if (!selectedVehicle) {
      throw new Error('Kjøretøy må defineres');
    }
    if (isType('MassInternal')) {
      return createInternalAssigment();
    }
    const payloads = [];
    const remainingLoads: {type?: string, perLoad: number}[] = [];
    for (let i = 0; i < loads.length; i += 1) {
      remainingLoads.push({ type: loads[i].type?.id, perLoad: loads[i].amount });
    }
    for (let i = 0; i < repeat; i += 1) {
      const tripLoad = [];
      for (let j = 0; j < remainingLoads.length; j += 1) {
        const l = remainingLoads[j];
        tripLoad.push({ typeId: l.type, amount: l.perLoad });
      }

      const from = fromTime
        ? addMinutes(fromTime, (duration
            + (inBetweenTime)) * i) : null;
      const endTime = from ? addMinutes(from, duration) : null;
      payloads.push({
        loads: tripLoad,
        start: allDay ? fullDayStart : from,
        end: allDay ? fullDayEnd : endTime,
        commentForDriver: driverComment,
        comment: privateComment,
      });
    }

    if (!fromProject) {
      throw new Error('Fra prosjekt må defineres');
    }
    if (!toProject) {
      throw new Error('Til prosjekt må defineres');
    }
    if (isType('MassIn')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }
        return {
          type: 'MassIn',
          fromProject: fromProject?.id,
          toProject: toProject.id,
          orderId: addingOrder.id,
          vehicleInternalNumber: selectedVehicle,
          loads: p.loads.map(
            (l) => ({
              massTypeId: l.typeId
                ? parseInt(l.typeId, 10)
                : undefined,
              amount: l.amount,
            }),
          ),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          orderComment: orderComment === initialOrderComment ? undefined : orderComment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('MassOut')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }
        return {
          type: 'MassOut',
          fromProject: fromProject?.id,
          toProject: toProject.id,
          orderId: addingOrder.id,
          vehicleInternalNumber: selectedVehicle,
          loads: p.loads.map(
            (l) => ({
              massTypeId: l.typeId
                ? parseInt(l.typeId, 10)
                : undefined,
              amount: l.amount,
            }),
          ),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          orderComment: orderComment === initialOrderComment ? undefined : orderComment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('ContainerDeliver')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }

        const totalLoad = countProperty(loads, (l) => l.amount);
        if (totalLoad <= 0) throw new Error('Må ha minst én container');
        if (totalLoad > MAX_CONTAINERS) throw new Error('For mange containere er valgt');
        if (loads.some((l) => l.amount > 0 && !l.containerSubcategory)) {
          throw new Error('Aktiv last må ha subkatagori');
        }

        return {
          type: 'ContainerDeliver',
          fromProject: fromProject?.id,
          toProject: toProject.id,
          collectionProject: !showExternalCollectionProject
            ? collectionProject?.id
            : undefined,
          externalCollectionProject: showExternalCollectionProject
            ? externalCollectionProject
            : undefined,
          vehicleInternalNumber: selectedVehicle,
          orderId: addingOrder.id,
          loads: loads.filter((l) => l.amount > 0).flatMap((l) => forI(l.amount, () => ({
            ...l,
            amount: 1,
            massTypeId: l?.type ? parseInt(l?.type.id, 10) : undefined,
          }))),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('ContainerCollect')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }

        return {
          type: 'ContainerCollect',
          fromProject: fromProject?.id,
          toProject: toProject.id,
          collectionProject: intermediateStorage || showExternalCollectionProject
            ? undefined
            : collectionProject?.id,
          externalCollectionProject: intermediateStorage || !showExternalCollectionProject
            ? undefined
            : externalCollectionProject,
          intermediateStorage,
          vehicleInternalNumber: selectedVehicle,
          orderId: addingOrder.id,
          loads: loads.filter((l) => l.amount > 0).flatMap((l) => forI(l.amount, () => ({
            ...l,
            amount: 1,
            wasteType: emptyGuard(l.wasteType),
          }))),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('ContainerEmptying')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }
        if (!assignmentType) {
          throw new Error('No type specified');
        }

        return {
          type: assignmentType,
          fromProject: fromProject?.id,
          toProject: fromProject?.id,
          collectionProject: !showExternalCollectionProject
            ? collectionProject?.id
            : undefined,
          externalCollectionProject: showExternalCollectionProject
            ? externalCollectionProject
            : undefined,
          vehicleInternalNumber: selectedVehicle,
          orderId: addingOrder.id,
          loads: loads.filter((l) => l.amount > 0).flatMap((l) => forI(l.amount, () => ({
            ...l,
            amount: 1,
            wasteType: emptyGuard(l.wasteType),
            containerSubcategory: l.container?.subCategoryName,
          }))),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('ContainerSwap')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }
        if (!assignmentType) {
          throw new Error('No type specified');
        }

        return {
          type: assignmentType,
          fromProject: fromProject?.id,
          toProject: toProject.id,
          collectionProject: collectionProject?.id,
          vehicleInternalNumber: selectedVehicle,
          orderId: addingOrder.id,
          loads: loads.filter((l) => l.amount > 0).flatMap((l) => forI(l.amount, () => ({
            ...l,
            amount: 1,
            wasteType: emptyGuard(l.wasteType),
          }))),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    if (isType('Haul')) {
      return postAssignments(payloads.map((p): NewAssignment => {
        if (!p.start) {
          throw new Error('Start tid må være definert');
        }
        if (!p.end) {
          throw new Error('Slutt tid må være definert');
        }

        if (loads.length !== 1) throw new Error('Kun én maskin er tillatt');

        loads.forEach((l) => {
          if (!l.machineExternal && !l.machineInternalNumber && !l.machine) {
            throw new Error('Ingen maskin valgt');
          }
        });

        return {
          type: isType('HaulHook') ? 'HaulHook' : 'HaulSemi',
          fromProject: fromProject?.id,
          toProject: toProject.id,
          orderId: addingOrder.id,
          vehicleInternalNumber: selectedVehicle,
          loads: loads.map((l) => ({
            ...l,
            machine: undefined,
            machineInternalNumber: l.machineInternalNumber
              ?? l.machine?.internalNumber,
          })),
          commentForDriver: p.commentForDriver,
          comment: p.comment,
          lockState,
          startTime: formatISO(p.start),
          endTime: formatISO(p.end),
        };
      })).unwrap().then(() => {
        showSuccess();
        setAddingOrder(null);
        setSending(false);
        onClose();
      });
    }
    throw new Error(`Kunne ikke sende oppdrag med type ${getTransportType(assignmentType ?? undefined)}`);
  };

  const createAssignment = async () => {
    try {
      setSending(true);
      await sendAssignmentsData();
    } catch (e: any) {
      toast.error(e?.message || 'Kunne ikke sende inn');
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setSending(false);
  };

  // check if repeat is greater then 0
  const hasTrips = repeat > 0;

  /** True if outside of allowed limit */
  const splitLoadLimit = loads.length > 1
  && inactiveLoad === null
  && (loads[0].amount > 10 || loads[1].amount > 10);

  const arrayToSort = [...(vehicles || [])];

  arrayToSort.sort((a: any, b: any) => (
    getCurrentDriverName(a).localeCompare(getCurrentDriverName(b))));

  const isEmptyDeliverAssignment = event?.order.type === 'ContainerEmptying' && event?.type === 'ContainerDeliver';
  /** True if a load requires a collection project */
  const collectionProjectRequired = isType('Container')
  && !isEmptyDeliverAssignment
  && !intermediateStorage && loads.some((l) => (l.amount && (l.wasteType || l.type)));

  /** True if any containers have waste */
  const showIntermediateStorage = orderIsType('ContainerCollect')
    && isType('ContainerCollect')
    && loads.some((l) => l.amount && l.wasteType);

  /** Total mass for all loads */
  const totalMass = useMemo(() => loads.reduce((a, l) => a + l.amount, 0), [loads]);

  /** Check if all required values are present */
  const formValid = useMemo(
    () => {
    // If form is sending disallow resending
      if (sending) return false;
      // All must have a type
      if (!assignmentType) return false;
      // Everything except ContainerEmpty needs fromProject
      if (!isType('ContainerEmptying') && !toProject) return false;
      // Everything except massInternal needs fromProject
      if (!isType('MassInternal') && !fromProject) return false;
      // Container assignments may not have more than MAX_CONTAINER loads
      if (isType('Container') && totalMass > MAX_CONTAINERS) return false;
      // Most assignments must include at least one load
      if (!isType('MassInternal') && totalMass <= 0) return false;
      // If connection project is required, make sure it's there
      if (collectionProjectRequired && ((!showExternalCollectionProject && !collectionProject)
      || (showExternalCollectionProject && (!externalCollectionProject || externalCollectionProject?.trim().length === 0)))) return false;
      // If a load is enabled, it must have a container subcat
      if (isType('ContainerDeliver') && loads.some((l) => (
        l.amount > 0 && !l.containerSubcategory
      ))) return false;
      // Mass in and mass out must have trips
      if (isType('MassIn', 'MassOut') && !hasTrips) return false;
      // Check split load limit
      if (isType('MassIn', 'MassOut') && splitLoadLimit) return false;

      // Otherwise all is in order
      return true;
    },
    [
      sending,
      assignmentType,
      toProject,
      fromProject,
      totalMass,
      collectionProjectRequired,
      collectionProject,
      externalCollectionProject,
      showExternalCollectionProject,
      isType,
      loads,
      hasTrips,
      splitLoadLimit,
    ],
  );

  const containerLimitExceded = useMemo(() => loads.filter((l) => l.amount > 0).length > 3, [loads]);

  return (
    <Dialog
      open={!!addingOrder}
      onClose={() => {
        setInactiveLoad(null);
        setAddingOrder(null);
      }}
      maxWidth="md"
      fullWidth
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>
          {canChangeVehicle ? 'kopiér oppdrag' : 'Opprett oppdrag'}
        </DialogTitle>
        <Box padding={2}>
          <Chip
            color={getTypeColor(assignmentType)}
            label={assignmentType && getTransportType(assignmentType)}
            size="medium"
          />
        </Box>
      </Box>
      <DialogContent>
        <div className="flexcenter mt5">
          <span className="flexchild mr5">
            <FormControl size="small" fullWidth>
              <InputLabel>Velg kjøretøy</InputLabel>
              <Select
                value={selectedVehicle}
                label="Velg kjøretøy"
                disabled={!canChangeVehicle}
                onChange={(e) => {
                  setSelectedVehicle(e.target.value);
                }}
                defaultValue="Laster..."
              >
                {
                  !arrayToSort
                    ? null
                    : arrayToSort.map((p: any) => {
                      const currDriver = getCurrentDriver(p);
                      return (
                        <MenuItem value={p.internalNumber} key={p.internalNumber}>
                          {`${currDriver?.fullName
                            ? currDriver.fullName : 'Navn mangler'} - ${currDriver?.mobilePhoneWork
                            ? currDriver.mobilePhoneWork : 'telefonnummer mangler'}
                              - ${p.vehicleRegistrationPlateNumber} - ${p.subCategoryName} `}
                        </MenuItem>
                      );
                    })
                }
              </Select>
            </FormControl>
          </span>
        </div>

        <Box className="flexcenter mt40" gap={2}>
          {!isType('MassInternal') && (
            <span className="flexchild">
              <ProjectPicker
                showLastUsed={false}
                value={fromProject}
                onChange={setFromProject}
                label={isType('ContainerEmptying') ? 'Prosjekt' : 'Fra prosjekt'}
                size="small"
                disabled={!projectEditable.from}
                required
              />
            </span>
          )}
          {!isType('ContainerEmptying') && (
            <span className="flexchild">
              <ProjectPicker
                showLastUsed={false}
                value={toProject}
                onChange={setToProject}
                label={isType('MassInternal') ? 'Prosjekt' : 'Til prosjekt'}
                size="small"
                disabled={!projectEditable.to}
                required
              />
            </span>
          )}
        </Box>

        <Box className="flexcenter mt40" gap={2}>
          <span className="flexchild">
            <DateTimePicker
              value={(
                allDay
                  ? setHours(setMinutes(fromTime || new Date(), 0), 7)
                  : fromTime) || new Date()}
              onChange={(nd) => {
                if (!allDay) {
                  setFromTime(nd);
                } else {
                  // Don't set the time, only the date
                  const time = new Date(nd);
                  if (!fromTime) return;
                  time.setHours(fromTime.getHours());
                  time.setMinutes(fromTime.getMinutes());
                  setFromTime(time);
                }
              }}
              fullWidth
              label="Fra"
              disableTime={allDay}
            />
          </span>
          <span className="flexchild">
            <DateTimePicker
              value={(
                allDay
                  ? setHours(setMinutes(fromTime || new Date(), 0), 15)
                  : toTime) || new Date()}
              minDate={fromTime || undefined}
              onChange={(nd) => {
                updateToTime(nd);
              }}
              fullWidth
              label="Til"
              disabled={allDay}
            />
          </span>
        </Box>
        {!isType('Container') && (
          <>
            <Box className="flexcenter" gap={2}>
              <span className="flexchild">
                <FormControlLabel
                  label="07:00 - 15:00"
                  control={(
                    <Checkbox
                      checked={allDay}
                      onChange={(v) => setAllDay(v.target.checked)}
                    />
                  )}
                />
              </span>
              <span className="flexchild" />
            </Box>
            <div className="mt40">
              <DurationPicker
                value={duration}
                allDay={allDay}
                disabled={allDay}
                onChange={(v) => {
                  setDuration(v);
                }}
              />
            </div>
            {!isType('MassInternal', 'Haul') && (
            <div className="mt40">
              <DurationPicker
                title="Tomkjøring"
                value={allDay ? 480 : (inBetweenTime || 0)}
                allDay={allDay}
                disabled={allDay}
                onChange={(v) => (
                  allDay
                    ? null
                    : setInBetweenTime(updateDuration(`${v}`)))}
              />
            </div>
            )}
          </>
        )}
        <div className="mt40">
          <LoadPicker
            orderType={event?.order.type}
            assignmentType={assignmentType}
            projectId={fromProject?.id ?? undefined}
            value={loads}
            onChange={setLoads}
          />
        </div>
        {containerLimitExceded && (
        <Box sx={{ paddingTop: 1 }}>
          <Alert severity="warning">
            Max 3 containere
          </Alert>
        </Box>
        )}

        {showIntermediateStorage && (
          <ContainerIntermediateStorage
            value={intermediateStorage}
            onChange={setIntermediateStorage}
          />
        )}

        {/* Container collectionproject */}
        {collectionProjectRequired && (
        <Box paddingTop={2}>
          {showExternalCollectionProject ? (
            <TextField
              label={`Eksternt ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}`}
              value={externalCollectionProject}
              onChange={(v) => setExternalCollectionProject(v.target.value)}
              error={!externalCollectionProject}
              helperText={!externalCollectionProject ? `Skriv inn et ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}` : ''}
              size="small"
              fullWidth
            />
          ) : (
            <ProjectPicker
              showLastUsed={false}
              value={collectionProject}
              onChange={setCollectionProject}
              label={isType('ContainerDeliver') ? 'Hentested' : 'Tømmested'}
              size="small"
              required
            />
          )}
          <FormControlLabel
            sx={{ paddingTop: showExternalCollectionProject ? 0 : 3 }}
            label={`Eksternt ${isType('ContainerDeliver') ? 'hentested' : 'tømmested'}`}
            control={(
              <Checkbox
                onChange={() => setShowExternalCollectionProject(!showExternalCollectionProject)}
                checked={showExternalCollectionProject}
              />
          )}
          />
        </Box>
        )}

        {isType('MassIn', 'MassOut') && (
        <div className="flexcenter mt40">
          <span className="flexchild mr5">
            <TextField
              id="outlined-number"
              label="Antall oppdrag"
              type="number"
              value={Math.max(0, repeat)}
              error={!hasTrips}
              helperText={!hasTrips ? 'Bestillingen er allerede oppfylt' : ''}
              onChange={updateRepeat}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
            />
          </span>
          <span className="flex2 ml5 flexwrap">
            {trips.map((t) => (
              <span key={t.id} className="mr10">
                {formatDate(t.start, 'HH:mm')}-{formatDate(t.end, 'HH:mm')}
              </span>
            ))}
          </span>
        </div>
        )}

        <div className="flexcenter mt40">
          <span className="flexchild">
            <TextField
              value=" "
              label="Kontakt Person"
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <span className="contactPerson">
                    {`${contactPersonName} `}
                    { contactPersonPhoneNumber
                        && <CallLink phoneNumber={contactPersonPhoneNumber} /> }
                  </span>
                ),
              }}
            />
          </span>
        </div>
        <div className="flexcenter mt40">
          <span className="flexchild">
            <TextField
              id="outlined-multiline-flexible"
              label="Bestillingskommentar"
              fullWidth
              multiline
              maxRows={4}
              value={orderComment}
              onChange={(e) => setOrderComment(e.target.value)}
            />
          </span>
        </div>

        <div className="flexcenter mt40">
          <span className="flexchild">
            <TextField
              id="outlined-multiline-flexible"
              label="Kommentar til sjåfør"
              fullWidth
              multiline
              maxRows={4}
              value={driverComment}
              onChange={(e) => setDriverComment(e.target.value)}
            />
          </span>
        </div>

        <div className="flexcenter mt40">
          <span className="flexchild">
            <TextField
              id="outlined-multiline-flexible"
              label="Intern kommentar"
              fullWidth
              multiline
              maxRows={4}
              value={privateComment}
              onChange={(e) => setPrivateComment(e.target.value)}
            />
          </span>
        </div>

      </DialogContent>

      <DialogActions>
        <span className="mr10">
          <Tooltip
            title={lockState ? 'Fjern lås oppdrag' : 'Lås oppdrag'}
            placement="top"
          >
            <Checkbox
              icon={<LockOpenIcon />}
              checkedIcon={<LockIcon />}
              checked={lockState}
              onChange={() => setLockState(!lockState)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
            />
          </Tooltip>
        </span>
        <Button
          variant="outlined"
          disabled={sending}
          onClick={() => {
            setAddingOrder(null);
            setInactiveLoad(null);
            onClose();
          }}
        >Avbryt
        </Button>
        <Button
          variant="contained"
          disabled={!formValid}
          onClick={createAssignment}
        >
          Opprett
        </Button>

        {sending && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
