import { FC, ReactElement, useEffect } from 'react';
import './Router.scss';
import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useHasRoles } from './core/msal';
import { Unauthenticated } from './shared/components/unauthenticated';
import { config } from './config';

import { LandingPage } from './Pages/LandingPage';
import { AccessDeniedPage } from './Pages/AccessDenied';
import { TransportplanleggerPage } from './Pages/TransportplanleggerPage';

import { MyAssignmentsPage } from './Pages/MyAssignmentsPage';

import { UnavailabilityPage } from './Pages/UnavailabilityPage';
import { CreateUnavailabilityPage } from './Pages/CreateUnavailabilityPage';
import { EditUnavailabilityPage } from './Pages/EditUnavailabilityPage';

import { RentalVehicleListPage } from './Pages/RentalVehicleListPage';
import { CreateRentalVehiclePage } from './Pages/CreateRentalVehiclePage';
import { EditRentalVehiclePage } from './Pages/EditRentalVehiclePage';

import { NotFound } from './shared/components/NotFound';

import { InvoicingPage } from './Pages/InvoicingPage';

import { config as orderConfig } from './shared/config/order';
import { ExternalRedirect } from './components/externalRedirect';
import { ContainersPage } from './Pages/ContainersPage';
import { urlCombine } from './shared/logic/functions';
import { Roles } from './core/types/roles';
import { DieselTransportPage } from './Pages/DieselTransportPage';
import { OrderDetailsTransportPage } from './Pages/orderDetailsTransportPage';

const Redirect:FC<{to: string}> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
};

const RoleAuthRoute = ({ children, roles }: {children: ReactElement, roles: Roles[]}) => {
  const [hasRole, { isAuthenticated }] = useHasRoles();
  if (!isAuthenticated || hasRole(...roles)) {
    return children || null;
  }
  return (
    <Redirect to="/ingentilgang" />
  );
};

export const Router = () => (
  <MsalAuthenticationTemplate
    authenticationRequest={{ scopes: [config.SCOPE] }}
    interactionType={InteractionType.Redirect}
    errorComponent={Unauthenticated}
  >

    <div className="router-wrapper">
      <ReactRouter>
        <Routes>
          <Route path="/ingentilgang" element={<AccessDeniedPage />} />

          <Route
            path="/bestilling"
            element={<ExternalRedirect to={orderConfig.FRONTEND_URL} />}
          />
          <Route
            path="/diesel/opprett"
            element={(
              <ExternalRedirect<'id'> to={() => urlCombine(orderConfig.FRONTEND_URL, 'diesel')} />
            )}
          />
          <Route
            path="/bestilling/:id"
            element={(
              <ExternalRedirect<'id'> to={(p) => urlCombine(
                orderConfig.FRONTEND_URL,
                `bestilling/transport/${p.id}`,
              )}
              />
            )}
          />
          <Route
            path="/prosjekter/:id"
            element={(
              <ExternalRedirect<'id'> to={(p) => urlCombine(
                orderConfig.FRONTEND_URL,
                `prosjekt/${p.id}`,
              )}
              />
            )}
          />
          <Route
            path="/prosjekter"
            element={(
              <ExternalRedirect to={urlCombine(
                orderConfig.FRONTEND_URL,
                'prosjekter',
              )}
              />
            )}
          />
          <Route
            path="/innleiebiler/opprett"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <CreateRentalVehiclePage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/innleiebiler/:id"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <EditRentalVehiclePage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/innleiebiler"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <RentalVehicleListPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/utilgjengelighet/opprett"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <CreateUnavailabilityPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/utilgjengelighet/:id"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <EditUnavailabilityPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/utilgjengelighet"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <UnavailabilityPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/transportplanlegger"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator', 'lese-ryfylke']}>
                <TransportplanleggerPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/dieselplanlegger"
            element={(
              <RoleAuthRoute roles={['admin', 'diesel-koordinator']}>
                <DieselTransportPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/dieselplanlegger/:id"
            element={<OrderDetailsTransportPage />}
          />
          <Route
            path="/mineoppdrag"
            element={<MyAssignmentsPage />}
          />
          <Route
            path="/containere"
            element={(
              <RoleAuthRoute roles={['transport-koordinator', 'container-koordinator']}>
                <ContainersPage />
              </RoleAuthRoute>
            )}
          />
          <Route
            path="/belastning"
            element={(
              <RoleAuthRoute roles={['admin']}>
                <InvoicingPage />
              </RoleAuthRoute>
            )}
          />
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ReactRouter>
    </div>
  </MsalAuthenticationTemplate>
);
