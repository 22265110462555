import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import NewOrder from '@mui/icons-material/AddComment';
import Planner from '@mui/icons-material/CalendarMonth';
import DieselPlanner from '@mui/icons-material/WaterDrop';
import Unavailability from '@mui/icons-material/EventBusy';
import Container from '@mui/icons-material/CalendarViewWeek';
import RentalVehicles from '@mui/icons-material/DepartureBoard';
import Invoice from '@mui/icons-material/Receipt';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { useHasRoles } from '../../core/msal';
import './style.scss';

const NavButton = ({
  path,
  icon,
  label,
  show = true,
}: {
  path: string,
  icon?: ReactNode,
  label: string,
  show?: boolean,
}) => {
  const navigate = useNavigate();
  if (!show) return null;
  return (
    <Button size="large" variant="contained" onClick={() => navigate(path)}>
      {icon ?? null}
      <Box flex={1}>{label}</Box>
    </Button>
  );
};

export const LandingPage: FC = () => {
  const [hasRole] = useHasRoles();

  return (
    <Page className="landing-page">
      <Header>Planleggingsverktøy</Header>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80%"
        paddingX={2}
      >
        <Box
          maxWidth={350}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <NavButton
            path="/bestilling"
            label="Bestilling"
            icon={<NewOrder />}
          />
          <NavButton
            show={hasRole('admin', 'transport-koordinator', 'container-koordinator', 'lese-ryfylke')}
            path="/transportplanlegger"
            label="Transportplanlegger"
            icon={<Planner />}
          />
          <NavButton
            show={hasRole('admin', 'diesel-koordinator')}
            path="/dieselplanlegger"
            label="Dieselplanlegger"
            icon={<DieselPlanner />}
          />
          <NavButton
            show={hasRole('admin', 'transport-koordinator', 'container-koordinator')}
            path="/utilgjengelighet"
            label="Utilgjengelighet"
            icon={<Unavailability />}
          />

          <NavButton
            show={hasRole('admin', 'transport-koordinator', 'container-koordinator')}
            path="/containere"
            label="Container oversikt"
            icon={<Container />}
          />
          <NavButton
            show={hasRole('admin', 'transport-koordinator', 'container-koordinator')}
            path="/innleiebiler"
            label="Innleiebiler"
            icon={<RentalVehicles />}
          />
          <NavButton
            show={hasRole('admin')}
            path="/belastning"
            label="Belastning"
            icon={<Invoice />}
          />
        </Box>
      </Box>
    </Page>
  );
};
