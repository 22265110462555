import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Skeleton,
  TextField,
} from '@mui/material';
import {
  compareAsc,
  setHours,
  startOfDay,
  isValid,
  formatISO,
} from 'date-fns';
import { toast } from 'react-toastify';
import {
  useDeleteSubstituteMutation,
  useGetSubstituteQuery,
  usePostSubstituteMutation,
  usePutSubstituteMutation,
} from '../../core/redux/transport';
import { Guid } from '../../core/types/guid';
import './style.scss';
import { ChooseContactPerson, ContactPerson } from '../ContactPerson';
import { NewSubstituteDriver } from '../../core/types/api/newSubstituteDriver';
import { PopConfirm } from '../PopConfirm';
import { useResources } from '../../Pages/TransportplanleggerPage/hooks/useResources';
import { ensureAfter } from '../../core/helpers/dates';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { DateTimePicker } from '../../shared/components/dateTimePicker';

export const EditSubstitute: FC<{
  currentDate: Date,
  id: Guid,
  subExist: number,
  onClose?: () => void;
}> = ({
  currentDate,
  id,
  subExist = 0,
  onClose = () => null,
}) => {
  const { data: sub, isLoading: subIsLoading } = useGetSubstituteQuery(
    { vehicleId: id, subId: subExist },
    { skip: subExist === 0 },
  );
  const [post, { isLoading: postIsLoading }] = usePostSubstituteMutation();
  const [put, { isLoading: putIsLoading }] = usePutSubstituteMutation();
  const [remove] = useDeleteSubstituteMutation();
  const { data: vehicles } = useVehicleList();
  const vehicle = vehicles?.find((v) => v.internalNumber === id);

  const { refetch: refetchResources } = useResources(currentDate, true);

  const defaultDay = currentDate ? startOfDay(currentDate) : startOfDay(new Date());

  const [start, setStart] = useState<Date>(setHours(defaultDay, 7));
  const [end, setEnd] = useState<Date>(setHours(defaultDay, 15));
  const [driver, setDriver] = useState<ContactPerson|null>(null);
  const [comment, setComment] = useState<string>('');

  const btnText = subExist !== 0 ? 'Oppdater' : 'Opprett';

  useEffect(() => {
    if (subExist !== 0 && sub) {
      setStart(new Date(sub.start));
      setEnd(new Date(sub.end));
      setDriver({
        name: sub.driver.name,
        number: sub.driver.phone,
        employeeNumber: sub.driver.id,
      });
      setComment(sub.comment);
    }
  }, [subExist, sub]);

  const valid = useMemo(() => {
    if (start === null || end === null) return false;
    if (compareAsc(start, end) >= 0) return false;
    if (!isValid(start)) return false;
    if (!isValid(end)) return false;
    if (driver === null || (driver.name === '' || driver.number === '')) return false;
    return true;
  }, [start, end, driver, comment]);

  const submit = () => {
    if (!start) return;
    if (!end) return;
    if (!driver) return;
    const body: NewSubstituteDriver = {
      driverId: driver.employeeNumber,
      start: formatISO(start),
      end: formatISO(end),
      driverName: driver.name,
      driverPhone: driver.number,
      comment,
    };
    if (subExist === 0) {
      post({ id, body }).unwrap()
        .then(() => {
          toast.success('Vikar opprettet');
          refetchResources();
          onClose();
        });
    } else {
      put({ vehicleId: id, subId: subExist, body }).unwrap()
        .then(() => {
          toast.success('Vikar oppdatert');
          refetchResources();
          onClose();
        });
    }
  };

  const deleteSub = () => {
    remove({ vehicleId: id, subId: subExist }).unwrap()
      .then(() => {
        toast.success('Vikar slettet');
        refetchResources();
        onClose();
      });
  };

  const changeStart = (date: Date) => {
    setStart(date);
    setEnd((old) => old && ensureAfter(old, date));
  };

  if (subIsLoading) {
    return (
      <div className="edit-substitute-content-component">
        <Skeleton variant="rectangular" height={50} />
        <span className="m10" />
        <div className="flexcenter g10">
          <Skeleton variant="rectangular" height={50} width={1000} />
          <Skeleton variant="rectangular" height={50} width={1000} />
        </div>
        <span className="m10" />
        <Skeleton variant="rectangular" height={50} />
        <span className="m10" />
        <Skeleton variant="rectangular" height={125} />
        <div className="send-buttons">
          <Skeleton variant="rectangular" height={36} width={82} />
          <span className="flex-gap-10">
            <Skeleton variant="rectangular" height={36} width={82} />
            <Skeleton variant="rectangular" height={36} width={82} />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="edit-substitute-content-component">
      <FormControl fullWidth className="form">
        <TextField
          label="Kjøretøy"
          value={`${vehicle?.vehicleRegistrationPlateNumber} - ${vehicle?.subCategoryName}`}
          disabled
        />
        <div className="flexcenter">
          <DateTimePicker
            label="Fra"
            fullWidth
            size="medium"
            value={start}
            onChange={(date) => (date && isValid(date) && changeStart(date))}
          />
          <span className="m10" />
          <DateTimePicker
            label="Til"
            fullWidth
            size="medium"
            value={end}
            minDate={start}
            onChange={(date) => (date && isValid(date) && setEnd(date))}
          />
        </div>
        <ChooseContactPerson
          onChange={(value) => setDriver(value)}
          value={driver}
          externalHelpText="Fyll inn navn og telefonnummer på ekstern sjåfør"
          checkboxLabel="Ekstern sjåfør"
          inputLabel="Søk etter sjåfør"
        />
        <TextField
          multiline
          rows={4}
          label="Kommentar"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </FormControl>
      <div className="send-buttons">
        {subExist !== 0 ? (
          <span>
            <PopConfirm
              onConfirm={deleteSub}
              content={(
                <>
                  Vil du slette denne vikaren?<br />
                  Det vil ikke være mulig å angre slettingen
                </>
              )}
            />
          </span>
        ) : (
          <span />
        )}
        <span>
          <Button variant="outlined" onClick={onClose}>Avbryt</Button>
          <Button
            sx={{ ml: 1.5 }}
            disabled={!valid || postIsLoading || putIsLoading}
            variant="contained"
            onClick={submit}
          >
            {postIsLoading || putIsLoading
              ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : btnText}
          </Button>
        </span>
      </div>
    </div>
  );
};
