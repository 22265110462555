import { useState } from 'react';
import {
  format,
  compareAsc,
  subDays,
// eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Popover,
  TextField,
} from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AlarmIcon from '@mui/icons-material/Alarm';
import { toast } from 'react-toastify';
import { getDieselLoadTitle } from '../../../core/helpers/functions';
import { msalInstance } from '../../../shared/msal';
import '../style.scss';
import { useHasAppRoles } from '../../../shared/hooks/hasAppRole';
import { config } from '../../../shared/config/transport';
import { Order } from '../../../core/types/order';
import { useIsOrderLate } from '../../TransportplanleggerPage/hooks/useIsLate';
import { useCompleteDieselOrderMutation, useGetDieselDeliveredAmountQuery, useGetDieselLoadQuery } from '../../../core/redux/transport';
import { DieselLoadCounter } from '../../../core/types/dieselLoadCounter';
import { CompleteDieselOrder } from '../../../core/types/api/completeDieselOrder';

const checkCanEdit = (
  order: Order | undefined,
  /** If true, bypass deadline and always allow editing if status is created */
  bypassDeadline = false,
) => {
  if (!order) return false;
  if (order.status !== 'Created') return false;
  if (bypassDeadline) return true;
  const deadline = new Date(order.date);
  deadline.setHours(12, 0, 0, 0);
  if (compareAsc(new Date(), subDays(deadline, 1)) === 1) return false;
  return true;
};

export const ViewOrder = ({
  order,
  onCancel,
  onStartEdit,
}:{
  order: Order | undefined,
  onCancel?: (id: number) => Promise<unknown>,
  onStartEdit?: () => void,
}) => {
  const [postCompleteOrder] = useCompleteDieselOrderMutation();
  const { data: dieselLoad } = useGetDieselLoadQuery(undefined, { skip: order?.type !== 'Diesel' });
  const { data: deliveredDiesel } = useGetDieselDeliveredAmountQuery(order?.id!, {
    skip: !order || order.type !== 'Diesel' || order.status !== 'Planned',
  });
  const { isLate } = useIsOrderLate(order);
  const isDieselAdmin = useHasAppRoles(config, 'admin', 'diesel-koordinator')
    && order?.type === 'Diesel'
    && order?.status === 'Created';
  const canEdit = checkCanEdit(order, isDieselAdmin);

  const [
    dieselDeliveryAmounts,
    setDieselDeliveryAmounts,
  ] = useState<CompleteDieselOrder>({ truck: 0, trailer: 0 });

  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const user = msalInstance.getActiveAccount();
  const isOwner = order && order.createdBy === user?.localAccountId;

  const handleDelete = () => {
    setAnchorEl(null);
    if (order) {
      onCancel?.(order.id).catch(() => (
        toast.error('Kunne ikke kansellere bestilling, prøv igjen')
      ));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const completeOrder = () => {
    if (!order) return;
    postCompleteOrder({
      id: order.id,
      data: dieselDeliveryAmounts,
    });
  };

  const getFuelAmount = (tank: DieselLoadCounter) => {
    const dieselType = order?.loads[0]?.dieselType;
    if (!dieselType) return 0;
    switch (dieselType) {
      case 'Blank':
        return tank.blank;
      case 'Colored':
        return tank.colored;
      case 'HVO':
        return tank.hvo;
      default:
        return 0;
    }
  };

  const hasError = (tank: 'truck' | 'trailer') => {
    if (!dieselLoad) return false;
    const fuelInTank = getFuelAmount(dieselLoad[tank]);
    return dieselDeliveryAmounts[tank] < 0 || dieselDeliveryAmounts[tank] > fuelInTank;
  };

  const getHelperText = (tank: 'truck' | 'trailer') => {
    if (!dieselLoad) return ' ';
    const maxFuel = getFuelAmount(dieselLoad[tank]);
    if (dieselDeliveryAmounts[tank] < 0) return 'Kan ikke være negativ';
    if (dieselDeliveryAmounts[tank] > maxFuel) {
      const place = tank === 'trailer' ? 'henger' : 'bilen';
      if (maxFuel === 0) return `Det er ingen diesel på ${place}`;
      return `Det er bare ${maxFuel} liter på ${place}`;
    }
    return ' ';
  };

  const dieselDeliveryValid = !hasError('truck') && !hasError('trailer')
    && (dieselDeliveryAmounts.trailer > 0 || dieselDeliveryAmounts.truck > 0);

  if (!order) {
    return (
      <Box sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
      }}
      ><CircularProgress />
      </Box>
    );
  }

  return (
    <div className="order-task">
      <div className="order-info">
        <div className="info-item">
          <span className="info-label">Innsender</span>
          <span className="info-content">{`${order.createdByName ?? ''}`}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Innsendt</span>
          <span className="info-content" title={isLate ? 'Sen innsending' : undefined}>
            {`${format(new Date(order.createdTimestamp), 'dd.MM.yyyy', { locale: nb })}
                       kl. ${format(new Date(order.createdTimestamp), 'HH:mm', { locale: nb })}`}
            {isLate && (<>&nbsp;<AlarmIcon fontSize="small" color="error" /></>)}
          </span>
        </div>
        <div className="info-item">
          <span className="info-label">Leveringsdato</span>
          <span className="info-content" title={order.earlyDelivery ? 'Kan leveres dagen før' : undefined}>
            {`${format(new Date(order.date), 'dd.MM.yyyy')}`}
            {order.earlyDelivery && (<>&nbsp;<AltRouteIcon fontSize="small" color="primary" /></>)}
          </span>
        </div>
        <div className="info-item">
          <span className="info-label">Kontaktperson</span>
          <span className="info-content">{`${order.contactPersonName ?? ''} (${order.contactPersonPhoneNumber ?? ''})`}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Last</span>
          {order.loads.map((l) => (
            <div key={l.id}>
              <span className="info-content mb5">
                {getDieselLoadTitle(l)}
              </span>
            </div>
          ))}
        </div>
        <div className="info-item">
          <span className="info-label">Kode for port</span>
          <span className="info-content">{order.gateCode ?? 'Nei'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Prosjekt</span>
          <span className="info-content">
            {order.toProject
              ? <a href={`/prosjekt/${order.toProject.id}`}>{`${order.toProject.id} ${order.toProject.projectName}`}</a>
              : 'N/A'}
          </span>
        </div>
        <div className="info-item">
          <span className="info-label">Bestillingskommentar</span>
          <span className="info-content">{`${order.comment || 'Ingen kommentar'}`}</span>
        </div>
        {isDieselAdmin && (
        <Card variant="outlined" sx={{ backgroundColor: '#fffff7' }}>
          <CardHeader sx={{ '& .MuiTypography-root': { fontSize: '1.3rem' } }} title="Mengde diesel levert" />
          <CardContent sx={{
            display: 'flex',
            gap: 2,
          }}
          >
            <TextField
              sx={{ flex: 1 }}
              type="number"
              label="Fra bil"
              value={dieselDeliveryAmounts.truck}
              onChange={(e) => {
                setDieselDeliveryAmounts((old) => ({ ...old, truck: +e.target.value }));
              }}
              inputProps={{
                min: 0,
                max: dieselLoad
                  ? getFuelAmount(dieselLoad.truck)
                  : undefined,
              }}
              error={hasError('truck')}
              helperText={getHelperText('truck')}
            />
            <TextField
              sx={{ flex: 1 }}
              type="number"
              label="Fra henger"
              value={dieselDeliveryAmounts.trailer}
              onChange={(e) => {
                setDieselDeliveryAmounts((old) => ({ ...old, trailer: +e.target.value }));
              }}
              inputProps={{
                min: 0,
                max: dieselLoad
                  ? getFuelAmount(dieselLoad.trailer)
                  : undefined,
              }}
              error={hasError('trailer')}
              helperText={getHelperText('trailer')}
            />
            <TextField
              sx={{ flex: 1 }}
              type="number"
              label="Totalt"
              value={dieselDeliveryAmounts.trailer + dieselDeliveryAmounts.truck}
              disabled
            />
          </CardContent>
        </Card>
        )}
        {
            order.status === 'Planned' && deliveredDiesel && (
              <div className="info-item">
                <span className="info-label">Levert diesel</span>
                {deliveredDiesel.truck > 0 && (
                  <span className="info-content">{deliveredDiesel.truck} liter fra bil</span>
                )}
                {deliveredDiesel.trailer > 0 && (
                  <span className="info-content">{deliveredDiesel.trailer} liter fra henger</span>
                )}
                {deliveredDiesel.truck > 0 && deliveredDiesel.trailer > 0 && (
                  <span className="info-content">
                    Totalt {deliveredDiesel.trailer + deliveredDiesel.truck} liter
                  </span>
                )}
              </div>
            )
          }
        {(isDieselAdmin || (isOwner && canEdit)) && (
          <div className="line-wrapper g10">
            {isDieselAdmin && (
              <Button
                disabled={!dieselDeliveryValid}
                sx={{ marginTop: '15px' }}
                fullWidth
                variant="contained"
                color="success"
                onClick={completeOrder}
              >Fullfør
              </Button>
            )}
            <Button
              aria-describedby={id}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{ marginTop: '15px' }}
              fullWidth
              variant="outlined"
            >
              Kanseller
            </Button>
            <Button
              onClick={() => onStartEdit?.()}
              sx={{ marginTop: '15px' }}
              fullWidth
              variant="contained"
            >
              Rediger
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={8}
              transitionDuration="auto"
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <div className="m10">
                <div className="ml5 mb10">
                  <span className="bold-text font-size-17">Er du sikker?</span>
                  <div className="mt5">
                    <span className="font-size-15 italic">
                      {`Du vil nå kansellere bestilling #${order.id}`}<br />
                      Det vil ikke være mulig å angre kansellering.
                    </span>
                  </div>
                </div>
                <div className="text-align-end">
                  <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
                  <Button
                    sx={{ m: 0.75 }}
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                  >
                    Kanseller bestilling
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};
