import { FC, useEffect, useState } from 'react';
import { Box, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getContainerLoadTitle, getPlannedContainerCount } from '../../core/helpers/functions';
import { Order } from '../../core/types/order';
import { Load } from '../../core/types/load';
import { useAttachDraggable } from '../../Pages/TransportplanleggerPage/hooks/useAttachDraggable';
import './style.scss';
import { AssignmentType } from '../../core/types/enums/assignmentType';

export const ContainerEmptyingCard: FC<{
  order: Order,
  load: Load,
  dataTitle: string,
  attributes: Record<string, string>,
  draggable?: boolean,
}> = ({
  order,
  load,
  dataTitle,
  attributes,
  draggable = true,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (getPlannedContainerCount(order, load) === 1) {
      if (getPlannedContainerCount(order, load, 'ContainerCollect', true) === 1) setExpanded(true);
      if (getPlannedContainerCount(order, load, 'ContainerDeliver', true) === 1) setExpanded(true);
    }
  }, []);

  const attachDraggable = useAttachDraggable();

  useEffect(() => {
    if (!draggable) return;
    attachDraggable();
  }, [draggable]);

  const options = (
    <Box margin={1}>
      <div
        key={`collect-${load.id}`}
        className={`flexchild single-event-draggable-parent${getPlannedContainerCount(order, load, 'ContainerCollect') > 0 ? '-planned' : ''} expand-item make-draggable`}
        data-title={dataTitle}
        data-id={order.id}
        data-duration="01:00"
        data-loadid={load.id}
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        data-assignmenttype={'ContainerCollect' as AssignmentType}
        draggable={draggable}
      >
        <Card>
          <div className="single-event-draggable">
            <MoreVertIcon />
            <span>Henting</span>
          </div>
        </Card>
      </div>
      <div
        key={`deliver-${load.id}`}
        className={`flexchild single-event-draggable-parent${getPlannedContainerCount(order, load, 'ContainerDeliver') > 0 ? '-planned' : ''} expand-item make-draggable`}
        data-title={dataTitle}
        data-id={order.id}
        data-loadid={load.id}
        data-duration="01:00"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        data-assignmenttype={'ContainerDeliver' as AssignmentType}
        draggable={draggable}
      >
        <Card>
          <div className="single-event-draggable">
            <MoreVertIcon />
            <span>Utkjøring</span>
          </div>
        </Card>
      </div>
    </Box>
  );

  return (
    <>
      <div
        className={`flexchild single-event-draggable-parent${getPlannedContainerCount(order, load) > 0 ? '-planned' : ''} make-draggable`}
        data-title={dataTitle}
        data-id={order.id}
        data-loadid={load.id}
        data-duration="01:00"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        draggable={draggable}
      >
        <Card>
          <CardActionArea onClick={() => setExpanded(!expanded)}>
            <Box display="flex" alignItems="center" gap={2}>
              <div className="single-event-draggable">
                <MoreVertIcon />
                <span>{getContainerLoadTitle(order.type, load, true)}</span>
              </div>
              {expanded ? (
                <ExpandLessIcon sx={{ fontSize: '19px' }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: '19px' }} />
              )}
            </Box>
          </CardActionArea>
        </Card>
      </div>
      <Box display={expanded ? 'block' : 'none'}>
        {options}
      </Box>
    </>
  );
};
