import React, { useEffect, useMemo, useState } from 'react';
import { compareAsc, format, startOfDay } from 'date-fns';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { useGetUserAssignmentQuery } from '../../core/redux/transport';
import { isValidStatus } from '../../core/helpers/drivers';
import { List } from './partials/List';
import { Details } from './partials/Details';
import './style.scss';

export const MyAssignmentsPage = () => {
  const today = useMemo(
    () => startOfDay(new Date()),
    [format(startOfDay(new Date()), 'yyyy-MM-dd')],
  );
  const [day, setDay] = useState<Date>(today);
  const [selectedId, setSelectedId] = useState<number|null>(null);

  const { data: assignments, isFetching, refetch } = useGetUserAssignmentQuery(format(day, 'yyyy-MM-dd'));

  const filteredAssignments = useMemo(() => {
    if (assignments === undefined) return undefined;
    return [...assignments]
      .filter((a) => isValidStatus(a.status))
      .sort((a, b) => compareAsc(new Date(a.startTime), new Date(b.startTime)))
      .sort((a, b) => (a.status === 'VehicleService' ? 0 : 1) - (b.status === 'VehicleService' ? 0 : 1));
  }, [assignments]);

  const hasData = useMemo(() => (
    filteredAssignments && filteredAssignments.length > 0 && !isFetching
  ), [filteredAssignments, isFetching]);

  const selectedAssignment = useMemo(() => {
    if (!assignments) return undefined;
    if (!selectedId) return undefined;
    return assignments.find((a) => a.id === selectedId);
  }, [assignments, selectedId]);

  useEffect(() => {
    if (selectedId && !selectedAssignment) {
      setSelectedId(null);
    }
  }, [selectedId, selectedAssignment]);

  return (
    <Page className={`my-assignments-page${selectedId ? '' : ' list'}`}>
      <Header
        onBack={selectedId ? () => setSelectedId(null) : undefined}
        disableHome
      >
        {selectedId ? 'Oppdragsdetaljer' : 'Mine Oppdrag'}
      </Header>
      { selectedId === null || selectedAssignment === undefined ? (
        <List
          day={day}
          setDay={setDay}
          refetch={refetch}
          onPick={setSelectedId}
          hasData={hasData}
          isFetching={isFetching}
          assignments={filteredAssignments}
        />
      ) : (
        <Details
          assignment={selectedAssignment}
          onBack={() => setSelectedId(null)}
          refetch={refetch}
        />
      )}
    </Page>
  );
};
