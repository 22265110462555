import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../style.scss';
import { ListRentalVehicles } from '../../../components/ListRentalVehicles';
import { EditRentalVehicle } from '../../../components/EditRentalVehicle';
import { CreateRentalVehicle } from '../../../components/CreateRentalVehicle';
import { Guid } from '../../../core/types/guid';

export const RentalModal: FC<{
  currentDate: Date;
  isOpen: boolean;
  onClose: () => void;
}> = ({
  currentDate,
  isOpen,
  onClose,
}) => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editId, setEditId] = useState<Guid|null>(null);

  const close = () => {
    setShowCreate(false);
    setEditId(null);
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      maxWidth="md"
      fullWidth
    >

      <div className="align-status">
        <div className="left">
          <DialogTitle>Innleiebiler</DialogTitle>
        </div>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        <div className="rental-modal-content-wrapper">
          {!showCreate && editId === null && (
            <ListRentalVehicles onClickItem={setEditId} onClickNew={() => setShowCreate(true)} />
          )}
          {editId !== null && (
            <EditRentalVehicle
              id={editId}
              currentDate={currentDate}
              onAction={() => setEditId(null)}
            />
          )}
          {showCreate && (
            <CreateRentalVehicle onClose={() => setShowCreate(false)} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
