import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import {
  add,
  addHours,
  addMinutes,
  format as dateFormat,
  getDay,
  getISOWeek,
  isBefore,
  isSameDay,
  isValid,
  parse,
  roundToNearestMinutes,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { CalendarPicker, PickersDay } from '@mui/x-date-pickers';
import { Modal } from './partials/pickerModal';
import './style.scss';

export const DateTimePicker: FC<{
  value: Date,
  onChange?: (newValue: Date) => void,
  /** Format to display time with, also used for parsing */
  format?: string,
  label?: string,
  fullWidth?: boolean,
  /** Disable time selection and entry - makes this date-only */
  disableTime?: boolean,
  /** Disable the entire input field and picker */
  disabled?: boolean,
  /** Minimum allowed date */
  minDate?: Date,
  size?: 'small' | 'medium',
  /**
   * Default time in hours - only applied if disableTime is true
   *
   * By default the time is set to the time in value
   * */
  defaultTime?: number,
  /** Close the picker after selection - only applied if disableTime is true */
  closeOnSelect?: boolean,
  error?: string | null;
}> = ({
  value,
  onChange: onChangeExternal = () => null,
  format = 'dd.MM.yyyy HH:mm',
  label,
  fullWidth = false,
  disableTime = false,
  disabled = false,
  minDate,
  size = 'small',
  defaultTime,
  closeOnSelect = false,
  error = null,
}) => {
  const [textValue, setTextValue] = useState<string>('');
  const [pickerValue, setPickerValue] = useState<Date>(value);
  const currentTimeRef = useRef<HTMLLIElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement|null>(null);
  const onChange = (v: Date) => {
    if (!disableTime) return onChangeExternal(v);
    const date = startOfDay(v);
    if (defaultTime !== undefined) {
      return onChangeExternal(addHours(date, defaultTime));
    }
    // If no default time is specified, use the time from the input
    return onChangeExternal(add(date, {
      hours: value.getHours(),
      minutes: value.getMinutes(),
    }));
  };

  useEffect(() => {
    currentTimeRef.current?.scrollIntoView({ block: 'center', inline: 'center' });
  }, [currentTimeRef.current]);

  useEffect(() => {
    setTextValue(dateFormat(value, format));
    setPickerValue(value);
  }, [value]);

  const parseDate = (str: string) => {
    const timeFormats = [
      'dd.MM.yy HH:mm',
      'dd.MM.yyHH:mm',
      'dd.MM.yyyy HH:mm',
      'dd.MM.yyyyHH:mm',
      'ddMMyy HHmm',
      'ddMMyyyy HHmm',
      'ddMMyyyyHHmm',
    ];

    const dateFormats = [
      'dd.MM.yy',
      'dd.MM.yyyy',
      'ddMMyy',
      'ddMMyyyy',
      // Picker should not accept time formats if time is disabled
      ...(disableTime ? [] : timeFormats),
    ];

    for (let i = 0; i < dateFormats.length; i += 1) {
      const date = parse(str, dateFormats[i], new Date());
      if (date && isValid(date)) return date;
    }
    return null;
  };

  const textValid = useMemo(() => {
    const newValue = parseDate(textValue);
    return newValue && isValid(newValue);
  }, [textValue]);

  const times = useMemo(() => {
    const ts = [] as string[];
    for (let i = 0; i < 24; i += 1) {
      ts.push(`${i < 10 ? '0' : ''}${i}:00`);
      ts.push(`${i < 10 ? '0' : ''}${i}:15`);
      ts.push(`${i < 10 ? '0' : ''}${i}:30`);
      ts.push(`${i < 10 ? '0' : ''}${i}:45`);
    }
    return ts;
  }, []);

  const currentTime = useMemo(() => (
    dateFormat(pickerValue, 'HH:mm')
  ), [pickerValue]);

  const AcceptButton: FC<{
    onClick?: () => void
  }> = useCallback(({
    onClick = () => null,
  }) => {
    if (closeOnSelect && disableTime) return null;
    return (
      <Button
        variant="contained"
        onClick={() => {
          onClick();
          onChange(pickerValue);
        }}
        sx={{ ml: 1 }}
      >Velg
      </Button>
    );
  }, [onChange, pickerValue]);

  const updatePickerValue = (date: Date) => {
    let newDate = date;
    if (minDate && isBefore(date, minDate)) {
      newDate = addMinutes(minDate, 15);
    }
    setPickerValue(newDate);

    if (closeOnSelect && disableTime) {
      onChange(newDate);
      setAnchorEl(null);
    }
  };

  const canPickTime = (time: string) => {
    if (!minDate) return true;
    if (!isSameDay(minDate, pickerValue)) return true;
    if (dateFormat(minDate, 'HH:mm').localeCompare(time) < 0) return true;
    return false;
  };

  const onSubmit = () => {
    const newValue = parseDate(textValue);
    if (newValue && isValid(newValue)) {
      onChange(roundToNearestMinutes(newValue, { nearestTo: 15 }));
    } else {
      setTextValue(dateFormat(value, format));
    }
    setAnchorEl(null);
  };

  return (
    <FormControl fullWidth={fullWidth} disabled={disabled}>
      <InputLabel htmlFor="date-time-picker-component">{label}</InputLabel>
      <OutlinedInput
        id="date-time-picker-component"
        error={error !== null}
        size={size}
        label={label}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        onBlur={onSubmit}
        endAdornment={(
          <InputAdornment
            position="end"
          >
            <Modal
              label="Velg tid"
              disabled={disabled}
              closeButton={<Button variant="outlined">Avbryt</Button>}
              acceptButton={<AcceptButton />}
              renderButton={<IconButton sx={{ m: 0, p: 0 }}><CalendarIcon /></IconButton>}
              currentDate={
                disableTime
                  ? dateFormat(pickerValue, format).replace(/ \d{2}:\d{2}$/, '')
                  : dateFormat(pickerValue, format)
              }
              anchor={anchorEl}
              onClose={() => setAnchorEl(null)}
              onOpen={(el) => {
                setAnchorEl(el);
                setTextValue(dateFormat(value, format));
                setPickerValue(value);
                setTimeout(() => {
                  currentTimeRef.current?.scrollIntoView({ block: 'center', inline: 'center' });
                }, 200);
              }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                pl: 1,
                pr: 1,
              }}
              >
                <CalendarPicker
                  date={pickerValue}
                  onChange={(d) => d && isValid(d) && updatePickerValue(d)}
                  minDate={minDate === undefined ? undefined : addMinutes(minDate, 15)}
                  renderDay={
                    (date, _, props) => (
                      <div key={date.getTime()}>
                        {getDay(date) === 1 && <div className="date-time-picker-component-week">{getISOWeek(date)}</div>}
                        <PickersDay
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...props}
                        />
                      </div>
                    )
                  }
                />
                {!disableTime && (
                <List
                  sx={{
                    width: '100px',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 340,
                    '& ul': { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {times.map((t) => (
                    <ListItem
                      key={t}
                      sx={{
                        padding: 0,
                        margin: 0,
                        backgroundColor: currentTime === t ? '#004b8b' : 'unset',
                        color: currentTime === t ? 'white' : 'unset',
                        fontWeight: currentTime === t ? 'bold' : 'unset',
                        transition: 'background-color 0.2s, color 0.2s, font-weight 0.2s',
                        textAlign: 'center',
                        cursor: 'pointer',
                        ':hover': {
                          backgroundColor: currentTime === t ? '#004b8baa' : '#44444422',
                          color: currentTime === t ? 'white' : 'unset',
                        },
                      }}
                      disabled={!canPickTime(t)}
                      ref={currentTime === t ? currentTimeRef : undefined}
                      onClick={() => {
                        if (!canPickTime(t)) return;
                        setPickerValue((old) => {
                          const split = t.split(':');
                          const h = parseInt(split[0], 10) || 0;
                          const m = parseInt(split[1], 10) || 0;
                          return setHours(setMinutes(old, m), h);
                        });
                      }}
                    >
                      <ListItemText primary={t} />
                    </ListItem>
                  ))}
                </List>
                )}
              </Box>
            </Modal>
          </InputAdornment>
      )}
      />
      <FormHelperText id="date-time-picker-component-invalid-info" sx={{ color: '#d32f2f' }}>
        {!textValid && 'Ugyldig verdi'}
      </FormHelperText>
      <FormHelperText id="date-time-picker-component-error" sx={{ color: '#d32f2f' }}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};
