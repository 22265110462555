/** Blue */
export const PRIMARY = '#004b8b';
/** Dark blue */
export const PRIMARY_DARK = '#142a42';
/** White */
export const SECONDARY = '#ffffff';
/** Red */
export const WARNING_COLOR = '#ff8000';
/** Medium grey */
export const DISABLED_COLOR = '#999999';

export const HEADER_HEIGHT = '24px';
export const MOBILE_HEADER_HEIGHT = '16px';
export const PAGEWIDTH = '800px';

/** Orange */
export const UNDER_PLANNING_COLOR = '#ff8000';
/** Blue */
export const APPROVED_COLOR = '#0066cc';
/** Green */
export const STARTED_COLOR = '#009900';
/** Dark grey */
export const COMPLETED_COLOR = '#4d4d4d';
/** Light grey */
export const CANCELLED_COLOR = '#cccccc';
/** Red */
export const NOT_DELIVERED_COLOR = '#ff0000';
/** Black */
export const DELETED_COLOR = 'black';
/** Light yellow */
export const EXTERNAL_UNAVAILABILITY_COLOR = '#ffde00';
/** Dark yellow */
export const UNAVAILABILITY_COLOR = '#ffde00';
/** Purple */
export const VEHICLE_SERVICE_COLOR = '#622aa8';
/** Light blue */
export const DELIVERED_COLOR = '#4FB7B7';
/** DARK red */
export const EXP_PROJECT_BORDER_COLOR = '#990000';

// Any changes here should also be applied to /constants.scss

// Region Soma as default region
export const DEFAULT_REGION = 1;
export const RYFYLKE_REGION = 2;

/** Represents the maximum number of containers one assignment can have */
export const MAX_CONTAINERS = 3;

/** Default depo project number */
export const DEFAULT_DEPO = 333991;

/** Tag color combos */
export const TAG_COLORS = [
  { background: '#ffffff', foreground: '#484848' },
  { background: '#e6e6e6', foreground: '#484848' },
  { background: '#808080', foreground: '#ffffff' },
  { background: '#1a1a1a', foreground: '#ffffff' },
  { background: '#da0063', foreground: '#ffffff' },
  { background: '#fef445', foreground: '#484848' },
  { background: '#fac712', foreground: '#ffffff' },
  { background: '#f34725', foreground: '#ffffff' },
  { background: '#cee741', foreground: '#484848' },
  { background: '#8ed14f', foreground: '#ffffff' },
  { background: '#3c8a3f', foreground: '#ffffff' },
  { background: '#14cdd4', foreground: '#ffffff' },
  { background: '#2d9bf0', foreground: '#ffffff' },
  { background: '#414bb2', foreground: '#ffffff' },
  { background: '#652cb3', foreground: '#ffffff' },
];
