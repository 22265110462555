import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getISOWeek, isBefore, max } from 'date-fns';
import {
  FC,
  useMemo,
  useState,
} from 'react';
import { addDays } from 'date-fns/esm';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { useCreateMutation, useGetAllQuery, useLockMutation } from '../../core/redux/invoicing';
import { InvoiceBasis } from '../../core/types/invoiceBasis';
import {
  format, timestringFormat,
} from '../../shared/logic/dates';
import { InvoiceDetailsModal } from './partials/invoiceDetailsModal';
import { ExportButton } from './partials/exportButton';

export const InvoicingPage: FC = () => {
  const { data, isLoading } = useGetAllQuery();
  const [create, createStatus] = useCreateMutation();
  const [lock, lockStatus] = useLockMutation();

  const [showDetailsFor, setShowDetailsFor] = useState<InvoiceBasis | null>(null);
  const allInvoiceBasises = useMemo(
    () => (data !== undefined ? [...data].sort((a, b) => b.from.localeCompare(a.from)) : []),
    [data],
  );

  const canGenerate = useMemo(() => {
    const lastPeriodEnd = max(allInvoiceBasises.map((x) => new Date(x.to)));
    const canStartNewPeriod = isBefore(addDays(lastPeriodEnd, 7), Date.now());
    return canStartNewPeriod
      || !allInvoiceBasises.every((x) => x.locked)
      || allInvoiceBasises.length === 0;
  }, [allInvoiceBasises]);

  return (
    <Page className="order-page">
      <Header>Faktureringsgrunnlag</Header>
      <div className="order-page-wrapper">
        <Box display="grid">
          <Button variant="outlined" onClick={() => create()} disabled={!canGenerate}>
            {createStatus.isLoading
              ? <CircularProgress size={24} />
              : <span>Generer</span>}
          </Button>
          <Box>
            { !isLoading && allInvoiceBasises ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>År</TableCell>
                      <TableCell>Uke</TableCell>
                      <TableCell>Fra</TableCell>
                      <TableCell>Til</TableCell>
                      <TableCell>Opprettet</TableCell>
                      <TableCell>Oppdatert</TableCell>
                      <TableCell>Last ned</TableCell>
                      <TableCell>Lås</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    { allInvoiceBasises.map((b) => (
                      <TableRow hover key={b.uid} onClick={() => setShowDetailsFor(b)}>
                        <TableCell>{b ? format(new Date(b.from), 'yyyy') : ''}</TableCell>
                        <TableCell>{b ? getISOWeek(new Date(b.from)) : ''}</TableCell>
                        <TableCell>{format(new Date(b.from), 'dd.MM.yyyy')}</TableCell>
                        <TableCell>{format(new Date(b.to), 'dd.MM.yyyy')}</TableCell>
                        <TableCell>
                          {timestringFormat(b.createdTimestamp)} av {b.createdByName}
                        </TableCell>
                        <TableCell>
                          {timestringFormat(b.updatedTimestamp)} av {b.updatedByName}
                        </TableCell>
                        <TableCell><ExportButton invoiceBasis={b} /></TableCell>
                        <TableCell>
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {b.locked ? <LockIcon />
                            : lockStatus.isLoading ? <CircularProgress size={24} />
                              : (
                                <IconButton
                                  onClick={(e) => { lock({ uid: b.uid }); e.preventDefault(); e.stopPropagation(); }}
                                >
                                  <LockOpenIcon />
                                </IconButton>
                              )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
              : (
                <CircularProgress
                  size={36}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
          </Box>
        </Box>

      </div>
      { showDetailsFor && <InvoiceDetailsModal invoiceBasis={showDetailsFor} onClose={() => setShowDetailsFor(null)} /> }
    </Page>
  );
};
