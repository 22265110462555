import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CreateRentalPeriod } from '../CreateRentalPeriod';
import { Guid } from '../../core/types/guid';

export const CreateRentalPeriodModal: FC<{
  currentDate?: Date;
  id: Guid,
  onClose: () => void;
}> = ({
  currentDate,
  id,
  onClose,
}) => (
  <Dialog
    open
    onClose={() => {
      onClose();
    }}
    maxWidth="xs"
    fullWidth
  >

    <DialogTitle>Opprett Innleieperiode</DialogTitle>

    <DialogContent>
      <CreateRentalPeriod
        currentDate={currentDate}
        id={id}
        onClose={onClose}
      />
    </DialogContent>
  </Dialog>
);
