import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Load, LoadItem } from '../../../core/types/load';
import { ContainerCollectPicker } from './ContainerCollectPicker';
import { getTransportType } from '../../../core/helpers/translate';
import { ContainerDeliverPicker } from './ContainerDeliverPicker';
import { MassPicker } from './MassPicker';
import { useIsType } from '../../../core/hooks/useIsType';
import { AssignmentType } from '../../../core/types/enums/assignmentType';
import { OrderType } from '../../../core/types/enums/orderType';
import { getMachineLabel } from '../../../core/helpers/labelling';

export const LoadPicker = <L extends LoadItem|Load>({
  orderType,
  assignmentType,
  value,
  onChange,
  disabled = false,
  projectId,
}: {
  orderType?: OrderType|undefined|null,
  assignmentType: AssignmentType|undefined|null,
  value: L[],
  onChange: (loads: L[]) => void,
  disabled?: boolean,
  projectId?: number,
}) => {
  const isType = useIsType(assignmentType);
  const orderIsType = useIsType(orderType);
  /** True if the order is type emptying and assignment is type collect */
  const isEmptyingCollection = orderIsType('ContainerEmptying') && isType('ContainerCollect');
  /** True if the order is type emptying and assignment is type deliver */
  const isEmptyingDelivery = orderIsType('ContainerEmptying') && isType('ContainerDeliver');

  // Internal does not have loads
  if (isType('MassInternal')) return null;

  if (isType('MassIn', 'MassOut')) {
    return (
      <MassPicker
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (isType('ContainerCollect', 'ContainerEmptying', 'ContainerSwap')) {
    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontSize="18px">Container</Typography>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          <ContainerCollectPicker
            value={value}
            onChange={(v) => onChange(v)}
            disabled={disabled || isEmptyingCollection}
            projectId={projectId}
          />
        </Box>
      </Box>
    );
  }

  if (isType('ContainerDeliver')) {
    return (
      <ContainerDeliverPicker
        disableSelection={isEmptyingDelivery}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (isType('Haul')) {
    const machineText = useMemo(() => {
      const load = value[0];
      if (!load) return null;
      if (load.machine) {
        return getMachineLabel(load.machine);
      }
      return load.machineInternalNumber
      ?? load.machineExternal;
    }, [value]);

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontSize="18px">Maskin</Typography>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          {machineText}
        </Box>
      </Box>
    );
  }

  // If assignment type is not set, return null
  if (!assignmentType) return null;

  // If unable to display, show error message
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography color="red">
        Last kan ikke vises for bestillingstype
        {' '}
        {assignmentType && getTransportType(assignmentType)}
      </Typography>
    </Box>
  );
};
