import React, { FC, useMemo } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { RentalVehicle } from '../../../core/types/rentalVehicle';
import { VehicleItem } from '../../../components/VehicleItem';
import { currentlyWithinPeriod } from '../../../core/helpers/dates';
import { Guid } from '../../../core/types/guid';

export const RentalVehicleItem: FC<{
  data?: RentalVehicle,
  onClick?: (id: Guid) => void,
  loading?: boolean,
}> = (
  {
    data,
    onClick = () => null,
    loading = false,
  },
) => {
  const isActive = useMemo(() => (
    !data || !data.rentalPeriods
      ? false
      : data?.rentalPeriods.some((p) => (
        currentlyWithinPeriod(new Date(p.from), new Date(p.to))
      ))
  ), [data?.rentalPeriods, format(new Date(), 'HH:mm')]);

  if (!data) {
    return (
      <Card
        sx={{ mt: 2 }}
      >
        <CardContent>
          <div className="project-item-wrapper">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <VehicleItem loading />
            </Box>
          </div>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card
      sx={{ mt: 2 }}
    >
      <CardActionArea onClick={() => {
        onClick(data.id);
      }}
      >
        <CardContent>
          <div key={data.id} className="project-item-wrapper">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <VehicleItem
                loading={loading || !data}
                driver={{
                  fullName: data.driverName,
                  mobilePhoneWork: data.driverPhoneNumber,
                }}
                registration={data.vehicleRegistrationPlateNumber}
                type={data.vehicleCategory}
                status={isActive}
              />
            </Box>
          </div>
        </CardContent>
      </CardActionArea>

    </Card>
  );
};
