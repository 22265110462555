import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import {
  compareAsc,
  formatISO,
  setHours,
  startOfDay,
} from 'date-fns';
import { toast } from 'react-toastify';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { getCurrentDriver } from '../../core/helpers/functions';
import { ensureAfter } from '../../core/helpers/dates';
import { usePostUnavailabilityMutation } from '../../core/redux/transport';
import './style.scss';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { UnavailabilityType } from '../../core/types/unavailability';
import { RadioGroup } from '../../shared/components/radioGroup';
import { NewUnavailability } from '../../core/types/api/newUnavailability';

export const CreateUnavailability: FC<{
  internalNumber?: string,
  currentDate?: Date,
  onClose?: () => void;
  type?: UnavailabilityType;
  }> = ({
    internalNumber,
    currentDate,
    onClose = () => null,
    type,
  }) => {
    const defaultDay = currentDate ? startOfDay(currentDate) : startOfDay(new Date());

    const [vehicle, setVehicle] = useState<{id: string, label: string}|null>(null);
    const [title, setTitle] = useState<string>('');
    const [from, setFrom] = useState<Date|null>(setHours(defaultDay, 7));
    const [to, setTo] = useState<Date|null>(setHours(defaultDay, 15));
    const [comment, setComment] = useState<string>('');
    const [internalComment, setInternalComment] = useState<string>('');
    const [serviceType, setServiceType] = useState<string|null>(null);
    const [selectedType, setSelectedType] = useState<UnavailabilityType|null>(type ?? null);

    const { data: vehicles } = useVehicleList();

    const [send, { isLoading }] = usePostUnavailabilityMutation();

    const vehicleOptions: { id: string, label: string}[] = useMemo(() => (
      !vehicles
        ? []
        : vehicles.map((p) => ({
          id: p.internalNumber,
          label: `${p.vehicleRegistrationPlateNumber} - ${p.subCategoryName} - ${getCurrentDriver(p)?.fullName || 'Ingen sjåfør'}`,
        }))), [vehicles]);

    useEffect(() => {
      setSelectedType(type ?? null);
    }, [type]);

    useEffect(() => {
      const selectedVehicle = vehicleOptions.find((v) => v.id === internalNumber);
      if (selectedVehicle) setVehicle(selectedVehicle);
    }, [internalNumber, vehicleOptions]);

    const valid = useMemo(() => {
      if (vehicle === null) return false;
      if (title === '') return false;
      if (from === null || to === null) return false;
      if (compareAsc(from, to) >= 0) return false;
      if (selectedType === 'VehicleService' && serviceType === null) return false;
      return true;
    }, [vehicle, title, from, to, comment, internalComment, selectedType, serviceType]);

    const submit = () => {
      if (!from) return;
      if (!to) return;
      if (!title) return;
      if (!vehicle) return;
      if (!selectedType) return;
      if (!valid) return;
      const body: NewUnavailability = {
        title,
        serviceType,
        from: formatISO(from),
        to: formatISO(to),
        comment,
        internalComment,
        type: selectedType,
      };
      send({ id: vehicle.id, body }).unwrap().then(() => {
        toast.success('Utilgjengelighet opprettet');
        onClose();
      });
    };

    const updateFrom = (time: Date) => {
      setFrom(time);
      setTo((old) => old && ensureAfter(old, time));
    };

    const fromError = useMemo(() => {
      if (!from) return 'Fra tid kan ikke være tom';
      if (!to) return null;
      if (compareAsc(from, to) >= 0) return 'Fra tid kan ikke være etter til tid';
      return null;
    }, [from, to]);

    const toError = useMemo(() => {
      if (!to) return 'Til tid kan ikke være tom';
      if (!from) return null;
      if (compareAsc(from, to) >= 0) return 'Til tid kan ikke være før fra tid';
      return null;
    }, [from, to]);

    return (
      <div className="unavailability-wrapper">
        <div className="unavailability-area">
          <div>
            <FormControl fullWidth sx={{ gap: 3 }}>
              <Autocomplete
                disablePortal
                value={vehicle}
                isOptionEqualToValue={(option, value) => option.id === value.label}
                clearOnBlur
                disabled={internalNumber !== undefined}
                clearOnEscape
                noOptionsText="Laster..."
                onChange={(e, newValue) => {
                  if (newValue) {
                    setVehicle(newValue);
                  } else {
                    setVehicle(null);
                  }
                }}
                options={vehicleOptions}
                fullWidth
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Kjøretøy" error={vehicle === null} helperText={vehicle === null ? 'Velg et kjøretøy' : null} />}
              />
              {type === undefined && (
                <RadioGroup<UnavailabilityType>
                  sx={{ flexDirection: 'row' }}
                  required
                  value={selectedType}
                  onChange={setSelectedType}
                  options={[
                    { id: 'Unavailability', label: 'Utilgjengelighet' },
                    { id: 'VehicleService', label: 'Service/PKK' },
                  ]}
                />
              )}
              {selectedType === 'Unavailability' && (
              <TextField
                error={title === ''}
                helperText={title === '' ? 'Tittel kan ikke være tom' : null}
                label="Tittel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              )}
              {selectedType === 'VehicleService' && (
              <>
                <RadioGroup
                  sx={{ flexDirection: 'row' }}
                  required
                  value={serviceType}
                  onChange={setServiceType}
                  options={[
                    { id: 'Service', label: 'Service' },
                    { id: 'PKK', label: 'PKK' },
                  ]}
                />
                <TextField
                  error={title === ''}
                  helperText={title === '' ? 'Sted kan ikke være tomt' : null}
                  label="Sted"
                  placeholder="F.eks hvilket verksted"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </>
              )}
              <DateTimePicker
                label="Fra"
                value={from || new Date()}
                onChange={(date) => date && updateFrom(date)}
                error={fromError}
              />
              <DateTimePicker
                label="Til"
                value={to || new Date()}
                onChange={(date) => date && setTo(date)}
                minDate={from || undefined}
                error={toError}
              />
              <TextField
                multiline
                rows={4}
                label="Sjåførkommentar"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <TextField
                multiline
                rows={4}
                label="Internkommentar"
                value={internalComment}
                onChange={(e) => setInternalComment(e.target.value)}
              />
            </FormControl>
            <div className="send-buttons">
              <Button variant="outlined" onClick={onClose}>Avbryt</Button>
              <Button
                disabled={!valid || isLoading}
                variant="contained"
                onClick={submit}
              >
                {isLoading
                  ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  ) : 'Opprett'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
