import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

export const LoadingFields: FC = () => (
  <div className="loading-container">
    <Skeleton variant="rectangular" height={56} />
    <Skeleton variant="rectangular" height={56} />
    <Skeleton variant="rectangular" height={56} />
    <Skeleton variant="rectangular" height={56} />
    <Skeleton variant="rectangular" height={125} />
    <div className="edit-buttons">
      <Skeleton variant="rectangular" height={36} width={82} />
      <span className="flex-gap-10">
        <Skeleton variant="rectangular" height={36} width={82} />
        <Skeleton variant="rectangular" height={36} width={82} />
      </span>
    </div>
  </div>
);
