import {
  CircularProgress, IconButton, SvgIcon,
} from '@mui/material';
import { getISOWeek } from 'date-fns';
import React, {
  FC, useState,
} from 'react';
import { InvoiceBasis } from '../../../core/types/invoiceBasis';
import { apiPath, getAccessToken } from '../../../shared/api';
import { config } from '../../../shared/config/transport';

export const ExportButton: FC<{
  invoiceBasis: InvoiceBasis;
}> = ({
  invoiceBasis,
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const exportExcel = async (b: InvoiceBasis) => {
    setIsDownloading(true);
    try {
      const token = await getAccessToken(config);
      const response = await fetch(
        `${apiPath(config, '/invoiceBasis')}/${b.uid}/export`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const blob = await response.blob();

      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';

      const to = new Date(b.to);
      a.download = `Belastes uke ${getISOWeek(to)} ${to.getFullYear()}.xlsx`;
      a.click();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <IconButton
      onClick={(e) => { exportExcel(invoiceBasis); e.preventDefault(); e.stopPropagation(); }}
    >
      {isDownloading ? <CircularProgress size={24} /> : (
        <SvgIcon>
          <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z" />
          </svg>
        </SvgIcon>
      ) }
    </IconButton>
  );
};
