import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { RootState } from '../../../core/redux';
import {
  setVehicleType,
  useIsFiltersActive,
} from '../../../core/redux/vehicleFilter';

export const VehicleFilter: FC<{
  show?: boolean,
  vehicleCategory: string[],
}> = ({
  show = true,
  vehicleCategory,
}) => {
  const {
    vehicleType,
  } = useSelector((state: RootState) => state.vehicleFilter);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterActive = useIsFiltersActive();

  return (
    <div className="vehicle-filter-partial">
      <div style={{ display: show || filterActive ? 'block' : 'none' }}>
        <FormControl sx={{ p: '8px 0' }} className="vehicle-form">
          <Autocomplete
            multiple
            id="assignmentStatus"
            disableCloseOnSelect
            value={vehicleType}
            onChange={((e, ns) => (dispatch(setVehicleType(ns))))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={vehicleCategory.map((s) => ({ id: s, label: s }))}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            renderTags={(items) => (
              !isOpen
                && (
                <span className="filter-display-line">
                  <span className="one-line-ellipse filter-display-width">
                    {`${items[0].label}`}
                  </span>
                  <span>
                    {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
                  </span>
                </span>
                )
            )}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Kjøretøykategori"
                size="small"
              />
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};
