import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { DieselLoadCounter } from '../../../core/types/dieselLoadCounter';
import { useUpdateDieselLoadMutation } from '../../../core/redux/transport';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  operation: 'fill' | 'empty';
  tank: 'truck' | 'trailer';
  currentLoad?: DieselLoadCounter;
}

const UpdateLoadDialog = ({
  open,
  setOpen,
  operation,
  tank,
  currentLoad,
}: Props) => {
  const [load, setLoad] = useState<DieselLoadCounter>({ blank: 0, colored: 0, hvo: 0 });

  const [updateLoad] = useUpdateDieselLoadMutation();

  useEffect(() => {
    if (currentLoad) setLoad(currentLoad);
    else setLoad({ blank: 0, colored: 0, hvo: 0 });
  }, [currentLoad, open]);

  const handleSubmit = () => {
    if (!load) return;
    const dto = {
      body: load,
      operation,
      tank,
    };
    updateLoad(dto);
    setOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    setLoad((prev) => ({
      ...prev,
      [field]: parseInt(event.target.value, 10),
    }));
  };

  const hasError = (fuelType: 'blank' | 'colored' | 'hvo') => {
    if (!currentLoad) return false;
    if (load[fuelType] < 0) return true;
    if (operation === 'empty' && load[fuelType] > currentLoad[fuelType]) return true;
    return false;
  };

  const getHelperText = (fuelType: 'blank' | 'colored' | 'hvo') => {
    if (!currentLoad) return ' ';
    if (load[fuelType] < 0) return 'Verdien kan ikke være negativ';
    if (operation === 'empty' && load[fuelType] > currentLoad[fuelType]) {
      return 'Verdien kan ikke være større en tilgjengelig mengde i tanken';
    }
    return ' ';
  };

  const isFormValid = () => {
    if (load.blank < 0 || load.colored < 0 || load.hvo < 0) return false;
    if (!currentLoad) return true;
    if (operation === 'empty') {
      if (load.blank > currentLoad?.blank) return false;
      if (load.colored > currentLoad?.colored) return false;
      if (load.hvo > currentLoad?.hvo) return false;
    }
    return true;
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {`${operation === 'fill' ? 'Fyll' : 'Tøm'} ${tank === 'truck' ? 'bil' : 'henger'}`}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            id="blank"
            label="Blank"
            value={load.blank}
            onChange={(e) => handleChange(e, 'blank')}
            type="number"
            error={hasError('blank')}
            helperText={getHelperText('blank')}
          />
          <TextField
            id="farget"
            label="Farget"
            value={load.colored}
            onChange={(e) => handleChange(e, 'colored')}
            type="number"
            error={hasError('colored')}
            helperText={getHelperText('colored')}
          />
          <TextField
            id="farget-hvo"
            label="Farget HVO"
            value={load.hvo}
            onChange={(e) => handleChange(e, 'hvo')}
            type="number"
            error={hasError('hvo')}
            helperText={getHelperText('hvo')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid()}>
          {operation === 'fill' ? 'Fyll' : 'Tøm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLoadDialog;
