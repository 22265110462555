import { NewAssignment } from '../types/api/newAssignment';
import { UpdateAssignment } from '../types/api/updateAssignment';
import {
  UpdateInOrder,
  UpdateOutOrder,
  UpdateInternalOrder,
  UpdateOrder,
  UpdateContainerDeliverOrder,
  UpdateContainerCollectOrder,
  UpdateContainerEmptyingOrder,
  UpdateHaulSemiOrder,
  UpdateHaulHookOrder,
  UpdateDieselOrder,
} from '../types/api/updateOrder';
import { Assignment } from '../types/assignment';
import { Order } from '../types/order';
import { RentalVehicle } from '../types/rentalVehicle';
import { Vehicle } from '../types/vehicle';
import { useIsType } from '../hooks/useIsType';
import { UpdateLoad } from '../types/api/updateLoad';

export const rentalVehicle2Vehicle = (r: RentalVehicle): Vehicle => {
  // A mirror of this function exists on the backend at
  // Models/Ext/BaseData.cs
  const vehicle: Vehicle = {
    internalNumber: r.id,
    vehicleRegistrationPlateNumber: r.vehicleRegistrationPlateNumber,
    mainCategoryName: 'rental',
    subCategoryName: r.vehicleCategory,
    categorySubcategoryConcatenated: `rental ${r.vehicleCategory}`,
    assignedDriverName: r.driverName,
    assignedDriver: { fullName: r.driverName, mobilePhoneWork: r.driverPhoneNumber },
    info: r.comment,
    group: 'rental',
    statusName: 'Tilgjengelig',
    isDummy: false,
    substituteDrivers: [],
  };
  return vehicle;
};

export const order2UpdateOrder = (order: Order): UpdateOrder => {
  const baseData: Partial<UpdateOrder> = {
    fromProject: order.fromProject?.id,
    toProject: order.toProject?.id,
    loads: order.loads.length
      ? order.loads.map((l): UpdateLoad => ({
        id: l.id,
        amount: l.amount,
        massTypeId: (l.type && parseInt(l.type.id, 10)) || undefined,
        wasteType: l.wasteType,
        containerInternalNumber: l.containerInternalNumber,
        containerType: l.containerType,
        containerExternal: l.containerExternal,
        containerSubcategory: l.containerSubcategory,
        machineInternalNumber: l.machine ? l.machine.internalNumber : l.machineInternalNumber,
        machineExternal: l.machineExternal,
      }))
      : undefined,
    date: order.date,
    when: order.when,
    importantDeadline: order.importantDeadline,
    earlyDelivery: order.earlyDelivery,
    vehicle: order.vehicle,
    extraTimeInFacility: order.extraTimeInFacility,
    comment: order.comment,
    contactPersonEmployeeNumber: order.contactPersonEmployeeNumber,
    contactPersonName: order.contactPersonName,
    contactPersonPhoneNumber: order.contactPersonPhoneNumber,
  };

  if (order.type === 'MassIn') {
    return {
      ...baseData,
      type: 'MassIn',
      numberOfVehicles: undefined,
    } as UpdateInOrder;
  }
  if (order.type === 'MassOut') {
    return {
      ...baseData,
      type: 'MassOut',
      numberOfVehicles: undefined,
    } as UpdateOutOrder;
  }
  if (order.type === 'MassInternal') {
    return {
      ...baseData,
      type: 'MassInternal',
      loads: undefined,
      numberOfVehicles: order.numberOfVehicles,
    } as UpdateInternalOrder;
  }
  if (order.type === 'ContainerDeliver') {
    return {
      ...baseData,
      type: 'ContainerDeliver',
      vehicle: undefined,
    } as UpdateContainerDeliverOrder;
  }
  if (order.type === 'ContainerCollect') {
    return {
      ...baseData,
      type: 'ContainerCollect',
      vehicle: undefined,
    } as UpdateContainerCollectOrder;
  }
  if (order.type === 'HaulSemi') {
    return {
      ...baseData,
      type: 'HaulSemi',
      gateCode: order.gateCode,
      extraEquipment: order.extraEquipment,
      hasDispensation: order.hasDispensation,
      daytimeDelivery: order.daytimeDelivery ?? false,
    } as UpdateHaulSemiOrder;
  }
  if (order.type === 'HaulHook') {
    return {
      ...baseData,
      type: 'HaulHook',
      gateCode: order.gateCode,
      extraEquipment: order.extraEquipment,
      hasDispensation: order.hasDispensation,
      daytimeDelivery: order.daytimeDelivery ?? false,
    } as UpdateHaulHookOrder;
  }
  if (order.type === 'Diesel') {
    return {
      ...baseData,
      type: 'Diesel',
      fromProject: undefined,
      vehicle: undefined,
      numberOfVehicles: undefined,
    } as UpdateDieselOrder;
  }
  return {
    ...baseData,
    type: 'ContainerEmptying',
    vehicle: undefined,
  } as UpdateContainerEmptyingOrder;
};

/**
 * Converts an assignment into a new assignment data object, returns null if
 * assignment is invalid
 * @param assignment Assignment to convert
 * @returns NewAssignment data ready for the API or null if invalid
 */
export const assignment2NewAssignment = (assignment: Assignment): NewAssignment | null => {
  if (!assignment.toProject || !assignment.order) {
    return null;
  }
  const isType = useIsType(assignment.type);
  const common = {
    orderId: assignment.order.id,
    vehicleInternalNumber: assignment.vehicle.internalNumber,
    startTime: assignment.startTime,
    endTime: assignment.endTime,
    commentForDriver: assignment.commentForDriver,
    comment: assignment.comment,
    lockState: assignment.lockState,
  };
  if (isType('MassInternal')) {
    return {
      type: 'MassInternal',
      toProject: assignment.toProject.id,
      fromProject: undefined,
      ...common,
    };
  }

  if (!assignment.fromProject) return null;
  if (!assignment.type) return null;

  return {
    ...assignment,
    type: assignment.type,
    toProject: assignment.toProject.id,
    fromProject: assignment.fromProject.id,
    collectionProject: assignment.collectionProject?.id,
    loads: assignment.loads.map((l) => ({
      ...l,
      machineInternalNumber: l.machine && l.machine.internalNumber,
      massTypeId: (l.type && parseInt(l.type.id, 10)) || undefined,
    })),
    ...common,
  };
};

export const assignment2UpdateAssignment = (assignment: Assignment): UpdateAssignment => ({
  type: assignment.type,
  toProject: assignment.toProject?.id ? assignment.toProject.id : undefined,
  fromProject: assignment.fromProject?.id ? assignment.fromProject.id : undefined,
  collectionProject: assignment.intermediateStorage ? undefined : assignment.collectionProject?.id,
  externalCollectionProject: assignment.externalCollectionProject,
  intermediateStorage: assignment.intermediateStorage,
  vehicleInternalNumber: assignment.vehicle.internalNumber,
  startTime: assignment.startTime,
  endTime: assignment.endTime,
  loads: assignment.loads.map((l) => ({
    ...l,
    massTypeId: l.type && parseInt(l.type.id, 10),
  })),
  commentForDriver: assignment.commentForDriver,
  comment: assignment.comment,
  lockState: assignment.lockState,
});

interface VehicleTypeItem {
  name: string,
  sortOrder: number,
}
const knownCategory = new Map<string, VehicleTypeItem>([
  ['6x4 tippbil', { name: '6X4 TIPPBIL', sortOrder: 1 }],
  ['8x4 tippbil', { name: '8X4 TIPPBIL', sortOrder: 2 }],
  ['6x4 trekkvogn', { name: '6X4 TREKKVOGN', sortOrder: 3 }],
  ['8x4 trekkvogn', { name: '8X4 TREKKVOGN', sortOrder: 4 }],
  ['6x6 tippbil', { name: '6X6 TIPPBIL', sortOrder: 5 }],
  ['8x4 kroklift', { name: 'KROKBIL', sortOrder: 6 }],
  ['tankbil', { name: 'TANKBIL', sortOrder: 7 }],
  ['4x2 kranbil', { name: 'KRANBILER', sortOrder: 8 }],
  ['4x8 kranbil', { name: 'KRANBILER', sortOrder: 8 }],
  ['6x2 kranbil', { name: 'KRANBILER', sortOrder: 8 }],
]);
export const vehicleCategory2ListItem = (vehicleCategory: string) => {
  const item = knownCategory.get(vehicleCategory.toLocaleLowerCase());
  return item || { name: vehicleCategory, sortOrder: 100 };
};

export const vehicleCategory2ListName = (vehicleCategory: string) => (
  knownCategory.get(vehicleCategory.toLocaleLowerCase())?.name || vehicleCategory
);

export const vehicleListNames2Categories = (vehicleNames: string[]) => {
  const categories: string[] = [];
  knownCategory.forEach((v, k) => vehicleNames.includes(v.name) && categories.push(k));
  return categories;
};
