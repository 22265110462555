import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { loggerMiddleware } from './loggerMiddleware';
import orderFilter from './orderFilter';
import assignmentFilter from './assignmentFilter';
import orderStates from './bestillingProsessState';
import containerOrderState from './containerOrderState';
import transport, { transportApi } from './transport';
import calendar, { calendarApi } from './calendar';
import tbn, { tbnApi } from './tbn';
import invoicing, { invoicingApi } from './invoicing';
import vehicleFilter from './vehicleFilter';
import region from './region';
import version, { versionApi } from '../../shared/redux/version';
import rentalOrderState from '../../shared/redux/local/rentalOrderState';

// Put additional reducers here
export const store = configureStore({
  reducer: {
    rentalOrderState,
    orderFilter,
    assignmentFilter,
    vehicleFilter,
    orderStates,
    containerOrderState,
    transport,
    calendar,
    version,
    region,
    tbn,
    invoicing,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(
      transportApi.middleware,
      versionApi.middleware,
      calendarApi.middleware,
      tbnApi.middleware,
      invoicingApi.middleware,
    )
    .concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
