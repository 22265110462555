import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { usePostRentalVehicleMutation } from '../../core/redux/transport';
import './style.scss';
import { useVehicleCategory } from '../../core/hooks/vehicleSubCategory';
import { NewRentalVehicle } from '../../core/types/api/newRentalVehicle';
import { PageContent } from '../PageContent';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { useHasRoles } from '../../core/msal';
import { hookVehicleName } from '../../core/constants';

export const CreateRentalVehicle: FC<{
  onClose?: () => void;
  }> = ({
    onClose = () => null,
  }) => {
    const [hasRole] = useHasRoles();
    const [registration, setRegistration] = useState<string>('');
    const [vehicleType, setVehicleType] = useState<string>('');
    const [driverName, setDriverName] = useState<string>('');
    const [driverPhoneNumber, setDriverPhoneNumber] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    useEffect(() => {
      if (hasRole('admin', 'transport-koordinator')) return;
      setVehicleType(hookVehicleName);
    }, [hasRole]);

    const [send, { isLoading }] = usePostRentalVehicleMutation();

    const { data: vehicles } = useVehicleList();
    const vehicleCategories = useVehicleCategory(vehicles || []);

    const valid = useMemo(() => {
      if (registration === '' || registration.length > 8) return false;
      if (vehicleType === '') return false;
      if (driverName === '') return false;
      if (driverPhoneNumber === '' || driverPhoneNumber.length > 12) return false;
      return true;
    }, [registration, vehicleType, driverName, driverPhoneNumber, comment]);

    const submit = () => {
      if (!registration) return;
      if (!vehicleType) return;
      if (!driverName) return;
      if (!driverPhoneNumber) return;
      const body: NewRentalVehicle = {
        vehicleRegistrationPlateNumber: registration,
        vehicleCategory: vehicleType,
        driverName,
        driverPhoneNumber,
        comment,
      };
      send(body).unwrap().then(() => {
        toast.success('Leiebil opprettet');
        onClose();
      }).catch(() => {});
    };

    return (
      <PageContent>
        <FormControl fullWidth className="form">
          <TextField
            label="Registreringsnummer"
            value={registration}
            onChange={(e) => setRegistration(e.target.value)}
            error={!registration || registration.length > 8}
            helperText={
              // eslint-disable-next-line no-nested-ternary
              !registration
                ? 'Registreringnummer kan ikke være tom'
                : registration.length > 8 ? 'Maks 8 symboler' : ''
            }
          />
          <Autocomplete
            disabled={!hasRole('admin', 'transport-koordinator')}
            value={vehicleType}
            freeSolo
            onInputChange={(e, value) => setVehicleType(value || '')}
            noOptionsText=""
            options={vehicleCategories}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Kjøretøykategori"
                error={vehicleType === ''}
                helperText={vehicleType === '' ? 'Kjøretøyskategori kan ikke være tom' : ''}
              />
            )}
          />
          <TextField
            label="Sjåfør navn"
            value={driverName}
            onChange={(e) => setDriverName(e.target.value)}
            error={!driverName}
            helperText={!driverName ? 'Sjåfør navn kan ikke være tom' : ''}
          />
          <TextField
            label="Sjåfør telefonnummer"
            type="number"
            value={driverPhoneNumber}
            onChange={(e) => setDriverPhoneNumber(e.target.value)}
            error={!driverPhoneNumber || driverPhoneNumber.length > 12}
            helperText={
              // eslint-disable-next-line no-nested-ternary
              !driverPhoneNumber
                ? 'Sjåfør telefonnummer kan ikke være tom'
                : driverPhoneNumber.length > 12 ? 'Maks 12 symboler' : ''
            }

          />
          <TextField
            multiline
            rows={4}
            label="Kommentar"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
        <div className="send-buttons">
          <Button variant="outlined" onClick={onClose}>Avbryt</Button>
          <Button
            disabled={!valid || isLoading}
            variant="contained"
            onClick={submit}
          >
            {isLoading
              ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : 'Opprett'}
          </Button>
        </div>
      </PageContent>
    );
  };
