import React, {
  FC,
  ReactElement,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react';
import { Menu, Box, Typography } from '@mui/material';

export const PopMenu: FC<{
  label?: string,
  closeButton?: ReactElement,
  renderButton?: ReactNode,
  side?: 'left' | 'right' | 'center',
  children: ReactNode,
  disabled?: boolean,
}> = ({
  label,
  closeButton,
  renderButton = null,
  side = 'right',
  children,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement|null>(null);

  const open: MouseEventHandler<HTMLSpanElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const isOpen = !disabled && Boolean(anchorEl);

  return (
    <>
      <Box component="span" onClick={open}>
        { renderButton }
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
        onClick={() => closeButton === undefined && close()}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: 1,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              // Calculate offset based on button component width
              right: side === 'right' ? Math.max(8, anchorEl?.offsetWidth || 40) / 4 : 'unset',
              left: side === 'left' ? Math.max(8, anchorEl?.offsetWidth || 32) / 4 : 'unset',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: side, vertical: 'top' }}
        anchorOrigin={{ horizontal: side, vertical: 'bottom' }}
      >
        <span>
          {label
        && (
        <Typography variant="overline" component="div" align="center" sx={{ fontSize: 15 }}>
          {label}
        </Typography>
        )}
          {children}
          {closeButton && React.cloneElement(closeButton, { onClick: close })}
        </span>
      </Menu>
    </>
  );
};
