import React, { FC } from 'react';
import { Skeleton } from '@mui/material';
import Card from '@mui/material/Card';

export const LoadingTaskItem: FC = () => (
  <div className="task-item">
    <Card sx={{ padding: '5px' }}>
      <div className="header">
        <Skeleton width="90%" />
      </div>
      <div className="subtitle flexcenter">
        <Skeleton sx={{ bgcolor: '#004B8B', mr: 2, borderRadius: 3 }} width="20%" height={40} />
        <Skeleton width="40%" height={25} />
      </div>
      <div className="footer">
        <span className="left flexcenter">
          <Skeleton variant="circular" width="12px" height="12px" sx={{ mr: 1 }} />
          <Skeleton width="20%" />
        </span>
        <span className="right">
          <Skeleton width="100px" />
        </span>
      </div>
    </Card>
  </div>
);
