import { ReactNode } from 'react';
import {
  areIntervalsOverlapping,
  compareAsc,
  compareDesc,
  endOfDay,
  format,
  startOfDay,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { Box } from '@mui/material';
import { AssignmentStatus } from '../types/enums/assignmentStatus';
import { OrderStatus } from '../types/enums/orderStatus';
import { Order } from '../types/order';
import { Assignment } from '../types/assignment';
import { Project } from '../types/project';
import { Vehicle } from '../types/vehicle';
import { Staff } from '../types/staff';
import { Load } from '../types/load';
import { AssignmentType } from '../types/enums/assignmentType';
import { SelectItem } from '../../shared/types/util/selectItem';
import { useIsType } from '../hooks/useIsType';
import { dieselTypeItems } from '../../shared/types/dieselType';

export const sumAssignedLoads = (id: string|undefined, order: Order) => {
  const vehicles = order.vehicles || [];
  if (!id) return 0;
  return vehicles
    .flatMap((v) => v?.assignments.filter((a) => a.status !== 'Cancelled' && a.status !== 'Deleted'))
    .flatMap((a) => a?.loads.filter((l) => l.type?.id === id))
    .reduce((prev, l) => prev + l.amount, 0);
};

export const getDeliveryTimes = (order: Order) => {
  const fromTime = order.when.from
    ? format(new Date(order.when.from), "dd.MM.yyyy 'kl.' HH:mm", { locale: nb })
    : null;
  const toTime = order.when.to
    ? format(new Date(order.when.to), "dd.MM.yyyy 'kl.' HH:mm", { locale: nb })
    : null;
  const customTime = (
    <div>
      <span>{`Fra: ${fromTime}`}</span>
      <br />
      <span>{`Til: \u00A0${toTime}`}</span>
    </div>
  );

  switch (order.when.when) {
    case 'DuringTheDay':
      return 'I løpet av dagen';
    case 'Morning':
      return 'Morgen';
    case 'BeforeFood':
      return 'Før mat';
    case 'AfterFood':
      return 'Etter mat';
    case 'Custom':
      return customTime;
    default:
      return 'I løpet av dagen';
  }
};

export const getOrderStatusColor = (currStatus: OrderStatus | undefined) => {
  switch (currStatus) {
    case 'Created':
      return 'CreatedColor';
    case 'UnderPlanning':
      return 'UnderPlanningColor';
    case 'Planned':
      return 'ApprovedColor';
    case 'Completed':
      return 'FinishedColor';
    case 'Cancelled':
      return 'CancelledColor';
    case 'Undelivered':
      return 'UndeliveredColor';
    case 'Declined':
      return 'CancelledColor';
    default:
      return 'CreatedColor';
  }
};

export const getAssignmentStatusColor = (currStatus: AssignmentStatus | undefined) => {
  switch (currStatus) {
    case 'UnderPlanning':
      return 'UnderPlanningColor';
    case 'Approved':
      return 'ApprovedColor';
    case 'Started':
      return 'StartedColor';
    case 'Completed':
      return 'CompletedColor';
    case 'Cancelled':
      return 'CancelledColor';
    case 'NotDelivered':
    case 'VerifiedNotDelivered':
      return 'NotDeliveredColor';
    case 'Deleted':
      return 'DeletedColor';
    default:
      return 'UnderPlanningColor';
  }
};

export const getOrderProject = (order: Order) => {
  const isType = useIsType(order.type);
  if (isType('ContainerCollect', 'ContainerEmptying', 'MassOut')) return order.fromProject;
  return order.toProject;
};

export const getAssignmentProject = (assignment: Assignment) => {
  switch (assignment.type) {
    case 'MassIn':
      return assignment.toProject;
    case 'MassOut':
      return assignment.fromProject;
    case 'MassInternal':
      return assignment.toProject;
    default:
      return null;
  }
};

/**
 *
 * @param type Assignmenttype
 * @param l Load
 * @param multiline flag indicating if function should return jsx for planning element for ContainerCollect and ContainerEmptying
 * @returns string or jsx element
 */
export const getContainerLoadTitle = (type: AssignmentType|undefined, l: Load, multiline: boolean = false): string|ReactNode => {
  if (type === 'ContainerCollect' || type === 'ContainerEmptying') {
    if (multiline) {
      return (
        <Box display="flex" marginLeft={1} flexDirection="column">
          <span>
            {`${(l.actualContainerExternal || l.containerExternal)
              ? `(Ekstern) ${l.actualContainerExternal || l.containerExternal}`
              : l.actualContainerInternalNumber || l.containerInternalNumber} ${l.container?.subCategoryName.replace('KROKCONTAINER', '') || ''}  ${l.wasteType ? `med ${l.wasteType}` : '(tom)'}`}
          </span>
        </Box>
      );
    }
    return `${l.containerExternal ? `(Ekstern) ${l.containerExternal}` : l.containerInternalNumber} ${l.wasteType ? `med ${l.wasteType}` : '(tom)'}`;
  }

  return `${l.amount}stk ${l.containerType} ${l.type?.name ? `med ${l.type.name}` : ''}`;
};

export const getPlannedContainerCount = (order: Order, load: Load, emptyingExpanded?: AssignmentType, checkType: boolean = false) => {
  // default ContainerDeliver
  let findFunc = (l: Load) => l.type?.name === load.type?.name && l.containerType === load.containerType;
  if (order.type === 'ContainerCollect' || order.type === 'ContainerEmptying') {
    findFunc = (l: Load) => (l.containerExternal
      ? l.containerExternal === load.containerExternal
      : l.containerInternalNumber === load.containerInternalNumber);
  }
  let count: number = 0;
  if (!order.vehicles) return 0;
  order.vehicles
    .forEach((a) => (
      a.assignments.filter((as) => ((as.status !== 'Cancelled' && as.status !== 'NotDelivered' && as.status !== 'VerifiedNotDelivered' && as.status !== 'Unavailable')
      && (emptyingExpanded
        ? (as.type === emptyingExpanded
          || (!checkType && as.type === 'ContainerEmptying')
          || (!checkType && as.type === 'ContainerSwap')) && as.loads.some(findFunc)
        : as.loads.some(findFunc))))
        .forEach((i) => i.loads.forEach((l) => { count += l.amount; }))));
  return count;
};

export const isContainerInIntermediateStorage = (order: Order, load: Load): boolean => {
  if (!order.vehicles) return false;
  if (order.type !== 'ContainerCollect') return false;
  if (order.vehicles.some((v) => v.assignments.some((a) => a.type === 'ContainerEmptying' && a.status !== 'Cancelled' && a.status !== 'NotDelivered' && a.status !== 'VerifiedNotDelivered'
  && a.loads.some((l) => ((l.actualContainerExternal || l.containerExternal)
    ? (l.actualContainerExternal || l.containerExternal) === (load.actualContainerExternal || load.containerExternal)
    : (l.actualContainerInternalNumber || l.containerInternalNumber) === (load.actualContainerInternalNumber || load.containerInternalNumber)))))) return false;
  let count: number = 0;
  order.vehicles
    // eslint-disable-next-line no-return-assign
    .forEach((a) => (
      a.assignments.filter((as) => (as.status === 'Completed'
          && as.type === 'ContainerCollect'
          && as.intermediateStorage
          && as.loads.some((l) => (l.actualContainerExternal
            ? (load.actualContainerExternal || load.containerExternal) === l.actualContainerExternal
            : (load.actualContainerInternalNumber || load.containerInternalNumber) === l.actualContainerInternalNumber))))
        .length > 0 ? count += 1 : null));
  return count > 0;
};

export const extractData = (order: Order) => {
  const project = getOrderProject(order);

  const title = `${project?.id} - ${project?.projectName}`;
  // Add together all masses of all loads
  const totalAmount = order.loads ? order.loads.reduce((acc, curr) => acc + curr.amount, 0) : 0;
  // Get the load with the most mass
  const mainType = order.loads.reduce(
    (acc, curr) => (curr.amount > acc.max ? { max: curr.amount, name: curr.type ? curr.type.name : '' } : acc),
    { max: 0, name: '' },
  ).name;

  const loads = Math.ceil(totalAmount / (order.vehicle === 'Single' ? 10 : 20)); // Each vehicle can take 20 m³ per load
  const vehicles = Math.ceil(loads / 6); // Each vehicle can make 6 loads a day

  return {
    title,
    totalAmount,
    mainType,
    loads,
    vehicles,
  };
};

export const extractLoadData = (order: Order) => ({
  trips: order.details?.assignmentCount,
  vehicles: order.details?.vehicleCount,
});
export const pluralize = (value: number, word: string, ending: string = 'er') => {
  if (value === 1) {
    return `${value} ${word}`;
  }
  return `${value} ${word}${ending}`;
};

export const onlyQuarters = (timeValue: number, clockType: 'hours' |'minutes' | 'seconds') => clockType === 'minutes' && (timeValue / 15) !== Math.round(timeValue / 15);

// if numbers are erased and equals null, or are negative, set to 0.
export const updateDuration = (newDuration: string) => {
  const num = parseInt(newDuration, 10) || 0;
  // Allows setting durations of ~200 years - should be fine right?
  return Math.min(num < 0 ? 0 : num, 100000000);
};

/**
 * Finds the latest assignment in a list of assignments.
 *
 * Defaults to first entry if equal
 *
 * @param assignments Assignments to search through
 * @returns Latest assignment
 */
export const latestAssignment = (assignments: Assignment[]): Assignment => {
  let newestDate = null;
  let newestAssignment = assignments[0];
  for (let i = 0; i < assignments.length; i += 1) {
    const assignment = assignments[i];
    if (newestDate === null || compareAsc(new Date(assignment.lastEditTime), newestDate) > 0) {
      newestDate = new Date(assignment.lastEditTime);
      newestAssignment = assignment;
    }
  }
  return newestAssignment;
};

/**
 * Checks if time period overlaps given day
 *
 * @param start Start of period to check
 * @param end End of period to check
 * @param day Day to check for - defaults to today
 * @returns True if any part of the period is within the given day, false otherwise
 */
export const timeWithinDay = (start: Date, end: Date, day: Date = new Date()) => areIntervalsOverlapping(
  {
    start: startOfDay(day),
    end: endOfDay(day),
  },
  { start, end },
);

/**
 * Converts nested objects into attributes
 *
 * @param attributes Attributes to process
 * @param prefix Prefix for attributes
 * @returns Object with attribute names and values
 */
export const createAttributeSet = (attributes: any, prefix = 'data-'): Record<string, string> => {
  const keys = Object.keys(attributes);
  let attribs: Record<string, string> = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = attributes[key];
    if (value === null) {
      attribs[(prefix + key).toLowerCase()] = '';
    } else if (
      typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean'
    ) {
      attribs[(prefix + key).toLowerCase()] = `${value}`;
    } else if (typeof value === 'object') {
      attribs = { ...attribs, ...createAttributeSet(value, `${prefix}${key}-`) };
    }
  }
  return attribs;
};

/**
 * Gets a list of attributes from a given element
 *
 * @param el Element to get from
 * @param attributes List of attributes to get
 * @param prefix Prefix used to get attributes - does not appear in data
 * @returns Object of attribute name -> attribute value
 */
export const getAttributes = (
  el: { getAttribute: (arg0: string) => any },
  attributes: string[],
  prefix = 'data-',
) => {
  const obj: Record<string, string | null> = {};
  for (let i = 0; i < attributes.length; i += 1) {
    const att = attributes[i];
    obj[att] = el.getAttribute((prefix + att).toLowerCase());
  }
  return obj;
};

export const getCurrentDriver = (v: Vehicle): Staff|undefined => {
  const subsWithDriver = v.substituteDrivers.filter((sub) => sub.driver);
  return subsWithDriver.length > 0 ? subsWithDriver[0].driver : v.assignedDriver;
};

export const getCurrentDriverName = (v: Vehicle) => {
  const driver = getCurrentDriver(v);
  return driver?.fullName
    ? driver.fullName : 'Ingen navn';
};

export const sortByMRU = (mrus: Map<number, Date>) => (a: Project, b: Project) => {
  const aMru = mrus.get(a.id);
  const bMru = mrus.get(b.id);
  if (!aMru && !bMru) return 0;
  if (!aMru) return 1;
  if (!bMru) return -1;
  return compareDesc(aMru, bMru);
};

/**
 * Creates an array of num length and populates it with func
 *
 * @param num Number of items in array
 * @param func Function to populate array
 * @returns Populated array
 */
export const forI = <T extends any>(num: number, func: (i: number) => T): T[] => {
  if (num <= 0) return [] as T[];
  return Array.from({ length: num }).map((v, i) => func(i));
};

/**
 * Checks and corrects the given value, returning undefined if the value is
 * the string '(tom)'
 *
 * Additionally checks for null, empty string, and the number 0 and returns
 * undefined in these cases
 * @param val Value to check
 * @returns Corrected value
 */
export const emptyGuard = (val: number|string|undefined|null) => {
  if (val === undefined || val === null) return undefined;
  if (val === '' || val === 0) return undefined;
  return `${val}` === '(tom)' ? undefined : `${val}`;
};

/**
 * Create a select item with the value as both the id and value
 *
 * @param val Value
 * @returns Select item or undefined if val is falsey
 */
export const toSelectItem = (val: number|string|null|undefined): SelectItem|undefined => {
  if (!val) return undefined;
  return {
    id: val,
    label: `${val}`,
  };
};

/**
 * Count total for property of objects in an array
 * @param arr Array of objects
 * @param getter Getter for property to be counted
 * @returns Total count
 */
export const countProperty = <T extends object>(
  arr: T[],
  getter: (v: T) => number,
) => (
    arr.reduce((a, v) => a + getter(v), 0)
  );

/**
 * Filter the array so only one object with the key exists
 * @param arr Array of objects
 * @param key Key of object that should be unique
 * @returns Unique array
 */
export const unique = <T extends object>(arr: T[]|undefined|null, key: keyof T) => {
  if (!arr) return [];
  const out: T[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (!out.some((o) => o[key] === item[key])) {
      out.push(item);
    }
  }
  return out;
};

export const calculateTonnage = (m3: number) => (m3 * 1.6).toFixed(1);

/**
 *  Stringify an optional boolean value. If the value is true, you get "Ja",
 * "Nei" for false, and by default "-" for null or undefined.
 *
 * ```ts
 * optionalBool(true); // "Ja"
 * optionalBool(false); // "Nei"
 * optionalBool(undefined); // "-"
 * optionalBool(null); // "-"
 * optionalBool(null, 'Ukjent'); // "Ukjent"
 * ```
 */
export const optionalBool = (
  /** Boolean value to stringify */
  v: boolean | undefined | null,
  /** String to return if value is not true or false */
  defaultText: string = '-',
) => {
  if (v === true) return 'Ja';
  if (v === false) return 'Nei';
  return defaultText;
};

/** Get the diesel load text */
export const getDieselLoadTitle = (l: Load) => {
  const containerAmount = pluralize(l.dieselContainerAmount ?? 0, 'tank');
  const type = dieselTypeItems.find((t) => t.id === l.dieselType)?.label;
  return (`${containerAmount} (${l.amount} liter) ${type ?? ''} diesel`);
};
