import { useMemo } from 'react';
import { vehicleCategory2ListItem } from '../helpers/converters';
import { Vehicle } from '../types/vehicle';

export const useVehicleCategory = (vehicles: Vehicle[]) => {
  const categories: string[] = useMemo(
    () => vehicles.map((v) => v.subCategoryName)
      .reduce((c, v) => (c.includes(v) ? c : [...c, v]), [] as string[]),
    [vehicles],
  );

  categories.sort();
  const cats = categories.map(vehicleCategory2ListItem).sort((a, b) => a.sortOrder - b.sortOrder);

  return Array.from(new Set<string>(cats.map((c) => c.name)));
};
