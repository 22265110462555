import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { EditRentalVehicle } from '../../components/EditRentalVehicle/index';
import { PageContent } from '../../components/PageContent';
import './style.scss';
import { Guid } from '../../core/types/guid';

export const EditRentalVehiclePage = () => {
  const navigate = useNavigate();

  const { id = '' } = useParams<{id: Guid}>();

  return (
    <Page className="edit-rental-vehicle-page">
      <Header onBack={() => navigate('/innleiebiler')}>Rediger Innleiebil</Header>
      <PageContent>
        <EditRentalVehicle id={id} onAction={() => navigate('/innleiebiler')} />
      </PageContent>
    </Page>

  );
};
