import { useState } from 'react';
import { format } from 'date-fns';
import { Chip, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { extractData } from '../../core/helpers/functions';
import { getTransportType } from '../../core/helpers/translate';
import { useCancelOrderMutation, useGetOrderQuery } from '../../core/redux/transport';
import { PageUnauthenticated } from '../../shared/components/page';
import { EditOrder } from './components/edit';
import { ViewOrder } from './components/view';
import './style.scss';
import { Header } from '../../components/Header';
import { OrderStatusIcon } from '../../components/OrderStatusIcon';
import { NotFound } from '../../shared/components/NotFound';

export const OrderDetailsTransportPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: order } = useGetOrderQuery(id ?? skipToken);
  const [cancelOrder, { isLoading: cancelLoading }] = useCancelOrderMutation();

  const [isEdit, setIsEdit] = useState(false);

  const orderData = order ? extractData(order) : undefined;

  if (order && order.type !== 'Diesel') {
    return <NotFound />;
  }

  return (
    <PageUnauthenticated className="order-page">
      <Header onBack={() => navigate('/dieselplanlegger')}>Bestillingsdetaljer</Header>
      <div className="order-wrapper">
        <div className="order-inner">
          <div className="order-header">
            <div className="line-wrapper">
              <span><Chip color="info" label={getTransportType(order?.type)} size="small" /></span>
              <span className="header">{`${orderData?.title ?? ''}`}</span>
              <span>
                <OrderStatusIcon currStatus={
                  order?.type === 'Diesel' && order.status === 'Planned'
                    ? 'Completed'
                    : order?.status
                  }
                />
              </span>
            </div>
            <div className="line-wrapper mt5">
              <span className="left updated-by">Oppdatert: {`${order?.lastEditTime
                ? `${format(new Date(order?.lastEditTime), 'dd.MM.yyyy')} kl. ${format(new Date(order?.lastEditTime), 'HH:mm')}`
                : 'uspesifisert tidspunkt'} av ${order?.lastEditByName
                ? order?.lastEditByName : 'uspesifisert person'}`}
              </span>
              <span className="right updated-by">#{order?.id ?? ''}</span>
            </div>
          </div>
          <div className="flex-task">
            {isEdit ? (
              <EditOrder
                order={order}
                onEndEdit={() => setIsEdit(false)}
              />
            ) : (
              <ViewOrder
                order={order}
                onCancel={cancelOrder}
                onStartEdit={() => setIsEdit(true)}
              />
            )}
          </div>
        </div>
      </div>
      {(cancelLoading) && (
      <CircularProgress
        size={40}
        sx={{
          position: 'fixed',
          bottom: '50%',
          left: '50%',
          marginBottom: '-12px',
          marginLeft: '-12px',
          zIndex: 1000,
        }}
      />
      )}
    </PageUnauthenticated>
  );
};
