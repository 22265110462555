import { Skeleton } from '@mui/material';
import { FC } from 'react';

export const Loading: FC = () => (
  <div className="worker-details">
    <div>
      <div className="flexcenter">
        <Skeleton variant="rectangular" height={35} width={200} />
        <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
      </div>
      <span className="m10 " />
      <div className="flexcenter mr10">
        <Skeleton variant="rectangular" height={35} width={200} />
        <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
      </div>
      <span className="m10" />
      <div className="flexcenter mr10">
        <Skeleton variant="rectangular" height={35} width={200} />
        <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
      </div>
    </div>
  </div>
);
