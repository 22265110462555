import { AssignmentType } from '../types/enums/assignmentType';
import { OrderType } from '../types/enums/orderType';

/** Provides a function that checks if the assignmentType is one of the given types
 *
 * Includes the utility "types" Container and Mass
 *
 * Note: This is not actually a hook, so it can be used anywhere
 */
export const useIsType = (type: AssignmentType|null|undefined) => {
  const isType = (...types: (AssignmentType|'Container'|'Mass'|'Haul')[]) => {
    if (!type) return false;
    const t = types.filter((v) => !['Container', 'Mass', 'Haul'].includes(v)) as AssignmentType[];
    if (types.includes('Haul') && isType(
      'HaulHook',
      'HaulSemi',
    )) return true;
    if (types.includes('Container') && isType(
      'ContainerCollect',
      'ContainerDeliver',
      'ContainerEmptying',
      'ContainerSwap',
    )) return true;
    if (types.includes('Mass') && isType(
      'MassIn',
      'MassOut',
      'MassInternal',
    )) return true;
    return t.includes(type);
  };
  return isType;
};

/** Provides a function that checks if the assignmentType is one of the given types
 *
 * Includes the utility "types" Container and Mass
 *
 * Note: This is not actually a hook, so it can be used anywhere
 */
export const useIsOrderType = (type: OrderType|null|undefined) => {
  const isType = (...types: (OrderType|'Container'|'Mass'|'Haul')[]) => {
    if (!type) return false;
    let t = types.filter((v) => !['Container', 'Mass', 'Haul'].includes(v)) as OrderType[];
    if (types.includes('Haul')) {
      t = [
        ...t,
        'HaulHook',
        'HaulSemi',
      ];
    }
    if (types.includes('Container')) {
      t = [
        ...t,
        'ContainerCollect',
        'ContainerDeliver',
        'ContainerEmptying',
      ];
    }
    if (types.includes('Mass')) {
      t = [
        ...t,
        'MassIn',
        'MassOut',
        'MassInternal',
      ];
    }
    return t.includes(type);
  };
  return isType;
};
