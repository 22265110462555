import { useState } from 'react';
import {
  Typography,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { useGetDieselLoadQuery } from '../../../core/redux/transport';
import { DieselLoadCounter } from '../../../core/types/dieselLoadCounter';
import UpdateLoadDialog from './UpdateLoadDialog';

export interface DieselLoadUpdate {
  body: DieselLoadCounter;
  operation: 'fill' | 'empty';
  tank: 'truck' | 'trailer';
}

interface DieselCounterValueProps {
  value: number | undefined;
  isLoading: boolean;
}

const DieselCounterValue = ({ value, isLoading }: DieselCounterValueProps) => {
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="flex-end">
        <CircularProgress size={20} />
      </Box>
    );
  }

  const isError = (value ?? 0) < 0;
  return (
    <Typography
      color={isError ? 'error' : undefined}
      fontWeight={isError ? 'bold' : undefined}
      textAlign="right"
    >{value ?? '0'}
    </Typography>
  );
};

interface DieselCounterLineProps {
  name: string;
  data: DieselLoadCounter | undefined;
  isLoading: boolean;
  onAction?: (tankToUpdate: 'truck' | 'trailer', action: 'fill' | 'empty') => void;
}

const DieselCounterLine = ({
  name,
  data,
  isLoading,
  onAction,
}: DieselCounterLineProps) => {
  const tank = name === 'På bil' ? 'truck' : 'trailer';

  return (
    <Box
      className="diesel-counter-line"
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        '&>*': { flex: 1 },
        minHeight: '40px',
        gap: '20px',
        color: '#333',
      }}
    >
      <Typography fontSize={14} textTransform="uppercase" textAlign="right">
        {name}
      </Typography>
      <DieselCounterValue value={data?.blank} isLoading={isLoading} />
      <DieselCounterValue value={data?.colored} isLoading={isLoading} />
      <DieselCounterValue value={data?.hvo} isLoading={isLoading} />
      {onAction ? (
        <Box flex={1} display="flex" gap={1} alignItems="center" justifyContent="flex-end">
          <Button variant="contained" size="small" onClick={() => onAction(tank, 'empty')}>
            Tøm
          </Button>
          <Button variant="contained" size="small" onClick={() => onAction(tank, 'fill')}>
            Fyll
          </Button>
        </Box>
      ) : (
        <Box flex={1} />
      )}
    </Box>
  );
};

export const DieselCounter = () => {
  const { data, isLoading } = useGetDieselLoadQuery();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tank, setTank] = useState<'truck' | 'trailer' | undefined>(undefined);
  const [operation, setOperation] = useState<'fill' | 'empty' | undefined>(undefined);

  const handleOpenDialog = (tankToUpdate: 'truck' | 'trailer', action: 'fill' | 'empty') => {
    setDialogOpen(true);
    setTank(tankToUpdate);
    setOperation(action);
  };

  return (
    <Box className="diesel-counter" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        className="diesel-counter-header"
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          '&>*': { flex: 1 },
          minHeight: '30px',
          gap: '20px',
          color: '#333',
        }}
      >
        <Typography />
        <Typography fontSize={14} textTransform="uppercase" textAlign="right">
          Blank
        </Typography>
        <Typography fontSize={14} textTransform="uppercase" textAlign="right">
          Farget
        </Typography>
        <Typography fontSize={14} textTransform="uppercase" textAlign="right">
          Farget HVO
        </Typography>
        <Typography />
      </Box>

      {data && tank && operation && (
        <UpdateLoadDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          operation={operation}
          tank={tank}
          currentLoad={data[tank]}
        />
      )}

      <DieselCounterLine
        name="På bil"
        data={data?.truck}
        isLoading={isLoading}
        onAction={handleOpenDialog}
      />
      <DieselCounterLine
        name="På henger"
        data={data?.trailer}
        isLoading={isLoading}
        onAction={handleOpenDialog}
      />
      <DieselCounterLine name="Ikke levert" data={data?.notDelivered} isLoading={isLoading} />
      <DieselCounterLine name="Rest" data={data?.remaining} isLoading={isLoading} />
    </Box>
  );
};
