import {
  Box,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RadioYesNo } from '../RadioYesNo';

/** Show yes/no and a text field if yes */
export const OptionalTextField = ({
  value = undefined,
  onChange = () => null,
  required = false,
  size = 'medium',
  label = 'Verdi',
}: {
  /** undefined for no, string value for yes */
  value?: string | undefined,
  /** New value will always be either undefined or a string */
  onChange?: (v: string|undefined) => void,
  /** If true, show as error until a selection is made */
  required?: boolean,
  /** Size of text field */
  size?: 'small' | 'medium',
  /** TItle for text field */
  label?: string,
}) => {
  const [stringVal, setStringVal] = useState<string>('');
  useEffect(() => {
    if (typeof value !== 'string') return;
    setStringVal(value);
  }, [value]);
  const showField = typeof value === 'string';

  return (
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
      <RadioYesNo
        required={required}
        value={showField}
        onChange={(v) => onChange(v ? stringVal : undefined)}
      />
      {showField && (
      <TextField
        required={required}
        fullWidth
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        error={!value}
        size={size}
      />
      )}
    </Box>
  );
};
