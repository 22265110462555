import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';
import { VehicleType, vehicleType } from '../../core/types/enums/vehicleType';
import { vehicleType as translateVehicleType } from '../../core/helpers/translate';

const options = vehicleType.map((v) => ({ label: translateVehicleType(v), id: v }));

export const VehicletypePicker = ({
  value,
  onChange = () => null,
  size = 'medium',
  fullWidth = false,
  disabled = false,
}: {
  value?: VehicleType | null,
  onChange?: (val: VehicleType | null) => void,
  size?: 'small' | 'medium',
  fullWidth?: boolean,
disabled?: boolean,
}) => {
  const v = useMemo(() => options.find((o) => o.id === value) ?? null, [value]);
  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      value={v}
      clearOnBlur
      size={size}
      fullWidth={fullWidth}
      clearOnEscape
      noOptionsText="Laster..."
      onChange={(e, newValue) => {
        if (newValue) {
          onChange(newValue.id);
        } else {
          onChange(null);
        }
      }}
      options={options}
        // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
