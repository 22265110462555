import React, { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Container } from '../../../core/types/container';
import { TextValue } from './TextValue';
import { Modal } from '../../../shared/components/modal';
import { ProjectPicker } from '../../../components/ProjectPicker';
import { Project } from '../../../core/types/project';
import { useUpdateContainerPositionMutation } from '../../../core/redux/transport';

export const ContainerDetails: FC<{
  container: Container;
}> = ({
  container,
}) => {
  const [changePlacement, setChangePlacement] = useState<boolean>(false);
  const [newPlacement, setNewPlacement] = useState<Project|null>(null);
  const [update] = useUpdateContainerPositionMutation();

  const close = () => {
    setNewPlacement(null);
    setChangePlacement(false);
  };

  const save = () => {
    if (newPlacement === null) return;
    update({
      internalNumber: container.internalNumber,
      body: {
        projectId: newPlacement?.id,
        latitude: '',
        longitude: '',
      },
    }).then(close);
  };

  return (
    <div>
      <Box display="flex" alignItems="stretch" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="row" gap={1}>
          <TextValue label="Internummer" value={container.internalNumber} />
          <TextValue label="Underkategori" value={container.subCategoryName} />
          <TextValue label="Status" value={container.status} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <TextValue label="Model" value={container.modelName} />
          <TextValue label="Serienummer" value={container.serialNumber} />
          <span style={{ width: '100%' }} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1} alignItems="flex-end">
          <TextValue label="Nåværende plassering" value={`${container.project?.id} ${container.project?.projectName}`} />
          <Box style={{ width: '100%' }}>
            <Button variant="outlined" onClick={() => setChangePlacement(true)}>Endre plassering</Button>
          </Box>
          <span style={{ width: '100%' }} />
        </Box>
      </Box>

      <Modal
        title="Endre plassering"
        onClose={() => setChangePlacement(false)}
        maxWidth="sm"
        open={changePlacement}
      >
        <Box minHeight="450px" display="flex" flexDirection="column">
          <Box className="mb40">
            <ProjectPicker value={newPlacement} onChange={(newValue) => setNewPlacement(newValue)} />
          </Box>
          <Box display="flex" gap={1} flexDirection="row-reverse">
            <Button variant="contained" onClick={save} disabled={newPlacement === null}>Lagre</Button>
            <Button variant="outlined" onClick={close}>Avbryt</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
