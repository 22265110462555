import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AlarmIcon from '@mui/icons-material/Alarm';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderStatusIcon } from '../../../components/OrderStatusIcon';
import { pluralize } from '../../../core/helpers/functions';
import { DieselOrder } from '../../../core/types/dieselOrder';
import { Load } from '../../../core/types/load';
import { APPROVED_COLOR, DELIVERED_COLOR, STARTED_COLOR } from '../../../shared/constants';
import { dieselTypeItems } from '../../../shared/types/dieselType';
import { useIsOrderLate } from '../../TransportplanleggerPage/hooks/useIsLate';

const translateDieselType = (load: Load) => {
  const item = dieselTypeItems.find((i) => i.id === load.dieselType);
  return item?.label;
};

const getLoadText = (load: Load) => {
  const type = translateDieselType(load);
  const containerAmount = pluralize(load.dieselContainerAmount ?? 0, 'tank');
  return (`${containerAmount} (${load.amount} liter) ${type ?? ''} diesel`);
};

const useIcons = (order: DieselOrder) => {
  const { isLate } = useIsOrderLate(order);
  return (
    <Box>
      {order.earlyDelivery && (
      <span className="fraction" title="Kan leveres dagen før">
        <AltRouteIcon fontSize="small" color="primary" />
      </span>
      )}
      {isLate && (
      <span className="fraction transform-type" title="Sen innsending">
        <AlarmIcon fontSize="small" color="error" />
      </span>
      )}
    </Box>
  );
};

export const DieselOrderCard = ({
  order,
  dayName,
}: {
  order: DieselOrder,
  dayName: string,
}) => {
  const icons = useIcons(order);
  const navigate = useNavigate();
  const dieselType = translateDieselType(order.load);
  const typeColor = useMemo(() => {
    switch (order.load.dieselType) {
      case 'Blank': return DELIVERED_COLOR;
      case 'Colored': return APPROVED_COLOR;
      case 'HVO': return STARTED_COLOR;
      default: return undefined;
    }
  }, [order.load.dieselType]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: order.id,
    data: { dayName },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      sx={{ display: 'flex' }}
    >
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...listeners}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          margin: 1,
          cursor: 'grab',
          '&:active': {
            cursor: 'grabbing',
          },
        }}
      >
        <DragIndicatorIcon sx={{ fontSize: 36 }} fontSize="large" />
      </Box>
      <Divider orientation="vertical" flexItem />
      <CardActionArea onClick={() => {
        navigate(`/dieselplanlegger/${order.id}`);
      }}
      >
        <CardContent sx={{
          gap: 0.5, display: 'flex', flexDirection: 'column', padding: 1.5,
        }}
        >
          <Box className="line-wrapper">
            <Box className="header left">
              {`${order.toProject?.id} - ${order.toProject?.projectName}`}
            </Box>
            <Box className="right transform-status">
              <Chip size="small" sx={{ backgroundColor: typeColor, color: 'white' }} label={dieselType} />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <Box className="load" display="flex" flexDirection="column" gap={0.5} overflow="hidden">
              {getLoadText(order.load)}
            </Box>
            <Typography sx={{ fontSize: 'small', textAlign: 'end', maxWidth: '300px' }}>
              {`${order.contactPersonName} - (${order.contactPersonPhoneNumber})`}
            </Typography>
          </Box>

          <Box className="line-wrapper">
            <Box className="left font-size-16">
              <OrderStatusIcon
                currStatus={order.status === 'Planned' ? 'Completed' : order.status}
                size="small"
              />
            </Box>
            <Box className="right transform-type">
              {icons}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
