import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  FormControl,
  Popover,
  TextField,
} from '@mui/material';
import {
  compareAsc,
  formatISO,
  isSameMinute,
} from 'date-fns';
import { toast } from 'react-toastify';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { getCurrentDriver } from '../../core/helpers/functions';
import {
  useDeleteUnavailabiltyMutation,
  useGetUnavailabilityQuery,
  usePutUnavailabilityMutation,
} from '../../core/redux/transport';
import { LoadingFields } from './partials/LoadingFields';
import './style.scss';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { RadioGroup } from '../../shared/components/radioGroup';
import { useHasRoles } from '../../core/msal';

export const EditUnavailability: FC<{
  id: string|number,
  onAction?: () => void
}> = ({
  id,
  onAction = () => null,
}) => {
  const [hasRole] = useHasRoles();

  const disabled = useMemo(() => (
    (hasRole('lese-ryfylke')
    && !hasRole('admin', 'container-koordinator', 'transport-koordinator'))
  ), [hasRole]);

  const [title, setTitle] = useState<string>('');
  const [from, setFrom] = useState<Date|null>(null);
  const [to, setTo] = useState<Date|null>(null);
  const [comment, setComment] = useState<string>('');
  const [internalComment, setInternalComment] = useState<string>('');
  const [serviceType, setServiceType] = useState<string|null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const { data: unavailability } = useGetUnavailabilityQuery(id);
  const { data: vehicles } = useVehicleList();

  const [send] = usePutUnavailabilityMutation();
  const [remove] = useDeleteUnavailabiltyMutation();

  const vehicle = useMemo(() => vehicles?.find((v) => (
    v.internalNumber === unavailability?.vehicle.internalNumber
  )), [vehicles, unavailability]);

  useEffect(() => {
    if (unavailability) {
      setTitle(unavailability.title);
      setFrom(new Date(unavailability.from));
      setTo(new Date(unavailability.to));
      setComment(unavailability.comment);
      setInternalComment(unavailability.internalComment);
      setServiceType(unavailability.serviceType);
      setIsDataLoaded(true);
    }
  }, [unavailability]);

  const type = useMemo(() => unavailability?.type, [unavailability]);

  const hasChanged = useMemo(() => {
    if (!isDataLoaded || !unavailability) return false;
    if (title !== unavailability.title) return true;
    if (comment !== unavailability.comment) return true;
    if (internalComment !== unavailability.internalComment) return true;
    if (from && !isSameMinute(from, new Date(unavailability.from))) return true;
    if (to && !isSameMinute(to, new Date(unavailability.to))) return true;
    if (serviceType !== unavailability.serviceType) return true;
    return false;
  }, [
    title,
    from,
    to,
    comment,
    internalComment,
    unavailability,
    isDataLoaded,
    serviceType,
  ]);

  const valid = useMemo(() => {
    if (!hasChanged) return false;
    if (title === '') return false;
    if (from === null || to === null) return false;
    if (compareAsc(from, to) >= 0) return false;
    return true;
  }, [title, from, to, comment, internalComment, hasChanged]);

  const submit = () => {
    if (disabled) return;
    if (!type) return;
    if (!from) return;
    if (!to) return;
    if (!title) return;
    const body = {
      title,
      type,
      serviceType,
      from: formatISO(from),
      to: formatISO(to),
      comment,
      internalComment,
    };
    send({ id, body }).unwrap().then(() => {
      toast.success('Utilgjengelighet lagret');
      onAction();
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    remove(`${id}`).unwrap().then(onAction);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popid = open ? 'popover' : undefined;

  const fromError = useMemo(() => {
    if (!from) return 'Fra tid kan ikke være tom';
    if (!to) return null;
    if (compareAsc(from, to) >= 0) return 'Fra tid kan ikke være etter til tid';
    return null;
  }, [from, to]);

  const toError = useMemo(() => {
    if (!to) return 'Til tid kan ikke være tom';
    if (!from) return null;
    if (compareAsc(from, to) >= 0) return 'Til tid kan ikke være før fra tid';
    return null;
  }, [from, to]);

  return (
    <div className="unavailability-wrapper">
      <div className="unavailability-area">
        {isDataLoaded ? (
          <div>
            <FormControl fullWidth sx={{ gap: 3 }}>
              <TextField
                label="Kjøretøy"
                value={vehicle && `${vehicle.vehicleRegistrationPlateNumber} - ${vehicle.subCategoryName} - ${getCurrentDriver(vehicle)?.fullName || 'Ingen sjåfør'}`}
                disabled
              />
              {type === 'Unavailability' && (
              <TextField
                disabled={disabled}
                error={title === ''}
                helperText={title === '' ? 'Tittel kan ikke være tom' : null}
                label="Tittel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              )}
              {type === 'VehicleService' && (
              <>
                <RadioGroup
                  disabled={disabled}
                  sx={{ flexDirection: 'row' }}
                  value={serviceType}
                  onChange={setServiceType}
                  options={[
                    { id: 'Service', label: 'Service' },
                    { id: 'PKK', label: 'PKK' },
                  ]}
                />
                <TextField
                  disabled={disabled}
                  error={title === ''}
                  helperText={title === '' ? 'Sted kan ikke være tom' : null}
                  label="Sted"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </>
              )}
              <DateTimePicker
                label="Fra"
                value={from || new Date()}
                onChange={(date) => date && setFrom(date)}
                error={fromError}
              />
              <DateTimePicker
                label="Til"
                value={to || new Date()}
                onChange={(date) => date && setTo(date)}
                minDate={from || undefined}
                error={toError}
              />
              <TextField
                disabled={disabled}
                multiline
                rows={4}
                label="Sjåførkommentar"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <TextField
                disabled={disabled}
                multiline
                rows={4}
                label="Internkommentar"
                value={internalComment}
                onChange={(e) => setInternalComment(e.target.value)}
              />
            </FormControl>
            <div className="edit-buttons">
              <Button disabled={disabled} onClick={handleClick} variant="contained" color="error">Slett</Button>
              <span className="flex-gap-10">
                <Button onClick={onAction} variant="outlined">Avbryt</Button>
                <Button disabled={!valid} variant="contained" onClick={submit}>Lagre</Button>
              </span>
            </div>
            <Popover
              id={popid}
              open={open && !disabled}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={8}
              transitionDuration="auto"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <div className="m10">
                <div className="ml5 mb10">
                  <span className="bold-text font-size-17">Er du sikker?</span>
                  <div className="mt5">
                    <span className="font-size-15 italic">
                      Vil du slette denne utilgjengelighet?<br />
                      Det vil ikke være mulig å angre sletting.
                    </span>
                  </div>
                </div>
                <div className="text-align-end">
                  <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
                  <Button
                    sx={{ m: 0.75 }}
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                  >
                    Slett
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        ) : (
          <LoadingFields />
        )}
      </div>
    </div>
  );
};
