import React, { FC } from 'react';
import {
  Box,
  Chip,
} from '@mui/material';
import { format } from 'date-fns';
import { getTransportType } from '../../core/helpers/translate';
import { Assignment } from '../../core/types/assignment';
import { AssignmentStatusIcon } from '../AssignmentStatusIcon';
import './style.scss';
import { getTypeColor } from '../../core/helpers/eventRender';

export const HoverEvent:FC<
{assignment: Assignment}> = ({ assignment }) => {
  if (!assignment) return null;

  const startDate = format(new Date(assignment.startTime), 'dd.MM');
  const startTime = format(new Date(assignment.startTime), 'HH:mm');
  const endDate = format(new Date(assignment.endTime), 'dd.MM');
  const endTime = format(new Date(assignment.endTime), 'HH:mm');

  const fromProjectId = assignment.fromProject?.id === undefined
    ? '(Uspesifisert prosjekt)'
    : assignment.fromProject.id;
  const toProjectId = assignment.toProject.id === undefined
    ? '(Uspesifisert prosjekt)'
    : assignment.toProject.id;

  const isMassInternal = assignment.type === 'MassInternal';

  return (
    <Box>
      {!isMassInternal ? (
        <div>
          <div className="header mb-s">
            <span>Fra:</span>
            <span className="bold">{` ${fromProjectId}  `}</span>
            <span>{assignment?.fromProject?.projectName}</span>
          </div>
          <div className="header mb-m">
            <span>Til:</span>
            <span className="bold">{` ${toProjectId} `}</span>
            <span>{assignment?.toProject?.projectName}</span>
          </div>
        </div>
      ) : (
        <div className="header mb-m">
          <span>Prosjekt:</span>
          <span className="bold">{` ${assignment.toProject?.id} `}</span>
          <span>{assignment.toProject.projectName}</span>
        </div>
      )}
      <div className="content mb-s">
        <span>Tid:</span>
        <span>{` ${startDate} kl. `}</span>
        <span className="bold">{`${startTime}`}</span>
        <span>{' - '}</span>
        <span>{`${endDate} kl. `}</span>
        <span className="bold">{`${endTime}`}</span>
      </div>
      <div className="content mb-m">
        <span>Kontakt:</span>
        <span className="bold">{` ${assignment.order?.contactPersonName} `}</span>
        <span>{`(${assignment?.order?.contactPersonPhoneNumber})`}</span>
      </div>
      <div className="content line-wrapper">
        <span className="right">
          <AssignmentStatusIcon currStatus={assignment.status} size="small" />
        </span>
        <span className="left">
          <Chip
            color={getTypeColor(assignment.type)}
            label={assignment.type && getTransportType(assignment.type)}
            size="small"
          />
        </span>
      </div>
    </Box>
  );
};
