import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DieselOrder } from '../../../core/types/dieselOrder';
import { DieselOrderCard } from './DieselOrderCard';
import { useChangeDieselOrderPositionMutation } from '../../../core/redux/transport';
import { ShowPeriod } from '../../../core/redux/orderFilter';

export interface DragDropData {
  activeDay: string;
  activeOrder: UniqueIdentifier;
  overOrder: UniqueIdentifier;
  newIndex: number;
}

export interface Day {
  day: string;
  unix: number;
  tasks: DieselOrder[];
  totals: {
    Blank: number,
    Colored: number,
    HVO: number,
    total: number,
  }
}

export const DieselOrderList = ({
  days,
  isLoading,
  showPeriod,
}: {
  days: Day[] | undefined,
  isLoading: boolean,
  showPeriod: ShowPeriod,
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [changePosition] = useChangeDieselOrderPositionMutation();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const activeData = active.data.current;
    if (!activeData) return;

    const activeDay = activeData.dayName as string;
    const dayToChange = days?.find((day) => day.day === activeDay);
    const newIndex = dayToChange?.tasks.findIndex((task) => task.id === over.id);

    if (newIndex === undefined || newIndex === -1) return;

    const data: DragDropData = {
      activeDay,
      activeOrder: active.id,
      overOrder: over.id,
      newIndex,
    };
    changePosition({ showPeriod, data });
  };

  if (!days || days?.length <= 0) {
    return (
      <div className="diesel-transport-empty">{isLoading ? (
        <CircularProgress
          size={50}
          sx={{
            position: 'absolute',
            top: '65%',
            left: '50%',
            marginTop: '-25px',
            marginLeft: '-25px',
            zIndex: 1000,
          }}
        />
      ) : 'Ingen bestillinger'}
      </div>
    );
  }

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
      <div>
        {days?.map((day) => (
          <div key={day.day}>
            <div className="day-header mt20 mb10">{day.day}</div>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              <Typography>Total</Typography>
              <Typography>{day.totals.Blank} liter Blank</Typography>
              <Typography>{day.totals.Colored} liter Farget</Typography>
              <Typography>{day.totals.HVO} liter Farget HVO</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <SortableContext items={day.tasks} strategy={verticalListSortingStrategy}>
                {day.tasks.map((item) => (
                  <DieselOrderCard order={item} dayName={day.day} key={item.id} />
                ))}
              </SortableContext>
            </Box>
          </div>
        ))}
      </div>
    </DndContext>
  );
};
