import React, { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { AssignmentItem } from './AssignmentItem';
import { DaySwitcher } from './DaySwitcher';
import { Assignment } from '../../../core/types/assignment';

export const List: FC<{
  day: Date,
  setDay?: (d: Date) => void,
  refetch?: () => void,
  onPick?: (x: number) => void,
  hasData?: boolean,
  isFetching?: boolean,
  assignments?: Assignment[],
}> = ({
  day,
  setDay = () => null,
  refetch = () => null,
  onPick = () => null,
  hasData = false,
  isFetching = true,
  assignments,
}) => (
  <div className="assignments-wrapper">
    <div className="assignments-area">
      <div className="control-area">
        <DaySwitcher value={day} onChange={setDay} />
        <div className="right-icon">
          <Button variant="text"><CachedIcon onClick={refetch} fontSize="large" /></Button>
        </div>
      </div>
      <div className="assignments-list">
        {hasData && assignments && assignments.map((a) => (
          <AssignmentItem key={a.id} assignment={a} onClick={onPick} />
        ))}
        {!hasData && !isFetching ? <span className="no-content">Ingen oppdrag</span> : null}
        {!hasData && isFetching ? <CircularProgress /> : null}
      </div>
    </div>
  </div>
);
