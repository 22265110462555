import { useCallback } from 'react';
import { useRegionVisibility } from '../redux/region';

export const useRegionFilter = () => {
  const rs = useRegionVisibility();
  const regions = rs.filter((r) => r.active).map((r) => r.id);
  return useCallback(
    (item: {region?: number}) => regions.some((r) => r === item.region),
    [regions],
  );
};
