import {
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';

export const ContainerIntermediateStorage = ({
  disabled = false,
  value,
  onChange,
} : {
  disabled?: boolean,
  value: boolean,
  onChange: (v: boolean) => void,
  }) => (
    <Box paddingTop={1}>
      <FormControlLabel
        label="Mellomlagre"
        disabled={disabled}
        control={(
          <Switch
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
              )}
      />
    </Box>
);
