import {
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
} from 'react';
import {
  parseISO,
  format,
  getUnixTime,
// eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

import { Unavailability } from '../../../core/types/unavailability';
import { useVehicleList } from '../../../core/hooks/useVehicleList';
import { RegistrationPlate } from '../../../components/RegistrationPlate';

interface Day {
  day: string;
  unix: number;
  tasks: Unavailability[];
}

const formatDate = (date: string) => format(parseISO(date), "dd.MM.yyyy 'kl.' HH:mm", { locale: nb });

export const UnavailabilityList: FC<{
  unavailabilities: Unavailability[] | undefined,
  isLoading: boolean,
  onClick?: Dispatch<SetStateAction<number>>,
}> = ({
  unavailabilities,
  isLoading,
  onClick,
}) => {
  const { data: vehicles } = useVehicleList();

  const unavailabilitiesWithVehicles = useMemo((): Unavailability[] => {
    if (!unavailabilities || !vehicles) return [];
    return unavailabilities?.map((u) => {
      const vehicle = vehicles?.find((v) => v.internalNumber === u.vehicle.internalNumber);
      if (!vehicle) return null;
      return { ...u, vehicle };
    }).filter((u) => u !== null) as Unavailability[];
  }, [unavailabilities, vehicles]);

  // eslint-disable-next-line max-len
  const days: Day[] = useMemo(() => (!unavailabilitiesWithVehicles
    ? []
    : unavailabilitiesWithVehicles.reduce((acc: Day[], curr: Unavailability) => {
      const date = parseISO(curr.from);
      const day = format(date, 'EEEE do MMM yyyy', { locale: nb });
      const unix = getUnixTime(date);
      const index = acc.findIndex((e) => e.day === day);
      if (index === -1) {
        return [...acc, { unix, day, tasks: [curr] }];
      }
      const arr = [...acc];
      arr[index].tasks.push(curr);
      return arr;
    }, [])), [unavailabilitiesWithVehicles]);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split('/')[1];

  days.sort((a, b) => a.unix - b.unix);

  return (
    <div>
      {!unavailabilitiesWithVehicles?.length
        ? (
          <div className="unavailability-empty">{isLoading ? (
            <CircularProgress
              size={50}
              sx={{
                position: 'absolute',
                top: '65%',
                left: '50%',
                marginTop: '-25px',
                marginLeft: '-25px',
                zIndex: 1000,
              }}
            />
          ) : 'Ingen utilgjengelighet'}
          </div>
        )
        : days?.map((day) => (
          <div key={day.day}>
            <div className="day-header mt20 mb20">{day.day}</div>
            {day.tasks.map((item) => (
              <Card
                key={item.id}
                sx={{ mt: 2 }}
              >
                <CardActionArea onClick={onClick ? () => onClick(item.id) : () => navigate(`/utilgjengelighet/${item.id}`)}>
                  <CardContent>
                    {item.type === 'Unavailability' && (
                    <div>
                      {!item.vehicle.assignedDriver ? null : (
                        <div className={item.vehicle.substituteDrivers.length <= 0 ? 'vehicle-line-wrapper flexparent bold-text' : 'vehicle-line-wrapper flexparent'}>
                          <PersonIcon fontSize="small" />
                          <span className="vehicle-driver-name shorten-name">
                            {item.vehicle.assignedDriver.fullName}
                          </span>
                          {item.vehicle.assignedDriver.fullName && item.vehicle.assignedDriver.mobilePhoneWork ? <span className="flexchild" /> : null}
                          <span className="vehicle-item.vehicle.assignedDriver-number">
                            <a className="number-link" href={`tel:${item.vehicle.assignedDriver.mobilePhoneWork}`}>
                              {item.vehicle.assignedDriver.mobilePhoneWork}
                            </a>
                          </span>
                        </div>
                      )}
                      {
                        [...item.vehicle.substituteDrivers]
                          .sort((a, b) => a.start.localeCompare(b.start))
                          .map((s) => (
                            <div className="line-wrapper flexparent bold-text inline-substitute">
                              <GroupIcon fontSize="small" />
                              <span className="vehicle-driver-name shorten-name">
                                {s.name}
                              </span>
                              {s.name && s.phone ? <span className="flexchild" /> : null}
                              <span className="vehicle-driver-number">
                                <a className="number-link" href={`tel:${s.phone}`}>
                                  {s.phone}
                                </a>
                              </span>
                            </div>
                          ))
                      }
                      <div className="line-wrapper">
                        <div className="mt10 mb10 font-size-16">
                          {item.serviceType ? `${item.serviceType} - ` : ''}{item.title}
                        </div>
                      </div>
                    </div>
                    )}
                    {item.type === 'VehicleService' && (
                      <span className="vehicle-driver-name shorten-name bold">
                        {`${item.serviceType} - ${item.title}`}
                      </span>
                    )}
                    {item.type === 'VehicleService' && item.comment && (
                      <div className="line-wrapper">
                        <span className="mt10 mb10 font-size-16">
                          Sjåførkommentar: {item.comment}
                        </span>
                      </div>
                    )}
                    <div className="line-wrapper">
                      <div className="mt10 font-size-16">
                        {formatDate(item.from)} - {formatDate(item.to)}
                      </div>
                    </div>

                    {url === 'utilgjengelighet' && (
                    <div className="line-wrapper flexparent right-aligned-vehicle mt10">
                      {!item.vehicle.vehicleRegistrationPlateNumber ? null : <RegistrationPlate number={item.vehicle.vehicleRegistrationPlateNumber} />}
                      <span className="vehicle-font">{item.vehicle.subCategoryName}</span>
                    </div>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </div>
        ))}
    </div>
  );
};
