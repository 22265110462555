import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  FormControl,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { LoadingFields } from './partials/LoadingFields';
import { UpdateRentalVehicle } from '../../core/types/api/updateRentalVehicle';
import { EditRentalPeriods } from '../EditRentalPeriods/index';
import { VehicleStatusIcon } from '../VehicleStatusIcon/index';
import {
  useDeleteRentalVehicleMutation,
  useGetRentalVehicleQuery,
  usePutRentalVehicleMutation,
} from '../../core/redux/transport';
import { useVehicleCategory } from '../../core/hooks/vehicleSubCategory';
import { currentlyWithinPeriod } from '../../core/helpers/dates';
import './style.scss';
import { PopConfirm } from '../PopConfirm/index';
import { Guid } from '../../core/types/guid';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { useHasRoles } from '../../core/msal';
import { hookVehicleName } from '../../core/constants';

export const EditRentalVehicle: FC<{
    id: Guid,
    currentDate?: Date,
    onAction?: () => void,
  }> = ({
    id,
    currentDate,
    onAction = () => null,
  }) => {
    const [hasRole] = useHasRoles();
    const { data: vehicles } = useVehicleList();
    const vehicleCategories = useVehicleCategory(vehicles || []);

    const { data, isLoading, refetch } = useGetRentalVehicleQuery(id);
    const [remove] = useDeleteRentalVehicleMutation();
    const [send] = usePutRentalVehicleMutation();
    const [reg, setReg] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const disabled = useMemo(() => (
      !hasRole('admin', 'transport-koordinator')
      && data?.vehicleCategory !== hookVehicleName
    ), [hasRole, data]);

    useEffect(() => { refetch(); }, []);

    useEffect(() => {
      if (data) {
        setReg(data.vehicleRegistrationPlateNumber);
        setCategory(data.vehicleCategory);
        setName(data.driverName);
        setPhone(data.driverPhoneNumber);
        setComment(data.comment);
      }
    }, [data]);

    const hasChanged = useMemo(() => {
      if (isLoading || !data) return false;
      if (reg !== data.vehicleRegistrationPlateNumber) return true;
      if (category !== data.vehicleCategory) return true;
      if (name !== data.driverName) return true;
      if (phone !== data.driverPhoneNumber) return true;
      if (comment !== data.comment) return true;
      return false;
    }, [isLoading, data, reg, category, name, phone, comment]);

    const isValid = useMemo(() => {
      if (!hasChanged) return false;
      if (reg === '' || reg.length > 8) return false;
      if (category === '') return false;
      if (name === '') return false;
      if (phone === '' || phone.length > 12) return false;
      return true;
    }, [hasChanged, reg, category, name, phone]);

    const submit = () => {
      if (!reg) return;
      if (!category) return;
      if (!name) return;
      if (!phone) return;
      const body: UpdateRentalVehicle = {
        vehicleRegistrationPlateNumber: reg,
        vehicleCategory: hasRole('container-koordinator') ? hookVehicleName : category,
        driverName: name,
        driverPhoneNumber: phone,
        comment,
      };
      send({ id, body }).unwrap().then((res) => {
        if ('data' in res) {
          toast.success('Leiebil lagret');
          onAction();
        }
      });
    };

    const isActive = useMemo(() => (
      !data || !data.rentalPeriods
        ? false
        : data?.rentalPeriods.some((p) => (
          currentlyWithinPeriod(new Date(p.from), new Date(p.to))
        ))
    ), [data?.rentalPeriods, format(new Date(), 'HH:mm')]);

    if (isLoading && !data) {
      return (
        <div className="edit-rental-vehicle-component">
          <LoadingFields />
        </div>
      );
    }
    if (!data) return <span>Kunne ikke finne innleiebilen</span>;

    return (
      <div className="edit-rental-vehicle-component">
        <FormControl fullWidth sx={{ gap: 3 }}>
          <TextField
            disabled={disabled}
            label="Registreringsnummer"
            value={reg}
            onChange={(e) => setReg(e.target.value)}
            error={!reg || reg.length > 8}
            helperText={
              // eslint-disable-next-line no-nested-ternary
              !reg
                ? 'Registreringnummer kan ikke være tom'
                : reg.length > 8 ? 'Maks 8 symboler' : ''
            }
          />
          <Autocomplete
            disabled={disabled || hasRole('container-koordinator')}
            value={category}
            freeSolo
            onInputChange={(e, value) => setCategory(value || '')}
            noOptionsText=""
            options={vehicleCategories}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Kjøretøykategori"
                error={category === ''}
                helperText={category === '' ? 'Kjøretøyskategori kan ikke være tom' : ''}
              />
            )}
          />
          <TextField
            disabled={disabled}
            error={name === ''}
            helperText={name === '' ? 'Sjåfør navn kan ikke være tomt' : null}
            label="Sjåfør navn"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            disabled={disabled}
            label="Sjåfør telefonnummer"
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={!phone || phone.length > 12}
            helperText={
              // eslint-disable-next-line no-nested-ternary
              !phone
                ? 'Sjåfør telefonnummer kan ikke være tom'
                : phone.length > 12 ? 'Maks 12 symboler' : ''
            }
          />
          <TextField
            disabled={disabled}
            multiline
            rows={4}
            label="Kommentar"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
        <div className="edit-buttons">
          {data.canDelete ? (
            <PopConfirm
              disabled={disabled}
              onConfirm={() => remove(`${id}`).unwrap().then(onAction)}
              content={(
                <>
                  Vil du slette denne innleiebilen?<br />
                  Det vil ikke være mulig å angre slettingen
                </>
              )}
            />
          ) : <div />}
          <span className="flex-gap-10">
            <Button
              disabled={disabled}
              onClick={onAction}
              variant="outlined"
            >Avbryt
            </Button>
            <Button
              disabled={!isValid || disabled}
              variant="contained"
              onClick={submit}
            >Lagre
            </Button>
          </span>
        </div>
        <div className="status-wrapper">
          <VehicleStatusIcon isActive={isActive} />
        </div>

        <EditRentalPeriods
          disabled={disabled}
          data={data.rentalPeriods}
          id={data.id}
          currentDate={currentDate}
        />
      </div>
    );
  };
