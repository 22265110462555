import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnavailabilityView from '../../../components/UnavailibilityView';

export const UnavailabilityModal: FC<{
  internalNumber: string;
  currentDate: Date;
  onClose: () => void;
  title: string;
}> = ({
  internalNumber,
  currentDate,
  onClose,
  title,
}) => (
  <Dialog open onClose={onClose} maxWidth="md" fullWidth>
    <div className="align-status">
      <div className="left">
        <DialogTitle>{title}</DialogTitle>
      </div>
      <IconButton sx={{ marginRight: '15px' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      <UnavailabilityView id={internalNumber} currentDate={currentDate} onClose={onClose} />
    </DialogContent>
  </Dialog>
);
