import React, { FC, useMemo, useState } from 'react';
import './style.scss';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import AddIcon from '@mui/icons-material/Add';
import { useGetRentalVehiclesQuery } from '../../core/redux/transport';
import { RentalVehicleItem } from '../../Pages/RentalVehicleListPage/partials/RentalVehicleItem';
import { Guid } from '../../core/types/guid';

export const ListRentalVehicles: FC<{
  onClickItem?: (id: Guid) => void,
  onClickNew?: () => void,
}> = ({
  onClickItem = () => null,
  onClickNew = () => null,

}) => {
  const { data: rawVehicles, isLoading } = useGetRentalVehiclesQuery();
  const [term, setTerm] = useState<string>('');

  const vehicles = useMemo(() => {
    if (!rawVehicles) return [];
    const sortedVehicles = [...rawVehicles];
    sortedVehicles.sort((a, b) => (
      a.vehicleRegistrationPlateNumber.localeCompare(b.vehicleRegistrationPlateNumber)
    ));

    if (term !== '') {
      const t = term.toLowerCase();
      return sortedVehicles.filter((v) => (
        v.vehicleRegistrationPlateNumber.toLowerCase().includes(t)
        || v.vehicleCategory.toLowerCase().includes(t)
        || v.driverName.toLowerCase().includes(t)
        || v.driverPhoneNumber.toLowerCase().includes(t)
        || `${v.id}`.toLowerCase().includes(t)
      ));
    }
    return sortedVehicles;
  }, [rawVehicles, term]);

  return (
    <div className="list-rental-vehicles-component">
      <div className="rental-vehicle-area-item rental-vehicle-search-bar">
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor="rental-vehicle-search">Søk innleiebiler</InputLabel>
          <OutlinedInput
            id="rental-vehicle-search"
            value={term}
            fullWidth
            onChange={(e) => setTerm(e.target.value)}
            label="Søk Innleiebiler"
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="Fjern søket"
                  onClick={() => setTerm('')}
                  onMouseDown={() => setTerm('')}
                  edge="end"
                >
                  {term === '' ? null : <BackspaceIcon /> }
                </IconButton>
              </InputAdornment>
                    )}
          />
        </FormControl>
        <Button onClick={() => onClickNew()} variant="contained"><AddIcon /></Button>
      </div>
      <div className="project-area-item rental-vehicle-list-container">
        {!isLoading && vehicles.map((v) => (
          <RentalVehicleItem onClick={onClickItem} key={v.id} data={v} />
        ))}
        {isLoading && [1, 2, 3, 4, 5].map((i) => <RentalVehicleItem key={i} />)}
        {
          !isLoading && vehicles.length === 0
            ? <span className="no-results">Ingen innleiebiler funnet</span>
            : null
        }
      </div>
    </div>
  );
};
