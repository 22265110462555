import React from 'react';
import { Box, Typography } from '@mui/material';
import { Page } from '../../components/Page';
import { Header } from '../../components/Header';

export const AccessDeniedPage = () => (
  <Page className="prosjekter-page">
    <Header>Ingen tilgang</Header>
    <Box sx={{ margin: 2 }}>
      <Typography variant="h3">Du har ikke tilgang til denne siden!</Typography>
      <Typography variant="subtitle1">Ta kontakt med IT-ansvarlig hvis du har behov for tilgang til denne siden.</Typography>
    </Box>
  </Page>
);
