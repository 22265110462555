import { FC, useMemo } from 'react';
import { CardActionArea, Chip } from '@mui/material';
import Card from '@mui/material/Card';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import AlarmIcon from '@mui/icons-material/Alarm';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { OrderStatusIcon } from '../../../components/OrderStatusIcon';
import {
  extractData,
  extractLoadData,
  createAttributeSet,
  getContainerLoadTitle,
} from '../../../core/helpers/functions';
import {
  getTransportType,
  translateMassType,
  translateTimeframe,
  translateVehicleType,
} from '../../../core/helpers/translate';
import { getTypeColor, projectLabel } from '../../../core/helpers/eventRender';
import { Order } from '../../../core/types/order';
import { useIsOrderLate } from '../hooks/useIsLate';
import { useIsType } from '../../../core/hooks/useIsType';
import { getMachineLabel } from '../../../core/helpers/labelling';

interface TaskItemProps {
  order: Order,
  onClick: () => void,
}

/** Order data card displayed on the right of the main planner */
export const TaskItem: FC<TaskItemProps> = ({ order, onClick }) => {
  const orderData = extractData(order);
  const orderInfo = order;
  const isType = useIsType(order.type);
  const loadData = orderInfo && extractLoadData(orderInfo);
  const { isLate } = useIsOrderLate(order);

  const attributes = createAttributeSet({
    from: projectLabel(order.fromProject),
    to: projectLabel(order.toProject),
    orderType: translateMassType(order),
    assignmentType: order.type,
    loadType: order.loads.map((l) => l.type?.name).join(', '),
    contact: `${order.contactPersonName} (${order.contactPersonPhoneNumber})`,
    isInternal: order.type === 'MassInternal',
  });

  const className = useMemo(() => {
    switch (order.assignmentStatus) {
      case 'Undelivered': return 'task-item task-item-undelivered';
      default: return 'task-item';
    }
  }, [order]);
  return (
    <div
      className={className}
      data-title={orderData.title}
      data-id={order.id}
      data-duration={order.type.includes('Container') ? '01:00' : '00:45'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      draggable
    >
      <Card>
        <CardActionArea onClick={onClick}>
          <div className="line-wrapper pt3">
            <span className="header one-line-ellipse">
              {orderData.title}
            </span>
            <span>
              <Chip
                color={getTypeColor(order.type)}
                label={getTransportType(order.type)}
                size="small"
              />
            </span>
          </div>
          <div className="subtitle flex-gap-5">
            <span className="one-line-ellipse flex-child flex-gap-5 pt4 flex-space-between">
              <span className="one-line-ellipse flex-child">
                {isType('Container') && (
                  order.loads.map((l) => (
                    getContainerLoadTitle(order.type, l)
                  )).join(', ')
                )}
                {isType('Mass') && (
                  <>
                    {order.loads.length > 1 ? 'Henger' : translateVehicleType(order)}
                    {!isType('MassInternal') && ' - '}
                    {!isType('MassInternal') && order.loads.map((l) => (`${l.amount}m³ ${l.type?.name}`)).join(', ')}
                  </>
                )}
                {isType('Haul') && (
                  order.loads[0].machine
                    ? `${getMachineLabel(order.loads[0].machine)}`
                    : (order.loads[0].machineInternalNumber ?? order.loads[0].machineExternal)
                )}
              </span>
              <span className="timeframe">
                {translateTimeframe(order)}
              </span>
            </span>
          </div>
          <div className="footer">
            <span className="left flexparent">
              <OrderStatusIcon currStatus={order.status} />
              {
                order.importantDeadline
                  ? <span className="important-deadline-small" title="Viktig tidsfrist!">Viktig frist!</span>
                  : null
              }
            </span>
            <span className="right">
              {isType('Container') && (
              <>
                <span className="fraction">
                  {`${order.details?.totalAmount || 0}/${orderData.totalAmount} `}
                  <CalendarViewWeekIcon fontSize="small" color="primary" />
                </span>
                <span className="fraction" title="Kan leveres dagen før">
                  {order.earlyDelivery
                    ? <AltRouteIcon fontSize="small" color="primary" />
                    : null}
                </span>
              </>
              )}

              {isType('MassIn', 'MassOut') && (
              <>
                <span className="fraction">
                  {`${loadData?.trips || '0'}/${orderData.loads} `}
                  <SwapHorizIcon fontSize="small" color="primary" />
                </span>
                <span className="fraction">
                  {`${loadData?.vehicles || '0'}/${orderData.vehicles} `}
                  <LocalShippingIcon fontSize="small" color="primary" />
                </span>
                <span className="fraction" title="Kan leveres dagen før">
                  {order.earlyDelivery
                    ? <AltRouteIcon fontSize="small" color="primary" />
                    : null}
                </span>
              </>
              )}

              {isType('MassInternal') && (
                <span className="fraction">
                  {`${loadData?.vehicles || '0'}/${order.numberOfVehicles} `}
                  <LocalShippingIcon fontSize="small" color="primary" />
                </span>
              )}

              {isType('Haul') && (
              <>
                <span className="fraction">
                  {`${loadData?.vehicles || '0'}/${orderData.vehicles} `}
                  <LocalShippingIcon fontSize="small" color="primary" />
                </span>
                <span className="fraction" title="Kan leveres dagen før">
                  {order.earlyDelivery
                    ? <AltRouteIcon fontSize="small" color="primary" />
                    : null}
                </span>
              </>
              )}

            </span>
            { isLate && (
            <span className="right" title="Sen innsending">
              <AlarmIcon fontSize="small" color="error" />
            </span>
            )}
            {isType('ContainerCollect') && order.assignmentStatus === 'InIntermediateStorage' && (
            <span className="right" title="Mellomlagring">
              <AutoDeleteIcon color="primary" />
            </span>
            )}
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};
