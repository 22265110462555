/**
 * Object containing week day names and the number you get from
 * `Date.getDay()`
 */
export const weekdays = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
} as const;
