import { useMemo } from 'react';
import { useGetRegionsQuery } from '../redux/transport';

export const useRegions = () => {
  const { data } = useGetRegionsQuery();

  // eslint-disable-next-line no-undef
  const regions = useMemo(() => {
    if (!data) return new Map<number, string>();
    return new Map<number, string>(
      data?.map((r) => ([r.referenceId, r.name])),
    );
  }, [data]);

  return regions;
};
