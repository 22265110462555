export const assignmentStatus = [
  'UnderPlanning',
  'Approved',
  'Started',
  'Completed',
  'NotDelivered',
  'Cancelled',
  'Deleted',
  'VerifiedNotDelivered',
  'Unavailable',
  'VehicleService',
] as const;

export type AssignmentStatus = typeof assignmentStatus[number];
