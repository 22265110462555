import React, { FC } from 'react';
import { TextField } from '@mui/material';

export const TextValue: FC<{ label: string; value: string; }> = ({ label, value }) => (
  <TextField
    fullWidth
    value={value || '-'}
    label={label}
    variant="standard"
    InputProps={{
      readOnly: true,
      disableUnderline: true,
    }}
  />
);
