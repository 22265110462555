import { useIsType } from '../hooks/useIsType';
import { NewAssignment } from '../types/api/newAssignment';
import { UpdateAssignment } from '../types/api/updateAssignment';
import { AssignmentType } from '../types/enums/assignmentType';

export const removeUnused = <T extends NewAssignment|UpdateAssignment>(a: T, type?: AssignmentType): T => {
  const t = ('type' in a && a?.type) || type;
  const isType = useIsType(t);
  const assignment = { ...a };

  if (isType('Mass')) {
    delete assignment.collectionProject;
    delete assignment.externalCollectionProject;
    delete assignment.intermediateStorage;
  }
  if (isType('ContainerDeliver')) {
    delete assignment.intermediateStorage;
  }
  if (isType('ContainerEmptying')) {
    delete assignment.intermediateStorage;
    assignment.fromProject = assignment.toProject;
  }

  assignment.loads = assignment.loads?.map((l) => {
    const load = { ...l };

    if (isType('Mass')) {
      delete load.wasteType;
      delete load.containerType;
      delete load.containerExternal;
      delete load.containerInternalNumber;
      delete load.containerSubcategory;
    }
    if (isType('ContainerDeliver')) {
      load.amount = 1;
      delete load.wasteType;
      // delete load.containerExternal;
      // delete load.containerInternalNumber;
    }
    if (isType('ContainerCollect', 'ContainerEmptying')) {
      load.amount = 1;
      delete load.massTypeId;
      delete load.containerType;
      delete load.containerSubcategory;
    }
    return load;
  });
  return assignment;
};
