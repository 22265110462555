import { Chip } from '@mui/material';
import { FC } from 'react';

export const RegistrationPlate: FC<{number: string}> = (props) => {
  const { number } = props;
  return (
    <Chip
      sx={{
        marginTop: '3px',
        fontWeight: 900,
      }}
      size="small"
      label={number}
    />
  );
};
