import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { isAfter } from 'date-fns';
import { format } from '../../logic/dates';
import { Certification } from '../../types/certification';

export const Certifications: FC<{
    cert: Certification[]|undefined,
    isLoading: boolean,
  }> = ({
    cert,
    isLoading = false,
  }) => {
    if (!cert || isLoading) return null;

    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Sertifiseringer</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sist sertifisert</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Utløpsdato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cert.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.lastCertified ? format(new Date(row.lastCertified), 'dd.MM.yyyy') : null}
                </TableCell>
                <TableCell>{row.expires ? format(new Date(row.expires), 'dd.MM.yyyy') : null}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.expires && isAfter(new Date(), new Date(row.expires))
                  ? <EventBusyIcon color="warning" />
                  : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
