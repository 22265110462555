import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Load, LoadItem } from '../../../core/types/load';
import { countProperty } from '../../../core/helpers/functions';
import { useGetProjectContainersQuery } from '../../../core/redux/transport';
import { MAX_CONTAINERS } from '../../../shared/constants';

export const ContainerCollectPicker = <L extends LoadItem|Load>({
  disableContainerSelection = false,
  value,
  onChange,
  disabled = false,
  projectId,
}: {
  disableContainerSelection?: boolean,
  value: L[],
  onChange: (v: L[]) => void,
  disabled?: boolean,
  projectId?: number,
}) => {
  const { data: containers = [] } = useGetProjectContainersQuery(projectId ? { projectId, random: 'random' } : skipToken);

  /** Toggle given container number */
  const toggle = (id: number|undefined) => {
    if (!id) return;
    onChange(value.map((v) => (
      v.id === id
        ? { ...v, amount: v.amount === 0 ? 1 : 0 }
        : v
    )));
  };

  const total = countProperty(value, (l) => l.amount);
  const isDisabled = total >= MAX_CONTAINERS;
  return (
    <Box>
      {value.filter((l) => l.containerInternalNumber || l.containerExternal).map((l) => {
        const checked = l.amount > 0;

        const container = containers.find((c) => [l.container?.internalNumber, l.containerInternalNumber].includes(c.internalNumber));
        const containerOccupied = container?.assignmentId;
        const itemDisabled = !!((isDisabled && !checked) || disabled || containerOccupied);

        let label = `${l.containerInternalNumber} - ${l.wasteType || '(tom)'}`;
        if (l.containerExternal) label = `(ekstern) ${l.containerExternal} - ${l.wasteType || '(tom)'}`;
        if (containerOccupied) label += ' - Allerede brukt';

        return (
          <Box key={l.id}>
            <FormControlLabel
              label={label}
              disabled={itemDisabled || disableContainerSelection}
              disableTypography={disableContainerSelection}
              control={(
                <Checkbox
                  checked={checked}
                  onChange={() => toggle(l.id)}
                />
                )}
            />
          </Box>
        );
      })}
    </Box>
  );
};
