import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullC from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import nbLocale from '@fullcalendar/core/locales/nb';
import { EventApi, EventContentArg } from '@fullcalendar/common';
import {
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  formatISO,
  isSameDay,
  max,
  min,
  parseISO,
  startOfDay,
} from 'date-fns';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from '../../core/hooks/redux';
import { Sidebar } from './partials/Sidebar';
import {
  CreateAssignmentData,
  CreateAssignmentModal,
} from '../../components/CreateAssignmentModal';
import { EditAssignmentModal } from '../../components/EditAssignmentModal';
import './style.scss';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { renderResource } from './partials/renderResource';
import { useAssignments } from './hooks/useAssignments';
import { useResources } from './hooks/useResources';
import { useDraggable } from './hooks/useDraggable';
import { useOrders } from './hooks/useOrders';
import {
  usePostApproveTodayAssignmentsMutation,
  usePostCreateAssignmentsMutation,
  usePutAssignmentsBodyMutation,
  useGetDailySummaryQuery,
  util,
  usePutAssignmentsStatusMutation,
} from '../../core/redux/transport';
import { EditUnavailabilityModal } from './partials/EditUnavailabilityModal';
import { useSignalR } from '../../core/signalR';
import { HoverEvent } from '../../components/HoverEvent';
import { Assignment } from '../../core/types/assignment';
import { AssignmentStatus } from '../../core/types/enums/assignmentStatus';
import { Order } from '../../core/types/order';
import { NewAssignment } from '../../core/types/api/newAssignment';
import { DailySummary } from '../../core/types/dailySummary';
import { TimelineHeader } from './partials/TimelineHeader';
import {
  setShowOnlyAvailable,
  useShowOnlyAvailable,
  setShowOnlyRentalVehicles,
  useShowOnlyRentalVehicles,
} from '../../core/redux/vehicleFilter';
import { RentalModal } from './partials/RentalModal';
import { CreateUnavailabilityModal } from './partials/CreateUnavailabilityModal';
import { EditRentalVehicleModal } from './partials/EditRentalVehicleModal';
import { AssignRegionModal } from './partials/AssignRegionModal';
import { VehicleAction } from '../../core/types/vehicle';
import {
  assignment2NewAssignment,
  assignment2UpdateAssignment,
  vehicleListNames2Categories,
} from '../../core/helpers/converters';
import { useVehicleCategory } from '../../core/hooks/vehicleSubCategory';
import { useCalendar } from '../../core/redux/calendar';
import { minutesFromMidnight } from '../../core/helpers/dates';
import { SubstituteModal } from './partials/SubstituteModal';
import { useCalendarDate } from '../../core/hooks/useCalendarDate';
import { clearShadow, setShadow, shadowEvent } from '../../shared/logic/shadowEvent';
import { ApproveAllData } from '../../core/types/api/approveAllData';
import { useRegionVisibility } from '../../core/redux/region';
import { getTransportType } from '../../core/helpers/translate';
import { useIsType } from '../../core/hooks/useIsType';
import { Modal } from '../../shared/components/modal';
import { WorkerDetails } from '../../components/WorkerDetails';
import { ServiceType, UnavailabilityType } from '../../core/types/unavailability';
import { useHasRoles } from '../../core/msal';
import { UnavailabilityModal } from './partials/UnavailabilityModal';

type MoveOrCopyEvent = {
  event: EventApi,
  oldEvent: EventApi,
  newResource?: {
    id: string,
  },
  jsEvent: MouseEvent,
}

const TransportplanleggerPage = () => {
  const [hasRole] = useHasRoles();
  const writeAccess = useMemo(() => (
    hasRole('admin', 'container-koordinator', 'transport-koordinator')
  ), [hasRole]);

  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [copyMenu, setCopyMenu] = useState<{x: number, y: number}|null>(null);
  const [addEvent, setAddEvent] = useState<CreateAssignmentData|null>(null);
  const [editAssignment, setEditAssignment] = useState<Assignment|null>(null);
  const [moveOrCopyEvent, setMoveOrCopyEvent] = useState<MoveOrCopyEvent|null>(null);
  const [draggingEventId, setDraggingEventId] = useState<string|null>(null);
  const [selectedUnavailability, setSelectedUnavailability] = useState<Assignment|null>(null);
  const [rentalModalOpen, setRentalModalOpen] = useState<boolean>(false);
  const [createUnavailabilityFor, setCreateUnavailabilityFor] = useState<string|null>(null);
  const [createUnavailabilityType, setCreateUnavailabilityType] = useState<UnavailabilityType|null>(null);
  const [editRentalVehicle, setEditRentalVehicle] = useState<string|null>(null);
  const [editRegion, setEditRegion] = useState<string|null>(null);
  const [substituteView, setSubstituteView] = useState<string|null>(null);
  const [unavailabilityView, setUnavailabilityView] = useState<string|null>(null);
  const [editSubstitute, setEditSubstitute] = useState<string|null>(null);
  const [subExist, setSubExist] = useState<number>(0);
  const [selectedDriver, setSelectedDriver] = useState<{id: number, name: string}>();

  const {
    vehicleType,
  } = useSelector((s) => s.vehicleFilter);
  const {
    orderTypes,
  } = useSelector((s) => s.orderFilter);

  const shouldShowSummary = useMemo(() => {
    if (orderTypes.length <= 0) return true;
    return orderTypes.some((ot) => ot.id !== 'Container');
  }, [orderTypes]);

  const { data: nonWorkingDays } = useCalendar();

  const calRef: RefObject<FullC> = useRef<FullC>(null);

  const calApi = useMemo(() => (calRef.current ? calRef.current.getApi() : null), [calRef.current]);

  const [currentDate, change] = useCalendarDate();

  useEffect(() => {
    if (calApi) calApi.gotoDate(currentDate);
  }, [currentDate, calApi]);

  const setCurrentDate = (date: Date) => change('/transportplanlegger', { date: format(date, 'yyyy-MM-dd') });

  const [putAssignmentsBody] = usePutAssignmentsBodyMutation();
  const [postAssignments, { isLoading: isCopyingAssigments }] = usePostCreateAssignmentsMutation();
  const [
    putAssignmentStatus,
    { isLoading: isPuttingAssignmentStatus },
  ] = usePutAssignmentsStatusMutation();

  const [dailySummary, setDailySummary] = useState<DailySummary|null>(null);

  const dispatch = useDispatch();
  const updateDailySummary = (data: DailySummary) => {
    if (data === undefined) return;
    const date = formatISO(parseISO(data.date), { representation: 'date' });
    dispatch(
      util.updateQueryData('getDailySummary', date, (state: DailySummary) => {
        Object.assign(state, { ...data, date });
      }),
    );
  };

  useSignalR({
    url: '/dailySummary',
    listeners: [
      { method: 'dailySummaryUpdated', action: updateDailySummary },
    ],
  });

  const { data: dailySummaryInfo } = useGetDailySummaryQuery(formatISO(currentDate, { representation: 'date' }));
  useEffect(() => {
    if (dailySummaryInfo !== undefined) setDailySummary(dailySummaryInfo);
  }, [dailySummaryInfo]);

  const closeCopyMenu = () => {
    setCopyMenu(null);
    setMoveOrCopyEvent(null);
  };

  useDraggable(writeAccess);
  const {
    vehicles,
    resources: rawResources,
  } = useResources(currentDate);
  const {
    assignments,
    events,
    loading,
    moveAssignment,
  } = useAssignments(currentDate);

  /** Update editAssignment when assignments change */
  useEffect(() => {
    if (editAssignment === null) return;
    const assignment = assignments.find((a) => a.id === editAssignment.id);
    if (!assignment) return;
    setEditAssignment(assignment);
  }, [assignments]);

  /**
   * Contains internalNumbers of vehicles with assignments today
   */
  const internalNumbersWithAssignments = useMemo(
    () => new Set(assignments.filter((a) => (
      a.status !== 'Deleted' && a.status !== 'Unavailable' && a.status !== 'Cancelled'
    )).map((a) => a.vehicle.internalNumber)),
    [assignments],
  );

  const unavailableVehicles = useMemo(
    () => {
      const counter = new Map<string, number>();
      const unavailable = new Set<string>();
      assignments.forEach((a) => {
        counter.set(
          a.vehicle.internalNumber,
          (counter.get(a.vehicle.internalNumber) || 0)
          + Math.abs(differenceInMinutes(
            max([new Date(a.startTime), startOfDay(currentDate)]),
            min([new Date(a.endTime), endOfDay(currentDate)]),
          )),
        );
      });
      counter.forEach((v, id) => {
        if (v >= 300) {
          unavailable.add(id);
        }
      });
      return unavailable;
    },
    [assignments],
  );

  const showOnlyRentalVehicles = useShowOnlyRentalVehicles();
  const showOnlyAvailable = useShowOnlyAvailable();
  const vehicleAvailable = (resource: { id: string }) => {
    if (!showOnlyAvailable) return true;
    return !internalNumbersWithAssignments.has(resource.id);
  };

  const resources = rawResources.filter(vehicleAvailable);

  const { orders } = useOrders();

  useEffect(() => {
    if (calApi) {
      setTimeout(() => {
        calApi.updateSize();
      }, 100);
    }
  }, [
    calApi,
    loading,
    resources,
    events,
  ]);

  const eventReceived = ({ event, revert }: EventReceiveArg) => {
    revert(); // Remove visual element
    const rr = event.getResources();
    const vehicleId = rr.length ? rr[0].id : null;
    const vehicle = vehicleId && vehicles
      ? vehicles.find((r) => `${r.internalNumber}` === `${vehicleId}`)
      : null;
    const order = orders?.find((o) => `${o.id}` === `${event.id}`);

    if (!vehicle || !order || !event.start) {
      // something went wrong - we need all of this data
      return;
    }
    setAddEvent({
      vehicle: vehicle.internalNumber,
      order,
      startTime: event.start,
      loadId: event.extendedProps.loadId,
      type: event.extendedProps.assignmentType,
      duration: (event.extendedProps.assignmentType || order.type).includes('Container') ? 60 : 45,
    });
  };

  const moveOnCalendar = (
    id: string|number,
    from: Date|string,
    to: Date|string,
    resourceId?: string,
  ) => {
    if (!calApi) return;
    const event = calApi.getEventById(`${id}`);
    if (!event) return;
    event.setDates(from, to);
    if (resourceId) {
      event.setResources([resourceId]);
    }
  };

  const putAssignment = (
    putEventId: string|null,
    putEventStart: Date|null,
    putEventEnd: Date|null,
    newVehicleInternalNumber?: string,
  ) => {
    const assignment = assignments.find((a) => `${a.id}` === putEventId);
    if (!assignment) return;
    const updateAssignmentData = assignment2UpdateAssignment(assignment);
    updateAssignmentData.startTime = putEventStart ? formatISO(putEventStart) : undefined;
    updateAssignmentData.endTime = putEventEnd ? formatISO(putEventEnd) : undefined;
    if (newVehicleInternalNumber) {
      updateAssignmentData.vehicleInternalNumber = newVehicleInternalNumber;
    }
    putAssignmentsBody({
      id: `${putEventId ? parseInt(putEventId, 10) : null}`,
      data: updateAssignmentData,
    }).unwrap()
      .catch(() => {
        // Reset assignment if the request failed
        moveAssignment(
          `${assignment.id}`,
          new Date(assignment.startTime),
          new Date(assignment.endTime),
          assignment.vehicle.internalNumber,
        );
        moveOnCalendar(
          assignment.id,
          assignment.startTime,
          assignment.endTime,
          assignment.vehicle.internalNumber,
        );
      });
  };

  const closeDialog = () => setEditAssignment(null);

  const renderUnavailability = (innerProps: EventContentArg, type: 'unavailability' | ServiceType) => (
    <div className="fc-event-main-frame clickable">
      { innerProps.timeText
        && <div className="fc-event-time">{ innerProps.timeText }</div>}
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">
          {type !== 'unavailability' ? `${type} - ` : ''}{innerProps.event.title}{' '}&#x2B25;{' '}
          {innerProps.event.extendedProps.startTime} - {innerProps.event.extendedProps.endTime}
        </div>
      </div>
    </div>
  );
  const renderInnerContent = (innerProps: EventContentArg, event?: Assignment) => {
    const extProps = innerProps.event.extendedProps;
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText
          && (
            <div className="fc-event-time">
              {innerProps.timeText}
            </div>
          )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {extProps.lock && <LockIcon sx={{ fontSize: 15, transform: 'translateY(2px)' }} />}
            {event?.type === 'ContainerDeliver'
              ? extProps.to || <>&nbsp;</>
              : extProps.from || <>&nbsp;</>}
            {extProps.from && extProps.to ? (
              <>
                {' '}
                <ArrowForwardIcon sx={{ fontSize: 13 }} />
                {' '}
              </>
            ) : null}
            {event?.type === 'ContainerDeliver'
              ? extProps.from || <>&nbsp;</>
              : extProps.to || <>&nbsp;</>}
          </div>
          <br />
          <div className="fc-event-title fc-sticky">
            {event ? getTransportType(event.type) : <>&nbsp;</>}
            {extProps.isInternal ? null : (
              <>
                {' '}&#x2B25;{' '}{/* Diamond character */}
                {extProps.loadType || <>&nbsp;</>}
              </>
            )}
            {!extProps.time ? null : (
              <>
                {' '}&#x2B25;{' '}{/* Diamond character */}
                {extProps.time || <>&nbsp;</>}
              </>
            )}
            {' '}&#x2B25;{' '}{/* Diamond character */}
            {extProps.contact || <>&nbsp;</>}

          </div>
        </div>
      </div>
    );
  };

  const regions = useRegionVisibility().filter((r) => r.active).map((r) => r.id);
  const [sendOrder] = usePostApproveTodayAssignmentsMutation();
  const approveAllHandler = () => {
    setApproveLoading(true);
    const categories = vehicleListNames2Categories(vehicleType.map((v) => v.label));
    const body: ApproveAllData = {
      date: formatISO(currentDate, { representation: 'date' }),
      regions,
      categories,
      onlyRental: showOnlyRentalVehicles,
    };
    sendOrder(body).then(() => setApproveLoading(false));
  };

  const moveAssignmentOnTimeline = (event?: MoveOrCopyEvent) => {
    const e = event || moveOrCopyEvent;
    if (!e) {
      return;
    }
    const resourceId = e?.newResource?.id || null;
    if (resourceId) {
      putAssignment(
        e.event.id,
        e.event.start,
        e.event.end,
        resourceId,
      );
    } else {
      putAssignment(
        e.event.id,
        e.event.start,
        e.event.end,
      );
    }
    if (e.event.start && e.event.end) {
      if (resourceId) {
        moveAssignment(e.event.id, e.event.start, e.event.end, resourceId);
      } else {
        moveAssignment(e.event.id, e.event.start, e.event.end);
      }
    }
    closeCopyMenu();
  };

  /**
   * Called when events are moved on the timeline
   * @param e Move or copy event
   */
  const eventChanged = (e: MoveOrCopyEvent) => {
    setCopyMenu({
      x: e.jsEvent.clientX - 20,
      y: e.jsEvent.clientY - 20,
    });
    setMoveOrCopyEvent(e);

    // e.event.remove();
  };

  const vehicleMovement = useMemo(() => {
    const e = moveOrCopyEvent;
    if (!e?.newResource) {
      return null;
    }
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    const resourceId = e?.newResource?.id || null;
    const toVehicle = resourceId && vehicles
      ? vehicles.find((r) => `${r.internalNumber}` === `${resourceId}`)
      : null;

    const fromVehicle = vehicles
      ? vehicles.find((r) => `${r.internalNumber}` === `${assignment?.vehicle?.internalNumber}`)
      : null;

    if (!toVehicle || !fromVehicle) {
      return null;
    }
    return { from: fromVehicle, to: toVehicle };
  }, [moveOrCopyEvent]);

  const canBeMoved = useMemo(() => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (assignment === undefined) return false;
    return assignment?.status === 'UnderPlanning';
  }, [moveOrCopyEvent]);

  const canBeCopied = useMemo(() => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (assignment === undefined) return false;
    if (useIsType(assignment.type)('Container')) return false;
    return true;
  }, [moveOrCopyEvent]);

  const assignmentIsStatus = useCallback((status: AssignmentStatus) => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (assignment === undefined) return false;
    if (assignment.status === status) return true;
    return false;
  }, [moveOrCopyEvent, assignments]);

  const canBeCancelledAndCopied = useMemo(() => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    return assignment?.status === 'Approved' && e?.newResource?.id && e?.newResource?.id !== assignment?.vehicle?.internalNumber;
  }, [moveOrCopyEvent]);

  const cancelAndCopyAssignment = async () => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (!assignment) {
      return;
    }
    const api = assignment2NewAssignment(assignment);
    if (!api) {
      return;
    }
    if (e?.newResource?.id) {
      api.vehicleInternalNumber = e.newResource.id;
    }
    if (e?.event.start && e?.event.end) {
      api.startTime = formatISO(e.event.start);
      api.endTime = formatISO(e.event.end);
    }

    postAssignments([api]);
    putAssignmentStatus({ id: `${assignment.id}`, data: 'Cancelled' });
    setCopyMenu(null);
    setMoveOrCopyEvent(null);
  };

  const copySingle = () => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (!assignment) {
      return;
    }
    const api = assignment2NewAssignment(assignment);
    if (!api) {
      return;
    }
    if (e?.newResource?.id) {
      api.vehicleInternalNumber = e.newResource.id;
    }
    if (e?.event.start && e?.event.end) {
      api.startTime = formatISO(e.event.start);
      api.endTime = formatISO(e.event.end);
    }

    postAssignments([api]);
    setCopyMenu(null);
    setMoveOrCopyEvent(null);
  };

  const copyAssignments = (
    fromVehicle: string,
    toVehicle: string,
    toTime?: Date,
    order?: Order,
  ) => {
    let raw = assignments
      .filter((a) => a.order)
      .filter((a) => `${a.vehicle.internalNumber}` === `${fromVehicle}`)
      .filter((a) => a.status !== 'Deleted' && a.status !== 'Cancelled');
    if (order) {
      raw = raw.filter((a) => a.order?.id === order.id);
    }
    if (!raw) {
      return;
    }

    let diff = 0;
    if (toTime) {
      const earliest = raw.reduce((acc, val) => {
        const d = minutesFromMidnight(new Date(val.startTime));
        return d < acc ? d : acc;
      }, Infinity);
      diff = minutesFromMidnight(toTime) - earliest;
    }

    const cooked = raw.map((a) => {
      const api = assignment2NewAssignment(a);
      if (!api) {
        return undefined;
      }
      api.startTime = formatISO(addMinutes(new Date(a.startTime), diff));
      api.endTime = formatISO(addMinutes(new Date(a.endTime), diff));
      api.vehicleInternalNumber = toVehicle;
      return api;
    }).filter((c): c is NewAssignment => c !== undefined);
    postAssignments(cooked);
    setCopyMenu(null);
    setMoveOrCopyEvent(null);
  };

  const copyOrderAssignments = () => {
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    if (!assignment) {
      return;
    }
    if (vehicleMovement && vehicleMovement.from && vehicleMovement.to) {
      copyAssignments(
        vehicleMovement.from.internalNumber,
        vehicleMovement.to.internalNumber,
        e?.event.start || undefined,
        assignment.order || undefined,
      );
    }
    setCopyMenu(null);
  };

  const moveOrderAssignments = useMemo(() => {
    const changeLabelStatus = ['Approved', 'NotDelivered', 'VerifiedNotDelivered'];
    const e = moveOrCopyEvent;
    const assignment = assignments.find((a) => `${a.id}` === e?.event.id);
    const resourceId = e?.newResource?.id || null;
    if (!assignment || !resourceId) return null;
    const filterOrderAssignments = assignments.filter((a) => a.order)
      .filter((a) => a.vehicle.internalNumber === vehicleMovement?.from.internalNumber)
      .filter((a) => a.order?.id === assignment.order?.id);

    const func = () => {
      filterOrderAssignments
        .forEach((a) => {
          if (a.status === 'UnderPlanning') {
            putAssignment(
              `${a.id}`,
              new Date(a.startTime),
              new Date(a.endTime),
              resourceId,
            );
          }
          if (a.status === 'Approved') {
            const api = assignment2NewAssignment(a);
            if (!api) return null;
            api.vehicleInternalNumber = resourceId;
            api.startTime = a.startTime;
            api.endTime = a.endTime;
            postAssignments([api]);
            putAssignmentStatus({ id: `${a.id}`, data: 'Cancelled' });
          }
          if (a.status === 'NotDelivered' || a.status === 'VerifiedNotDelivered') {
            const api = assignment2NewAssignment(a);
            if (!api) return null;
            api.vehicleInternalNumber = resourceId;
            api.startTime = a.startTime;
            api.endTime = a.endTime;
            postAssignments([api]);
          }
          return null;
        });
      setCopyMenu(null);
      setMoveOrCopyEvent(null);
    };

    const label = filterOrderAssignments.some((a) => changeLabelStatus.includes(a.status))
      ? 'Flytt / kopiér og kanseller bestilling'
      : 'Flytt bestilling';

    return { func, label };
  }, [assignments, vehicleMovement]);

  const copyAll = () => {
    if (vehicleMovement && vehicleMovement.from && vehicleMovement.to) {
      copyAssignments(
        vehicleMovement.from.internalNumber,
        vehicleMovement.to.internalNumber,
        moveOrCopyEvent?.event.start || undefined,
      );
    }
    setCopyMenu(null);
  };

  const menuItems = useMemo(() => {
    const res = [];
    if (canBeMoved) {
      res.push(
        <MenuItem key="move" onClick={() => moveAssignmentOnTimeline()}>Flytt oppdrag</MenuItem>,
      );
    } else if (!vehicleMovement && assignmentIsStatus('Approved')) {
      res.push((
        <MenuItem key="move" onClick={() => moveAssignmentOnTimeline()}>Flytt oppdrag</MenuItem>));
    }
    if (canBeCancelledAndCopied) {
      res.push(
        <MenuItem key="cancel-and-copy" onClick={() => cancelAndCopyAssignment()}>Kanseller og kopiér oppdrag</MenuItem>,
      );
    }
    if (canBeCopied) {
      res.push(
        <MenuItem key="copy" onClick={copySingle}>Kopiér oppdrag</MenuItem>,
      );
      if (vehicleMovement) {
        res.push(
          <MenuItem key="move-order" onClick={moveOrderAssignments?.func}>{moveOrderAssignments?.label}</MenuItem>,
          <MenuItem key="copy-order" onClick={copyOrderAssignments}>Kopiér bestilling</MenuItem>,
          <MenuItem key="copy-all" onClick={copyAll}>Kopiér alle</MenuItem>,
        );
      }
    }
    res.push(<MenuItem key="cancel" onClick={closeCopyMenu}>Avbryt</MenuItem>);
    return res;
  }, [canBeMoved, canBeCopied, canBeCancelledAndCopied, vehicleMovement, moveOrCopyEvent]);

  const isLoading = approveLoading || loading || isCopyingAssigments || isPuttingAssignmentStatus;

  const estimatedVehicles = useMemo(() => ((dailySummary !== undefined && dailySummary !== null)
    ? ((dailySummary?.orderedVechicles || 0)
        + Math.ceil((dailySummary?.estimatedLoadCount || 0) / 6.0))
    : 0), [dailySummary]);

  const unlockedUnderPlanningExists = useMemo(() => (
    assignments.some((a) => a.status === 'UnderPlanning' && a.lockState === false)
  ), [assignments]);

  const focus = () => document.getElementById('datePicker')?.focus();

  const onResourceButtonPressed = (action: VehicleAction, id: string, subId?: number) => {
    if (action === 'createUnavailability' || action === 'createVehicleService') {
      setCreateUnavailabilityFor(id);
      setCreateUnavailabilityType(action.replace('create', '') as UnavailabilityType);
    } else if (action === 'edit') {
      setEditRentalVehicle(id);
    } else if (action === 'editRegion') {
      setEditRegion(id);
    } else if (action === 'substituteView') {
      setSubstituteView(id);
    } else if (action === 'editSubstitute') {
      setSubExist(subId !== undefined ? subId : 0);
      setEditSubstitute(id);
    } else if (action === 'unavailabilityView') {
      setUnavailabilityView(id);
    }
  };

  const onDriverPressed = (id: number, name: string) => {
    setSelectedDriver({ id, name });
  };

  const vehicleCategory = useVehicleCategory(vehicles || []);

  const FCEvents = useMemo(() => {
    calApi?.removeAllEvents();
    return [
      ...[...events].map((e) => {
        const event = { ...e };
        if (moveOrCopyEvent && e.id === moveOrCopyEvent.event.id) {
          event.backgroundColor = `${e.color}88`;
          event.start = moveOrCopyEvent?.event.startStr || e.start;
          event.end = moveOrCopyEvent?.event.endStr || e.end;
          event.resourceId = moveOrCopyEvent?.event.getResources()[0]?.id || e.resourceId;
        } else if (draggingEventId && draggingEventId === e.id) {
          event.backgroundColor = `${e.color}88`;
          event.display = 'none';
        }
        return event;
      }),
      shadowEvent,
    ];
  }, [events, moveOrCopyEvent, draggingEventId]);

  useEffect(() => {
    if (!calApi) return;
    calApi.refetchEvents();
    if (moveOrCopyEvent) {
      const assignment = assignments.find((a) => `${a.id}` === moveOrCopyEvent?.event.id);
      if (!assignment) return;
      setShadow(
        calApi,
        assignment.startTime,
        assignment.endTime,
        assignment.vehicle.internalNumber,
      );
    } else if (draggingEventId) {
      const assignment = assignments.find((a) => `${a.id}` === draggingEventId);
      if (!assignment) return;
      setShadow(
        calApi,
        assignment.startTime,
        assignment.endTime,
        assignment.vehicle.internalNumber,
      );
    } else {
      clearShadow(calApi);
    }
  }, [FCEvents, draggingEventId]);

  const renderEventContent = (e: EventContentArg) => {
    if (e?.event.extendedProps.type === 'assignment') {
      const event = assignments.find((a) => `${a.id}` === `${e.event.id}`);
      if (!event) {
        return renderInnerContent(e);
      }
      return (
        <Tooltip
          title={(
            <HoverEvent
              assignment={event}
            />
          )}
          placement="left-start"
          enterDelay={1000}
          enterNextDelay={1000}
          arrow
        >
          { renderInnerContent(e, event) }
        </Tooltip>
      );
    }
    return renderUnavailability(e, e?.event?.extendedProps?.type ?? 'unavailability');
  };

  return (
    <Page className="transportplanlegger-page">
      <Header title="Transportplanlegger" showRegion>
        <Box sx={{
          display: shouldShowSummary ? 'flex' : 'none',
          flexGrow: 3,
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: 2,
          px: 4,
        }}
        >
          <Typography variant="h6" component="div">
            Bestilling:&nbsp;
            {dailySummary?.orderCount} bestillinger,&nbsp;
            { (dailySummary === undefined || dailySummary === null)
              ? 0 : (dailySummary?.assignmentCount || 0) - (dailySummary?.assignedVehicles || 0)}/
            {dailySummary?.estimatedLoadCount} lass (estimert),&nbsp;
            {dailySummary?.assignedVehicles}/{dailySummary?.orderedVechicles} internkjøring
          </Typography>
          <Typography variant="h6" component="div">
            Masse inn/ut:&nbsp;
            {dailySummary?.orderedAmountIn} m³ / {dailySummary?.orderedAmountOut} m³
          </Typography>
          <Typography variant="h6" component="div">
            Kjøretøy:&nbsp; {estimatedVehicles} bestilt (estimert),&nbsp;
            {resources.filter((v) => !unavailableVehicles.has(v.id)).length} tilgjengelig,&nbsp;
            {(dailySummary === undefined || dailySummary === null) ? 0 : dailySummary.usedVehicleCount} i bruk
          </Typography>
        </Box>
      </Header>
      <Box className="full-calendar-wrapper">
        <div className={`calendar-wrapper${isLoading ? ' loading' : ''}`}>
          <TimelineHeader
            vehicleCategory={vehicleCategory}
            day={currentDate}
            unlockedUnderPlanningExists={unlockedUnderPlanningExists}
            onDayChange={setCurrentDate}
            onApproveAll={approveAllHandler}
            assignmentsLoading={loading}
          />
          <div className="calendar-inner">
            <FullC
              ref={calRef}
              plugins={[resourceTimelinePlugin, interactionPlugin]}
              initialView="resourceTimelineCustomOneDay"
              resources={resources}
              resourceOrder="title"
              resourceLabelContent={(d) => renderResource(d, onResourceButtonPressed, onDriverPressed, !writeAccess)}
              resourceAreaHeaderContent={(
                <div className="space-between">
                  <span className="pl5">Kjøretøy</span>
                  <span className="flex-gap-10 pr5">
                    {writeAccess && (
                      <Tooltip title="Innleiebiler">
                        <IconButton color="inherit" aria-label="rental options" onClick={() => setRentalModalOpen(true)}>
                          <AddIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Vis kun innleiebiler">
                      <IconButton
                        color={showOnlyRentalVehicles ? 'primary' : 'inherit'}
                        aria-label="Innleiebiler"
                        onClick={() => dispatch(setShowOnlyRentalVehicles(!showOnlyRentalVehicles))}
                      >
                        <DepartureBoardIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Vis kun uten oppdrag">
                      <IconButton
                        color={showOnlyAvailable ? 'primary' : 'inherit'}
                        aria-label="vehicle with assignment"
                        onClick={() => dispatch(setShowOnlyAvailable(!showOnlyAvailable))}
                      >
                        <AssignmentIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </span>
                </div>
              )}
              resourceLabelClassNames={(r) => (
                !isLoading
                && internalNumbersWithAssignments.has(r.resource.extendedProps.internalNumber)
                  ? 'has-assignments'
                  : ''
              )}
              events={FCEvents}
              eventClick={(e) => {
                const event = assignments.find((a) => `${a.id}` === `${e.event.id}`);
                if (e?.event.extendedProps.type === 'assignment') {
                  setEditAssignment(event || null);
                } else if (event) setSelectedUnavailability(event);
              }}
              eventResize={(e) => moveAssignmentOnTimeline(e)}
              eventDrop={(e) => {
                eventChanged(e);
                if (!e.event.start) return;
                if (!e.event.end) return;
                moveOnCalendar(
                  e.event.id,
                  e.event.start,
                  e.event.end,
                  e.event.getResources()[0]?.id,
                );
              }}
              eventDragStart={(e) => setDraggingEventId(e.event.id)}
              eventDragStop={() => setDraggingEventId(null)}
              eventContent={renderEventContent}
              headerToolbar={false}
              nowIndicator
              droppable
              eventReceive={eventReceived}
              height="100%"
              eventResourceEditable
              resourceAreaWidth={300}
              locale={nbLocale}
              scrollTimeReset={false}
              businessHours={{
                daysOfWeek: nonWorkingDays?.some((d) => isSameDay(new Date(d.date), currentDate))
                  ? []
                  : [1, 2, 3, 4, 5],
                startTime: '07:00',
                endTime: '15:00',
              }}
              views={{
                resourceTimelineCustomOneDay: {
                  type: 'resourceTimeline',
                  buttonText: 'Dag',
                  slotLabelFormat: { hour: '2-digit', minute: '2-digit' },
                  snapDuration: '00:15',
                  slotMinWidth: 75,
                },
              }}
              dateClick={() => focus()}
              // Not a secret since it can easily be spotted in minified files
              // no matter where or how it is stored
              schedulerLicenseKey="0067403127-fcs-1701517614"
            />
          </div>
        </div>

        <Sidebar orders={orders} />
        {isLoading && (
          <CircularProgress
            size={50}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
              zIndex: 1000,
            }}
          />
        )}
        <EditAssignmentModal
          assignment={editAssignment}
          today={currentDate}
          onClose={closeDialog}
        />
        <CreateAssignmentModal
          event={addEvent}
          today={currentDate}
          onClose={() => setAddEvent(null)}
        />
        {selectedUnavailability && (
          <EditUnavailabilityModal
            id={selectedUnavailability?.id}
            onClose={() => setSelectedUnavailability(null)}
          />
        )}
        {unavailabilityView && (
          <UnavailabilityModal
            internalNumber={unavailabilityView}
            currentDate={currentDate}
            onClose={() => setUnavailabilityView(null)}
            title={`Utilgjengelighet - 
              ${vehicles.find((v) => v.internalNumber === unavailabilityView)
              ?.vehicleRegistrationPlateNumber}`}
          />
        )}
        {createUnavailabilityFor && createUnavailabilityType && (
          <CreateUnavailabilityModal
            type={createUnavailabilityType}
            currentDate={currentDate}
            internalNumber={createUnavailabilityFor}
            onClose={() => setCreateUnavailabilityFor(null)}
          />
        )}
        {editRentalVehicle && (
          <EditRentalVehicleModal
            internalNumber={editRentalVehicle}
            currentDate={currentDate}
            onClose={() => setEditRentalVehicle(null)}
          />
        )}
        {editRegion && (
          <AssignRegionModal
            internalNumber={editRegion}
            currentDate={currentDate}
            onClose={() => setEditRegion(null)}
          />
        )}
        <RentalModal
          currentDate={currentDate}
          isOpen={rentalModalOpen}
          onClose={() => setRentalModalOpen(false)}
        />
        {(editSubstitute || substituteView) && (
          <SubstituteModal
            internalNumber={editSubstitute || substituteView || ''}
            currentDate={currentDate}
            title={editSubstitute
              ? `${subExist ? 'Endre vikar' : 'Opprett vikar'}`
              : `Vikarer - ${vehicles.find((v) => v.internalNumber === substituteView)?.vehicleRegistrationPlateNumber}`}
            isEdit={!!editSubstitute}
            subExist={subExist}
            onClose={() => {
              if (editSubstitute) {
                setEditSubstitute(null);
                setSubExist(0);
              } else {
                setSubstituteView(null);
              }
            }}
          />
        )}
        {selectedDriver && (
          <Modal
            compactHeader
            title={
              selectedDriver
                ? `${selectedDriver.id} ${selectedDriver.name}`
                : 'Sjåførdetaljer'
            }
            onClose={() => setSelectedDriver(undefined)}
          >
            <WorkerDetails id={selectedDriver?.id} />
          </Modal>
        )}
      </Box>

      <Menu
        open={copyMenu !== null}
        onClose={closeCopyMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          copyMenu !== null
            ? { top: copyMenu.y, left: copyMenu.x }
            : undefined
        }
      >
        {menuItems}
      </Menu>
    </Page>
  );
};

export { TransportplanleggerPage };
