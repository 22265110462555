import { useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Typography from '@mui/material/Typography';
import { ContainerLoad } from '../../../../core/types/utilityTypes';
import { Assignment } from '../../../../core/types/assignment';
import { useIsType } from '../../../../core/hooks/useIsType';
import {
  useGetContainerWasteTypesQuery,
  useGetProjectContainersQuery,
} from '../../../../core/redux/transport';
import { Container } from '../../../../core/types/container';
import { Button } from '../../../../shared/components/button';
import { DataField } from '../../../../shared/components/dataField';
import { unique } from '../../../../core/helpers/functions';
import { SearchSelectDirect } from '../../../../shared/components/searchSelect';

export const ContainerItem = ({
  container,
  assignment,
  isLoading = false,
  disabledContainers = [],
  onWasteChange = () => null,
  onContainerChange = () => null,
  onContainerTwoChange = () => null,
  onExternalContainerChange = () => null,
} : {
  container: ContainerLoad,
  assignment: Assignment,
  isLoading?: boolean,
  disabledContainers?: string[],
  onWasteChange?: (v: string|number|undefined) => void,
  onContainerChange?: (v: string|number|undefined) => void,
  onContainerTwoChange?: (v: string|number|undefined) => void,
  onExternalContainerChange?: (v: string|number|undefined) => void,
}) => {
  const isType = useIsType(assignment.type);
  const [externalContainer, setExternalContainer] = useState<string|null>(container.container || container.default);
  const { approved } = useMemo(() => (
    {
      approved: assignment.status === 'Approved',
    }), [assignment]);

  const { data: wasteTypes } = useGetContainerWasteTypesQuery(isType('ContainerDeliver') ? skipToken : undefined);

  const { projectId, subCategory } = useMemo(() => {
    if (isType('ContainerSwap') && approved) {
      return {
        projectId: assignment.toProject?.id || 0,
        subCategory: container.subCategory || undefined,
      };
    }

    return {
      projectId: assignment.fromProject?.id || 0,
      subCategory: assignment.type === 'ContainerDeliver' ? container.subCategory || undefined : undefined,
    };
  }, [isType, assignment]);

  const { data: projectContainers, refetch } = useGetProjectContainersQuery(
    {
      projectId,
      random: `${assignment.id}`,
      // Filter on sub category when delivery
      subCategory,
    },
  );

  /** All containers as select items with disabled set correctly */
  const containerOpts = useMemo(() => {
    const shouldExcludeBasedOnAssignment = (x: Container) => (
      x.assignmentId !== undefined
      && x.assignmentId !== assignment.id) || container.id === x.internalNumber;

    const usedContainers = projectContainers?.filter((x) => shouldExcludeBasedOnAssignment(x)) || [];

    /** DisabledContainers */
    const dc = [
      ...disabledContainers.filter((x) => x !== (container.container || container.default)),
      ...usedContainers.map((x) => x.internalNumber),
    ];

    return unique(projectContainers?.map((c) => ({
      id: c.internalNumber,
      label: `${c.internalNumber} - ${c.subCategoryName}`,
      disabled: dc.includes(c.internalNumber),
    })), 'id');
  }, [projectContainers, disabledContainers, assignment]);

  /** New container interface should be shown */
  const chooseNewContainer = useMemo(() => isType('ContainerDeliver', 'ContainerSwap'), [isType]);
  /** User can pick a new container from the list */
  const canChooseNewContainer = useMemo(() => (isType('ContainerDeliver', 'ContainerSwap') && approved), [isType, assignment]);
  /** Existing container interface should be shown */
  const chooseExistingContainer = useMemo(() => isType('ContainerCollect', 'ContainerEmptying', 'ContainerSwap'), [isType]);
  /** User can pick an existing container from the list */
  const canChooseExistingContainer = useMemo(() => (projectContainers && !isType('ContainerSwap') && approved), [isType, assignment]);
  /** User can click the "velg [container_number]" button */
  const canChooseDefaultContainer = useMemo(() => (
    projectContainers
    && projectContainers.some((c) => c.internalNumber === container.default && c.assignmentId === undefined)
    && !isType('ContainerSwap')
    && approved
  ), [isType, assignment]);

  const onNewContainerChange = useMemo(() => {
    if (isType('ContainerSwap')) { return onContainerTwoChange; }
    return onContainerChange;
  }, [isType, onContainerChange, onContainerTwoChange]);

  const newContainer = useMemo(() => {
    if (isType('ContainerSwap')) { return container.containerTwo; }
    return container.container;
  }, [isType, container]);

  if (!isType('Container')) return null;
  return (
    <Box sx={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
      { chooseNewContainer && (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Typography display={isType('ContainerSwap') ? 'block' : 'none'}>Utkjøring</Typography>
          <Box style={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            <DataField
              sx={{ flex: 1, marginRight: 5 }}
              label="Massetype"
            >
              {container.massType || '(tom)'}
            </DataField>
            <DataField
              sx={{ flex: 1, marginLeft: 5 }}
              label="Kategori"
            >
              {container.subCategory?.replace('KROKCONTAINER', '') || ''}
            </DataField>
          </Box>
          <DataField
            label="Container"
          >
            {container.subCategory === 'Ekstern' ? (
              <Box display="flex" width="50%" gap={2}>
                <TextField
                  size="small"
                  fullWidth
                  value={externalContainer}
                  onChange={(v) => setExternalContainer(v.currentTarget.value)}
                  disabled={isLoading || !canChooseNewContainer}
                />
                <Button
                  variant="contained"
                  disabled={externalContainer === container.container || externalContainer?.trim().length === 0 || !canChooseNewContainer}
                  onClick={() => onExternalContainerChange(`${externalContainer}`)}
                >Lagre
                </Button>
              </Box>
            ) : (
              <SearchSelectDirect
                size="small"
                label={newContainer ? '' : 'Velg container'}
                onChange={onNewContainerChange}
                onClose={refetch}
                value={newContainer || undefined}
                disabled={isLoading || !canChooseNewContainer}
                required={!newContainer}
              >
                {containerOpts}
              </SearchSelectDirect>
            )}
          </DataField>
        </Box>
      )}

      {chooseExistingContainer && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography display={isType('ContainerSwap') ? 'block' : 'none'}>Henting</Typography>
          <Box sx={{ display: 'flex' }}>
            <DataField
              sx={{ flex: 1, marginRight: 1 }}
              label="Kategori"
            >
              {container.subCategory?.replace('KROKCONTAINER', '') || 'Ekstern'}
            </DataField>
            <DataField
              sx={{ flex: 1, marginLeft: 1 }}
              label="Bestilt container"
            >
              {container.default}
            </DataField>
          </Box>
          <Box
            sx={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1.5,
                flex: 1,
              }}
            >
              <DataField
                sx={{
                  flex: 1,
                }}
                label="Avfallstype"
              >
                <SearchSelectDirect
                  label=""
                  size="small"
                  disabled={(
                    !assignment.collectionProject
                    && !assignment.externalCollectionProject
                    && !assignment.intermediateStorage
                  ) || !canChooseExistingContainer}
                  onChange={onWasteChange}
                  value={container.wasteType || undefined}
                >
                  {wasteTypes?.map((w) => ({ id: w, label: w }))}
                </SearchSelectDirect>
              </DataField>
              <DataField
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'stretch',
                }}
                label="Container"
              >
                <Box sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                }}
                >
                  {!container.container && (
                  <Button
                    fullWidth
                    disabled={!canChooseDefaultContainer}
                    variant={container.container ? 'outlined' : 'contained'}
                    onClick={() => (!container.subCategory
                      ? onExternalContainerChange(`${externalContainer}`)
                      : onContainerChange(container.default || undefined))}
                    loading={isLoading}
                  >
                    Velg {container.default}
                  </Button>
                  )}
                  {!container.subCategory ? (
                    <Box display="flex" width="100%" gap={1}>
                      <TextField
                        size="small"
                        fullWidth
                        value={externalContainer}
                        onChange={(v) => setExternalContainer(v.currentTarget.value)}
                      />
                      <Button
                        variant="contained"
                        disabled={externalContainer === container.container || externalContainer?.trim().length === 0}
                        onClick={() => onExternalContainerChange(`${externalContainer}`)}
                      >
                        {!container.container ? 'Velg' : 'Lagre'}
                      </Button>
                    </Box>
                  ) : (
                    <SearchSelectDirect
                      size="small"
                      label={container.container ? '' : 'Velg annen'}
                      onChange={onContainerChange}
                      value={container.container || undefined}
                      loading={isLoading}
                      disabled={!canChooseExistingContainer}
                      onClose={refetch}
                    >
                      {containerOpts}
                    </SearchSelectDirect>
                  )}
                </Box>
              </DataField>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
