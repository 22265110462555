/**
 * Helper functions for drivers - results may not be valid for planner
*/

import { AssignmentStatus } from '../types/enums/assignmentStatus';

/**
 * Determines if an assignment with the given status should be shown to a driver
 * @param status Status to check
 * @returns Whether or not to show
 */
export const isValidStatus = (status: AssignmentStatus) => {
  if (status === 'Approved') return true;
  if (status === 'Started') return true;
  if (status === 'Completed') return true;
  if (status === 'NotDelivered') return true;
  if (status === 'VerifiedNotDelivered') return true;
  if (status === 'Cancelled') return true;
  if (status === 'VehicleService') return true;
  return false;
};
