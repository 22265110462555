import { AssignmentStatus } from '../types/enums/assignmentStatus';

const canEditFromTimeStatuses = ['UnderPlanning', 'Approved'];
const canEditToTimeStatuses = ['UnderPlanning', 'Approved', 'Started', 'Completed', 'NotDelivered', 'VerifiedNotDelivered'];
const canEditDataStatuses = ['UnderPlanning', 'Approved', 'Started', 'Completed', 'NotDelivered', 'VerifiedNotDelivered'];
const canEditVehicleStatuses = ['UnderPlanning'];

export const canEditFromTime = (status: AssignmentStatus|undefined|null) => (
  status ? canEditFromTimeStatuses.includes(status) : false
);
export const canEditToTime = (status: AssignmentStatus|undefined|null) => (
  status ? canEditToTimeStatuses.includes(status) : false
);
export const canEditData = (status: AssignmentStatus|undefined|null) => (
  status ? canEditDataStatuses.includes(status) : false
);
export const canEditVehicle = (status: AssignmentStatus|undefined|null) => (
  status ? canEditVehicleStatuses.includes(status) : false
);
