import { Typography, IconButton, Box } from '@mui/material';

export const NumberAdornment = ({
  units,
  value,
  onChange,
  max,
  min = 0,
}: {
  units: string,
  value: number,
  onChange: (n: number) => void,
  max?: number,
  min?: number,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Typography sx={{ color: 'grey' }}>{units}</Typography>
    <IconButton
      sx={{
        m: 0,
        p: 0,
        width: 20,
        height: 20,
      }}
      onClick={() => {
        if (value <= min) return;
        onChange(value - 1);
      }}
    >
      -
    </IconButton>
    <IconButton
      sx={{
        m: 0,
        p: 0,
        width: 20,
        height: 20,
      }}
      onClick={() => {
        if (max && value >= max) return;
        onChange(value + 1);
      }}
    >
      +
    </IconButton>
  </Box>
);
