import React, { FC, ReactNode } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { Logout, Home as HomeIcon } from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { PopMenu } from '../PopMenu';
import { RegionSelector } from './partials/RegionSelector';
import { useGetVersionQuery } from '../../shared/redux/version';
import { logOut, msalInstance } from '../../shared/msal';
import { useGetRoles, useHasRoles } from '../../core/msal';

interface HeaderProps {
  onBack?: null | (() => void)
  title?: string;
  disableHome?: boolean;
  showRegion?: boolean;
  children: ReactNode;
}

export const Header: FC<HeaderProps> = ({
  children,
  onBack = null,
  title = null,
  disableHome = false,
  showRegion = false,
}) => {
  const [hasRole] = useHasRoles();

  const { data: serverVersion } = useGetVersionQuery();
  const isAuthenticated = useIsAuthenticated();
  const account = msalInstance.getActiveAccount();
  const [roles] = useGetRoles();

  const getInitials = () => {
    const nameArray = account?.name?.split(' ');
    const firstName = nameArray ? nameArray[0] : null;
    const lastName = nameArray ? nameArray[nameArray.length - 1] : null;
    if (!firstName || !lastName) { // can not get username
      return 'N/A';
    }
    return (firstName.charAt(0) + lastName.charAt(0));
  };

  const checkLogIn = () => isAuthenticated;

  return (
    <AppBar position="sticky">
      <Toolbar>
        {!disableHome && (
          <IconButton component={Link} to="/" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <HomeIcon />
          </IconButton>
        )}

        {!onBack ? (
          null
        ) : (
          <IconButton edge="start" color="inherit" aria-label="back" sx={{ mr: 2 }} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {title !== null && (
          <>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{title}</Typography>
            {children}
          </>
        )}
        {title === null && (
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {children}
          </Typography>
        )}
        {showRegion && hasRole('admin', 'container-koordinator', 'transport-koordinator') && (
        <Box sx={{ mr: 3 }}>
          <RegionSelector />
        </Box>
        )}
        {checkLogIn() && (
        <PopMenu renderButton={(
          <div>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="profile"
            >
              <Avatar sx={{ width: 36, height: 36 }}>{getInitials()}</Avatar>
            </IconButton>
          </div>
            )}
        >
          <MenuItem>
            <Avatar />
            {' '}
            {account?.name}
          </MenuItem>
          <Divider />
          <MenuItem onClick={logOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logg ut
          </MenuItem>
          <Typography
            sx={{ px: 2 }}
            title={`Roller: \n${roles.join(' \n')}`}
            variant="caption"
          >
            {process.env.REACT_APP_VERSION} {serverVersion?.version ?? ''}
          </Typography>
        </PopMenu>
        )}
      </Toolbar>
    </AppBar>
  );
};
