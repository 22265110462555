import {
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  SxProps,
  Theme,
} from '@mui/material';
import { useMemo } from 'react';
import { SelectItem } from '../../types/util/selectItem';

const errorColorSx = (error: boolean) => (error
  ? (t: Theme) => ({ color: t.palette.error.main })
  : undefined
);

export const RadioGroup = <T extends string>({
  value = null,
  onChange = () => null,
  options = [],
  sx = {},
  required = false,
  disabled = false,
}: {
  value?: T|null,
  onChange?: (value: T) => void,
  options?: SelectItem<T>[],
  sx?: SxProps,
  required?: boolean,
  disabled?: boolean,
}) => {
  const error = useMemo(() => {
    if (!required) return false;
    return !value;
  }, [required, value]);

  const opts = useMemo(() => options.map((o) => (
    <FormControlLabel
      sx={errorColorSx(error)}
      key={o.id}
      value={o.id}
      control={<Radio disabled={disabled} sx={errorColorSx(error)} />}
      label={o.label}
    />
  )), [options, error]);

  return (
    <MuiRadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      sx={sx}
    >
      {opts}
    </MuiRadioGroup>
  );
};
