import {
  Box,
  Skeleton,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { UpdateOrder } from '../../../core/types/api/updateOrder';
import { translateOrderField } from '../../../core/helpers/translate';
import { DateTimePicker } from '../../../shared/components/dateTimePicker';
import { ProjectPicker } from '../../ProjectPicker';
import { ChooseContactPerson } from '../../ContactPerson';
import { VehicletypePicker } from '../../VehicleTypePicker';
import { WhenInput } from '../../WhenInput';
import { useIsOrderType } from '../../../core/hooks/useIsType';
import { NumberAdornment } from '../../NumberAdornment';
import { OptionalTextField } from '../../OptionalTextField';
import { RadioYesNo } from '../../RadioYesNo';

export const OrderField = ({
  field,
  order,
  onChange,
}: {
  field: keyof UpdateOrder,
  order: Partial<UpdateOrder> | undefined,
  onChange: (v: Partial<UpdateOrder>) => void,
}) => {
  const isType = useIsOrderType(order?.type);
  const inputEl = useMemo(() => {
    if (!order) return null;
    switch (field) {
      case 'fromProject':
      case 'toProject': return (
        <ProjectPicker
          usePortal
          required={(
            isType('MassOut', 'ContainerCollect', 'ContainerEmptying')
              ? field === 'fromProject'
              : field === 'toProject'
          )}
          label=""
          errorText=""
          size="small"
          value={order[field]}
          onChange={(p) => onChange({ [field]: p?.id ?? p ?? undefined })}
        />
      );
      case 'date': return (
        <DateTimePicker
          disableTime
          fullWidth
          format="dd.MM.yyyy"
          value={new Date(order[field] ?? '')}
          onChange={(e) => onChange({ [field]: e.toISOString() })}
        />
      );
      case 'contactPersonEmployeeNumber': return (
        <ChooseContactPerson
          size="small"
          externalHelpText={null}
          inputLabel=""
          value={{
            employeeNumber: order.contactPersonEmployeeNumber,
            name: order.contactPersonName ?? '',
            number: order.contactPersonPhoneNumber ?? '',
          }}
          onChange={(c) => c && onChange({
            contactPersonEmployeeNumber: c.employeeNumber,
            contactPersonName: c.name,
            contactPersonPhoneNumber: c.number,
          })}
        />
      );
      case 'vehicle': return (
        <VehicletypePicker
          fullWidth
          size="small"
          value={order[field] || null}
          onChange={(e) => onChange({ [field]: e ?? undefined })}
          disabled={(order?.loads?.length ?? 0) > 1}
        />
      );
      case 'extraTimeInFacility': return (
        <TextField
          fullWidth
          size="small"
          value={order[field] || '0'}
          onChange={(e) => {
            let val = parseInt(e.target.value, 10);
            if (Number.isNaN(val)) val = 0;
            onChange({ [field]: Math.abs(val) });
          }}
          InputProps={{
            endAdornment: (
              <NumberAdornment
                units="timer"
                value={order[field] ?? 0}
                onChange={(v) => onChange({ [field]: v })}
              />
            ),
          }}
        />
      );
      case 'numberOfVehicles': return (
        <TextField
          fullWidth
          size="small"
          value={order[field] || '0'}
          onChange={(e) => {
            let val = parseInt(e.target.value, 10);
            if (Number.isNaN(val)) val = 0;
            onChange({ [field]: Math.abs(val) });
          }}
          InputProps={{
            endAdornment: (
              <NumberAdornment
                units="stk"
                value={order[field] ?? 0}
                onChange={(v) => onChange({ [field]: v })}
              />
            ),
          }}
        />
      );
      case 'earlyDelivery': return (
        <FormControlLabel
          label="Kan leveres dagen før"
          control={(
            <Checkbox
              checked={order[field] ?? false}
              onChange={(_, v) => onChange({ [field]: v })}
            />
          )}
        />
      );
      case 'when': return (
        <WhenInput
          value={order[field] || undefined}
          onChange={(e) => onChange({ [field]: e ?? undefined })}
          referenceDate={order.date}
          type={order.type}
        />
      );
      case 'gateCode':
      case 'extraEquipment': return (
        <OptionalTextField
          size="small"
          label={
            field === 'gateCode'
              ? 'Kode for port'
              : 'Ekstra utstyr'
          }
          value={order[field]}
          onChange={(e) => onChange({ [field]: e })}
        />
      );
      case 'daytimeDelivery':
      case 'hasDispensation': return (
        <RadioYesNo
          value={order[field]}
          onChange={(e) => onChange({ [field]: e })}
        />
      );
      case 'contactPersonPhoneNumber':
      case 'contactPersonName': return (
        <h1>Not used</h1>
      );
      default: return (
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={order[field] ?? ''}
          onChange={(e) => onChange({ [field]: e.target.value })}
        />
      );
    }
  }, [field, order]);

  if (isType('Container') && field === 'numberOfVehicles') return null;
  if (isType('Container') && field === 'vehicle') return null;
  if (isType('MassInternal') && field === 'fromProject') return null;
  if (!isType('MassIn', 'MassOut') && field === 'extraTimeInFacility') return null;
  if (!isType('MassInternal') && field === 'numberOfVehicles') return null;
  if (isType('ContainerEmptying') && field === 'toProject') return null;
  if (isType('Haul') && field === 'vehicle') return null;
  if (!isType('Haul') && field === 'hasDispensation') return null;
  if (!isType('Haul') && field === 'extraEquipment') return null;
  if (!isType('Haul') && field === 'gateCode') return null;
  if (!isType('Haul') && field === 'daytimeDelivery') return null;

  // Loading state
  if (!order) return <Skeleton />;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Typography sx={{ width: 180, paddingY: '8px' }}>{translateOrderField(field)}</Typography>
      <Box sx={{ flex: 1 }}>
        {inputEl}
      </Box>
    </Box>
  );
};
