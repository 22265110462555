import React, { FC, useMemo } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { autoFormat as format } from '../../../core/helpers/dates';
import { Data } from './Data';
import { AssignmentStatusDriver } from '../../../components/AssignmentStatusDriver';
import { translateMassType, translateUnavailabilityType } from '../../../core/helpers/translate';
import { Assignment } from '../../../core/types/assignment';
import { formatLoad } from '../../../core/helpers/assignmentHelpers';
import { RegistrationPlate } from '../../../components/RegistrationPlate';
import { useIsType } from '../../../core/hooks/useIsType';
import { optionalBool } from '../../../core/helpers/functions';

export const Details: FC<{
  assignment: Assignment,
  onBack?: () => void,
  refetch?: () => void,
}> = ({
  assignment,
  onBack = () => null,
  refetch = () => null,
}) => {
  const isType = useIsType(assignment.type);
  const isUnavailable = useMemo(() => {
    if (assignment.status === 'Unavailable') return true;
    if (assignment.status === 'VehicleService') return true;
    return false;
  }, [assignment]);
  const assignmentTime = useMemo(() => {
    const { startTime, endTime } = assignment;
    if (assignment.status !== 'Unavailable') return `${format(new Date(startTime))} til ${format(new Date(endTime))}`;

    return `${format(new Date(startTime), 'yyyy-MM-dd HH:mm')} til ${format(new Date(endTime), 'yyyy-MM-dd HH:mm')}`;
  }, [assignment]);

  const contactData = () => (
    <span>
      {assignment.order?.contactPersonName || ''}
      {' - '}
      {assignment.order?.contactPersonPhoneNumber && (
      <a href={`tel:${assignment.order?.contactPersonPhoneNumber}`}>
        {assignment.order?.contactPersonPhoneNumber}
      </a>
      )}
    </span>
  );

  const orderComment = () => (
    <div className="text-field">
      {assignment.order?.comment || ''}
    </div>
  );

  const driverComment = () => (
    <div className="text-field">
      {assignment.commentForDriver || ''}
    </div>
  );

  return (
    <div className="assignment-details">
      <div className="assignment-details-inner">

        <div className="toolbar-wrapper">
          <div><Button onClick={onBack}><ArrowBackIcon fontSize="large" /></Button></div>
          <div><Button onClick={refetch}><CachedIcon fontSize="large" /></Button></div>
        </div>

        <div className="assignment-header-wrapper">
          <div className="assignment-header">
            Oppdrag {assignment.id}
          </div>
          <div className="align-right">
            <RegistrationPlate number={assignment.vehicle.vehicleRegistrationPlateNumber} />
            <span className="order-type">
              {
              assignment.order
                ? translateMassType(assignment.order)
                : translateUnavailabilityType(assignment)
              }
            </span>
          </div>
        </div>
        {assignment.title && (
        <Data
          header={assignment.status === 'VehicleService' ? 'Sted' : 'Tittel'}
          data={assignment.title}
        />
        )}
        <Data
          header="Tidsrom"
          data={assignmentTime}
        />
        { !isUnavailable && (
        <>
          <Data
            header="Fra prosjekt"
            data={`${assignment.fromProject?.id} ${assignment.fromProject?.projectName}`}
            show={!isType('MassInternal')}
          />
          <Data
            header={isType('MassInternal') ? 'Prosjekt' : 'Til prosjekt'}
            data={`${assignment.toProject?.id} ${assignment.toProject?.projectName}`}
          />
          <Data
            header="Kode for port"
            data={assignment.order?.gateCode ?? 'Nei'}
            show={isType('Haul')}
          />
          <Data
            header="Ekstra utstyr"
            data={assignment.order?.extraEquipment ?? 'Nei'}
            show={isType('Haul')}
          />
          <Data
            header="Dispensasjon"
            data={optionalBool(assignment.order?.hasDispensation)}
            show={isType('Haul')}
          />
          <Data
            header={isType('Haul') ? 'Maskin' : 'Last'}
            data={assignment.loads.map((l) => formatLoad(l, assignment.type)).join(', ')}
            show={!isType('MassInternal')}
          />
          <Data
            header="Kontakt"
            data={contactData}
            className="flexchild"
          />
          <Data
            header="Bestillingskommentar"
            textAbove
            data={orderComment}
          />
        </>
        )}
        <Data
          header="Sjåførkommentar"
          textAbove
          data={driverComment}
        />
        <div className="last-changed">
          Sist endret
          {' '}
          { assignment.lastEditTime && format(new Date(assignment.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")}
          { assignment.lastEditedByName && ` av ${assignment.lastEditedByName}` }
        </div>
        <AssignmentStatusDriver status={assignment.status} serviceType={assignment.orderComment} />
      </div>
    </div>
  );
};
