import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useGetMassTypesQuery } from '../../core/redux/transport';
import { UpdateLoad } from '../../core/types/api/updateLoad';
import { OrderType } from '../../core/types/enums/orderType';
import { calculateTonnage } from '../../core/helpers/functions';
import { UpdateOrder } from '../../core/types/api/updateOrder';
import { NumberAdornment } from '../../components/NumberAdornment';
import { useIsOrderType } from '../../core/hooks/useIsType';

export const useIsMassValid = (type: OrderType|undefined, loads: UpdateLoad[]) => {
  const isType = useIsOrderType(type);
  if (!isType('Mass')) return true;
  if (loads.length === 0) return isType('MassInternal');
  const [primary, secondary] = loads;
  if (!primary.massTypeId) return false;
  if (!secondary) return true;
  if (primary.amount > 10) return false;
  if (secondary.amount > 10) return false;
  if (!secondary.massTypeId) return false;
  return true;
};

export const MassPicker = ({
  value,
  onChange: onChangeOrder,
  type,
}: {
  value: UpdateLoad[],
  onChange: (v: Partial<UpdateOrder>) => void,
  type: OrderType,
}) => {
  const [primaryLoad, secondaryLoad] = value;

  const { data: massTypes } = useGetMassTypesQuery();

  const massTypeOptions = useMemo(() => {
    if (!massTypes) return null;
    const massTypeRef = type === 'MassIn' ? 'Inn' : 'Ut';
    return massTypes
      .filter((e) => e.type === massTypeRef)
      .map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>);
  }, [massTypes, type]);

  const onChange = (l: UpdateLoad[]) => {
    onChangeOrder({ loads: l });
  };

  const setPrimaryLoad = (l: UpdateLoad) => {
    if (secondaryLoad === undefined) onChange([l]);
    else onChange([l, ...value.slice(1)]);
  };

  const setSecondaryLoad = (l: UpdateLoad) => {
    if (primaryLoad === undefined) return;
    onChange([primaryLoad, l]);
  };

  const updateCubic = (v: string, setSplit: boolean = false) => {
    if (v) {
      if (setSplit) {
        setSecondaryLoad({ ...secondaryLoad, amount: Number.parseFloat(v) || 0 });
      } else {
        setPrimaryLoad({ ...primaryLoad, amount: Number.parseFloat(v) || 0 });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (setSplit) {
        setSecondaryLoad({ ...secondaryLoad, amount: 0 });
      } else {
        setPrimaryLoad({ ...primaryLoad, amount: 0 });
      }
    }
  };

  const splitDisabled = useMemo(() => primaryLoad.amount > 10, [primaryLoad]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControl size="small" fullWidth>
          <InputLabel>Massetype</InputLabel>
          <Select
            value={`${primaryLoad.massTypeId}`}
            label="Massetype"
            onChange={(e) => (
              setPrimaryLoad({ ...primaryLoad, massTypeId: +e.target.value || undefined })
            )}
            defaultValue="Laster..."
          >
            {massTypeOptions}
          </Select>
        </FormControl>
        <TextField
          value={primaryLoad.amount || ''}
          label="Mengde"
          onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''), false)}
          id="outlined-basic"
          placeholder="Mengde masse"
          error={primaryLoad.amount === 0 || primaryLoad.amount === null}
          autoComplete="off"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <NumberAdornment
                units="m³"
                value={primaryLoad.amount ?? 0}
                onChange={(v) => updateCubic(`${v}`, false)}
              />
            ),
          }}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Box>
      <div>
        {`Cirka ${calculateTonnage(primaryLoad.amount || 0)} tonn`}
      </div>
      <div>
        <FormGroup>
          <FormControlLabel
            disabled={splitDisabled}
            control={(
              <Checkbox
                checked={!!secondaryLoad}
                onClick={() => (
                  secondaryLoad
                    ? onChangeOrder({ vehicle: 'CarWithTrailer', loads: [primaryLoad] })
                    : onChange([primaryLoad, { amount: 10 }]))}
              />
                              )}
            label="Splittet last (maks 10m³)"
          />
        </FormGroup>
      </div>
      {!splitDisabled && secondaryLoad && (
        <>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl size="small" fullWidth>
              <InputLabel>Massetype Henger</InputLabel>
              <Select
                value={secondaryLoad.massTypeId ? `${secondaryLoad.massTypeId}` : null}
                required
                label="Massetype Henger"
                onChange={(e) => setSecondaryLoad({ ...secondaryLoad, massTypeId: e.target.value ? (+e.target.value || undefined) : undefined })}
                defaultValue="Laster..."
                error={!secondaryLoad.massTypeId}
              >
                {massTypeOptions}
              </Select>
            </FormControl>
            <TextField
              value={secondaryLoad.amount ?? ''}
              label="Mengde Henger"
              onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''), true)}
              id="outlined-basic"
              placeholder="Mengde masse"
              autoComplete="off"
              error={
            (secondaryLoad.amount ? secondaryLoad.amount > 10 : false)
            || (secondaryLoad.amount === 0 || secondaryLoad.amount === null)
          }
              helperText={secondaryLoad.amount && secondaryLoad.amount > 10 ? 'Maks 10m³' : ''}
              InputProps={{
                inputProps: { min: 0, max: 10 },
                endAdornment: (
                  <NumberAdornment
                    units="m³"
                    value={secondaryLoad.amount ?? 0}
                    onChange={(v) => updateCubic(`${v}`, true)}
                    max={10}
                  />
                ),
              }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {`Cirka ${calculateTonnage(secondaryLoad.amount || 0)} tonn`}
          </Typography>
        </>
      )}
    </Box>
  );
};
