import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CreateRegionAssignment } from '../../../components/CreateRegionAssignment';

export const AssignRegionModal: FC<{
  internalNumber: string;
  currentDate?: Date;
  onClose: () => void;
}> = ({
  internalNumber,
  currentDate,
  onClose,
}) => (
  <Dialog
    open
    onClose={onClose}
    maxWidth="xs"
    fullWidth
  >
    <div className="align-status">
      <div className="left">
        <DialogTitle>Endre region</DialogTitle>
      </div>
      <IconButton sx={{ marginRight: '15px' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      <CreateRegionAssignment
        internalNumber={internalNumber}
        currentDate={currentDate}
        onClose={onClose}
      />
    </DialogContent>
  </Dialog>
);
