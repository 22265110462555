import { Box, Card } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { useMemo } from 'react';
import {
  getPlannedContainerCount,
  isContainerInIntermediateStorage,
  getContainerLoadTitle,
  createAttributeSet,
  extractData,
} from '../../../core/helpers/functions';
import { ContainerEmptyingCard } from '../../../components/ContainerEmptyingCards';
import { useIsType } from '../../../core/hooks/useIsType';
import { Order } from '../../../core/types/order';
import { projectLabel } from '../../../core/helpers/eventRender';
import { translateMassType } from '../../../core/helpers/translate';
import { useHasRoles } from '../../../core/msal';

/** Display a draggable piece used for creating assignments */
export const PlanningDraggable = ({
  isType,
  order,
  orderData,
}: {
  isType: ReturnType<typeof useIsType>,
  order: Order,
  orderData: ReturnType<typeof extractData>,
}) => {
  const [hasRole] = useHasRoles();

  const draggable = useMemo(() => hasRole('admin', 'container-koordinator', 'transport-koordinator'), [hasRole]);

  const orderType = order !== undefined ? translateMassType(order) : null;

  const attributes = createAttributeSet({
    from: order !== undefined ? projectLabel(order.fromProject) : null,
    to: order !== undefined ? projectLabel(order.toProject) : null,
    orderType,
    assignmentType: order !== undefined ? order.type : null,
    loadType: order !== undefined ? order.loads.map((l) => l.type?.name).join(', ') : null,
    contact: order !== undefined ? `${order.contactPersonName} (${order.contactPersonPhoneNumber})` : null,
    isInternal: order?.type === 'MassInternal',
    loadId: undefined,
  });

  return (
    <div className="footer">
      <Box display="flex" flex={1} flexDirection="column">
        {isType('Container') && (
          order.loads.map((l) => {
            if (order.type === 'ContainerEmptying') {
              return <ContainerEmptyingCard key={l.id} dataTitle={orderData.title} order={order} load={l} attributes={attributes} draggable={draggable} />;
            }
            return (
              <div
                key={l.id}
                className={`flexchild single-event-draggable-parent${getPlannedContainerCount(order, l) > 0 ? '-planned' : ''}${draggable ? ' make-draggable' : ''}`}
                data-id={order.id}
                data-title={orderData.title}
                data-duration="01:00"
                data-loadid={l.id}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                {...attributes}
                data-assignmenttype={isContainerInIntermediateStorage(order, l) ? 'ContainerEmptying' : order.type}
                draggable={draggable}
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Card sx={{ flex: 1, display: 'flex' }}>
                    <div className="single-event-draggable">
                      <MoreVertIcon />
                      <span>{getContainerLoadTitle(order.type, l, isType('ContainerCollect'))}</span>
                    </div>
                    {isContainerInIntermediateStorage(order, l) && (
                    <AutoDeleteIcon color="secondary" />
                    )}
                  </Card>
                  {isType('ContainerDeliver') && (
                  <Box marginRight={1} fontSize="16px" fontWeight="bold">
                    <span>{getPlannedContainerCount(order, l)}/{l.amount}</span>
                  </Box>
                  )}
                </Box>
              </div>
            );
          })
        )}
        {isType('Mass') && (
        <div
          className={`flexchild single-event-draggable-parent${draggable ? ' make-draggable' : ''}`}
          data-id={order.id}
          data-title={orderData.title}
          data-duration="00:45"
                            // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
          draggable={draggable}
        >
          <Card>
            <div className="single-event-draggable">
              <MoreVertIcon />
              <span>{orderData.title}</span>
            </div>
          </Card>
        </div>
        )}
        {isType('Haul') && (
        <div
          className={`flexchild single-event-draggable-parent${draggable ? ' make-draggable' : ''}`}
          data-id={order.id}
          data-title={orderData.title}
          data-duration="00:45"
                            // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
          draggable={draggable}
        >
          <Card>
            <div className="single-event-draggable">
              <MoreVertIcon />
              <span>{orderData.title}</span>
            </div>
          </Card>
        </div>
        )}
      </Box>
    </div>
  );
};
