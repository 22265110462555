import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegionFilter } from '../../../core/hooks/useRegionFilter';
import {
  useForceRefetchOfOrdersMutation,
  useGetOrdersQuery,
  util,
} from '../../../core/redux/transport';
import { Order } from '../../../core/types/order';
import { useSignalR } from '../../../core/signalR';
import { useShowPeriod, useShowPlanned } from '../../../core/redux/orderFilter';

const useOrders = () => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<Order[] | null>(null);
  const showPlanned = useShowPlanned();
  const showPeriod = useShowPeriod();
  const { data: ordersData } = useGetOrdersQuery({ showPeriod, showPlanned });
  const [forceRefetchOrder] = useForceRefetchOfOrdersMutation();

  useEffect(() => {
    setOrders(ordersData || null);
  }, [ordersData]);

  const addOrder = (newOrder: Order) => {
    setOrders((oldOrders) => (oldOrders === null ? [newOrder] : [...oldOrders, newOrder]));
  };

  const updateOrder = (updatedOrder: Order) => {
    dispatch(
      util.updateQueryData('getOrder', updatedOrder.id, (order: Order) => {
        Object.assign(order, { ...updatedOrder });
      }),
    );
    setOrders((old) => {
      if (old === null) return [updatedOrder];
      const result = old.map((o) => (o.id === updatedOrder.id ? updatedOrder : o));
      if (result.find((o) => o.id === updatedOrder.id)) return result;
      return [...result, updatedOrder];
    });
  };

  useSignalR({
    listeners: [
      { method: 'orderCreated', action: addOrder },
      { method: 'orderAssignmentUpdated', action: updateOrder },
      { method: 'orderAssignmentCreated', action: updateOrder },
      {
        method: 'orderUpdated',
        action: (o) => {
          updateOrder(o);
          forceRefetchOrder();
        },
      },
    ],
  });

  const regionFilter = useRegionFilter();
  const filteredOrders = useMemo(
    () => (
      orders && orders.filter((o) => (o.fromProject && regionFilter(o.fromProject))
    || (o.toProject && regionFilter(o.toProject)))),
    [regionFilter, orders],
  );

  return { orders: filteredOrders };
};

export { useOrders };
