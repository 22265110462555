import { FC } from 'react';
import { Badge, Tooltip } from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';

export const ExpiredCert: FC<{
  expCert: number,
}> = ({
  expCert,
}) => {
  if (expCert > 0) {
    return (
      <Tooltip
        title="Utgått sertifikat"
        followCursor
        placement="right-start"
        enterDelay={300}
        enterNextDelay={300}
        arrow
      >
        <span>
          {expCert > 1 ? (
            <Badge
              badgeContent={expCert}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  right: -3,
                  top: 8,
                  border: '2px solid',
                  padding: '0 3px',
                  fontSize: '11px',
                  height: 17,
                  minWidth: 14,
                },
              }}
            >
              <EventBusyIcon color="error" sx={{ fontSize: '17px', verticalAlign: 'middle' }} />
            </Badge>
          ) : (
            <EventBusyIcon color="error" sx={{ fontSize: '17px', verticalAlign: 'middle' }} />
          )}
        </span>
      </Tooltip>
    );
  }

  return null;
};
