import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { getCurrentDriver, getCurrentDriverName } from '../../core/helpers/functions';
import { Vehicle } from '../../core/types/vehicle';

export const SelectVehicle = ({
  value,
  options = [],
  onChange = () => null,
  disabled = false,
}: {
  value: string|null,
  options?: Vehicle[],
  onChange: (v: string|null) => void,
  disabled?: boolean,
}) => {
  const vehicleSort = (a: Vehicle, b: Vehicle) => (
    getCurrentDriverName(a).localeCompare(getCurrentDriverName(b))
  );
  const vehicles = [...(options || [])].sort(vehicleSort);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Velg kjøretøy</InputLabel>
      <Select
        value={value}
        label="Velg kjøretøy"
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        defaultValue="Laster..."
      >
        {vehicles.map((p) => {
          const driver = getCurrentDriver(p);
          return (
            <MenuItem value={p.internalNumber} key={p.internalNumber}>
              {`${driver?.fullName
                ? driver.fullName : 'Navn mangler'} - ${driver?.mobilePhoneWork
                ? driver.mobilePhoneWork : 'telefonnummer mangler'}
                  - ${p.vehicleRegistrationPlateNumber} - ${p.subCategoryName} `}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
