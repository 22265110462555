import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import {
  formatISO,
  compareAsc,
  setHours,
  startOfDay,
  isValid,
} from 'date-fns';
import { toast } from 'react-toastify';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { usePostRentalPeriodMutation } from '../../core/redux/transport';
import { NewRentalPeriod } from '../../core/types/api/newRentalPeriod';
import { Guid } from '../../core/types/guid';
import './style.scss';
import { ensureAfter } from '../../core/helpers/dates';

export const CreateRentalPeriod: FC<{
    currentDate?: Date,
    id: Guid,
    onClose?: () => void;
    }> = ({
      currentDate,
      id,
      onClose = () => null,
    }) => {
      const defaultDay = currentDate ? startOfDay(currentDate) : startOfDay(new Date());

      const [from, setFrom] = useState<Date|null>(setHours(defaultDay, 7));
      const [to, setTo] = useState<Date|null>(setHours(defaultDay, 15));
      const [comment, setComment] = useState<string>('');

      const [send, { isLoading }] = usePostRentalPeriodMutation();

      const valid = useMemo(() => {
        if (from === null || to === null) return false;
        if (compareAsc(from, to) >= 0) return false;
        if (!isValid(from)) return false;
        if (!isValid(to)) return false;
        return true;
      }, [from, to, comment]);

      const submit = () => {
        if (!from) return;
        if (!to) return;
        const body: NewRentalPeriod = {
          rentalVehicleId: id,
          from: formatISO(from),
          to: formatISO(to),
          comment,
        };
        send(body).unwrap().then(() => {
          toast.success('Innleieperiode opprettet');
          onClose();
        });
      };

      const fromError = useMemo(() => {
        if (!from) return 'Fra tid kan ikke være tom';
        if (!isValid(from)) return 'Fra tid er ugyldig';
        if (!to) return null;
        if (compareAsc(from, to) >= 0) return 'Fra tid kan ikke være etter til tid';
        return null;
      }, [from, to]);

      const toError = useMemo(() => {
        if (!to) return 'Til tid kan ikke være tom';
        if (!isValid(to)) return 'Til tid er ugyldig';
        if (!from) return null;
        if (compareAsc(from, to) >= 0) return 'Til tid kan ikke være før fra tid';
        return null;
      }, [from, to]);

      const updateFrom = (time: Date) => {
        setFrom(time);
        setTo((old) => old && ensureAfter(old, time));
      };

      return (
        <div className="create-rental-period-content-component">
          <div className="create-rental-period-content-inner">
            <FormControl fullWidth className="form">
              <DateTimePicker
                label="Fra"
                value={from || new Date()}
                onChange={(date) => date && updateFrom(date)}
                error={fromError}
              />
              <DateTimePicker
                label="Til"
                value={to || new Date()}
                onChange={(date) => date && setTo(date)}
                minDate={from || undefined}
                error={toError}
              />
              <TextField
                multiline
                rows={4}
                label="Kommentar"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>
            <div className="send-buttons">
              <Button variant="outlined" onClick={onClose}>Avbryt</Button>
              <Button
                disabled={!valid || isLoading}
                variant="contained"
                onClick={submit}
              >
                {isLoading
                  ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  ) : 'Opprett'}
              </Button>
            </div>
          </div>
        </div>
      );
    };
