import React, { FC, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  format,
  getUnixTime,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { TaskItem } from './TaskItem';
import { Day } from './Sidebar';
import { LoadingTaskItem } from './LoadingTaskItem';
import { Order } from '../../../core/types/order';
import {
  setShowPeriod,
  setShowPlanned,
  setShowEarlyDelivery,
  ShowPeriod,
  useFilteredOrders,
  useIsFiltersActive,
  useShowEarlyDelivery,
  useShowPeriod,
  useShowPlanned,
} from '../../../core/redux/orderFilter';
import { extractData, getOrderProject } from '../../../core/helpers/functions';
import { OrderFilter } from './OrderFilter';
import { useIsLate } from '../hooks/useIsLate';

export const OrderList: FC<{
  orders: Order[] | undefined | null,
  onSelect: (order: Order) => void,
}> = ({
  orders,
  onSelect,
}) => {
  const filterActive = useIsFiltersActive();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const showPeriod = useShowPeriod();
  const showPlanned = useShowPlanned();
  const showEarlyDelivery = useShowEarlyDelivery();
  const dispatch = useDispatch();

  const filteredOrders = useFilteredOrders(orders || []);
  const days = !orders ? undefined : filteredOrders.reduce((acc: Day[], curr: Order) => {
    const date = new Date(curr.date);
    const day = format(date, 'EEEE do MMM yyyy', { locale: nb });
    const unix = getUnixTime(date);
    const index = acc.findIndex((e) => e.day === day);
    if (index === -1) {
      return [...acc, { unix, day, tasks: [curr] }];
    }
    const arr = [...acc];
    arr[index].tasks.push(curr);
    return arr;
  }, []);

  if (days !== undefined) { days.sort((a, b) => a.unix - b.unix); }

  const sortByProjectMass = (a: Order, b: Order, projectMass: Map<string, number>) => {
    const pa = getOrderProject(a)?.id.toString();
    const pb = getOrderProject(b)?.id.toString();
    const ca = projectMass?.get(pa || '') || 0;
    let cb = projectMass?.get(pb || '') || 0;
    if (ca === cb) {
      if (pa !== pb) {
        if ((pa?.localeCompare(pb || '') || 0) > 0) {
          cb += 1;
        }
      }
    }
    return cb - ca;
  };

  const { isLate } = useIsLate();
  return (
    <div className="tasks-wrapper">
      <div id="external-events">
        <div className="orders-header">
          <div className="title-wrapper">
            <div className="title">
              Bestillinger
            </div>
            <div>
              <Button onClick={() => setShowFilter((v) => !v)} size="small">
                <Badge
                  color="info"
                  variant="dot"
                  invisible={!filterActive}
                  sx={{
                    '& .MuiBadge-badge': {
                      right: 2,
                      top: 6,
                    },
                  }}
                >
                  <FilterListIcon />
                </Badge>
              </Button>
            </div>
            <div>
              <Button onClick={() => window.open('/bestilling')} size="small">
                <AddIcon />
              </Button>
            </div>
          </div>
          <OrderFilter show={showFilter} />

          <Box className="orders-filter-partial" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Box sx={{ flexGrow: 1 }}>
              <FormControl size="small">
                <Select
                  value={showPeriod}
                  onChange={(e) => (dispatch(setShowPeriod(e.target.value as ShowPeriod)))}
                >
                  <MenuItem value="Future">Fremtidige</MenuItem>
                  <MenuItem value="Last7Days">Siste 7 dager</MenuItem>
                  <MenuItem value="Last14Days">Siste 14 dager</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{
              pb: '3px', pl: 2, flexGrow: 0,
            }}
            >
              <FormControlLabel
                sx={{ p: 0, m: 0 }}
                disabled={showPeriod !== 'Future'}
                control={(
                  <Checkbox
                    sx={{ m: 0, p: 0, mr: 0.8 }}
                    size="small"
                    checked={showPlanned}
                    onChange={(e, ns) => (dispatch(setShowPlanned(ns)))}
                  />
)}
                label={<Typography variant="subtitle2"> Vis planlagte</Typography>}
              />
              <FormControlLabel
                sx={{ p: 0, m: 0 }}
                control={(
                  <Checkbox
                    sx={{ m: 0, p: 0, mr: 0.8 }}
                    size="small"
                    checked={showEarlyDelivery}
                    onChange={(e, ns) => (dispatch(setShowEarlyDelivery(ns)))}
                  />
)}
                label={<Typography variant="subtitle2">Kan leveres dagen før</Typography>}
              />
            </Box>
          </Box>
        </div>

        <div className="orders-content">
          {!!days && days.map((day) => {
            const projectMass = day.tasks?.reduce((a, v) => (a.has(getOrderProject(v)?.id.toString())
              ? a.set(
                getOrderProject(v)?.id.toString(),
                a.get(getOrderProject(v)?.id.toString()) + extractData(v).totalAmount,
              )
              : a.set(getOrderProject(v)?.id.toString(), extractData(v).totalAmount)), new Map());
            return (
              <div key={day.day}>
                <div className="day-header">{day.day}</div>
                {day.tasks.sort((a, b) => (
                  extractData(b).totalAmount - extractData(a).totalAmount))
                  .sort((a, b) => sortByProjectMass(a, b, projectMass))
                  .sort((a, b) => ((a.type === 'MassInternal' ? 1 : 0) - (b.type === 'MassInternal' ? 1 : 0)))
                  .sort((a, b) => ((a.assignmentStatus === 'Undelivered' ? 0 : 1) - (b.assignmentStatus === 'Undelivered' ? 0 : 1)))
                  .sort((a, b) => (isLate(a) ? 1 : 0) - (isLate(b) ? 1 : 0))
                  .map((order) => (
                    <TaskItem key={order.id} order={order} onClick={() => onSelect(order)} />
                  ))}
              </div>
            );
          })}
          {
            days !== undefined
              ? null
              : [1, 2, 3, 4, 5].map((x) => (<LoadingTaskItem key={x} />))
          }
        </div>
      </div>
    </div>
  );
};
