import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import {
  addDays,
  differenceInMinutes,
  format,
  isValid,
  roundToNearestMinutes,
} from 'date-fns';
import { When } from '../../core/types/when';
import { WhenType } from '../../core/types/enums/whenType';
import { onlyQuarters } from '../../shared/logic/functions';
import { OrderType } from '../../core/types/enums/orderType';

export const isWhenValid = (when: When|undefined) => {
  if (!when) return false;
  if (!when.when) return false;
  if (when.when !== 'Custom') return true;
  return when.from && when.to;
};

const WhenButton = ({
  id,
  active,
  label,
  onChange,
}: {
  id: WhenType,
  active: WhenType | undefined,
  label: string,
  onChange: (v: WhenType) => void,
}) => (
  <Button
    variant={id === active ? 'contained' : 'outlined'}
    onClick={() => onChange(id)}
    fullWidth={false}
    sx={{ whiteSpace: 'nowrap' }}
  >
    {label}
  </Button>
);

const getTimeStr = (d: string|undefined|null) => {
  if (!d) return '';
  const date = new Date(d);
  if (!isValid(date)) return '';
  return format(date, 'HH:mm');
};

export const WhenInput = ({
  value,
  onChange = () => null,
  referenceDate,
  type,
}: {
  value?: When,
  onChange?: (v: When) => void,
  referenceDate: string|undefined,
  type: OrderType|undefined,
}) => {
  const [startInput, setStartInput] = useState<string>(getTimeStr(value?.from));
  const [endInput, setEndInput] = useState<string>(getTimeStr(value?.to));
  const [startDate, setStartDate] = useState<Date|null>(null);
  const [endDate, setEndDate] = useState<Date|null>(null);
  useEffect(() => {
    if (!value?.from) return;
    setStartDate(new Date(value?.from));
  }, [value?.from]);
  useEffect(() => {
    if (!value?.to) return;
    setEndDate(new Date(value?.to));
  }, [value?.to]);

  const refDateObj = useMemo(() => (
    referenceDate ? new Date(referenceDate) : new Date()
  ), [referenceDate]);

  const refDate = useMemo(() => (
    format(referenceDate ? new Date(referenceDate) : new Date(), 'yyyy-MM-dd')
  ), [referenceDate]);

  useEffect(() => {
    setStartInput(getTimeStr(value?.from));
    setEndInput(getTimeStr(value?.to));
  }, [value]);

  const update = (when: WhenType) => {
    if (when !== 'Custom') {
      onChange({ when });
      return;
    }
    onChange({ when, from: startDate?.toISOString(), to: endDate?.toISOString() });
  };

  const updateCustomStart = (e: string | null | undefined) => {
    if (e === null && startDate !== null) {
      setStartDate(new Date(startDate?.toISOString()));
      return;
    }
    if (!e) return;
    if (!value) return;
    const tempTime = new Date(e);
    const timeStr = isValid(tempTime)
      ? format(tempTime, 'HH:mm')
      : e;
    const timeVal = new Date(`${refDate} ${timeStr}`);
    const rounded = roundToNearestMinutes(timeVal, { nearestTo: 15 });
    const end = (endDate && (differenceInMinutes(rounded, endDate) % 1440 >= 0))
      ? new Date(`${format(addDays(refDateObj, 1), 'yyyy-MM-dd')} ${endInput}`)
      : endDate && new Date(`${refDate} ${endInput}`);
    setStartDate(rounded);
    onChange({ ...value, from: rounded.toISOString(), to: end?.toISOString() });
  };

  const updateCustomEnd = (e: string | null | undefined) => {
    if (e === null && endDate !== null) {
      setEndDate(new Date(endDate?.toISOString()));
      return;
    }
    if (!e) return;
    if (!value) return;
    const tempTime = new Date(e);
    const timeStr = isValid(tempTime)
      ? format(tempTime, 'HH:mm')
      : e;
    const timeVal = new Date(`${refDate} ${timeStr}`);
    if (!isValid(timeVal)) return;
    let rounded = roundToNearestMinutes(timeVal, { nearestTo: 15 });
    if (startDate && differenceInMinutes(startDate, rounded) % 1440 >= 0) {
      rounded = addDays(rounded, 1);
    }
    setEndDate(rounded);
    onChange({ ...value, to: rounded.toISOString() });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ButtonGroup fullWidth>
        <WhenButton active={value?.when} onChange={update} id="DuringTheDay" label="I løpet av dagen" />
        {type !== 'MassInternal' && (
          <>
            <WhenButton active={value?.when} onChange={update} id="Morning" label="Morgen" />
            <WhenButton active={value?.when} onChange={update} id="BeforeFood" label="Før mat" />
            <WhenButton active={value?.when} onChange={update} id="AfterFood" label="Etter mat" />
          </>
        )}
        <WhenButton active={value?.when} onChange={update} id="Custom" label="Egendefinert" />
      </ButtonGroup>
      {value?.when === 'Custom' && (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TimePicker
          value={startDate}
          label="Tidligst"
          ampm={false}
          onAccept={updateCustomStart}
          onChange={(e) => setStartInput(getTimeStr(e))}
          shouldDisableTime={onlyQuarters}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Typography sx={{ color: 'grey' }}>
                      {value?.from && format(new Date(value?.from), 'dd.MM.yyyy')}
                    </Typography>
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
              error={value?.from === undefined}
              onBlur={() => updateCustomStart(startInput || null)}
            />
          )}
        />
        <TimePicker
          value={endDate}
          label="Senest"
          ampm={false}
          onAccept={updateCustomEnd}
          onChange={(e) => setEndInput(getTimeStr(e))}
          shouldDisableTime={onlyQuarters}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Typography sx={{ color: 'grey' }}>
                      {value?.to && format(new Date(value?.to), 'dd.MM.yyyy')}
                    </Typography>
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
              error={value?.to === undefined}
              onBlur={() => updateCustomEnd(endInput || null)}
            />
          )}
        />
      </Box>
      )}
    </Box>
  );
};
