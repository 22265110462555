import React, {
  useEffect, useState,
} from 'react';
import {
  Button, Checkbox, FormControlLabel, IconButton, MenuItem,
} from '@mui/material';
import { Map } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useRegions } from '../../../core/hooks/useRegions';
import { setRegionVisibility, useRegionVisibility } from '../../../core/redux/region';
import { PopMenu } from '../../PopMenu';
import { DEFAULT_REGION } from '../../../shared/constants';

interface RegionMenuItem {
    id: number,
    name: string,
    active: boolean,
  }

export const RegionSelector = () => {
  const regions = useRegions();
  const storedRegion = useRegionVisibility();

  const dispatch = useDispatch();

  const [activeRegions, setActiveRegions] = useState<RegionMenuItem[]>([]);

  const getRegions = () => {
    const list: RegionMenuItem[] = [];

    regions.forEach((name, id) => {
      const prevItem = storedRegion.find((sr) => sr.id === id);
      const active = prevItem ? prevItem.active : id === DEFAULT_REGION;
      list.push({ id, name, active });
    });
    setActiveRegions(list);
  };

  useEffect(() => {
    getRegions();
  }, [regions, storedRegion]);

  const updateRegion = async () => {
    await dispatch(setRegionVisibility(activeRegions));
  };

  return (
    <PopMenu
      label="Regioner"
      closeButton={(
        <div className="m10">
          <Button onClick={updateRegion} fullWidth variant="contained">Oppdater</Button>
        </div>
)}
      renderButton={(
        <div>
          <IconButton
            onClick={getRegions}
            edge="end"
            color="inherit"
            aria-label="profile"
          >
            <Map sx={{ width: 36, height: 36 }} />
          </IconButton>
        </div>
            )}
    >
      {activeRegions.map((r) => (
        <MenuItem key={r.id}>
          <FormControlLabel
            label={r.name}
            control={(
              <Checkbox
                checked={r.active}
                onChange={(v) => setActiveRegions((old) => old.map(
                  (o) => (o.id === r.id
                    ? { ...o, active: v.target.checked }
                    : o),
                ))}
              />
                    )}
          />
        </MenuItem>
      ))}
    </PopMenu>
  );
};
