import { FC, useMemo } from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useGetProjectsQuery } from '../../core/redux/transport';
import { useMRUs } from '../../core/hooks/useMRUs';
import { Project } from '../../core/types/project';
import { sortByMRU } from '../../core/helpers/functions';
import './style.scss';

export const ProjectPicker: FC<{
  value?: Project|number|null,
  onChange?: (newValue: Project|null) => void
  label?: string,
  size?: 'medium' | 'small',
  disabled?: boolean,
  required?: boolean,
  showLastUsed?: boolean,
  usePortal?: boolean,
  errorText?: string,
  }> = ({
    value = null,
    disabled = false,
    onChange = (val) => val,
    label = 'Søk etter prosjekt',
    size = 'medium',
    required = false,
    showLastUsed = true,
    usePortal = false,
    errorText = 'Velg et prosjekt',
  }) => {
    const { data: rawProjects = [], isLoading: projectsLoading } = useGetProjectsQuery();

    const project = typeof value === 'number'
      ? rawProjects.find((p) => p.id === value)
      : value;

    const mrus = useMRUs();

    const options = useMemo((): Array<Project & {lastUsed?: string}> => {
      let ps = [...rawProjects];

      // sort by ID
      ps.sort((a, b) => (
        (a.id === 0 ? Infinity : a.id) - (b.id === 0 ? Infinity : b.id)
      ));

      // Sort by recent usage
      ps.sort(sortByMRU(mrus));

      // Map in the lastUsed string
      ps = ps.map((p): Project & {label: string, lastUsed?: string} => {
        const mru = mrus.get(p.id);
        if (!mru) return ({ ...p, label: `${p.id} - ${p.projectName}` });
        return ({ ...p, label: `${p.id} - ${p.projectName}`, lastUsed: format(mru, "dd.MM.yyyy 'kl.' HH:mm") });
      });

      return ps;
    }, [rawProjects, mrus]);

    if (projectsLoading) {
      return (
        <Skeleton
          width="100%"
          height={`${size === 'medium' ? 56 : 40}px`}
          variant="rectangular"
        />
      );
    }

    const fixVal = project
      ? { ...project, label: `${project.id} - ${project.projectName}` }
      : null;

    return (
      <Autocomplete
        disablePortal={!usePortal}
        value={fixVal}
        disabled={disabled}
        isOptionEqualToValue={(a, b) => `${a.id}` === `${b.id}`}
        clearOnBlur
        clearOnEscape
        renderOption={(p, v) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...p} className={`${p.className} option`}>
            <span className="label">{`${v.id} - ${v.projectName}`}</span>
            {showLastUsed && v.lastUsed && (
            <span className="last-used">{`Sist brukt ${v.lastUsed}`}</span>
            )}
          </li>
        )}
        onChange={(e, newValue) => {
          if (disabled) return;
          if (typeof newValue === 'string') return;
          onChange(newValue);
        }}
        options={options}
        fullWidth
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            className="avoid-height-shift"
            label={label}
            helperText={required && value === null ? errorText : null}
            error={required && value === null}
          />
        )}
        size={size}
      />
    );
  };
