import React, {
  FC,
} from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Modal } from '../../../shared/components/modal';
import {
  InvoiceBasis,
  InvoiceBasisRow,
} from '../../../core/types/invoiceBasis';
import { useGetDetailsQuery } from '../../../core/redux/invoicing';
import {
  format,
} from '../../../shared/logic/dates';
import { ExportButton } from './exportButton';

export const InvoiceDetailsModal: FC<{
  invoiceBasis: InvoiceBasis;
  onClose: () => void;
}> = ({
  invoiceBasis,
  onClose,
}) => {
  const { data: details, isLoading } = useGetDetailsQuery({ uid: invoiceBasis.uid });

  const project = (row: InvoiceBasisRow) => {
    const p = row.projectAssignment.project;
    return `${p.id} - ${p.projectName}`;
  };

  return (
    <Modal
      title={`Faktureringsgrunnlag ${format(new Date(invoiceBasis.from), 'dd.MM.yyyy')} - ${format(new Date(invoiceBasis.to), 'dd.MM.yyyy')}`}
      onClose={onClose}
      statusIcon={<ExportButton invoiceBasis={invoiceBasis} />}
      maxWidth="xl"
    >
      { isLoading ? (
        <CircularProgress
          size={36}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Prosjekt</TableCell>
                <TableCell>Anlegg</TableCell>
                <TableCell>Internnummer</TableCell>
                <TableCell>Fra</TableCell>
                <TableCell>Til</TableCell>
                <TableCell>Dager</TableCell>
                <TableCell>Kost per dag</TableCell>
                <TableCell>Totalkost</TableCell>
                <TableCell>Kommentar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details?.rows.map((row) => (
                <TableRow key={row.uid}>
                  <TableCell>{project(row)}</TableCell>
                  <TableCell>{row.projectAssignment.worksite}</TableCell>
                  <TableCell>{row.projectAssignment.internalNumber}</TableCell>
                  <TableCell>{format(new Date(row.start), 'dd.MM.yyyy')}</TableCell>
                  <TableCell>{format(new Date(row.end), 'dd.MM.yyyy')}</TableCell>
                  <TableCell>{row.durationInDays}</TableCell>
                  <TableCell>{row.costPerDay}</TableCell>
                  <TableCell>{row.cost}</TableCell>
                  <TableCell>{row.projectAssignment.comment}</TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Modal>
  );
};
