import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EditRentalVehicle } from '../../../components/EditRentalVehicle/index';

export const EditRentalVehicleModal: FC<{
  internalNumber: string;
  currentDate?: Date;
  onClose: () => void;
}> = ({
  internalNumber,
  currentDate,
  onClose,
}) => (
  <Dialog
    open
    onClose={onClose}
    maxWidth="md"
    fullWidth
  >
    <div className="align-status">
      <div className="left">
        <DialogTitle>Rediger innleiebil</DialogTitle>
      </div>
      <IconButton sx={{ marginRight: '15px' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      <EditRentalVehicle
        id={internalNumber}
        currentDate={currentDate}
        onAction={onClose}
      />
    </DialogContent>
  </Dialog>
);
