import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';

export const Created: FC<{
  time: string;
  name: string;
}> = ({
  time, name,
}) => (
  <Typography variant="caption">
    Opprettet
    {' '}
    {format(new Date(time), "dd.MM.yyyy 'kl.' HH:mm")}
    {` av ${name}`}
  </Typography>
);
