import {
  useEffect,
  useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  ButtonGroup,
} from '@mui/material';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import './style.scss';
import { useGetDieselOrdersQuery } from '../../core/redux/transport';
import { DieselOrderList } from './partials/DieselOrderList';
import { DieselCounter } from './partials/DieselCounter';
import { ShowPeriod } from '../../core/redux/orderFilter';

export const DieselTransportPage = () => {
  const [selection, setSelection] = useState<ShowPeriod>('Future');

  const {
    data: unavailabilities,
    isLoading,
    isFetching,
    refetch,
  } = useGetDieselOrdersQuery(selection);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Page className="diesel-transport-page">
      <Header>Dieseltransport</Header>
      <div className="diesel-transport-wrapper">
        <div className="diesel-transport-area">

          <DieselCounter />

          <Box className="header" pt={1}>
            <span className="header-group">
              <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
                <Button variant={selection === 'Future' ? 'contained' : 'outlined'} onClick={() => setSelection('Future')}>Fremtidige</Button>
                <Button variant={selection === 'Last7Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last7Days')}>siste 7 dager</Button>
                <Button variant={selection === 'Last14Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last14Days')}>siste 14 dager</Button>
              </ButtonGroup>
            </span>
            <span className="header-button">
              <ButtonGroup fullWidth aria-label="outlined create button">
                <Button onClick={() => window.open('/diesel/opprett')} variant="contained"><AddIcon /></Button>
              </ButtonGroup>
            </span>
          </Box>

          <DieselOrderList
            days={unavailabilities}
            isLoading={isLoading || isFetching}
            showPeriod={selection}
          />
        </div>
      </div>
    </Page>

  );
};
