import { Box, Card, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate } from '../../core/helpers/dates';
import { getAssignmentStatusColor } from '../../core/helpers/functions';
import { getAssignmentStatusType } from '../../core/helpers/translate';
import { Order } from '../../core/types/order';
import { Vehicle } from '../../core/types/vehicle';
import { VehicleItem } from '../VehicleItem';
import { useIsType } from '../../core/hooks/useIsType';

export const AssignedVehiclesList = ({
  vehicles,
  order,
} : {
    vehicles: Vehicle[],
    order: Order,
    }) => {
  const isType = useIsType(order?.type);

  return (
    <>
      <div className="info-item">
        <span className="info-label">Tildelte kjøretøy</span>
      </div>
      <div className="vehicle-list-wrapper">
        {
            order && (order?.vehicles === null || order?.vehicles?.length === 0)
              ? <span>Ingen Kjøretøy</span>
              : null
        }
        {!order
          ? <span>Laster...</span>
          : order?.vehicles?.map((v) => {
            const vehicleData = vehicles?.find((vv) => vv.internalNumber === v.id);
            return (
              <div key={v.id + v.driver.name} className="vehicle-list-item">
                <Card>
                  <VehicleItem
                    driver={v.driver ? {
                      fullName: v.driver.name,
                      mobilePhoneWork: v.driver.phone,
                    } : {
                      fullName: 'Navn mangler',
                      mobilePhoneWork: 'Telefonnnummer mangler',
                    }}
                    registration={vehicleData?.vehicleRegistrationPlateNumber}
                    type={v.type}
                  />
                  <div className="info-item">
                    <span className="info-label">Oppdrag</span>
                  </div>
                  <div className="vehicle-list-assignments-wrapper">
                    <div className="vehicle-list-assignments-item head">
                      <span className="date">Dato</span>
                      <span className="amount">{isType('Container') ? 'Antall' : 'Mengde'}</span>
                      <span className="date-field">
                        Fra-Til
                      </span>
                      <span className="status">Status</span>
                    </div>
                    {v.assignments.map((a) => (
                      <div
                        key={a.id}
                        className={
                          a.status === 'Cancelled'
                            ? 'vehicle-list-assignments-item line-through'
                            : 'vehicle-list-assignments-item'
                        }
                      >
                        <span className="date">{format(new Date(a.startTime), 'dd.MM')}</span>
                        <span className="amount">
                          {isType('Container') ? (
                            <Box display="flex" gap={0.5} alignItems="center">
                              <span>{a.loads.reduce((acc, val) => acc + val.amount, 0)}</span>
                              <Tooltip
                                title={(
                                  <Box display="flex" margin={2} fontSize="16px" gap={1} flexDirection="column">
                                    {isType('ContainerDeliver') ? (
                                      <>
                                        <span>Containertype: {a.loads[0].containerType}</span>
                                        {a.loads[0].type ? <span>{`Massetype: ${a.loads[0].type.name}`}</span> : null }
                                      </>
                                    ) : (
                                      <>
                                        {a.loads.map((l, i) => (
                                          <Box display="flex" key={l.containerInternalNumber} gap={0.5} flexDirection="row">
                                            {a.loads.length > 1 ? <span className="bold">{i + 1}.</span> : null}
                                            <Box display="flex" flexDirection="column">
                                              <span>ContainerID: {l.containerInternalNumber}</span>
                                              {l.wasteType ? <span>{`Avfallstype: ${l.wasteType}`}</span> : null }
                                            </Box>
                                          </Box>
                                        ))}
                                      </>
                                    )}
                                  </Box>
                                )}
                                placement="top"
                              >
                                <InfoIcon cursor="help" color="primary" fontSize="inherit" />
                              </Tooltip>
                            </Box>
                          ) : (
                            a.loads.map((l) => (
                              <div key={l.id}>
                                {`${l.amount}m³`}
                              </div>
                            ))
                          )}
                        </span>
                        <span className="date-field">
                          {formatDate(new Date(a.startTime), 'HH:mm')}-
                          {formatDate(new Date(a.endTime), 'HH:mm')}
                        </span>
                        <span className={`status ${getAssignmentStatusColor(a.status)}`}>
                          {getAssignmentStatusType(a.status)}
                        </span>
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            );
          })}
      </div>
    </>
  );
};
