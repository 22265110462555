import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Card,
  TextField,
  Typography,
  Popover,
  Box,
} from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AltRouteIcon from '@mui/icons-material/AltRoute';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import {
  extractData,
  pluralize,
  getDeliveryTimes,
  sumAssignedLoads,
  getContainerLoadTitle,
  optionalBool,
} from '../../../core/helpers/functions';
import {
  translateVehicleType,
  getTransportType,
} from '../../../core/helpers/translate';
import {
  useDeclineOrderMutation,
  useSetOrderPlannedMutation,
  useGetOrderQuery,
  useCompleteOrderMutation,
} from '../../../core/redux/transport';
import { OrderStatusIcon } from '../../../components/OrderStatusIcon';
import { useIsOrderLate } from '../hooks/useIsLate';
import { Order } from '../../../core/types/order';
import { CallLink } from '../../../components/CallLink';
import { useVehicleList } from '../../../core/hooks/useVehicleList';
import { useAttachDraggable } from '../hooks/useAttachDraggable';
import { AssignedVehiclesList } from '../../../components/AssignedVehiclesList';
import { useIsType } from '../../../core/hooks/useIsType';
import { useIsStatus } from '../../../core/hooks/useIsStatus';
import { EditOrderInfo } from '../../../components/EditOrder';
import { getMachineLabel } from '../../../core/helpers/labelling';
import { useHasRoles } from '../../../core/msal';
import { PlanningDraggable } from './PlanningDraggable';
import { getTypeColor } from '../../../core/helpers/eventRender';

export const OrderInfo: FC<{
  orderId: number,
  onBack?: () => void,
}> = (
  {
    orderId,
    onBack = () => null,
  },
) => {
  const [hasRole] = useHasRoles();

  const { data: vehicles } = useVehicleList();

  const { data: order, isLoading } = useGetOrderQuery(orderId);

  const isType = useIsType(order?.type);

  const [declineOrder] = useDeclineOrderMutation();
  const [setOrderPlanned] = useSetOrderPlannedMutation();
  const [completeOrder] = useCompleteOrderMutation();

  const [focus, setFocus] = useState(false);

  const attachDraggable = useAttachDraggable();

  const orderData = useMemo(() => {
    if (order !== undefined) {
      return extractData(order);
    }
    return null;
  }, [order]);

  const canEdit = useMemo(() => hasRole('admin', 'container-koordinator', 'transport-koordinator'), [hasRole]);

  useEffect(() => {
    if (!canEdit) return;
    attachDraggable();
  }, [canEdit]);

  const canDecline = canEdit && order !== undefined
    && (order.vehicles?.flatMap((v) => v.assignments.filter((a) => !useIsStatus(a.status)('NotDelivered', 'VerifiedNotDelivered', 'Cancelled'))).length || 0) === 0;
  const canSetPlanned = canEdit && !canDecline && order !== undefined
    && (order.status === 'UnderPlanning' || order.status === 'Undelivered');
  const canComplete = canEdit && order !== undefined && (order.assignmentStatus === 'Undelivered' && order.status === 'Planned');
  const isDeclineEnabled = canEdit && order !== undefined && order.status !== 'Declined';
  const { isLate } = useIsOrderLate(order);

  // popover stuff
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleConfirm = (e: Order) => {
    setAnchorEl(null);
    declineOrder(e.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  // popover end

  return (
    <div className="tasks-wrapper order-info">
      <div id="external-events">
        <div className="orders-header">
          <div className="title-wrapper">
            <div>
              <Button size="small" onClick={onBack}>
                <ArrowBackIcon />
              </Button>
            </div>
            <div className="title">
              Bestillingsdetaljer
            </div>
          </div>

        </div>
        <div className="orders-content">
          {!isLoading && order && orderData
              && (
                <div className="task-item">
                  <Card>
                    <div className="info-item">
                      <span className="info-label">Oppdatert</span>
                      <span className="updated-by">{`${order?.lastEditTime
                        ? `${format(new Date(order?.lastEditTime), 'dd.MM.yyyy')} kl. ${format(new Date(order?.lastEditTime), 'HH:mm')}`
                        : 'uspesifisert tidspunkt'} av ${order?.lastEditByName
                        ? order?.lastEditByName : 'uspesifisert person'}`}
                      </span>
                    </div>
                    <div className="header">
                      {orderData.title}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Bestillingstype</span>
                      <span className="info-content">
                        <Chip
                          color={getTypeColor(order.type)}
                          label={getTransportType(order.type)}
                          size="small"
                        />
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Status</span>
                      <span className="info-content">
                        <span className="status">
                          <OrderStatusIcon currStatus={order.status} />
                        </span>
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Bestillingsnummer</span>
                      <span className="info-content">#{order.id}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Innsendt</span>
                      <span
                        className={isLate ? 'info-content info-content-bold' : 'info-content'}
                        title={isLate ? 'Sen innsending' : undefined}
                      >
                        {`${format(new Date(order.createdTimestamp), 'dd.MM.yyyy', { locale: nb })}
                  kl. ${format(new Date(order.createdTimestamp), 'HH:mm', { locale: nb })}`}
                        {isLate && (<>&nbsp;<AlarmIcon fontSize="small" color="error" /></>)}
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Innsender</span>
                      <span className="info-content">{`${order.createdByName}`}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Kontaktperson</span>
                      <Box className="info-content" gap={0.5}>
                        <span>{order.contactPersonName}</span>
                        <div>(<CallLink phoneNumber={order.contactPersonPhoneNumber} />)</div>
                      </Box>
                    </div>
                    {!isType('MassInternal', 'Haul') && order.loads.map((l) => (
                      <div key={l.id} className="info-item">
                        <span className="info-label">Last</span>
                        {order.type.includes('Container') ? (
                          <span className="info-content-bold">
                            {getContainerLoadTitle(order.type, l)}
                          </span>
                        ) : (
                          <span className="info-content-bold">{`${order.vehicles
                            ? sumAssignedLoads(l.type?.id, order)
                            : 0}m³ av ${l.amount}m³ ${l.type?.name}`}
                          </span>
                        )}
                      </div>
                    ))}
                    {isType('Haul') && (
                      <div className="info-item">
                        <span className="info-label">Maskin</span>
                        <span className="info-content-bold">
                          {
                            order.loads?.[0]?.machine
                              ? getMachineLabel(order.loads[0].machine)
                              : (
                                order.loads?.[0]?.machineInternalNumber
                                ?? order.loads?.[0]?.machineExternal
                              )
                          }
                        </span>
                      </div>
                    )}

                    {isType('Mass') && (
                    <div className="info-item">
                      <span className="info-label">Ønsket kjøretøy</span>
                      <span className="info-content">{translateVehicleType(order)}</span>
                    </div>
                    )}

                    {isType('MassInternal', 'ContainerEmptying') ? (
                      <div className="info-item">
                        <span className="info-label">Prosjekt</span>
                        <span className="info-content">
                          {order.toProject
                            ? <a href={`/prosjekter/${order.toProject.id}`}>{`${order.toProject.id} ${order.toProject.projectName}`}</a>
                            : 'N/A'}
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className="info-item">
                          <span className="info-label">Fra prosjekt</span>
                          <span className="info-content">
                            {order.fromProject
                              ? <a href={`/prosjekter/${order.fromProject.id}`}>{`${order.fromProject.id} ${order.fromProject.projectName}`}</a>
                              : 'N/A'}
                          </span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Til prosjekt</span>
                          <span className="info-content">
                            {order.toProject
                              ? <a href={`/prosjekter/${order.toProject.id}`}>{`${order.toProject.id} ${order.toProject.projectName}`}</a>
                              : 'N/A'}
                          </span>
                        </div>
                      </>
                    )}

                    {isType('MassInternal') && (
                      <div>
                        <div className="info-item">
                          <span className="info-label">Bestilt antall kjøretøy</span>
                          <span className="info-content-bold">{`${order.numberOfVehicles}`}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Faktisk antall kjøretøy </span>
                          <span className="info-content-bold">{order.details === undefined ? 0 : order.details.vehicleCount}</span>
                        </div>
                      </div>
                    )}
                    {isType('Container') && (
                      <div className="info-item">
                        <span className="info-label">Antall containere (opprettet/bestilling)</span>
                        <span className="info-content-bold">{`${order.details?.totalAmount || 0}/${orderData.totalAmount}`}</span>
                      </div>
                    )}
                    {isType('MassIn', 'MassOut') && (
                      <>
                        <div className="info-item">
                          <span className="info-label">Antall oppdrag (opprettet/estimert)</span>
                          <span className="info-content-bold">{`${order.details?.assignmentCount || 0}/${orderData.loads}`}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Antall kjøretøy (opprettet/estimert)</span>
                          <span className="info-content-bold">{`${order.details?.vehicleCount || 0}/${orderData.vehicles}`}</span>
                        </div>
                      </>
                    )}

                    <div className="info-item">
                      <span className="info-label">Ønsket leveringsdato</span>
                      <span className="info-content-bold linebreak" title={order.earlyDelivery ? 'Kan leveres dagen før' : undefined}>
                        {format(new Date(order.date), 'dd.MM.yyyy')}
                        {order.earlyDelivery && (<>&nbsp;<AltRouteIcon fontSize="small" color="primary" /></>)}
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="info-label">Ønsket leveringstidspunkt</span>
                      <span className="info-content-bold linebreak">
                        {getDeliveryTimes(order)}
                      </span>
                    </div>
                    {order.importantDeadline && (
                      <div className="info-item">
                        <span className="info-label" />
                        <span className="info-content">
                          <span className="important-deadline-orderinfo" title="Viktig tidsfrist!">Viktig frist!</span>
                        </span>
                      </div>
                    )}
                    {!isType('MassInternal', 'Haul') && (
                    <div className="info-item">
                      <span className="info-label">Ekstra tid i anlegget</span>
                      <span className="info-content">{`${pluralize(order.extraTimeInFacility || 0, 'time', 'r')} `}</span>
                    </div>
                    )}
                    {isType('Haul') && (
                    <div className="info-item">
                      <span className="info-label">Kode for port</span>
                      <span className="info-content-bold">{order.gateCode ?? 'Nei'}</span>
                    </div>
                    )}
                    {isType('Haul') && (
                    <div className="info-item">
                      <span className="info-label">Ekstra utstyr</span>
                      <span className="info-content-bold">{order.extraEquipment ?? 'Nei'}</span>
                    </div>
                    )}
                    {isType('Haul') && (
                    <div className="info-item">
                      <span className="info-label">Har dispensasjon</span>
                      <span className="info-content-bold">{optionalBool(order.hasDispensation)}</span>
                    </div>
                    )}
                    {isType('Haul') && (
                    <div className="info-item">
                      <span className="info-label">Kan tas på dagtid</span>
                      <span className="info-content-bold">{order.daytimeDelivery ? 'Ja' : 'Nei'}</span>
                    </div>
                    )}
                    <div className="mr10 mt20 mb10">
                      <span className="flexchild">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Bestillingskommentar"
                          variant="filled"
                          fullWidth
                          multiline
                          maxRows={4}
                          value={order.comment || ''}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </span>
                      {canEdit && (
                        <div>
                          <Button variant="contained" onClick={() => setFocus(true)} sx={{ m: 0.5 }}>Rediger</Button>
                        </div>
                      )}
                    </div>
                    <div className="info-item">
                      { canDecline && (
                      <Button variant="contained" onClick={handleClick} sx={{ m: 0.5 }} disabled={!isDeclineEnabled}>Avslå</Button>
                      )}
                      { canSetPlanned && (
                      <Button variant="contained" onClick={() => setOrderPlanned(order.id)} sx={{ m: 0.5 }} disabled={!isDeclineEnabled}>Ferdig planlagt</Button>
                      )}
                      { canComplete && (
                      <Button variant="contained" onClick={() => completeOrder(order.id)} sx={{ m: 0.5 }} disabled={!isDeclineEnabled}>Fullfør</Button>
                      )}
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      elevation={8}
                      transitionDuration="auto"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <div className="m10">
                        <div className="ml5 mb10">
                          <span className="bold-text font-size-17">Er du sikker?</span>
                          <div className="mt5">
                            <span className="font-size-15 italic">
                              Du vil nå avslå bestillingen og den vil bli<br />
                              fjernet fra oversikten. Ikke reversebart
                            </span>
                          </div>
                        </div>
                        <div className="text-align-end">
                          <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
                          <Button
                            sx={{ m: 0.75 }}
                            variant="contained"
                            onClick={() => handleConfirm(order)}
                          >Avslå
                          </Button>
                        </div>
                      </div>
                    </Popover>
                    {canEdit && (
                      <>
                        <div className="info-item">
                          <span className="info-label">Planleggingsbrikke</span>
                        </div>
                        <PlanningDraggable
                          isType={isType}
                          order={order}
                          orderData={orderData}
                        />
                      </>
                    )}
                    <AssignedVehiclesList vehicles={vehicles || []} order={order} />
                  </Card>
                </div>
              )}
          {!isLoading && !order && (
            <Typography
              sx={{
                marginTop: '1rem',
                marginLeft: '1rem',
                marginBottom: 'auto',
              }}
              color="error"
            >Kan ikke finne ordre {orderId}!
            </Typography>
          )}
          {isLoading && (
          <CircularProgress
            size={50}
            sx={{
              margin: 'auto',
              marginTop: '1rem',
              marginBottom: 'auto',
            }}
          />
          )}
          <EditOrderInfo
            orderId={orderId}
            open={focus}
            onClose={() => setFocus(false)}
          />
        </div>
      </div>
    </div>
  );
};
