import { Box } from '@mui/material';
import { DISABLED_COLOR } from '../../../../shared/constants';

export const Divider = () => (
  <Box sx={{
    height: 0,
    borderTopColor: DISABLED_COLOR,
    borderTopWidth: 0.75,
    borderTopStyle: 'solid',
    flex: 1,
  }}
  />
);
