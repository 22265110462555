import { ConfigurationSettings, createConfig } from '.';
import { getEnvironmentName } from '../../localEnvironment';
import { EnvironmentName } from '../types/enums/environmentName';

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3004',
  BACKEND_URL: '',
  CLIENT_ID: '969d7589-76a4-4fea-9cc7-3c5d5b95ba8c',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://delightful-island-036696f03.2.azurestaticapps.net',
  BACKEND_URL: '',
  CLIENT_ID: '969d7589-76a4-4fea-9cc7-3c5d5b95ba8c',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://bestilling-test.tsmaskin.no/',
  BACKEND_URL: '',
  CLIENT_ID: 'cd1073b3-f3b7-4180-9954-9698adf7a684',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://bestilling.tsmaskin.no/',
  BACKEND_URL: '',
  CLIENT_ID: 'abdec801-3ab6-425f-99c2-d591b142a6fc',
};

export const getEnvironment = (
  envName: EnvironmentName,
): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
