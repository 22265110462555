import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SearchSelectDirect } from '../../shared/components/searchSelect';
import { UpdateLoad } from '../../core/types/api/updateLoad';
import { OrderType } from '../../core/types/enums/orderType';
import { SelectItem } from '../../shared/types/util/selectItem';
import {
  useGetContainerTypesQuery,
  useGetContainerWasteTypesQuery,
  useGetMassTypesQuery,
  useGetProjectContainersQuery,
} from '../../core/redux/transport';
import { useIsOrderType } from '../../core/hooks/useIsType';
import { NumberAdornment } from '../../components/NumberAdornment';

export const useDuplicateContainers = (type: OrderType|undefined, loads: UpdateLoad[]) => {
  const duplicates = useMemo(() => {
    if (type !== 'ContainerDeliver') return [];
    if (!loads) return [];
    return (
      loads.filter((c) => (
        loads.some((o) => (
          o.id !== c.id
          && o.containerType === c.containerType
          && o.massTypeId === c.massTypeId
        ))
      ))
        .map((c) => c.id)
    );
  }, [loads]);
  return duplicates;
};

export const useIsContainersValid = (type: OrderType|undefined, loads: UpdateLoad[]) => {
  const isType = useIsOrderType(type);
  const duplicates = useDuplicateContainers(type, loads);
  if (!isType('Container')) return true;
  if (duplicates.length > 0) return false;
  if (type === 'ContainerDeliver') return !loads.some((l) => l.amount <= 0 || !l.containerType || l.containerType === '');
  return !loads.some((l) => l.amount <= 0 || (!l.containerInternalNumber && !l.containerExternal));
};

export const ContainerPicker = ({
  value,
  onChange,
  type,
  fromProjectId,
}: {
  value: UpdateLoad[],
  onChange: (v: UpdateLoad[]) => void,
  type: OrderType,
  fromProjectId?: number|undefined,
}) => {
  const { data: massTypes } = useGetMassTypesQuery();
  const { data: containerTypes } = useGetContainerTypesQuery();
  const { data: wasteTypes } = useGetContainerWasteTypesQuery();
  const { data: exContainers } = useGetProjectContainersQuery(fromProjectId ?? skipToken);

  const duplicates = useDuplicateContainers(type, value);

  const setVal = (itemId: number|undefined, val: Partial<UpdateLoad>) => {
    if (!value) return;
    if (!itemId) return;
    onChange(value.map((c) => (c.id === itemId ? { ...c, ...val } : c)));
  };

  const containerList = useMemo(() => {
    const list: SelectItem<string>[] = [];
    if (type === 'ContainerDeliver' && massTypes && containerTypes) {
      containerTypes.forEach((c) => (list.push({ id: c, label: c })));
    }
    if ((type === 'ContainerCollect' || type === 'ContainerEmptying') && wasteTypes && exContainers) {
      exContainers.forEach((c) => (list.push({ id: c.internalNumber, label: c.internalNumber, disabled: c.assignmentId !== undefined || c.orderId !== undefined })));
    }
    return list;
  }, [containerTypes, exContainers, massTypes, type]);

  const massList = useMemo(() => {
    const list: SelectItem<number>[] = [{ id: 0, label: '(tom)' }];
    if (type === 'ContainerDeliver' && massTypes && containerTypes) {
      massTypes.filter((m) => m.type === 'Inn').forEach((m) => (list.push({ id: parseInt(m.id, 10), label: m.name })));
    }
    if ((type === 'ContainerCollect' || type === 'ContainerEmptying') && wasteTypes && exContainers) {
      wasteTypes.forEach((m, i) => (list.push({ id: i + 1, label: m })));
    }
    return list;
  }, [type, massTypes, containerTypes, wasteTypes, exContainers]);

  const setAmount = (itemId: number|undefined, v: string) => {
    if (itemId === undefined) return;
    const amount = v === '' ? 0 : parseInt(v, 10);
    if (Number.isNaN(amount)) return;
    setVal(itemId, { amount });
  };

  const addContainer = (external: boolean = false) => {
    if (!value) return;
    const itemId = (value[value.length - 1]?.id ?? 0) + 1;
    if (!itemId) return;
    onChange(
      [
        ...value,
        {
          id: itemId,
          containerExternal: external ? '' : undefined,
          containerInternalNumber: undefined,
          containerType: undefined,
          amount: 1,
          massTypeId: undefined,
        },
      ],
    );
  };

  const removeContainer = (itemId: number|undefined) => {
    if (!value) return;
    if (!itemId) return;
    onChange(value.filter((f) => f.id !== itemId));
  };

  const containersHasDuplicat = (duplicates !== null && duplicates.length > 0);

  const disableAddContainer = (type !== 'ContainerDeliver'
    && value
    && value.filter((v) => !v.containerExternal !== undefined && !v.containerInternalNumber).length
    >= containerList.filter((c) => !c.disabled).length)
    || containersHasDuplicat;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {value.map((c, i) => (
        <Box
          key={c.id}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            flex: 1,
            '.item': {
              flex: 1,
            },
          }}
        >
          <div className="item">
            { i === 0 && (<Typography>{type === 'ContainerDeliver' ? 'Container type' : 'Container'}</Typography>)}
            {c.containerExternal !== undefined ? (
              <TextField
                label="Ekstern container"
                fullWidth
                size="small"
                helperText={!c.containerExternal ? 'Skriv inn ekstern container' : ''}
                error={!c.containerExternal}
                required
                value={c.containerExternal}
                onChange={(e) => setVal(c.id, { containerExternal: e.currentTarget.value })}
              />
            ) : (
              <SearchSelectDirect
                label=""
                size="small"
                errorLabel={duplicates?.includes(c.id) ? 'Duplikat' : 'Velg en container'}
                forceError={duplicates?.includes(c.id)}
                required
                value={type === 'ContainerDeliver' ? c.containerType : c.containerInternalNumber}
                onChange={(p) => setVal(c.id, type === 'ContainerDeliver' ? { containerType: p } : { containerInternalNumber: p })}
              >
                {containerList.filter((cl) => (!value.some((v) => `${v.containerInternalNumber}` === cl.id)))}
              </SearchSelectDirect>
            )}
          </div>
          {type === 'ContainerDeliver' && (
          <div className="item">
            { i === 0 && (<Typography>Antall</Typography>)}
            <TextField
              size="small"
              fullWidth
              value={c.amount === 0 ? '' : c.amount}
              onChange={(e) => setAmount(c.id, e.currentTarget.value)}
              onBlur={() => c.amount < 1 && setAmount(c.id, '1')}
              error={c.amount === 0}
              InputProps={{
                endAdornment: (
                  <NumberAdornment
                    units="stk"
                    value={c.amount}
                    onChange={(v) => setAmount(c.id, `${v}`)}
                    min={1}
                  />
                ),
              }}
            />
          </div>
          )}
          <div className="item">
            { i === 0 && (<Typography>{type === 'ContainerDeliver' ? 'Masse' : 'Avfallstype'}</Typography>)}
            <SearchSelectDirect
              label=""
              size="small"
              errorLabel={type === 'ContainerDeliver'
                ? `${duplicates?.includes(c.id) ? 'Duplikat' : 'Velg masse'}`
                : 'Velg avfallstype'}
              forceError={duplicates?.includes(c.id)}
              required
              value={c.massTypeId || 0}
              onChange={(p) => { setVal(c.id, { massTypeId: p }); }}
            >
              {massList}
            </SearchSelectDirect>
          </div>
          {value.length > 1 && (
          <Box sx={{ pt: i === 0 ? '25px' : '1px' }}>
            <Button
              className="delete-btn"
              size="medium"
              color="error"
              variant="contained"
              onClick={() => removeContainer(c.id)}
            >
              Slett
            </Button>
          </Box>
          )}
        </Box>
      ))}
      <Box display="flex" gap={1} className="add-btn">
        <Button
          sx={{ flex: 1 }}
          size="small"
          disabled={disableAddContainer}
          variant="contained"
          onClick={() => addContainer()}
        >
          Legg til flere
        </Button>
        {type !== 'ContainerDeliver' && (
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            onClick={() => addContainer(true)}
          >
            Legg til ekstern
          </Button>
        )}
      </Box>
    </Box>
  );
};
