import { useMemo } from 'react';
import { UpdateOrder } from '../../../core/types/api/updateOrder';
import { isWhenValid } from '../../WhenInput';
import { useIsType } from '../../../core/hooks/useIsType';

/** Check if all order fields are valid */
export const useIsFormValid = (data: Partial<UpdateOrder>) => {
  const isValid = useMemo(() => {
    const isType = useIsType(data.type);
    if (!isWhenValid(data.when)) return false;
    const project = isType('MassOut', 'ContainerCollect', 'ContainerEmptying')
      ? data.fromProject
      : data.toProject;
    if (!project) return false;
    if (isType('Haul')) {
      if (data.gateCode === '') return false;
      if (data.extraEquipment === '') return false;
      if (data.fromProject === undefined) return false;
      if (data.toProject === undefined) return false;
    }
    return true;
  }, [data]);
  return isValid;
};
