import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import './style.scss';
import { CreateRentalVehicle } from '../../components/CreateRentalVehicle/index';
import { PageContent } from '../../components/PageContent';

export const CreateRentalVehiclePage = () => {
  const navigate = useNavigate();

  return (
    <Page className="create-rental-vehicle-page">
      <Header onBack={() => navigate('/innleiebiler')}>Opprett Innleiebil</Header>
      <PageContent>
        <CreateRentalVehicle onClose={() => navigate('/innleiebiler')} />
      </PageContent>
    </Page>

  );
};
