import { useGetProjectsQuery } from '../redux/transport';
import { DEFAULT_DEPO } from '../../shared/constants';

/** Fetch default depo data from the API */
export const useDefaultDepo = (skip: boolean = false) => {
  const { data: project } = useGetProjectsQuery(undefined, {
    selectFromResult: (obj) => ({
      data: obj.data?.find((p) => p.id === DEFAULT_DEPO),
    }),
    skip,
  });
  return project;
};
