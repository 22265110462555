import { MouseEventHandler, useState } from 'react';
import { Button, Popover } from '@mui/material';
import { Assignment } from '../../core/types/assignment';

/** Provides a link to the order and a failsafe in case the user hasn't saved */
export const OrderLink = ({
  assignment = null,
  isUpdated,
  saveDisabled,
  onCancel = () => null,
  onConfirm = () => null,
  disabled = false,
}: {
  assignment?: Assignment|null,
  isUpdated: boolean,
  saveDisabled: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  disabled?: boolean,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element|null>(null);
  const handleClick: MouseEventHandler = (event) => {
    if (!isUpdated) {
      onConfirm();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const close = () => setAnchorEl(null);

  const confirm = () => {
    close();
    onConfirm();
  };

  const cancel = () => {
    close();
    onCancel();
  };

  return (
    <div>
      <Button disabled={disabled} onClick={disabled ? undefined : handleClick}>
        {`Bestilling #${assignment?.order?.id || ''}`}
      </Button>
      <Popover
        id={anchorEl ? 'popover' : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={close}
        elevation={8}
        transitionDuration="auto"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="m10">
          <div className="ml5 mb10">
            <span className="bold-text font-size-17">
              {saveDisabled ? (
                'Du har ugyildig verdi i input'
              ) : (
                'Du har ulagrede endringer'
              )}
            </span>
          </div>
          <div>
            <Button
              variant="outlined"
              onClick={close}
              sx={{ m: 0.75 }}
            >
              Avbryt
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={cancel}
              sx={{ m: 0.75 }}
            >
              Forkast Endringer
            </Button>
            <Button
              variant="contained"
              disabled={saveDisabled}
              onClick={confirm}
              sx={{ m: 0.75 }}
            >
              Lagre Endringer
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
