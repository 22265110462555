import React, {
  FC,
} from 'react';
import { Container } from '../../../core/types/container';
import { Tabs } from '../../../shared/components/tabs';
import { Modal } from '../../../shared/components/modal';
import { ContainerInvoicing } from './ContainerInvoicing';
import { ContainerDetails } from './ContainerDetails';

export const ContainerDetailsModal: FC<{
  container: Container;
  onClose: () => void;
}> = ({
  container,
  onClose,
}) => (
  <Modal
    title={`Container ${container.internalNumber}`}
    onClose={onClose}
    maxWidth="xl"
  >
    <Tabs tabs={[
      { id: 'info', title: 'Detaljer', content: <ContainerDetails container={container} /> },
      { id: '', title: 'Fakturering', content: <ContainerInvoicing container={container} /> },
    ]}
    />
  </Modal>
);
