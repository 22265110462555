import { TextField } from '@mui/material';

export const CommentBox = ({
  label = 'Kommentar',
  value,
  onChange = () => null,
  disabled = false,
}: {
  label?: string,
  value?: string|null,
  onChange?: (v: string) => void,
  disabled?: boolean,
}) => (

  <TextField
    label={label || ''}
    fullWidth
    multiline
    disabled={disabled}
    maxRows={4}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);
