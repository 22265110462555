import React, { FC } from 'react';
import { Order } from '../../../core/types/order';
import { OrderList } from './OrderList';
import { OrderInfo } from './OrderInfo';
import { useUrl } from '../../../shared/hooks/params';

interface SidebarProps {
  orders: Order[] | null,
}

interface Day {
    day: string,
    unix: number,
    tasks: Order[],
  }

export const Sidebar: FC<SidebarProps> = ({ orders }) => {
  const [{ id }, change, remove] = useUrl();
  const orderId = parseInt(id, 10);

  return (
    <div>
      <div style={{ display: id ? 'none' : 'block' }}>
        <OrderList orders={orders} onSelect={(o) => change('/transportplanlegger', { id: o.id })} />
      </div>
      {!id
        ? null
        : <OrderInfo orderId={orderId} onBack={() => remove('/transportplanlegger', ['id'])} />}
    </div>
  );
};

export type {
  Day,
};
