import React, { FC, ReactNode } from 'react';
import './style.scss';

/**
 * Centers and gives max width to child components
 */
export const PageContent: FC<{children: ReactNode}> = ({ children }) => (
  <div className="page-content-component">
    <div className="page-content-inner">
      {children}
    </div>
  </div>
);
