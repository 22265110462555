import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import './style.scss';
import { CreateUnavailability } from '../../components/CreateUnavailability';

export const CreateUnavailabilityPage = () => {
  const navigate = useNavigate();

  return (
    <Page className="create-unavailability-page">
      <Header onBack={() => navigate('/utilgjengelighet')}>Opprett Utilgjengelighet</Header>
      <CreateUnavailability onClose={() => navigate('/utilgjengelighet')} />
    </Page>

  );
};
