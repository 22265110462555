import React, { FC, useMemo } from 'react';

import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // In progress
import CheckIcon from '@mui/icons-material/Check'; // Completed
import ClearIcon from '@mui/icons-material/Clear'; // Canceled
import TripOriginIcon from '@mui/icons-material/TripOrigin'; // received
import BuildIcon from '@mui/icons-material/BuildCircle'; // received
import Brightness1Icon from '@mui/icons-material/Brightness1'; // Generic
import { getEventColorForDriver } from '../../core/helpers/eventRender';

import { AssignmentStatus } from '../../core/types/enums/assignmentStatus';
import { translateAssignmentStatusDriver } from '../../core/helpers/translate';

export const AssignmentStatusDriver: FC<{
  status: AssignmentStatus,
  dotonly?: boolean,
  serviceType?: string,
}> = ({
  status,
  dotonly = false,
  serviceType,
}) => {
  const Icon = useMemo(() => {
    switch (status) {
      case 'Approved': return TripOriginIcon;
      case 'Started': return LocalShippingIcon;
      case 'VerifiedNotDelivered':
      case 'NotDelivered':
        return ClearIcon;
      case 'Completed': return CheckIcon;
      case 'VehicleService': return BuildIcon;
      default: return Brightness1Icon;
    }
  }, [status]);
  const color = useMemo(() => getEventColorForDriver(status), [status]);

  return (
    <span className="status-partial">
      <Icon className="status-icon" sx={{ color }} /> {dotonly || translateAssignmentStatusDriver(status, serviceType)}
    </span>
  );
};
