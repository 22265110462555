import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  addDays,
  compareAsc,
  isValid,
  startOfDay,
  subDays,
} from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ChooseContactPerson, ContactPerson } from '../../../components/ContactPerson';
import { ProjectPicker } from '../../../components/ProjectPicker';
import { RadioYesNo } from '../../../components/RadioYesNo';
import { SearchSelectDirect } from '../../../shared/components/searchSelect';
import { order2UpdateOrder } from '../../../core/helpers/converters';
import { useIsType } from '../../../core/hooks/useIsType';
import { usePutDieselOrderMutation } from '../../../core/redux/transport';
import { Project } from '../../../core/types/project';
import { dateWithoutTimezone } from '../../../shared/logic/dates';
import { Order } from '../../../core/types/order';
import { config } from '../../../shared/config/transport';
import { RYFYLKE_REGION } from '../../../shared/constants';
import { weekdays } from '../../../shared/logic/weekdays';
import { useHasAppRoles } from '../../../shared/hooks/hasAppRole';
import { dieselTypeItems } from '../../../shared/types/dieselType';
import { InnsendingLinje } from '../../../components/InnsendingLinje';

export const EditOrder = ({
  order,
  onEndEdit: onEditEnd,
}: {
  order: Order | undefined,
  onEndEdit?: () => void,
}) => {
  const [putDieselOrder, { isLoading: putLoading }] = usePutDieselOrderMutation();

  const isType = useIsType(order?.type);

  const loadCount = order?.loads && (order?.loads.length > 1 || false);

  const [toProject, setToProject] = useState<Project|null>(order?.toProject || null);
  const [orderComment, setOrderComment] = useState(order?.comment || null);
  const [deliveryDate, setDeliveryDate] = useState<Date|null>(order?.date ? new Date(order?.date) : null);
  const [primaryLoad, setPrimaryLoad] = useState({
    id: order?.loads[0]?.id ?? null,
    name: order?.loads[0]?.type?.name ?? '',
    amount: order?.loads[0]?.amount ?? null,
    dieselContainerCount: order?.loads[0]?.dieselContainerAmount ?? null,
    dieselType: order?.loads[0]?.dieselType ?? null,
  });
  const [contactPerson, setContactPerson] = useState<ContactPerson | null>({
    name: order?.contactPersonName || '',
    number: order?.contactPersonPhoneNumber || '',
    employeeNumber: order?.contactPersonEmployeeNumber || undefined,
  });

  const [gateCode, setGateCode] = useState<string|undefined>(order?.gateCode);
  const [gateCodeRequired, setGateCodeRequired] = useState<boolean>(!!order?.gateCode);

  const [isEarlyDelivery, setIsEarlyDelivery] = useState<boolean>(order?.earlyDelivery || false);

  useEffect(() => {
    setToProject(order?.toProject || null);
    setIsEarlyDelivery(order?.earlyDelivery || false);
    setOrderComment(order?.comment || null);

    setDeliveryDate(order?.date ? new Date(order?.date) : null);

    setPrimaryLoad({
      id: order?.loads[0]?.id || null,
      name: order?.loads[0]?.type?.name || '',
      amount: order?.loads[0]?.amount || null,
      dieselContainerCount: order?.loads[0]?.dieselContainerAmount ?? null,
      dieselType: order?.loads[0]?.dieselType ?? null,
    });
    setContactPerson({
      name: order?.contactPersonName || '',
      number: order?.contactPersonPhoneNumber || '',
      employeeNumber: order?.contactPersonEmployeeNumber || undefined,
    });

    setGateCode(order?.gateCode);
    setGateCodeRequired(!!order?.gateCode);
  }, [order, loadCount]);

  const isDiesel = isType('Diesel');
  const today = startOfDay(new Date());

  const updatePrimaryLoad = (v: Partial<typeof primaryLoad>) => {
    setPrimaryLoad((old) => ({ ...old, ...v }));
  };

  /** List of allowed days for diesel */
  const reqDays = useMemo((): number[] => (
    toProject?.region === RYFYLKE_REGION ? [
      weekdays.wednesday,
    ] : [
      weekdays.tuesday,
      weekdays.thursday,
    ]
  ), [toProject]);
  const isDieselCoordinator = useHasAppRoles(config, 'diesel-koordinator');

  /** True if date should be disabled in the date picker */
  const shouldDisableDate = (d: Date) => {
    if (!isDiesel) return false;
    if (isDieselCoordinator) return false;
    return !reqDays.includes(d.getDay());
  };

  const minDate = useMemo(() => {
    if (isDiesel && isDieselCoordinator) return today;
    const limit = new Date();
    limit.setHours(12, 0, 0, 0);
    if (deliveryDate && compareAsc(new Date(), limit) === 1) {
      return addDays(today, 2);
    }
    return addDays(today, 1);
  }, [today, isDiesel, isDieselCoordinator]);

  const dateValid = () => {
    if (!deliveryDate) return false;
    if (isDiesel && isDieselCoordinator) return true;
    return compareAsc(new Date(), subDays(deliveryDate.setHours(12, 0, 0, 0), 1)) === 1;
  };

  const isFormOk = () => {
    if (isDiesel) {
      if ((toProject === null)) return false;
      if ((contactPerson === null || contactPerson.name === '' || contactPerson.number === '')) return false;
      if (!deliveryDate) return false;
      if (shouldDisableDate(deliveryDate)) return false;
      if (primaryLoad === null) return false;
      if (primaryLoad.dieselType === null) return false;
      if (!primaryLoad.dieselContainerCount || primaryLoad.dieselContainerCount < 1) return false;
      if (!primaryLoad.amount || primaryLoad.dieselContainerCount < 1) return false;
      if ((dateValid() === false)) return false;
    }
    return true;
  };

  const updateOrderHandler = () => {
    if (order === undefined) return;
    const putData = order2UpdateOrder(order);
    if (putData.type !== 'Diesel') return;
    putData.load = {
      id: primaryLoad.id ?? undefined,
      amount: primaryLoad.amount ?? 0,
      dieselContainerAmount: primaryLoad.dieselContainerCount ?? undefined,
      dieselType: primaryLoad.dieselType ?? undefined,
    };
    putData.date = deliveryDate ? dateWithoutTimezone(deliveryDate) : '';
    putData.earlyDelivery = isEarlyDelivery;
    putData.comment = orderComment || '';
    putData.contactPersonEmployeeNumber = contactPerson?.employeeNumber;
    putData.contactPersonName = contactPerson?.name ?? '';
    putData.contactPersonPhoneNumber = contactPerson?.number ?? '';
    putData.gateCode = gateCode;

    putDieselOrder({
      id: `${order.id}`,
      data: putData,
    })
      .then(() => onEditEnd?.())
      .catch(() => toast.error('Kunne ikke oppdatere bestilling, prøv igjen'));
  };

  const getDieselHelperText = (value: number | null | undefined) => {
    if (value === null || value === undefined) return 'Dette feltet er påkrevd';
    if (value < 1) return 'Denne verdien må være større enn 0';
    return ' ';
  };

  return (
    <div className="order-task">
      <div className="order-info">
        <div className="info-item-edit">
          <span className="info-label-edit">Prosjekt</span>
          <div className="info-content-edit">
            <ProjectPicker
              value={toProject}
              onChange={(e) => {
                setToProject(e);
              }}
              label="Til prosjekt"
              required
            />
          </div>
        </div>
        <div className="info-item-edit timer-clicker g10">
          <span className="info-label-edit">Bestillingsdetaljer</span>
          <SearchSelectDirect
            value={primaryLoad.dieselType}
            onChange={(v) => updatePrimaryLoad({ dieselType: v ?? null })}
            label="Diesel type"
            required
          >{dieselTypeItems}
          </SearchSelectDirect>
          <TextField
            label="Antall tanker"
            type="number"
            fullWidth
            value={primaryLoad.dieselContainerCount ?? ''}
            onChange={(e) => updatePrimaryLoad({ dieselContainerCount: Math.round(+e.target.value) })}
            required
            error={!primaryLoad.dieselContainerCount || primaryLoad.dieselContainerCount < 1}
            helperText={getDieselHelperText(primaryLoad.dieselContainerCount)}
          />
          <TextField
            label="Estimert antall liter"
            type="number"
            fullWidth
            value={primaryLoad.amount ?? ''}
            onChange={(e) => updatePrimaryLoad({ amount: Math.round(+e.target.value) })}
            required
            error={!primaryLoad.amount || primaryLoad.amount < 1}
            helperText={getDieselHelperText(primaryLoad.amount)}
          />
          <InnsendingLinje text="Kode for port" />
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
            }}
          >
            <RadioYesNo required value={gateCodeRequired} onChange={(v) => setGateCodeRequired(v)} />
            {gateCodeRequired && <TextField required fullWidth value={gateCode || ''} onChange={(e) => setGateCode(e.target.value)} label="Adgangskode" error={!gateCode} />}
          </Box>
        </div>
        <div className="info-item-edit">
          <span className="info-label-edit">Leveringsdato</span>
          <div className="info-content-edit-small">
            <DatePicker
              value={deliveryDate}
              views={['day']}
              minDate={minDate}
              inputFormat="dd.MM.yyyy"
              mask="__.__.____"
              onChange={(e) => (e && isValid(e) ? setDeliveryDate(e) : null)}
              onAccept={(e) => setDeliveryDate(e)}
              shouldDisableDate={shouldDisableDate}
              renderInput={(p) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...p} />
              )}
            />
          </div>
          <FormControlLabel className="pt5" control={<Checkbox checked={isEarlyDelivery} onClick={() => setIsEarlyDelivery(!isEarlyDelivery)} />} label="Kan leveres dagen før" />
        </div>
        <div className="info-item-edit">
          <span className="info-label-edit">Kontaktperson</span>
          <div className="info-content-edit">
            <ChooseContactPerson onChange={(value) => setContactPerson(value)} value={contactPerson} />
          </div>
        </div>
        <div className="info-item-edit">
          <span className="info-label-edit">Bestillingskommentar</span>
          <div className="info-content-edit">
            <TextField id="outlined-multiline-flexible" variant="outlined" fullWidth multiline maxRows={4} onChange={(e) => setOrderComment(e.target.value)} value={orderComment || ''} />
          </div>
        </div>
        <div className="flex g10">
          <Button onClick={() => onEditEnd?.()} fullWidth disabled={putLoading} variant="outlined">Avbryt</Button>
          <Button onClick={updateOrderHandler} fullWidth disabled={!isFormOk() || putLoading} variant="contained">
            {putLoading ? <CircularProgress size={25} /> : 'Bekreft'}
          </Button>
        </div>
      </div>
    </div>
  );
};
