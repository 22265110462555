import { FC, useMemo, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  Popover,
} from '@mui/material';
import { GlobalAssignmentStatus } from '../../core/types/utilityTypes';
import './style.scss';
import { Assignment } from '../../core/types/assignment';
import { useIsType } from '../../core/hooks/useIsType';
import { AssignmentStatus } from '../../core/types/enums/assignmentStatus';

export const StatusButtons: FC<{
  assignment: Assignment,
  isSaveDisabled: boolean,
  updateAssignment: (s: GlobalAssignmentStatus) => void,
  disabled?: boolean,
}> = ({
  assignment,
  isSaveDisabled,
  updateAssignment,
  disabled = false,
}) => {
  const { status, type } = assignment;
  const isType = useIsType(type);

  /** Whether user is not allowed to switch to given status */
  const disabledButtons = useMemo(() => {
    const rec: Record<AssignmentStatus, boolean> = {
      UnderPlanning: false,
      Approved: false,
      Started: false,
      Completed: false,
      Cancelled: false,
      Deleted: false,
      Unavailable: false,
      NotDelivered: false,
      VerifiedNotDelivered: false,
      VehicleService: false,
    };
    if (!isType('Container')) return rec;

    if (isType('ContainerSwap')) {
      rec.Started = assignment.loads.some((l) => !l.actualContainerTwoInternalNumber);
      rec.Completed = assignment.loads.some((l) => !l.actualContainerInternalNumber);
      return rec;
    }
    rec.Started = assignment.loads.some((l) => !l.actualContainerInternalNumber && !l.actualContainerExternal);
    rec.Completed = rec.Started;
    return rec;
  }, [status, type, assignment]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleButton = (s: GlobalAssignmentStatus) => {
    updateAssignment(s);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    handleButton('DeleteOrder');
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleButton('CancelOrder');
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  switch (status) {
    case 'UnderPlanning': return (
      <DialogActions className="buttons-to-left">
        <Button
          variant="contained"
          onClick={(() => handleButton('Deleted'))}
          className={isSaveDisabled ? '' : 'DeletedBackgroundColor'}
          disabled={isSaveDisabled}
        >Slett
        </Button>
        { !isType('Container')
        && (
        <div className="ml5">
          <Button
            aria-describedby={id}
            onClick={handleClick}
            color="error"
            variant="contained"
            disabled={isSaveDisabled}
          >
            Slett alle
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            elevation={8}
            transitionDuration="auto"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <div className="m10">
              <div className="ml5 mb10">
                <span className="bold-text font-size-17">Er du sikker?</span>
                <div className="mt5">
                  <span className="font-size-15 italic">
                    Alle dagens oppdrag under planlegging fra samme<br />
                    bestilling på dette kjøretøyet vil slettes etter lagring.
                  </span>
                </div>
              </div>
              <div className="text-align-end">
                <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
                <Button
                  sx={{ m: 0.75 }}
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >Slett alle
                </Button>
              </div>
            </div>
          </Popover>
        </div>
        )}
        <Button
          variant="contained"
          onClick={(() => handleButton('Approved'))}
          className={isSaveDisabled ? '' : 'ApprovedBackgroundColor'}
          disabled={isSaveDisabled || disabledButtons.Approved}
        >Godkjenn
        </Button>
      </DialogActions>
    );
    case 'Approved': return (
      <DialogActions className="buttons-to-left">
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={1}>
            <Button
              variant="contained"
              onClick={(() => handleButton('Started'))}
              className={disabled ? '' : 'StartedBackgroundColor'}
              disabled={disabledButtons.Started || disabled}
            >Start
            </Button>
            <Button
              variant="contained"
              onClick={(() => handleButton('Completed'))}
              className={disabled ? '' : 'CompletedBackgroundColor'}
              disabled={disabledButtons.Completed || disabled}
            >Fullfør
            </Button>
          </Box>
          <Box display="flex" gap={1} justifySelf="flex-end">
            <Button
              variant="contained"
              onClick={(() => handleButton('NotDelivered'))}
              className={disabled ? '' : 'NotDeliveredBackgroundColor'}
              disabled={disabledButtons.NotDelivered || disabled}
            >Ikke levert
            </Button>
            <Button
              variant="contained"
              onClick={(() => handleButton('Cancelled'))}
              className={disabled ? '' : 'CancelledBackgroundColor'}
              disabled={disabledButtons.Cancelled || disabled}
            >Kanseller
            </Button>
            <div>
              <Button
                aria-describedby={id}
                onClick={handleClick}
                sx={{ bgcolor: '#999999' }}
                variant="contained"
                disabled={disabledButtons.Cancelled || disabled}
              >
                Kanseller alle
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={8}
                transitionDuration="auto"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                <div className="m10">
                  <div className="ml5 mb10">
                    <span className="bold-text font-size-17">Er du sikker?</span>
                    <div className="mt5">
                      <span className="font-size-15 italic">
                        Alle dagens godkjente oppdrag fra samme<br />
                        bestilling på dette kjøretøyet vil kanselleres etter lagring.
                      </span>
                    </div>
                  </div>
                  <div className="text-align-end">
                    <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>Avbryt</Button>
                    <Button
                      sx={{ m: 0.75 }}
                      variant="contained"
                      color="error"
                      onClick={handleCancel}
                    >Kanseller alle
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </Box>
        </Box>
      </DialogActions>
    );
    case 'Started': return (
      <DialogActions className="buttons-to-left">
        <Button
          variant="contained"
          onClick={(() => handleButton('Completed'))}
          className={disabled ? '' : 'CompletedBackgroundColor'}
          disabled={disabledButtons.Completed || disabled}
        >Fullfør
        </Button>
        <Button
          variant="contained"
          onClick={(() => handleButton('NotDelivered'))}
          className={disabled ? '' : 'NotDeliveredBackgroundColor'}
          disabled={disabledButtons.NotDelivered || disabled}
        >Ikke levert
        </Button>
      </DialogActions>
    );
    default: return (
      <DialogActions className="buttons-to-left" />
    );
  }
};
