/* eslint-disable no-param-reassign */
import { useCallback } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useSelector } from 'react-redux';
import { Vehicle } from '../types/vehicle';
import { vehicleCategory2ListName } from '../helpers/converters';

const stateName = 'vehicleFilter';

interface SelectItem {
  id: number | string;
  label: string;
}

interface VehicleFilter {
  vehicleType: SelectItem[];
  showOnlyAvailable: boolean;
  showOnlyRentalVehicles: boolean;
}

const initialState: VehicleFilter = {
  vehicleType: [],
  showOnlyAvailable: false,
  showOnlyRentalVehicles: false,
};

const vehicleFilterSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setVehicleType(state, action: PayloadAction<SelectItem[]>) {
      state.vehicleType = action.payload;
    },
    setShowOnlyAvailable(state, action: PayloadAction<boolean>) {
      state.showOnlyAvailable = action.payload;
    },
    setShowOnlyRentalVehicles(state, action: PayloadAction<boolean>) {
      state.showOnlyRentalVehicles = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const useIsFiltersActive = (): boolean => {
  const {
    vehicleType,
  } = useSelector(
    (state: { [stateName]: VehicleFilter }) => state[stateName],
  );
  return !!(vehicleType.length);
};

export const useShowOnlyAvailable = (): boolean => {
  const {
    showOnlyAvailable,
  } = useSelector(
    (state: { [stateName]: VehicleFilter }) => state[stateName],
  );
  return showOnlyAvailable;
};

export const useShowOnlyRentalVehicles = (): boolean => {
  const {
    showOnlyRentalVehicles,
  } = useSelector(
    (state: { [stateName]: VehicleFilter }) => state[stateName],
  );
  return showOnlyRentalVehicles;
};

export const useFilterVehicles = () => {
  const {
    vehicleType,
    showOnlyRentalVehicles,
  } = useSelector(
    (state: { [stateName]: VehicleFilter }) => state[stateName],
  );

  const filterVehicles = useCallback((vehicle: Vehicle): boolean => {
    if (vehicleType.length) {
      if (!vehicleType.some((t) => (
        vehicleCategory2ListName(vehicle.subCategoryName) === t.id))
      ) return false;
    }
    if (showOnlyRentalVehicles) {
      if (vehicle.group !== 'rental') return false;
    }
    return true;
  }, [vehicleType, showOnlyRentalVehicles]);

  return filterVehicles;
};

export const {
  setVehicleType,
  setShowOnlyAvailable,
  setShowOnlyRentalVehicles,
  reset,
} = vehicleFilterSlice.actions;
export default persistReducer({ key: 'vehicleFilter', storage }, vehicleFilterSlice.reducer);
