import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
import { Vehicle, VehicleAction } from '../../../core/types/vehicle';
import { VehicleItem } from './VehicleItem';

export const renderResource = (
  d: ResourceLabelContentArg,
  onButtonPressed: (action: VehicleAction, id: string, subId?: number) => void,
  onDriverPressed: (id: number, name: string) => void,
  disabled = false,
) => {
  const data = d.resource.extendedProps as Vehicle & {loading?: boolean};
  if (!data) {
    return null;
  }
  return (
    <VehicleItem
      type={data.subCategoryName || ''}
      registration={data.vehicleRegistrationPlateNumber}
      internalNumber={data.internalNumber}
      driver={
          data.assignedDriver?.fullName && data.assignedDriver?.mobilePhoneWork
            ? {
              id: data.assignedDriver.employeeNumber,
              fullName: data.assignedDriver.fullName,
              mobilePhoneWork: data.assignedDriver.mobilePhoneWork
              || '',
              expiredCerts: data.assignedDriver.expiredCertifications,
              missingSecTraining: data.assignedDriver.missingSecurityTraining,
            }
            : undefined
          }
      substitutes={data.substituteDrivers}
      loading={data.loading}
      isRental={data.group === 'rental'}
      isDeleted={data.statusName === 'Deleted'}
      onButtonPressed={onButtonPressed}
      onDriverPressed={onDriverPressed}
      disabled={disabled}
    />
  );
};
