import {
  TypedUseSelectorHook,
  useDispatch as baseUseDispatch,
  useSelector as baseUseSelector,
} from 'react-redux';
import {
  AppDispatch,
  RootState,
} from '../redux';

export const useDispatch = () => baseUseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = baseUseSelector;
