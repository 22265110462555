import {
  addMinutes,
  format,
  getUnixTime,
  isAfter,
  isWithinInterval,
  Locale,
  startOfDay,
// eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { DateTime } from '../types/dateTime';

export interface Options {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 ;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

export function formatDate(date: Date, formatStr = 'PP') {
  return format(date, formatStr, { locale: nb });
}

export const minutesFromMidnight = (time: Date) => (
  Math.round((getUnixTime(time) - getUnixTime(startOfDay(time))) / 60)
);

export const currentlyWithinPeriod = (start: Date, end: Date, now: Date = new Date()) => (
  isWithinInterval(now, { start, end })
);

/**
 * Formats a date to hours and minutes
 *
 * @param time Time as date object or date string
 * @returns Time formatted as HH:mm
 */
export const formatTime = (time: Date | DateTime) => (time instanceof Date ? formatDate(time, 'HH:mm') : formatDate(new Date(time), 'HH:mm'));

export const autoFormat = (date: Date, dateFormat: string = 'HH:mm P', options: Options = {}) => (
  format(date, dateFormat, { locale: nb, ...options })
);

/**
 * Ensures the given date is after the reference date
 * @param date Date to alter
 * @param refDate Date to compare to
 */
export const ensureAfter = (date: Date, refDate: Date): Date => {
  // If the date is after the reference date it's fine
  if (isAfter(date, refDate)) return date;

  // Get the time from each date
  const mins = minutesFromMidnight(date);
  const refMins = minutesFromMidnight(refDate);

  // If time is after reference time, return ref date with time
  if (mins > refMins) {
    return addMinutes(startOfDay(refDate), mins);
  }

  // Time is also before reference date, return reference date + 1 hour
  return addMinutes(refDate, 60);
};
