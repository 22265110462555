import { AssignmentStatus } from '../types/enums/assignmentStatus';

/** Provides a function that checks if the assignment status is one of the given types
 *
 * Note: This is not actually a hook, so it can be used anywhere
 */
export const useIsStatus = (status: AssignmentStatus|null|undefined) => {
  const isStatus = (...statuses: AssignmentStatus[]) => {
    if (!status) return false;
    return statuses.includes(status);
  };

  return isStatus;
};
