import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import './style.scss';
import { format } from 'date-fns';
import { Edit, Delete } from '@mui/icons-material';
import { RentalPeriod } from '../../core/types/rentalPeriod';
import { CreateRentalPeriodModal } from '../CreateRentalPeriodModal';
import { PopConfirm } from '../PopConfirm';
import { useDeleteRentalPeriodMutation } from '../../core/redux/transport';
import { EditRentalPeriodModal } from '../EditRentalPeriodModal/index';
import { Guid } from '../../core/types/guid';

export const EditRentalPeriods: FC<{
  disabled?: boolean,
  data: RentalPeriod[],
  id: Guid,
  currentDate?: Date,
}> = ({
  disabled = false,
  data,
  id,
  currentDate,
}) => {
  const [remove] = useDeleteRentalPeriodMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<number|null>(null);
  const editObj = editId && data.find((d) => d.id === editId);

  return (
    <div className="edit-rental-periods-component">
      <h3 className="header">Innleieperioder</h3>
      <div className="content">

        <div className="content-line header">
          <div className="content-data time-field">
            Fra
          </div>
          <div className="content-data time-field">
            Til
          </div>
          <div className="content-data comment-field">
            Kommentar
          </div>
          <div className="content-data actions-field" />
        </div>
        {data.map((d) => (
          <div className="content-line" key={d.id}>
            <div className="content-data time-field">
              {format(new Date(d.from), "dd.MM.yyyy 'kl.' HH:mm")}
            </div>
            <div className="content-data time-field">
              {format(new Date(d.to), "dd.MM.yyyy 'kl.' HH:mm")}
            </div>
            <div className="content-data comment-field" title={d.comment}>
              {d.comment}
            </div>
            <div className="content-data actions-field">
              <Button
                disabled={disabled}
                onClick={() => setEditId(d.id)}
                sx={{ padding: 0, margin: 0, minWidth: 0 }}
              >
                <Edit />
              </Button>
              <PopConfirm
                disabled={disabled}
                onConfirm={() => remove({ periodId: d.id, vehicleId: d.rentalVehicleId })}
                buttonText={<Delete />}
                content={(
                  <>
                    Vil du slette denne innleieperioden?<br />
                    Det vil ikke være mulig å angre slettingen
                  </>
                )}
                buttonProps={{
                  color: 'error',
                  variant: 'text',
                  sx: { padding: 0, margin: 0, minWidth: 0 },
                }}
              />
            </div>
          </div>
        ))}
        {data.length === 0 && <span className="no-content">Ingen innleieperioder</span>}
      </div>
      <div className="action-area">
        <Button disabled={disabled} variant="contained" onClick={() => setIsOpen(true)}>Legg til</Button>
      </div>
      {isOpen
      && (
      <CreateRentalPeriodModal
        id={id}
        currentDate={currentDate}
        onClose={() => setIsOpen(false)}
      />
      )}
      {editId !== null && editObj
      && (
      <EditRentalPeriodModal
        data={editObj}
        onClose={() => setEditId(null)}
      />
      )}
    </div>
  );
};
