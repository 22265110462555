import { formatISO } from 'date-fns';
import { useMemo, useState, useEffect } from 'react';
import { vehicleCategory2ListItem, rentalVehicle2Vehicle } from '../../../core/helpers/converters';
import { useFilterVehicles } from '../../../core/redux/vehicleFilter';
import { timeWithinDay } from '../../../core/helpers/functions';
import { RentalVehicle } from '../../../core/types/rentalVehicle';
import { useSignalR } from '../../../core/signalR';
import { useGetDayVehiclesQuery } from '../../../core/redux/transport';
import { Vehicle } from '../../../core/types/vehicle';
import { useRegionFilter } from '../../../core/hooks/useRegionFilter';

interface VehicleResource extends Vehicle {
  id: string,
  title: string,
  loading: boolean,
}
interface LoadingResource {
  id: string,
  title: string,
  loading: boolean,
}

export const useResources = (currentDate: Date, skip = false) => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const { data, isFetching, refetch } = useGetDayVehiclesQuery(
    formatISO(currentDate, { representation: 'date' }),
    { refetchOnMountOrArgChange: !skip },
  );
  const filterVehicles = useFilterVehicles();

  useEffect(() => {
    if (data) {
      setVehicles(data);
    }
  }, [data, setVehicles]);

  const addVehicle = (newVehicle: RentalVehicle) => {
    if (newVehicle.rentalPeriods.some((p) => (
      timeWithinDay(new Date(p.from), new Date(p.to), currentDate)
    ))) {
      setVehicles((old) => {
        if (old.some((v) => v.internalNumber === newVehicle.id)) {
          return old;
        }
        return [
          ...old,
          rentalVehicle2Vehicle(newVehicle),
        ];
      });
    }
  };

  const updateVehicle = (newVehicle: RentalVehicle) => {
    setVehicles((vs) => {
      if (newVehicle.rentalPeriods.some((p) => (
        timeWithinDay(new Date(p.from), new Date(p.to), currentDate)
      ))) {
        if (vs.some((v) => v.internalNumber === newVehicle.id)) {
          return vs.map((v) => (
            v.internalNumber === newVehicle.id
              ? rentalVehicle2Vehicle(newVehicle)
              : v
          ));
        }
        return [...vs, rentalVehicle2Vehicle(newVehicle)];
      }
      if (vs.some((v) => v.internalNumber === newVehicle.id)) {
        return vs.filter((v) => v.internalNumber !== newVehicle.id);
      }
      return vs;
    });
    refetch();
  };

  const removeVehicle = (internalNumber: string) => {
    setVehicles((vs) => vs.filter((v) => (
      v.internalNumber !== internalNumber
    )));
    refetch();
  };

  const listeners = useMemo(
    () => [
      { method: 'vehicleCreated', action: addVehicle },
      { method: 'vehicleUpdated', action: updateVehicle },
      { method: 'vehicleDeleted', action: removeVehicle },
    ],
    [currentDate],
  );

  useSignalR({
    url: '/vehicle',
    listeners,
  });

  const regionFilter = useRegionFilter();

  const resources = useMemo((): VehicleResource[] | LoadingResource[] => {
    if (isFetching) {
      return Array.from(Array(50).keys()).map((i) => ({
        id: `loading-${i}`,
        loading: true,
        title: '',
      }));
    } if (vehicles && vehicles.length) {
      return vehicles
        .filter(filterVehicles)
        .filter(regionFilter)
        .map(
          (v: Vehicle) => ({
            ...v,
            id: v.internalNumber,
            title: `${(`0000${vehicleCategory2ListItem(v.subCategoryName).sortOrder}`).slice(-4)} ${v.subCategoryName}`,
            loading: false,
          }),
        );
    }
    return [];
  }, [
    // Remember to include all filters here
    vehicles,
    isFetching,
    filterVehicles,
    regionFilter,
  ]);
  return {
    vehicles,
    resources,
    isFetching,
    refetch,
  };
};
