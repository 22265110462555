import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { RentalPeriod } from '../../core/types/rentalPeriod';
import { EditRentalPeriod } from '../EditRentalPeriod/index';

export const EditRentalPeriodModal: FC<{
  data: RentalPeriod,
  onClose: () => void;
}> = ({
  data,
  onClose,
}) => (
  <Dialog
    open
    onClose={() => {
      onClose();
    }}
    maxWidth="xs"
    fullWidth
  >

    <DialogTitle>Rediger Innleieperiode</DialogTitle>

    <DialogContent>
      <EditRentalPeriod
        data={data}
        onAction={onClose}
      />
    </DialogContent>
  </Dialog>
);
