import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { formatISO, parseISO } from 'date-fns';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DatePicker } from '@mui/x-date-pickers';
import { isSameDay } from 'date-fns/esm';
import { LoadingButton } from '@mui/lab';
import { formatDate } from '../../../shared/logic/dates';
import { ContainerProjectAssignment } from '../../../core/types/containerProjectAssignment';
import { Created } from './Created';
import { LastUpdated } from './LastUpdated';
import { useUpdateContainerProjectAssignmentMutation } from '../../../core/redux/transport';

export const ContainerInvoicingRow: FC<{ row: ContainerProjectAssignment; }> = ({ row }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [from, setFrom] = useState<Date|null>(null);
  const [to, setTo] = useState<Date|null>(null);
  const [comment, setComment] = useState<string>('');
  const [invoiceable, setInvoiceable] = useState<boolean>(false);
  const [update] = useUpdateContainerProjectAssignmentMutation();

  const reset = () => {
    setFrom(new Date(row.from));
    setTo(row.to ? new Date(row.to) : null);
    setComment(row.comment || '');
    setInvoiceable(row.invoiceable);
    setEdit(false);
  };

  useEffect(() => reset(), [row]);

  const save = () => {
    if (from == null) return; // Should not happen!

    setSaving(true);
    update({
      internalNumber: row.container.internalNumber,
      uid: row.uid,
      // TODO: Check timezone!!
      from: formatISO(from, { representation: 'date' }),
      to: to !== null ? formatISO(to, { representation: 'date' }) : null,
      comment,
      invoiceable,
    }).finally(() => setSaving(false));
  };

  const cancel = () => {
    reset();
  };

  const close = () => {
    if (!open) { return; }
    if (edit) setEdit(false);
    // TODO: Handle editing state
    setOpen(false);
  };

  const hasChanges = useMemo(
    () => !edit
        || !isSameDay(from || 0, new Date(row.from))
        || !isSameDay(to || 0, row.to ? new Date(row.to) : 0)
        || comment !== row.comment
        || invoiceable !== row.invoiceable,
    [from, to, comment, invoiceable, edit],
  );

  return (
    <>
      <TableRow
        hover
        className="container-invoicing-row"
        onClick={edit ? undefined : () => setOpen(!open)}
        key={row.uid}
        sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell sx={{ width: '1rem', borderBottom: 'unset' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={edit}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{`${row.project?.id} - ${row.project?.projectName}`}</TableCell>
        <TableCell>
          { edit
            ? (
              <DatePicker
                disabled={saving}
                value={from}
                onChange={(d) => d !== null && setFrom(d)}
                renderInput={(params: any) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...params} />
                )}
              />
            )
            : formatDate(parseISO(row.from))}
        </TableCell>
        <TableCell>
          { edit
            ? (
              <DatePicker
                disabled={row.to === undefined || saving}
                value={to}
                onChange={(d) => d !== null && setTo(d)}
                renderInput={(params: any) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...params} />
                )}
              />
            )
            : row.to && formatDate(parseISO(row.to)) }
        </TableCell>
        { edit
          ? (
            <TableCell>
              <RadioGroup
                row
                value={invoiceable}
                onChange={(e) => setInvoiceable(e.target.value === 'true')}
              >
                <FormControlLabel disabled={saving} value control={<Radio />} label="Ja" />
                <FormControlLabel disabled={saving} value={false} control={<Radio />} label="Nei" />
              </RadioGroup>

            </TableCell>
          )
          : <TableCell>{row.invoiceable ? 'Ja' : 'Nei'}</TableCell>}
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box display="flex" alignItems="stretch" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="row" gap={1}>
                  <TextField
                    label="Kommentar"
                    multiline
                    rows={4}
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={!edit || saving}
                    InputProps={{
                      readOnly: !edit || saving,
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Created name={row.createdByName} time={row.createdTimestamp} />
                  <LastUpdated name={row.updatedByName} time={row.updatedTimestamp} />
                </Box>
                <Box display="flex" flexDirection="row-reverse" gap={1} flexGrow={1}>
                  { edit
                    ? <LoadingButton variant="contained" disabled={!hasChanges} onClick={save} loading={saving}>Lagre</LoadingButton>
                    : <Button variant="contained" onClick={() => setEdit(true)}>Rediger</Button>}
                  { edit && <Button variant="outlined" onClick={cancel}>Avbryt</Button>}
                  <Button variant="outlined" onClick={close} disabled={edit}>Lukk</Button>
                </Box>
              </Box>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
