import { useMemo } from 'react';
import { Box, CircularProgress, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Divider } from './partials/Divider';
import { Assignment } from '../../../core/types/assignment';
import { ContainerLoad } from '../../../core/types/utilityTypes';
import { ContainerItem } from './partials/ContainerItem';
import { useIsType } from '../../../core/hooks/useIsType';
import {
  usePutAssignmentContainerMutation,
  usePutAssignmentContainerTwoMutation,
  usePutAssignmentExternalContainerMutation,
  usePutContainerWasteTypeMutation,
} from '../../../core/redux/transport';

export const ContainerSelector = ({
  assignment,
  isLoading = false,
}: {
  assignment: Assignment;
  isLoading?: boolean;
}) => {
  /** Order type for assignment */
  const { id } = assignment;
  const [putContainer, { isLoading: putContainerLoading }] = usePutAssignmentContainerMutation();
  const [putContainerTwo, { isLoading: putContainerTwoLoading }] = usePutAssignmentContainerTwoMutation();
  const [putContainerWasteType, { isLoading: putContainerWasteTypeLoading }] = usePutContainerWasteTypeMutation();
  const [putExternalContainer, { isLoading: putExternalContainerLoading }] = usePutAssignmentExternalContainerMutation();

  const isPutting = putContainerLoading || putContainerTwoLoading || putContainerWasteTypeLoading || putExternalContainerLoading;

  /** All containers with the assignment's subcategory */
  const containers = useMemo(() => assignment.loads.map((l): ContainerLoad => ({
    id: `${l.id}`,
    label: l.containerType || '',
    container: l.actualContainer?.internalNumber
      || l.actualContainerInternalNumber
      || l.actualContainerExternal
      || null,
    containerTwo: l.actualContainerTwo?.internalNumber
      || l.actualContainerTwoInternalNumber
      || null,
    default: l.containerExternal || l.container?.internalNumber || l.containerInternalNumber || null,
    containerType: l.containerType || l.container?.subCategoryName || null,
    massType: l.type?.name || null,
    wasteType: l.wasteType || null,
    subCategory: useIsType(assignment.order?.type)('ContainerEmptying') && useIsType(assignment.type)('ContainerDeliver')
      ? l.containerSubcategory || null
      : l.container?.subCategoryName
      || l.containerSubcategory
      || assignment.containerSubcategory
      || null,
  })), [assignment]);

  const disabledContainers = useMemo(() => (
    containers
      .filter((c) => (c.container || c.default))
      .map((c) => (c.container || c.default) as string)
  ), [containers]);

  if (assignment.status === 'UnderPlanning') return null;

  if (!useIsType(assignment.type)('Container')) return null;

  const orderLoadId = (c: ContainerLoad) => assignment.order?.loads.find((l) => (l.containerInternalNumber
    ? l.containerInternalNumber === c.default
    : l.containerExternal === c.default))?.id || 0;

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        background: 'rgba(255,255,0,0.04)',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'space-between' }}>
        <Typography sx={{
          color: 'rgba(0, 0, 0, 0.87)',
          margin: 0,
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: 1.5,
          letterSpacing: '0.00938em',
        }}
        >
          Sjåførvisning
        </Typography>

        {isPutting && (
          <CircularProgress size={26} />
        )}

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {containers.map((c, i) => (
          <Box key={c.id} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {i > 0 && <Divider />}
            <ContainerItem
              container={c}
              assignment={assignment}
              isLoading={isLoading}
              disabledContainers={disabledContainers}
              onWasteChange={(v) => putContainerWasteType({
                id, loadId: parseInt(c.id, 10), wasteType: `${v}`,
              })}
              onContainerChange={(v) => putContainer({
                id, loadId: parseInt(c.id, 10), orderLoadId: orderLoadId(c), containerNumber: `${v}`,
              })}
              onContainerTwoChange={(v) => putContainerTwo({ id, loadId: parseInt(c.id, 10), containerNumber: `${v}` })}
              onExternalContainerChange={(v) => putExternalContainer({
                id, loadId: parseInt(c.id, 10), orderLoadId: orderLoadId(c), containerExternal: `${v}`,
              })}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
