/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Order } from '../types/order';

const stateName = 'orderFilter';

export type ShowPeriod = 'Future' | 'Last7Days' | 'Last14Days';

interface SelectItem {
  id: number | string;
  label: string;
}

interface OrderFilter {
  projects: SelectItem[];
  vehicleTypes: SelectItem[];
  orderTypes: SelectItem[];
  loadTypes: SelectItem[];
  showPeriod: ShowPeriod;
  showPlanned: boolean;
  showEarlyDelivery: boolean;
}

const initialState: OrderFilter = {
  projects: [],
  vehicleTypes: [],
  orderTypes: [],
  loadTypes: [],
  showPeriod: 'Future',
  showPlanned: false,
  showEarlyDelivery: false,
};

const orderFilterSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<SelectItem[]>) {
      state.projects = action.payload;
    },
    setVehicleTypes(state, action: PayloadAction<SelectItem[]>) {
      state.vehicleTypes = action.payload;
    },
    setOrderTypes(state, action: PayloadAction<SelectItem[]>) {
      state.orderTypes = action.payload;
    },
    setLoadTypes(state, action: PayloadAction<SelectItem[]>) {
      state.loadTypes = action.payload;
    },
    setShowPlanned(state, action: PayloadAction<boolean>) {
      state.showPlanned = action.payload;
    },
    setShowEarlyDelivery(state, action: PayloadAction<boolean>) {
      state.showEarlyDelivery = action.payload;
    },
    setShowPeriod(state, action: PayloadAction<ShowPeriod>) {
      state.showPeriod = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const useIsFiltersActive = (): boolean => {
  const {
    projects, vehicleTypes, loadTypes, orderTypes,
  } = useSelector(
    (state: { [stateName]: OrderFilter }) => state[stateName],
  );
  return !!(projects.length || vehicleTypes.length || loadTypes.length || orderTypes.length);
};

/** Get order type filter for given selected order types */
export const getOrderTypeFilter = (orderTypes: SelectItem[]) => {
  const showContainer = orderTypes.some((o) => o.id === 'Container');
  const showMass = orderTypes.some((o) => o.id === 'Mass');

  // ~~~~~~~
  // MassInternal counts as a mass order if the vehicle type is not HookLift.
  // But if it is, it counts as a Container order. This is because MassInternal
  // serves a double purpose.
  // ~~~~~~~

  const orderTypeFilter = (f: Order) => {
    if (showMass && showContainer) return orderTypes.some((t) => f.type.includes(`${t.id}`));
    // If we have an exact match, always let it through
    if (orderTypes.some((o) => o.id === f.type)) return true;
    if (showMass) {
      return orderTypes.some((t) => f.type.includes(`${t.id}`)) && f.vehicle !== 'HookLift';
    }
    if (showContainer) {
      return orderTypes.some((t) => f.type.includes(`${t.id}`)) || (f.type === 'MassInternal' && f.vehicle === 'HookLift');
    }
    return false;
  };

  return orderTypeFilter;
};

export const useFilteredOrders = (orders: Order[]) => {
  const {
    projects, vehicleTypes, loadTypes, orderTypes, showPeriod, showPlanned, showEarlyDelivery,
  } = useSelector(
    (state: { [stateName]: OrderFilter }) => state[stateName],
  );
  const filteredOrders = useMemo(() => {
    let fo = [
      ...orders.filter((o) => o.status !== 'Cancelled')
        .filter(
          (o) => (showPeriod !== 'Future'
          || o.status === 'Created'
          || o.status === 'UnderPlanning'
          || o.assignmentStatus === 'InIntermediateStorage'
          || (o.status === 'Planned' && showPlanned)
          || (o.status !== 'Completed' && o.assignmentStatus === 'Undelivered')),
        ).filter((o) => !showEarlyDelivery || o.earlyDelivery === true),
    ];
    if (projects.length) {
      // eslint-disable-next-line max-len
      fo = fo.filter((o) => projects.some((p) => o.toProject?.id === p.id || o.fromProject?.id === p.id));
    }
    if (vehicleTypes.length) {
      fo = fo.filter((f) => vehicleTypes.some((v) => v.id === f.vehicle));
    }
    if (loadTypes.length) {
      fo = fo.filter((f) => loadTypes.some((l) => f.loads.some((fl) => fl.type?.id === l.id)));
    }
    if (orderTypes.length) {
      const orderTypeFilter = getOrderTypeFilter(orderTypes);
      fo = fo.filter(orderTypeFilter);
    }
    return fo;
  }, [orders, projects, vehicleTypes, orderTypes, loadTypes, showPlanned, showEarlyDelivery]);

  return filteredOrders;
};

export const useShowPeriod = (): ShowPeriod => {
  const { showPeriod } = useSelector((state: { [stateName]: OrderFilter }) => state[stateName]);
  return showPeriod;
};

export const useShowPlanned = (): boolean => {
  const { showPlanned } = useSelector((state: { [stateName]: OrderFilter }) => state[stateName]);
  return showPlanned;
};

export const useShowEarlyDelivery = (): boolean => {
  const {
    showEarlyDelivery,
  } = useSelector((state: { [stateName]: OrderFilter }) => state[stateName]);
  return showEarlyDelivery;
};

export const {
  setProjects,
  setVehicleTypes,
  setLoadTypes,
  setOrderTypes,
  setShowPeriod,
  setShowPlanned,
  setShowEarlyDelivery,
  reset,
} = orderFilterSlice.actions;
export default persistReducer({ key: 'orderFilter', storage }, orderFilterSlice.reducer);
