import React, { FC, ReactNode } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import { Unauthenticated } from './Unauthenticated';
import { config } from '../shared/config/transport';

interface PageProps { className?: string, children: ReactNode}

export const Page: FC<PageProps> = ({ children, className }) => (
  <Box className={`page-component ${className}`}>
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: [config.SCOPE] }}
      interactionType={InteractionType.Redirect}
      errorComponent={Unauthenticated}
    >
      {children}
    </MsalAuthenticationTemplate>
  </Box>
);

Page.defaultProps = {
  className: '',
};
