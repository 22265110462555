import React, { FC } from 'react';
import {
  CardActionArea,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import { format } from 'date-fns';
import { SubstituteDriver } from '../../../core/types/substituteDriver';
import { CallLink } from '../../CallLink';

export const SubItem: FC<{
  sub: SubstituteDriver,
  onClick?: (value: number) => void;
}> = ({
  sub,
  onClick = () => null,
}) => (
  <Card
    sx={{ mt: 2 }}
  >
    <CardActionArea onClick={() => onClick(sub.id)}>
      <div className="p8">
        <div className="line-wrapper">
          <div className="left">
            <PersonIcon className="transform-icon" fontSize="medium" /><span className="sub-name">{sub.driver.name}</span>
          </div>
          <span>
            <CallLink phoneNumber={sub.driver.phone} className="sub-number" stopPropagation />
          </span>
        </div>
        <div className="sub-period">
          {format(new Date(sub.start), "dd.MM.yyyy 'kl.' HH:mm")} - {format(new Date(sub.end), "dd.MM.yyyy 'kl.' HH:mm")}
        </div>
      </div>
    </CardActionArea>
  </Card>
);
