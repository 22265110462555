import { EnvironmentName } from './shared/types/enums/environmentName';

export const getEnvironmentName = (env?: string): EnvironmentName => {
  const url = new URL(env || document.baseURI);
  if (url.hostname === 'localhost') return 'local';
  if (url.hostname === 'black-moss-03e038c03.azurestaticapps.net' && url.protocol === 'https:') return 'dev';
  if (url.hostname === 'transport-test.tsmaskin.no' && url.protocol === 'https:') return 'test';
  if (url.hostname === 'planlegger-test.tsmaskin.no' && url.protocol === 'https:') return 'test';
  if (url.hostname === 'white-pebble-02b2a7803.1.azurestaticapps.net' && url.protocol === 'https:') return 'prod';
  if (url.hostname === 'transport.tsmaskin.no' && url.protocol === 'https:') return 'prod';
  return '';
};
