import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  formatISO,
  isValid,
  startOfDay,
} from 'date-fns';
import { toast } from 'react-toastify';
import { useGetRegionsQuery, usePostRegionAssignmentMutation } from '../../core/redux/transport';
import './style.scss';
import { NewVehicleRegion } from '../../core/types/api/newVehicleRegion';
import { useVehicleList } from '../../core/hooks/useVehicleList';

export const CreateRegionAssignment: FC<{
  internalNumber?: string,
  currentDate?: Date,
  onClose?: () => void;
  }> = ({
    internalNumber,
    currentDate,
    onClose = () => null,
  }) => {
    const defaultDay = currentDate ? startOfDay(currentDate) : startOfDay(new Date());
    const [region, setRegion] = useState<number|null>(null);
    const [date, setDate] = useState<Date|null>(defaultDay);

    const { data: vehicles } = useVehicleList();
    const { data: regions, isLoading } = useGetRegionsQuery();
    const [send] = usePostRegionAssignmentMutation();

    const vehicle = useMemo(() => (
      vehicles?.find((v) => v.internalNumber === internalNumber)
    ), [vehicles, internalNumber]);

    const valid = useMemo(() => {
      if (date === null) return false;
      if (!isValid(date)) return false;
      if (!internalNumber) return false;
      if (!region) return false;
      return true;
    }, [date, region, internalNumber]);

    const submit = () => {
      if (!valid) return;
      if (!region) return;
      if (!date) return;
      if (!internalNumber) return;
      const body: NewVehicleRegion = {
        start: formatISO(date),
        regionId: region,
      };
      send({ id: internalNumber, body }).unwrap().then(() => {
        toast.success('Regionsendring lagret');
        onClose();
      });
    };

    const dateError = useMemo(() => {
      if (!date) return 'Velg en dato';
      if (!isValid(date)) return 'Ugyldig dato';
      return null;
    }, [date]);

    return (
      <div className="create-region-assignment-component">
        <Typography sx={{ pb: 3 }}>
          Velg ny region for kjøretøy {vehicle?.vehicleRegistrationPlateNumber}
        </Typography>
        <FormControl fullWidth error={!region} className="form" sx={{ gap: 0, mb: 4 }}>
          <FormLabel id="region-picker-radio">Ny region</FormLabel>
          <RadioGroup
            aria-labelledby="region-picker-radio"
            name="radio-buttons-group"
            row
            value={region}
            onChange={(e) => setRegion(Number(e.target.value))}
          >
            {(regions || []).map((r) => (
              <FormControlLabel
                key={r.id}
                value={r.referenceId}
                control={<Radio />}
                label={r.name}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{!region ? 'Velg en region' : null}</FormHelperText>
        </FormControl>

        <FormControl fullWidth className="form">
          <DatePicker
            label="Gjelder fra"
            value={date}
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            onChange={(d) => setDate(d)}
            renderInput={(params: any) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} error={!!dateError} helperText={dateError} />
            )}
          />
        </FormControl>
        <div className="send-buttons">
          <Button variant="outlined" onClick={onClose}>Avbryt</Button>
          <Button
            disabled={!valid || isLoading}
            variant="contained"
            onClick={submit}
          >
            {isLoading
              ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : 'Lagre'}
          </Button>
        </div>
      </div>
    );
  };
