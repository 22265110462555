import React, { FC } from 'react';
import {
  Skeleton,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { VehicleStatusIcon } from '../VehicleStatusIcon';
import { SubstituteAssignment } from '../../core/types/substituteAssignment';
import './style.scss';
import { RegistrationPlate } from '../RegistrationPlate';

interface Contact {
  fullName: string,
  mobilePhoneWork: string,
}

export const VehicleItem: FC<{
  type?: string,
  registration?: string,
  driver?: Contact,
  substitutes?: SubstituteAssignment[],
  loading?: boolean,
  status?: boolean,
}> = ({
  type = '',
  registration = '',
  driver = null,
  substitutes = [],
  loading = false,
  status,
}) => {
  if (loading) {
    return (
      <div className="vehicle-item-component loading">
        <Skeleton variant="text" height={25} width="50%" />
        <Skeleton variant="text" width="75%" />
      </div>
    );
  }

  return (
    <div className="vehicle-item-component">
      {!driver ? null : (
        <div className={substitutes.length <= 0 ? 'vehicle-line-wrapper flexparent bold-text' : 'vehicle-line-wrapper flexparent'}>
          <PersonIcon fontSize="small" />
          <span className="vehicle-driver-name shorten-name">
            {driver.fullName}
          </span>
          {driver.fullName && driver.mobilePhoneWork ? <span className="flexchild" /> : null}
          <span className="vehicle-driver-number">
            <a className="number-link" href={`tel:${driver.mobilePhoneWork}`}>
              {driver.mobilePhoneWork}
            </a>
          </span>
        </div>
      )}
      {
        [...substitutes].sort((a, b) => a.start.localeCompare(b.start)).map((s) => (
          <div className="vehicle-line-wrapper flexparent bold-text inline-substitute">
            <GroupIcon fontSize="small" />
            <span className="vehicle-driver-name shorten-name">
              {s.name}
            </span>
            {s.name && s.phone ? <span className="flexchild" /> : null}
            <span className="vehicle-driver-number">
              <a className="number-link" href={`tel:${s.phone}`}>
                {s.phone}
              </a>
            </span>
          </div>
        ))
      }
      <div className="vehicle-line-wrapper flexcenter right-aligned-vehicle">
        <span className="flexcenter flex-gap-10">
          {!registration ? null : <RegistrationPlate number={registration} />}
          {status !== undefined && <VehicleStatusIcon isActive={status} />}
        </span>
        <span className="vehicle-font">{type}</span>
      </div>
    </div>
  );
};
