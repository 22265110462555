import CircleIcon from '@mui/icons-material/Circle';
import './style.scss';
import { Box } from '@mui/material';
import { getOrderStatusType } from '../../core/helpers/translate';
import { getOrderStatusColor } from '../../core/helpers/functions';
import { OrderStatus } from '../../core/types/enums/orderStatus';

export const OrderStatusIcon = ({
  currStatus,
  iconOnly = false,
  size = 'medium',
}: {
  currStatus?: OrderStatus | undefined,
  iconOnly?: boolean,
  size?: 'small' | 'medium',
}) => {
  const statusType = getOrderStatusType(currStatus);
  const statusColor = getOrderStatusColor(currStatus);
  if (iconOnly) {
    return (
      <div className="align-icon" title={statusType}>
        <CircleIcon fontSize={size} className={statusColor} />
      </div>
    );
  }
  return (
    <Box className="align-icon" sx={{ fontSize: size }}>
      <CircleIcon fontSize={size} className={statusColor} />
      {statusType}
    </Box>
  );
};
