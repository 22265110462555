/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactPerson } from '../../components/ContactPerson';
import { SelectContainer } from '../types/SelectContainer';
import { DeliveryDate } from '../types/utilityTypes';

interface DeliveryTime {
  type: number;
  time: string;
}

interface ContainerOrderState {
  projectTo: number | null;
  projectFrom: number | null;

  containers: SelectContainer[];
  deliverContainers: SelectContainer[];

  deliveryTimeType: DeliveryTime;
  startTime: Date | null;
  endTime: Date | null;

  userDefinedDeliveryDate: Date | null;

  deliveryDate: DeliveryDate;

  isEarlyDelivery: boolean;
  isImportantDeadLine: boolean;
  timerCounter: number | null;

  lastCreatedOrderId: number;

  specifyContactPerson: boolean;
  internalContactPerson: number | null;
  localInternalContactPerson: string | null;
  externalContactPerson: string | null;
  externalContactNumber: number | null;
  orderComment: string;

  contactPerson: ContactPerson | null;
}

const initialState: ContainerOrderState = {
  projectFrom: null,
  projectTo: null,

  containers: [{
    id: 0, isExternal: false, containerInternalNumber: undefined, containerExternal: undefined, type: undefined, amount: 1, mass: undefined,
  }],

  deliverContainers: [{
    id: 0, isExternal: false, containerInternalNumber: undefined, containerExternal: undefined, type: undefined, amount: 1, mass: { id: 0, label: '(tom)' },
  }],

  deliveryTimeType: { type: 1, time: 'I løpet av dagen' },
  startTime: null,
  endTime: null,

  userDefinedDeliveryDate: null,
  deliveryDate: { type: 0, day: null },

  isEarlyDelivery: false,
  isImportantDeadLine: false,
  lastCreatedOrderId: 0,
  timerCounter: 0,

  specifyContactPerson: false,
  internalContactPerson: null,
  localInternalContactPerson: null,
  externalContactPerson: null,
  externalContactNumber: null,

  orderComment: '',

  contactPerson: null,
};

const containerOrderSlice = createSlice({
  name: 'containerOrderState',
  initialState,
  reducers: {
    setProjectFrom(state, action: PayloadAction<number | null>) {
      state.projectFrom = action.payload;
    },
    setProjectTo(state, action: PayloadAction<number | null>) {
      state.projectTo = action.payload;
    },
    setContainers(state, action: PayloadAction<SelectContainer[]>) {
      state.containers = action.payload;
    },
    setDeliverContainers(state, action: PayloadAction<SelectContainer[]>) {
      state.deliverContainers = action.payload;
    },
    setDeliveryTimeType(state, action: PayloadAction<DeliveryTime>) {
      state.deliveryTimeType = action.payload;
    },
    setStartTime(state, action: PayloadAction<Date | null>) {
      state.startTime = action.payload;
    },
    setEndTime(state, action: PayloadAction<Date | null>) {
      state.endTime = action.payload;
    },
    setUserDefinedDeliveryDate(state, action: PayloadAction<Date | null>) {
      state.userDefinedDeliveryDate = action.payload;
    },
    setDeliveryDate(state, action: PayloadAction<DeliveryDate>) {
      state.deliveryDate = action.payload;
    },
    setIsEarlyDelivery(state, action: PayloadAction<boolean>) {
      state.isEarlyDelivery = action.payload;
    },
    setIsImportantDeadline(state, action: PayloadAction<boolean>) {
      state.isImportantDeadLine = action.payload;
    },
    setLastCreatedOrderId(state, action: PayloadAction<number>) {
      state.lastCreatedOrderId = action.payload;
    },
    setTimerCounter(state, action: PayloadAction<number | null>) {
      state.timerCounter = action.payload;
    },
    setSpecifyContactPerson(state, action: PayloadAction<boolean>) {
      state.specifyContactPerson = action.payload;
    },
    setInternalContactPerson(state, action: PayloadAction<number | null>) {
      state.internalContactPerson = action.payload;
    },
    setLocalInternalContactPerson(state, action: PayloadAction<string | null>) {
      state.localInternalContactPerson = action.payload;
    },
    setExternalContactPerson(state, action: PayloadAction<string | null>) {
      state.externalContactPerson = action.payload;
    },
    setExternalContactNumber(state, action: PayloadAction<number | null>) {
      state.externalContactNumber = action.payload;
    },
    setOrderComment(state, action: PayloadAction<string>) {
      state.orderComment = action.payload;
    },
    setContactPerson(state, action: PayloadAction<ContactPerson | null>) {
      state.contactPerson = action.payload;
    },
    resetStates(state) {
      Object.assign(state, { ...initialState, lastCreatedOrderId: state.lastCreatedOrderId });
    },
  },
});

export const {
  setProjectFrom,
  setProjectTo,
  setContainers,
  setDeliverContainers,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setUserDefinedDeliveryDate,
  setDeliveryDate,
  setIsEarlyDelivery,
  setIsImportantDeadline,
  setLastCreatedOrderId,
  setTimerCounter,
  setSpecifyContactPerson,
  setInternalContactPerson,
  setLocalInternalContactPerson,
  setExternalContactPerson,
  setExternalContactNumber,
  setOrderComment,
  setContactPerson,
  resetStates,
} = containerOrderSlice.actions;
export default containerOrderSlice.reducer;
