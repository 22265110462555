import React, { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import './style.scss';

export const VehicleStatusIcon: FC<{
  isActive: boolean,
}> = ({
  isActive,
}) => {
  const statusType = isActive ? 'Aktiv' : 'Inaktiv';
  const statusColor = isActive ? 'active-color' : 'inactive-color';
  return (
    <div className="align-icon">
      <CircleIcon className={statusColor} />
      {statusType}
    </div>
  );
};
