import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Header } from '../../components/Header';
import { Page } from '../../components/Page';
import { useGetAllContainersQuery, useGetContainerSubcategoriesQuery } from '../../core/redux/transport';
import './style.scss';
import { RegistrationPlate } from '../../components/RegistrationPlate';
import { ContainerDetailsModal } from './partials/ContainerDetailsModal';
import { Container } from '../../core/types/container';
import { Project } from '../../core/types/project';

type FilterItem = {
  id: string | number;
  label: string;
}

export const ContainersPage: FC = () => {
  const { data: allContainers } = useGetAllContainersQuery({ includeAll: true }, { pollingInterval: 30000 });
  const [term, setTerm] = useState<string>('');
  const [showDetailsFor, setShowDetailsFor] = useState<Container | null>(null);
  const [projectFilter, setProjectFilter] = useState<FilterItem[]>([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState<FilterItem[]>([]);
  const [statusFilter, setStatusFilter] = useState<FilterItem[]>([{ id: 'Tilgjengelig', label: 'Tilgjengelig' }]);

  const applyFilter = (value:string|undefined, filter: string): boolean => value !== undefined
    && value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;

  // Refresh displayed container (if any)
  useEffect(() => {
    if (showDetailsFor === null) return;
    if (allContainers === undefined) return;

    const newValue = allContainers.find((c) => c.internalNumber === showDetailsFor.internalNumber);
    if (newValue) setShowDetailsFor(newValue);
  }, [allContainers]);

  type FilterFn = (c: Container) => boolean;
  const filter: FilterFn = useMemo(() => {
    const filterFns: FilterFn[] = [];
    if (projectFilter.length > 0) {
      const filterValues = new Set(projectFilter.map((p) => p.id));
      filterFns.push((c: Container) => filterValues.has(c.project?.id || -1));
    }

    if (subCategoryFilter.length > 0) {
      const filterValues = new Set(subCategoryFilter.map((p) => p.id));
      filterFns.push((c: Container) => filterValues.has(c.subCategoryName));
    }

    if (statusFilter.length > 0) {
      const filterValues = new Set(statusFilter.map((p) => p.id));
      filterFns.push((c: Container) => filterValues.has(c.status));
    }

    return (c) => filterFns.every((predicate) => predicate(c));
  }, [projectFilter, subCategoryFilter, statusFilter]);

  const containers = useMemo(
    () => allContainers?.filter(filter)
      .filter((c) => !term
        || applyFilter(c.internalNumber, term)
        || applyFilter(c.subCategoryName, term)
        || applyFilter(c.manufacturerName, term)
        || applyFilter(c.modelName, term)
        || applyFilter(c.serialNumber, term)
        || applyFilter(c.status, term)
        || applyFilter(c.project?.id.toString(), term)
        || applyFilter(c.project?.projectName, term)),
    [allContainers, term, filter],
  );

  const projects = useMemo(
    () => allContainers?.map((c) => c.project)
      .filter((p) => p !== undefined)
      .filter((p, i, arr) => arr.findIndex((x) => x?.id === p?.id) === i) as Project[] || [],
    [allContainers],
  );

  const statusList = useMemo(
    () => allContainers?.map((c) => c.status)
      .filter((s, i, arr) => arr.indexOf(s) === i) || [],
    [allContainers],
  );

  const { data: subCategories } = useGetContainerSubcategoriesQuery();

  return (
    <Page className="order-page">
      <Header>Container oversikt</Header>
      <div className="order-page-wrapper">
        {allContainers
          ? (
            <>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h5">Søk</Typography>

                <Box sx={{ display: 'flex' }}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel htmlFor="rental-vehicle-search">Søk</InputLabel>
                    <OutlinedInput
                      id="rental-vehicle-search"
                      value={term}
                      fullWidth
                      onChange={(e) => setTerm(e.target.value)}
                      label="Søk"
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Fjern søket"
                            onClick={() => setTerm('')}
                            onMouseDown={() => setTerm('')}
                            edge="end"
                          >
                            {term === '' ? null : <BackspaceIcon /> }
                          </IconButton>
                        </InputAdornment>
                    )}
                    />
                  </FormControl>
                </Box>

                <Typography variant="h5">Filter</Typography>
                <Box display="flex" gap={1}>
                  <Autocomplete
                    fullWidth
                    multiple
                    value={projectFilter}
                    id="project"
                    size="small"
                    disableCloseOnSelect
                    onChange={(e, ns) => setProjectFilter(ns)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={projects.map((m) => ({ id: m.id, label: `${m.id} - ${m.projectName}` }))}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        label="Prosjekt"
                        size="small"
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    multiple
                    value={subCategoryFilter}
                    id="subcategory"
                    disableCloseOnSelect
                    size="small"
                    onChange={(e, ns) => setSubCategoryFilter(ns)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={subCategories?.map((s) => ({ id: s, label: s })) || []}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        label="Underkategori"
                        size="small"
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    multiple
                    value={statusFilter}
                    id="status"
                    disableCloseOnSelect
                    size="small"
                    onChange={(e, ns) => setStatusFilter(ns)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statusList?.map((s) => ({ id: s, label: s })) || []}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        label="Status"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Internnummer</TableCell>
                      <TableCell component="th">Underkategori</TableCell>
                      <TableCell component="th">Modell</TableCell>
                      <TableCell component="th">Serienummer</TableCell>
                      <TableCell component="th">Status</TableCell>
                      <TableCell component="th">Prosjekt</TableCell>
                      <TableCell component="th">Bestilling</TableCell>
                      <TableCell component="th">På bil</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {containers?.map((c) => (
                      <TableRow
                        key={c.internalNumber}
                        className="containerRow"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setShowDetailsFor(c)}
                        hover
                      >
                        <TableCell>{c.internalNumber}</TableCell>
                        <TableCell>{c.subCategoryName}</TableCell>
                        <TableCell>{c.modelName}</TableCell>
                        <TableCell>{c.serialNumber}</TableCell>
                        <TableCell>{c.status}</TableCell>
                        <TableCell>{c.project ? `${c.project.id} ${c.project.projectName}` : '' }</TableCell>
                        <TableCell>{c.orderId ? <a href={`/bestilling/${c.orderId}`} target="_blank" rel="noreferrer">Bestilling {c.orderId}</a> : ''}</TableCell>
                        <TableCell>{c.assignment !== undefined
                          ? (
                            <>
                              <RegistrationPlate number={c.assignment.vehicle.vehicleRegistrationPlateNumber} /> <br />
                              (<a href={`/bestilling/${c.assignment.order?.id}`} target="_blank" rel="noreferrer">Bestilling {c.assignment.order?.id}</a> / Oppdrag #{c.assignment.id})
                            </>
                          )
                          : '' }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <CircularProgress
              size={36}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) }
      </div>
      { showDetailsFor && <ContainerDetailsModal container={showDetailsFor} onClose={() => setShowDetailsFor(null)} /> }
    </Page>
  );
};
