import { Params, useParams } from 'react-router-dom';

/**
 * Redirect to given path. Also copies path parameters
 */
export const ExternalRedirect = <T extends string>({ to }: {to: string |((params: Params<T>) => string)}) => {
  const params = useParams<T>();
  if (typeof to === 'string') window.location.replace(to);
  else if (typeof params !== 'string') {
    window.location.replace(to(params as Params<T>));
  }
  return null;
};
