import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ButtonGroup,
  Button,
  Box,
} from '@mui/material';
import { updateDuration } from '../../core/helpers/functions';

export const DurationPicker = ({
  title = 'Varighet',
  value = 45,
  allDay = false,
  disabled = false,
  onChange = () => null,
}: {
  title?: string,
  value?: number,
  allDay?: boolean,
  disabled?: boolean,
  onChange?: (v: number) => void,
}) => {
  const getValidDuration = (d: number) => {
    let rounded = Math.round((d || 0) / 15) * 15;
    while (rounded <= 0) {
      rounded += 1440; // 24 hours in minutes (60 * 24)
    }
    return Math.min(rounded, 1000000);
  };

  const validate = () => {
    onChange(getValidDuration(value));
  };

  return (
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor={`${title}input`}>{title}</InputLabel>
        <OutlinedInput
          id={`${title}-input`}
          label={title}
          type="number"
          endAdornment={<InputAdornment position="end">minutter</InputAdornment>}
          value={allDay ? 480 : (Math.abs(value) || '')}
          disabled={disabled}
          onChange={(e) => onChange(updateDuration(e.target.value))}
          onBlur={validate}
          inputProps={{ type: 'number', step: 15, min: 15 }}
          size="small"
          fullWidth
        />
      </FormControl>
      <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>
        <Button
          disabled={disabled}
          variant={value === 45 && !allDay ? 'contained' : 'outlined'}
          onClick={() => onChange(45)}
        >
          45 min
        </Button>
        <Button
          disabled={disabled}
          variant={value === 60 && !allDay ? 'contained' : 'outlined'}
          onClick={() => onChange(60)}
        >
          60 min
        </Button>
        <Button
          disabled={disabled}
          variant={value === 240 && !allDay ? 'contained' : 'outlined'}
          onClick={() => onChange(240)}
        >
          4 timer
        </Button>
      </ButtonGroup>
    </Box>
  );
};
