import { ButtonGroup, Button } from '@mui/material';
import { forI } from '../../core/helpers/functions';

export const ButtonGroupPicker = ({
  min = 1,
  max = 2,
  value = 1,
  minSelectable = min,
  maxSelectable = max,
  forceDisable = false,
  onChange = () => null,
} : {
  min?: number,
  max?: number,
  value: number,
  minSelectable?: number,
  maxSelectable?: number,
  forceDisable?: boolean,
  onChange: (n: number) => void,
}) => (
  <ButtonGroup fullWidth={false}>
    {forI((max - min) + 1, (i) => {
      const num = min + i;
      const disabled = num < minSelectable || num > maxSelectable;
      return (
        <Button
          key={num}
          variant={value === num ? 'contained' : 'outlined'}
          size="small"
          onClick={() => onChange(num)}
          disabled={forceDisable || disabled}
        >
          {num}
        </Button>
      );
    })}
  </ButtonGroup>
);
