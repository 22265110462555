import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { SubItem } from './SubItem';
import { SubstituteDriver } from '../../../core/types/substituteDriver';

export const SubList: FC<{
  subs: SubstituteDriver[] | undefined,
  isLoading: boolean,
  onClick?: (value: number) => void;
}> = ({
  subs,
  isLoading,
  onClick = () => null,
}) => (
  <div>
    {!subs?.length
      ? (
        <div className="subs-empty">{isLoading ? (
          <CircularProgress
            size={50}
            sx={{
              position: 'absolute',
              top: '65%',
              left: '50%',
              marginTop: '-25px',
              marginLeft: '-25px',
              zIndex: 1000,
            }}
          />
        ) : 'Ingen vikarer'}
        </div>
      ) : (
        <>
          {subs.map((s) => (
            <SubItem
              key={s.id}
              sub={s}
              onClick={onClick}
            />
          ))}
          {isLoading && (
            <CircularProgress
              size={50}
              sx={{
                position: 'absolute',
                top: '70%',
                left: '50%',
                marginTop: '-25px',
                marginLeft: '-25px',
                zIndex: 1000,
              }}
            />
          )}
        </>
      )}
  </div>
);
