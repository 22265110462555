import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Skeleton,
  TextField,
} from '@mui/material';
import './style.scss';
import { Machine } from '../../shared/types/machine';
import { Checkbox } from '../../shared/components/checkbox';
import { getMachineLabel } from '../../core/helpers/labelling';
import { useGetMachinesQuery } from '../../core/redux/transport';

export type MachinePickerValue = {
  internal?: string | null,
  external?: string | null,
}

export const MachinePicker = ({
  value = { internal: null, external: null },
  disabled = false,
  onChange = (val) => val,
  label = 'Søk etter maskin',
  size = 'medium',
  required = false,
  showLastUsed = true,
}: {
  /** When value is null or a valid machine ID the value is considered an
   * internal machine, otherwise it is considered an external machine */
  value?: MachinePickerValue,
  onChange?: (newValue: MachinePickerValue) => void
  label?: string,
  size?: 'medium' | 'small',
  disabled?: boolean,
  required?: boolean,
  showLastUsed?: boolean,
}) => {
  const { data: rawMachines = [], isLoading: machinesLoading } = useGetMachinesQuery();

  const options = useMemo((): Array<Machine & {lastUsed?: string}> => {
    let ps = [...rawMachines];

    // sort by ID
    ps.sort((a, b) => {
      const an = parseInt(a.internalNumber, 10) || Infinity;
      const bn = parseInt(b.internalNumber, 10) || Infinity;
      return an - bn;
    });

    // Map in the lastUsed string
    ps = ps.map((p): Machine & {label: string, lastUsed?: string} => ({
      ...p, label: getMachineLabel(p),
    }));

    return ps;
  }, [rawMachines]);

  const getCustom = () => {
    if (value.internal === '') return true;
    if (value.internal === undefined) return true;
    if (options.some((o) => o.internalNumber === value.internal)) return false;
    return true;
  };

  const [custom, setCustom] = useState<boolean>(getCustom);

  useEffect(() => {
    setCustom(getCustom());
  }, [rawMachines]);

  const machine = typeof value.internal === 'string'
    ? rawMachines.find((p) => p.internalNumber === value.internal)
    : value.internal;

  if (machinesLoading) {
    return (
      <Skeleton
        width="100%"
        height={`${size === 'medium' ? 56 : 40}px`}
        variant="rectangular"
      />
    );
  }

  const fixVal = machine
    ? { ...machine, label: getMachineLabel(machine) }
    : null;

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      alignItems: 'center',
    }}
    >
      <Box sx={{ flex: 1 }} display={custom ? 'none' : undefined}>
        <Autocomplete
          disablePortal
          value={fixVal}
          disabled={disabled}
          isOptionEqualToValue={(a, b) => `${a.internalNumber}` === `${b.internalNumber}`}
          clearOnBlur
          clearOnEscape
          renderOption={(p, v) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...p} className={`${p.className} option`}>
              <span className="label">{getMachineLabel(v)}</span>
              {showLastUsed && v.lastUsed && (
                <span className="last-used">{`Sist brukt ${v.lastUsed}`}</span>
              )}
            </li>
          )}
          onChange={(e, newValue) => {
            if (typeof newValue === 'string') {
              onChange(newValue);
            }
            onChange({ internal: newValue?.internalNumber || null });
          }}
          options={options}
          fullWidth
          renderInput={(params) => (
            <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              className="avoid-height-shift"
              label={label}
              helperText={required && value === null ? 'Velg et prosjekt' : null}
              error={required && value === null}
            />
          )}
          size={size}
        />
      </Box>
      <Box sx={{ flex: 1 }} display={custom ? undefined : 'none'}>
        <TextField
          className="avoid-height-shift"
          label="Maskin navn"
          size={size}
          value={value.external || ''}
          onChange={(e) => onChange({ external: e.target.value })}
          fullWidth
        >Maskin
        </TextField>
      </Box>
      <Box sx={{ pt: size === 'small' ? 0 : 2 }}>
        <Checkbox checked={custom} onChange={setCustom} label="Ekstern" />
      </Box>
    </Box>
  );
};
