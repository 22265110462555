import {
  Box,
  Checkbox,
  SxProps,
  Typography,
} from '@mui/material';
import { toSelectItem, countProperty } from '../../../core/helpers/functions';
import { useGetContainerSubcategoriesQuery } from '../../../core/redux/transport';
import { LoadItem, Load } from '../../../core/types/load';
import { DataField } from '../../../shared/components/dataField';
import { SearchSelect } from '../../../shared/components/searchSelect';
import { ButtonGroupPicker } from '../../ButtonGroupPicker';
import { MAX_CONTAINERS } from '../../../shared/constants';

const notSelectedStyle: SxProps = {
  color: '#888',
};

export const ContainerDeliverPicker = <L extends LoadItem|Load>({
  disabled = false,
  disableSelection = false,
  value,
  onChange,
}: {
  disabled?: boolean,
  disableSelection?: boolean,
  value: L[],
  onChange: (v: L[]) => void,
}) => {
  const disabledStyle: SxProps = {
    ...notSelectedStyle,
    textDecoration: disabled ? 'line-through' : '',
  };

  const { data: containerSubcategories } = useGetContainerSubcategoriesQuery();
  const containerSubCatOptions = containerSubcategories
    ? containerSubcategories.map((c) => ({ id: c, label: c }))
    : [];

  const setValue = (id: number|undefined, obj: Partial<LoadItem&Load>) => {
    if (!id) return;
    onChange(value.map((v) => (v.id === id ? { ...v, ...obj } : v)));
  };

  const setAmount = (id: number|undefined, amount: number) => {
    setValue(id, { amount });
  };

  const toggleAmount = (id: number|undefined) => {
    if (!id) return;
    const newAmount = (value.find((v) => v.id === id)?.amount || 0) > 0
      ? 0
      : 1;
    setAmount(id, newAmount);
  };

  const setContainerSubcat = (id: number|undefined, containerSubcat: string|undefined) => {
    setValue(id, { containerSubcategory: containerSubcat });
  };

  /** Total amount of containers */
  const total = countProperty(value, (v) => v.amount);

  /** Remaining containers to assign - always zero or higher */
  const remaining = Math.max(0, MAX_CONTAINERS - total);

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      {value.map((l) => {
        const unselectable = !l.amount && remaining <= 0;
        let style = {};
        if (unselectable) style = disabledStyle;
        else if (disabled) style = notSelectedStyle;
        else if (l.amount <= 0) style = notSelectedStyle;

        return (
          <Box
            key={l.id}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'stretch',
              flexDirection: 'column',
            }}
          >
            <Box sx={{
              flex: 1,
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
            >
              <Box>
                <Checkbox
                  sx={{ padding: 0, margin: 0 }}
                  checked={l.amount > 0}
                  onChange={() => toggleAmount(l?.id)}
                  disabled={disabled || unselectable || disableSelection}
                />
              </Box>
              <Box flex={1}>
                {l.containerType ? (
                  <DataField label="Containertype">
                    <Typography sx={style}>{l.containerType}</Typography>
                  </DataField>
                ) : (
                  <DataField label="Underkategori">
                    <Typography sx={style}>{l.containerSubcategory || l.container?.subCategoryName}</Typography>
                  </DataField>
                )}
              </Box>
              <Box flex={1}>
                <DataField label="Massetype">
                  <Typography sx={style}>
                    {l.type?.name || '(tom)'}
                  </Typography>
                </DataField>
              </Box>

              <DataField label="Antall">
                <ButtonGroupPicker
                  value={l.amount}
                  forceDisable={disabled || disableSelection}
                  onChange={(v) => setAmount(l.id, v)}
                  max={MAX_CONTAINERS}
                  maxSelectable={l.amount <= 0 ? 0 : l.amount + remaining}
                />
              </DataField>
            </Box>
            {l.amount > 0 && (
              <Box flex={1}>
                <SearchSelect
                  value={toSelectItem(l.containerSubcategory)}
                  onChange={(v) => setContainerSubcat(l.id, v?.label)}
                  label="Underkategori"
                  size="small"
                  required
                  errorLabel="Velg en underkategori"
                  disabled={disabled}
                >
                  {containerSubCatOptions}
                </SearchSelect>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
